/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnagrafeDTO } from './AnagrafeDTO';
import {
    AnagrafeDTOFromJSON,
    AnagrafeDTOFromJSONTyped,
    AnagrafeDTOToJSON,
} from './AnagrafeDTO';
import type { CaricoDTO } from './CaricoDTO';
import {
    CaricoDTOFromJSON,
    CaricoDTOFromJSONTyped,
    CaricoDTOToJSON,
} from './CaricoDTO';
import type { CicloDTO } from './CicloDTO';
import {
    CicloDTOFromJSON,
    CicloDTOFromJSONTyped,
    CicloDTOToJSON,
} from './CicloDTO';

/**
 * 
 * @export
 * @interface DocumentoMacelloDTO
 */
export interface DocumentoMacelloDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idCiclo?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idAnagrafeMittente?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idAnagrafeDestinatario?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoMacelloDTO
     */
    dataDocumento?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoMacelloDTO
     */
    numeroDocumento?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoMacelloDTO
     */
    dataOperazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoMacelloDTO
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentoMacelloDTO
     */
    tipo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idArxivar?: number | null;
    /**
     * 
     * @type {CicloDTO}
     * @memberof DocumentoMacelloDTO
     */
    ciclo?: CicloDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoMacelloDTO
     */
    anagrafeMittente?: AnagrafeDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoMacelloDTO
     */
    anagrafeDestinatario?: AnagrafeDTO;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idCarico?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idAnagrafePrimario?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloDTO
     */
    idAnagrafeSecondario?: number | null;
    /**
     * 
     * @type {CaricoDTO}
     * @memberof DocumentoMacelloDTO
     */
    carico?: CaricoDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoMacelloDTO
     */
    anagrafePrimario?: AnagrafeDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoMacelloDTO
     */
    anagrafeSecondario?: AnagrafeDTO;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentoMacelloDTO
     */
    fineCarico?: boolean;
}

/**
 * Check if a given object implements the DocumentoMacelloDTO interface.
 */
export function instanceOfDocumentoMacelloDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentoMacelloDTOFromJSON(json: any): DocumentoMacelloDTO {
    return DocumentoMacelloDTOFromJSONTyped(json, false);
}

export function DocumentoMacelloDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentoMacelloDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idCiclo': !exists(json, 'idCiclo') ? undefined : json['idCiclo'],
        'idAnagrafeMittente': !exists(json, 'idAnagrafeMittente') ? undefined : json['idAnagrafeMittente'],
        'idAnagrafeDestinatario': !exists(json, 'idAnagrafeDestinatario') ? undefined : json['idAnagrafeDestinatario'],
        'dataDocumento': !exists(json, 'dataDocumento') ? undefined : (new Date(json['dataDocumento'])),
        'numeroDocumento': !exists(json, 'numeroDocumento') ? undefined : json['numeroDocumento'],
        'dataOperazione': !exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'idArxivar': !exists(json, 'idArxivar') ? undefined : json['idArxivar'],
        'ciclo': !exists(json, 'ciclo') ? undefined : CicloDTOFromJSON(json['ciclo']),
        'anagrafeMittente': !exists(json, 'anagrafeMittente') ? undefined : AnagrafeDTOFromJSON(json['anagrafeMittente']),
        'anagrafeDestinatario': !exists(json, 'anagrafeDestinatario') ? undefined : AnagrafeDTOFromJSON(json['anagrafeDestinatario']),
        'idCarico': !exists(json, 'idCarico') ? undefined : json['idCarico'],
        'idAnagrafePrimario': !exists(json, 'idAnagrafePrimario') ? undefined : json['idAnagrafePrimario'],
        'idAnagrafeSecondario': !exists(json, 'idAnagrafeSecondario') ? undefined : json['idAnagrafeSecondario'],
        'carico': !exists(json, 'carico') ? undefined : CaricoDTOFromJSON(json['carico']),
        'anagrafePrimario': !exists(json, 'anagrafePrimario') ? undefined : AnagrafeDTOFromJSON(json['anagrafePrimario']),
        'anagrafeSecondario': !exists(json, 'anagrafeSecondario') ? undefined : AnagrafeDTOFromJSON(json['anagrafeSecondario']),
        'fineCarico': !exists(json, 'fineCarico') ? undefined : json['fineCarico'],
    };
}

export function DocumentoMacelloDTOToJSON(value?: DocumentoMacelloDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idCiclo': value.idCiclo,
        'idAnagrafeMittente': value.idAnagrafeMittente,
        'idAnagrafeDestinatario': value.idAnagrafeDestinatario,
        'dataDocumento': value.dataDocumento === undefined ? undefined : (value.dataDocumento.toISOString()),
        'numeroDocumento': value.numeroDocumento,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'note': value.note,
        'tipo': value.tipo,
        'idArxivar': value.idArxivar,
        'ciclo': CicloDTOToJSON(value.ciclo),
        'anagrafeMittente': AnagrafeDTOToJSON(value.anagrafeMittente),
        'anagrafeDestinatario': AnagrafeDTOToJSON(value.anagrafeDestinatario),
        'idCarico': value.idCarico,
        'idAnagrafePrimario': value.idAnagrafePrimario,
        'idAnagrafeSecondario': value.idAnagrafeSecondario,
        'carico': CaricoDTOToJSON(value.carico),
        'anagrafePrimario': AnagrafeDTOToJSON(value.anagrafePrimario),
        'anagrafeSecondario': AnagrafeDTOToJSON(value.anagrafeSecondario),
        'fineCarico': value.fineCarico,
    };
}

