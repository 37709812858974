/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversioneUpdateDTO
 */
export interface ConversioneUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof ConversioneUpdateDTO
     */
    idFiliera: number;
    /**
     * 
     * @type {string}
     * @memberof ConversioneUpdateDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConversioneUpdateDTO
     */
    idAllevatore?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ConversioneUpdateDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversioneUpdateDTO
     */
    fineValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ConversioneUpdateDTO
     */
    valore?: number;
}

/**
 * Check if a given object implements the ConversioneUpdateDTO interface.
 */
export function instanceOfConversioneUpdateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idFiliera" in value;

    return isInstance;
}

export function ConversioneUpdateDTOFromJSON(json: any): ConversioneUpdateDTO {
    return ConversioneUpdateDTOFromJSONTyped(json, false);
}

export function ConversioneUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversioneUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idFiliera': json['idFiliera'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'idAllevatore': !exists(json, 'idAllevatore') ? undefined : json['idAllevatore'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'fineValidita': !exists(json, 'fineValidita') ? undefined : (new Date(json['fineValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
    };
}

export function ConversioneUpdateDTOToJSON(value?: ConversioneUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idFiliera': value.idFiliera,
        'descrizione': value.descrizione,
        'idAllevatore': value.idAllevatore,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'fineValidita': value.fineValidita === undefined ? undefined : (value.fineValidita.toISOString()),
        'valore': value.valore,
    };
}

