/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TipoAnimaleUpdateDTO
 */
export interface TipoAnimaleUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof TipoAnimaleUpdateDTO
     */
    codice: string;
    /**
     * 
     * @type {string}
     * @memberof TipoAnimaleUpdateDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TipoAnimaleUpdateDTO
     */
    idRazza?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TipoAnimaleUpdateDTO
     */
    maschio?: boolean;
}

/**
 * Check if a given object implements the TipoAnimaleUpdateDTO interface.
 */
export function instanceOfTipoAnimaleUpdateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "codice" in value;

    return isInstance;
}

export function TipoAnimaleUpdateDTOFromJSON(json: any): TipoAnimaleUpdateDTO {
    return TipoAnimaleUpdateDTOFromJSONTyped(json, false);
}

export function TipoAnimaleUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipoAnimaleUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codice': json['codice'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'idRazza': !exists(json, 'idRazza') ? undefined : json['idRazza'],
        'maschio': !exists(json, 'maschio') ? undefined : json['maschio'],
    };
}

export function TipoAnimaleUpdateDTOToJSON(value?: TipoAnimaleUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codice': value.codice,
        'descrizione': value.descrizione,
        'idRazza': value.idRazza,
        'maschio': value.maschio,
    };
}

