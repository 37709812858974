import React, { useRef } from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Popup,
  Form,
  Lookup,
  Button
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { nazioneDataSource } from '../../../datasources/nazioneDataSource';
import { tecniciDataSource } from '../../../datasources/tecniciDataSource';
import PopupContatti, { ContattiPopupHandle } from '../../../components/popupContatti/popupContatti';
import { dataGridErrorHandling } from '../../../utils/errorHandling';


export default function AnagraficaTecniciPage() {
  const contattiRef = useRef<ContattiPopupHandle>(null);

  function openContattiPopup(e: any) {
    contattiRef.current?.show(e.row.key);
  }

  function dataGridInitNewRow(e: any) {
    e.data.idNazione = "ITA";
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Tecnici</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={tecniciDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        errorRowEnabled={false}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
        onInitNewRow={dataGridInitNewRow}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Tecnico" showTitle={true} />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="ragioneSociale" colSpan={2} />
              <Item dataField="cognome" />
              <Item dataField="nome" />
              <Item dataField="email" />
            </Item>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField="partitaIva" />
              <Item dataField="codiceFiscale" />
              <Item dataField="sdi" />
            </Item>
            <Item itemType="group" caption="Indirizzo" colCount={2} colSpan={2}>
              <Item dataField="indirizzo" />
              <Item dataField="numeroCivico" />
              <Item dataField="cap" />
              <Item dataField="localita" />
              <Item dataField="provincia" />
              <Item dataField="idNazione" editorType="dxSelectBox" />
            </Item>
            <Item itemType="group" caption="Banca" colCount={2} colSpan={2}>
              <Item dataField="banca.descrizioneBreve" />
              <Item dataField="banca.abi" />
              <Item dataField="banca.cab" />
              <Item dataField="banca.iban" />
              <Item dataField="banca.sia" />
            </Item>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
          <Button hint="Contatti" icon="card" onClick={openContattiPopup} />
        </Column>
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'ragioneSociale'} sortIndex={0} sortOrder="asc" />
        <Column dataField={'cognome'} />
        <Column dataField={'nome'} />
        <Column dataField={'indirizzo'} />
        <Column dataField={'numeroCivico'} />
        <Column dataField={'cap'} />
        <Column dataField={'localita'} />
        <Column dataField={'provincia'} />
        <Column dataField={'codiceFiscale'} />
        <Column dataField={'partitaIva'} />
        <Column dataField={'sdi'} />
        <Column dataField={'email'} />
        <Column dataField={'idNazione'} >
          <Lookup dataSource={nazioneDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>
        <Column dataField={'banca.descrizioneBreve'} visible={false} />
        <Column dataField={'banca.abi'} visible={false} />
        <Column dataField={'banca.cab'} visible={false} />
        <Column dataField={'banca.iban'} visible={false} />
        <Column dataField={'banca.sia'} visible={false} />
      </DataGrid>
      <PopupContatti ref={contattiRef}></PopupContatti>
    </React.Fragment>
  )
}

