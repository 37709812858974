/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportRegistroCaricoScaricoDTO
 */
export interface ReportRegistroCaricoScaricoDTO {
    /**
     * 
     * @type {Date}
     * @memberof ReportRegistroCaricoScaricoDTO
     */
    dataDa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportRegistroCaricoScaricoDTO
     */
    dataA?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ReportRegistroCaricoScaricoDTO
     */
    provvisorio?: boolean;
}

/**
 * Check if a given object implements the ReportRegistroCaricoScaricoDTO interface.
 */
export function instanceOfReportRegistroCaricoScaricoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportRegistroCaricoScaricoDTOFromJSON(json: any): ReportRegistroCaricoScaricoDTO {
    return ReportRegistroCaricoScaricoDTOFromJSONTyped(json, false);
}

export function ReportRegistroCaricoScaricoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRegistroCaricoScaricoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataDa': !exists(json, 'dataDa') ? undefined : (new Date(json['dataDa'])),
        'dataA': !exists(json, 'dataA') ? undefined : (new Date(json['dataA'])),
        'provvisorio': !exists(json, 'provvisorio') ? undefined : json['provvisorio'],
    };
}

export function ReportRegistroCaricoScaricoDTOToJSON(value?: ReportRegistroCaricoScaricoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataDa': value.dataDa === undefined ? undefined : (value.dataDa.toISOString()),
        'dataA': value.dataA === undefined ? undefined : (value.dataA.toISOString()),
        'provvisorio': value.provvisorio,
    };
}

