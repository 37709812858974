import CustomStore from "devextreme/data/custom_store";
import { NazioniApi } from "../data/apis/NazioniApi";
import { apiConfiguration } from "../apiConfig";

export const nazioneDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const api = new NazioniApi(apiConfiguration);
    
        return api.getNazioni();
    },
  
    byKey: (key) => {
      const api = new NazioniApi(apiConfiguration);
        return api.getNazioneById({id:key});
    },
  
    insert: (values) => {
      const api = new NazioniApi(apiConfiguration);
      return api.insertNazione({
        nazioneCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const api = new NazioniApi(apiConfiguration);
      return api.updateNazione({
        id: key,
        nazioneUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const api = new NazioniApi(apiConfiguration);
      return api.deleteNazione({
        id: key
      });
     }
  });