import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi } from '../data/apis/CicliApi';

export function chiusuraVariazioniCapiDataSource( 
    idFiliera : number, 
    idCiclo: number
) {
    const cicliApi = new CicliApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return cicliApi.getChiusuraVariazioneCapi({ 
                idFiliera: idFiliera, 
                idCiclo:idCiclo 
            });
        },

        byKey: (key) => {
            return cicliApi.getChiusuraVariazioneCapiById({
                idFiliera:idFiliera, 
                idCiclo:idCiclo, 
                idVariazioneCapi: key
            });
        },

        insert: (values) => {
            return cicliApi.insertChiusuraVariazioneCapi({
                idFiliera: idFiliera,
                idCiclo:idCiclo,
                chiusuraVariazioneCapiCreateDTO: values
            });
        },

        update: (key, values) => {
            return cicliApi.updateChiusuraVariazioneCapi({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idVariazioneCapi: key,
                chiusuraVariazioneCapiUpdateDTO: values
            });
        },

        remove: (key) => {
            return cicliApi.deleteChiusuraVariazioneCapi({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idVariazioneCapi: key
            });
        }
    });
};
