import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetDocumentiRequest, InsertDocumentoRequest, UpdateDocumentoRequest, DeleteDocumentoRequest, GetDocumentiByTipoRequest } from '../data/apis/DocumentiApi';



export function documentiDataSource(idFiliera: number, idCiclo: number, tipo: string) {
    const documentiApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return documentiApi.getDocumentiByTipo({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                tipo: tipo
            });
        },

        byKey: (key) => {
            return documentiApi.getDocumentoById({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key
            });
        },

        insert: (values) => {
            return documentiApi.insertDocumento({
                idCiclo: idCiclo,
                idFiliera: idFiliera,
                documentoCreateDTO: values
            });
        },

        update: (key, values) => {
            return documentiApi.updateDocumento({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key,
                documentoUpdateDTO: values
            });
        },

        remove: (key) => {
            return documentiApi.deleteDocumento({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key
            });
        }
    });
};

