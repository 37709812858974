import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Button, Column, Editing, FilterRow, Form, Lookup, Pager, Paging, RequiredRule } from "devextreme-react/data-grid";
import { dataGridErrorHandling, showError } from "../../utils/errorHandling";
import { Item, Label, Tab, TabPanelOptions, TabbedItem } from "devextreme-react/form";
import { allevamentiDataSource } from "../../datasources/allevamentiDataSource";
import { nazioneDataSource } from "../../datasources/nazioneDataSource";
import 'devextreme-react/text-area';
import { tecniciDataSource } from "../../datasources/tecniciDataSource";
import PopupCapannoni, { CapannoniPopupHandle } from "../popupCapannoni/popupCapannoni";
import { AddFilieraPermissionRequest, AddPermissionRequest, AnagraficheApi, UtenteDTO, UtentiApi } from "../../data";
import { apiConfiguration } from "../../apiConfig";
import { permissionsDataSource } from "../../datasources/permissionsDataSource";
import ResponsiveBox, { Col } from "devextreme-react/responsive-box";
import { alert } from "devextreme/ui/dialog";
import { filieraPermissionsDataSource } from "../../datasources/filieraPermissionsDataSource";
import { SelectBox } from "devextreme-react";
import { filiereDataSource } from "../../datasources/filiereDataSource";
import FilieraContext from "../../contexts/filieraContext";

type Props = {}

export type PermissionsPopupHandle = {
  show: (userId: string) => void,
}

type state = {
  userId: string | undefined,
  user: UtenteDTO | undefined,
  visible: boolean,
  title: string
}

const PopupPermissions: React.ForwardRefRenderFunction<PermissionsPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ userId: undefined, user: undefined, visible: false, title: "" });
  const { currentFiliera } = useContext(FilieraContext);
  const [localFiliera, setLocalFiliera] = useState<number>(currentFiliera.Id);
  const gridFilieraPermissions = useRef<DataGrid>(null);

  function hideInfo() {
    setState({ userId: undefined, user: undefined, visible: false, title: "" });
  }

  useImperativeHandle(ref, () => ({
    show(userId) {
      var api = new UtentiApi(apiConfiguration);
      GetUser(userId)
        .then(function (user) {
          if (user != null) {
            setState({
              userId: userId,
              user: user,
              visible: true,
              title: "Permessi di " + user.userId
            });

            setLocalFiliera(currentFiliera.Id);
          }
        })
        .catch(function (ex) {
          console.log(ex);
        })

    }
  }));

  function GetUser(userId: string) {
    var api = new UtentiApi(apiConfiguration);
    return api.getUtenteById({ id: userId })
      .catch(function (ex) {
        console.log(ex);
      });
  }


  function checkPermessi_calculateCellValue(e: any) {
    //console.log('calculateCellValue', e,state.user);
    return state.user?.permissions?.find(_ => _.permissionId === e.permissionId) != null;
  }

  function checkPermessi_setCellValue(newData: any, value: boolean, currentRowData: any) {
    var api = new UtentiApi(apiConfiguration);
    if (value) {
      var addPermissionRequest: AddPermissionRequest = {
        id: state.user?.userId as string,
        utenteAddPermissionDTO: {
          permissionId: currentRowData.permissionId
        }
      };

      return api.addPermission(addPermissionRequest)
        .catch(function (error) {
          showError(error);
          newData = false;
        })
        .then(function () {
          if (state.userId != null)
            GetUser(state.userId)
              .then(function (user) {
                if (user != null) {
                  setState({ ...state, user: user });
                }
              });
        });
    }
    else {
      return api.removePermission({
        id: state.user?.userId as string,
        utenteRemovePermissionDTO: {
          permissionId: currentRowData.permissionId
        }
      }).catch(function (error) {
        showError(error);
        newData = true;
      })
      .then(function () {
        if (state.userId != null)
          GetUser(state.userId)
            .then(function (user) {
              if (user != null) {
                setState({ ...state, user: user });
              }
            });
      });
    }

    //console.log('setCellValue', newData, value, currentRowData);
  }

  function checkFilieraPermessi_calculateCellValue(e: any) {
    return state.user?.filieraPermissions?.find(_ => _.filieraPermissionId === e.filieraPermissionId && _.idFiliera === localFiliera) != null;
  }

  function checkFilieraPermessi_setCellValue(newData: any, value: boolean, currentRowData: any) {
    var api = new UtentiApi(apiConfiguration);
    if (value) {
      var addPermissionRequest: AddFilieraPermissionRequest = {
        id: state.user?.userId as string,
        utenteAddFilieraPermissionDTO: {
          idFiliera: localFiliera,
          permissionId: currentRowData.filieraPermissionId
        }
      };

      return api.addFilieraPermission(addPermissionRequest)
        .catch(function (error) {
          showError(error);
          newData = false;
        })
        .then(function () {
          if (state.userId != null)
            GetUser(state.userId)
              .then(function (user) {
                if (user != null) {
                  setState({ ...state, user: user });
                }
              });
        });
    }
    else {
      return api.removeFilieraPermission({
        id: state.user?.userId as string,
        utenteRemoveFilieraPermissionDTO: {
          idFiliera: localFiliera,
          permissionId: currentRowData.filieraPermissionId
        }
      }).catch(function (error) {
        showError(error);
        newData = true;
      })
      .then(function () {
        if (state.userId != null)
          GetUser(state.userId)
            .then(function (user) {
              if (user != null) {
                setState({ ...state, user: user });
              }
            });
      });
      
    }

    //console.log('setCellValue', newData, value, currentRowData);
  }

  return (<React.Fragment>

    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      fullScreen={true}
      showTitle={true}
      onHidden={hideInfo}
      title={state.title}
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      <h5>Permessi generali</h5>
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={permissionsDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Paging defaultPageSize={5} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />
        <Editing
          mode="cell"
          allowUpdating={true}>

        </Editing>

        {/* <Column  type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
        </Column> */}
        <Column name="check" calculateCellValue={checkPermessi_calculateCellValue} setCellValue={checkPermessi_setCellValue}></Column>
        <Column dataField={'permissionId'} allowEditing={false} />
        <Column dataField={'description'} allowEditing={false} />
      </DataGrid>
      <h5>Permessi per filiera</h5>
      <SelectBox
        dataSource={filiereDataSource}
        valueExpr={'id'}
        displayExpr={'ragioneSociale'}
        value={localFiliera}
        onValueChanged={function (e) { setLocalFiliera(e.value); }}
      />
      <DataGrid
        ref={gridFilieraPermissions}
        className={'dx-card wide-card'}
        dataSource={filieraPermissionsDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Paging defaultPageSize={5} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />
        <Editing
          mode="cell"
          allowUpdating={true}>

        </Editing>

        {/* <Column  type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
        </Column> */}
        <Column name="check" calculateCellValue={checkFilieraPermessi_calculateCellValue} setCellValue={checkFilieraPermessi_setCellValue}></Column>
        <Column dataField={'filieraPermissionId'} allowEditing={false} />
        <Column dataField={'description'} allowEditing={false} />
      </DataGrid>
    </Popup>
  </React.Fragment>
  );
}

export default React.forwardRef(PopupPermissions);
