import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetMovimentiAccasamentiRequest, InsertMovimentoAccasamentoRequest, UpdateMovimentoAccasamentoRequest, DeleteMovimentoAccasamentoRequest } from '../data/apis/DocumentiApi';

const documentiApi = new DocumentiApi(apiConfiguration);

export function MovimentiAccasamentiDataSource(
    idFiliera: number,
    idCiclo: number,
    idDocumento: number
) {
    const cicliApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            var accasamentoRequest: GetMovimentiAccasamentiRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento
            };
            return documentiApi.getMovimentiAccasamenti(accasamentoRequest);
        },

        insert: (values) => {

            // insert: (values) => {
            //     return api.insertTipoTrattamento({
            //       tipoTrattamentoCreateDTO: values
            //     });
            //   }
        

            return documentiApi.insertMovimentoAccasamento({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                movimentoAccasamentoCreateDTO: values});
        },

        update: (key, values) => {
            var UpdateAccasamentoRequest: UpdateMovimentoAccasamentoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key,
                movimentoAccasamentoUpdateDTO: values
            };

            return documentiApi.updateMovimentoAccasamento(UpdateAccasamentoRequest);
        },

        remove: (key) => {
            var deleteAccasamentoRequest: DeleteMovimentoAccasamentoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key
            };

            return documentiApi.deleteMovimentoAccasamento(deleteAccasamentoRequest);
        }
    });
};