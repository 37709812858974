/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryCarichiDTO
 */
export interface SummaryCarichiDTO {
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    numeroMaschiCaricati?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    kgMaschiCaricati?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    pesoMedioMaschi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    numeroFemmineCaricate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    kgFemmineCaricate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    pesoMedioFemmine?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    numeroMaschiDaCaricare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryCarichiDTO
     */
    numeroFemmineDaCaricare?: number | null;
}

/**
 * Check if a given object implements the SummaryCarichiDTO interface.
 */
export function instanceOfSummaryCarichiDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SummaryCarichiDTOFromJSON(json: any): SummaryCarichiDTO {
    return SummaryCarichiDTOFromJSONTyped(json, false);
}

export function SummaryCarichiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryCarichiDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numeroMaschiCaricati': !exists(json, 'numeroMaschiCaricati') ? undefined : json['numeroMaschiCaricati'],
        'kgMaschiCaricati': !exists(json, 'kgMaschiCaricati') ? undefined : json['kgMaschiCaricati'],
        'pesoMedioMaschi': !exists(json, 'pesoMedioMaschi') ? undefined : json['pesoMedioMaschi'],
        'numeroFemmineCaricate': !exists(json, 'numeroFemmineCaricate') ? undefined : json['numeroFemmineCaricate'],
        'kgFemmineCaricate': !exists(json, 'kgFemmineCaricate') ? undefined : json['kgFemmineCaricate'],
        'pesoMedioFemmine': !exists(json, 'pesoMedioFemmine') ? undefined : json['pesoMedioFemmine'],
        'numeroMaschiDaCaricare': !exists(json, 'numeroMaschiDaCaricare') ? undefined : json['numeroMaschiDaCaricare'],
        'numeroFemmineDaCaricare': !exists(json, 'numeroFemmineDaCaricare') ? undefined : json['numeroFemmineDaCaricare'],
    };
}

export function SummaryCarichiDTOToJSON(value?: SummaryCarichiDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numeroMaschiCaricati': value.numeroMaschiCaricati,
        'kgMaschiCaricati': value.kgMaschiCaricati,
        'pesoMedioMaschi': value.pesoMedioMaschi,
        'numeroFemmineCaricate': value.numeroFemmineCaricate,
        'kgFemmineCaricate': value.kgFemmineCaricate,
        'pesoMedioFemmine': value.pesoMedioFemmine,
        'numeroMaschiDaCaricare': value.numeroMaschiDaCaricare,
        'numeroFemmineDaCaricare': value.numeroFemmineDaCaricare,
    };
}

