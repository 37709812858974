import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis/AnagraficheApi";

export const macelliDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const api = new AnagraficheApi(apiConfiguration);
    
        return api.getMacelli();
    },
  
    byKey: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
        return api.getMacelloById({idMacello:key});
    },
  
    insert: (values) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.insertMacello({
        macelloCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const api = new AnagraficheApi(apiConfiguration);

      return api.updateMacello({
        idMacello: key,
        macelloUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.deleteMacello({
        idMacello: key
      });
     }
  });