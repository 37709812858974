/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilieraPermissionDTO,
  PermissionDTO,
} from '../models';
import {
    FilieraPermissionDTOFromJSON,
    FilieraPermissionDTOToJSON,
    PermissionDTOFromJSON,
    PermissionDTOToJSON,
} from '../models';

/**
 * 
 */
export class PermissionsApi extends runtime.BaseAPI {

    /**
     * Elenco di tutte le FilierePermissions
     */
    async getFilierePermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilieraPermissionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Permissions/filiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilieraPermissionDTOFromJSON));
    }

    /**
     * Elenco di tutte le FilierePermissions
     */
    async getFilierePermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilieraPermissionDTO>> {
        const response = await this.getFilierePermissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte le Permissions
     */
    async getPermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PermissionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionDTOFromJSON));
    }

    /**
     * Elenco di tutte le Permissions
     */
    async getPermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PermissionDTO>> {
        const response = await this.getPermissionsRaw(initOverrides);
        return await response.value();
    }

}
