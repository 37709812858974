import React, { useContext, useRef } from 'react';
import FilieraContext from '../../contexts/filieraContext';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Toolbar,
  Button as GridButton,
  Form,
  Item as GridItem
} from 'devextreme-react/data-grid';
import { EmptyItem, Item } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import { dataGridErrorHandling } from '../../utils/errorHandling';
import { CarichiDataSource } from '../../datasources/CarichiDataSource';
import { useNavigate } from 'react-router-dom';
import PopupCarico, { CaricoPopupHandle } from '../../components/popupCarico/popupCarico';
import { CicloDTO } from '../../data';

type Props = {
  ciclo: CicloDTO,
  readonly?:boolean
};

export default function CarichiTable(params: Props) {
  const gridRef = useRef<DataGrid>(null);
  const popupCaricoRef = useRef<CaricoPopupHandle>(null);
  const { currentFiliera } = useContext(FilieraContext);
  const navigate = useNavigate();

  function dataGridInitNewRow(e: any) {
    e.data.data = new Date();
  }
 
  function refreshGrid() {
    gridRef.current?.instance.refresh();
  }

  function openDettagli(e:any) {
    //console.log(e);
    popupCaricoRef.current?.show(e.row.data.id, params.readonly ?? false);
  }

  return (
    <React.Fragment>
      <DataGrid
        ref={gridRef}
        key={"SessioniCarico"}
        className={'dx-card wide-card'}
        dataSource={CarichiDataSource(Number(currentFiliera.Id), Number(params.ciclo.id))}
        showBorders={false}
        focusedRowEnabled={true}
        errorRowEnabled={false}
        defaultFocusedRowIndex={0}
        defaultSelectionFilter=""
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onDataErrorOccurred={dataGridErrorHandling}
        onInitNewRow={dataGridInitNewRow}
        onRowUpdating={function (e) {
          e.newData = Object.assign({}, e.oldData, e.newData);
        }}
      >
        <Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true}>
          <Form labelMode='floating'>
                            <Item dataField="data" />
                            <EmptyItem/>
                            <Item dataField="note" colSpan={2} editorType='dxTextArea' />
                    </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column type="buttons" alignment='left'>
          <GridButton name="edit" disabled={params.readonly} />
          <GridButton name="delete" disabled={params.readonly} />
          <GridButton hint="Dettagli Documento" icon="search" onClick={openDettagli} />
        </Column>
        <Column dataField={'id'} width={90} allowEditing={false} visible={false} />
        <Column dataField={'data'} caption={'Data'} dataType='date' />
        <Column dataField={'tipiAnimali'} caption={'Tipi animali'} />
        <Column dataField={'numeroCapi'} caption={'Numero capi'} dataType='number' />
        <Column dataField={'kgCaricati'} caption={'Kg caricati'} dataType='number' />
        <Column dataField={'pesoMedio'} caption={'Peso medio (Kg/capi)'} dataType='number' format={"#.00#"} />
        <Column dataField={'fineCarico'} caption={'Fine carico'} />
        <Column dataField={'note'} caption={'Note'}  />

        <Toolbar>
          <GridItem location="after">
            <Button
              icon='refresh'
              onClick={refreshGrid}
            />
          </GridItem>
          <GridItem name="addRowButton" disabled={params.readonly} />
        </Toolbar>
      </DataGrid>

      <PopupCarico ref={popupCaricoRef} ciclo={params.ciclo} />
    </React.Fragment>
  );
}
