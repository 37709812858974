import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalInstance } from '.';
import { loginRequest } from './authConfig';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import FilieraProvider from './contexts/FilieraContextProvider';
import { locale ,loadMessages } from 'devextreme/localization';
import itMessages from "devextreme/localization/messages/it.json";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
 
config({ licenseKey });  
//export const currencyFormat = { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 };

type AppProps = {
  pca: IPublicClientApplication;
};

function App() {
  return <Content />;
}

export default function Root({ pca }: AppProps) {
  const screenSizeClass = useScreenSizeClass();

  loadMessages(itMessages);
  locale(navigator.language);
  config({
    defaultCurrency: 'EUR',
    defaultUseCurrencyAccountingStyle: false 
  });

  return (
    <Router>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
          <NavigationProvider>
            <FilieraProvider>
              <div className={`app ${screenSizeClass}`}>
                <App />
              </div>
            </FilieraProvider>
          </NavigationProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  );
}
