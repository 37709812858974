import React from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  RequiredRule,
  MasterDetail,
  StateStoring
} from 'devextreme-react/data-grid';

import { nazioneDataSource } from '../datasources/nazioneDataSource';

import { allevatoriDataSource } from '../datasources/allevatoriDataSource';
import { dataGridErrorHandling } from '../utils/errorHandling';

import AllevatoriDetailPanel from '../components/allevatoriDetailPanel/allevatoriDetailPanel';

export default function TestPage() {

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Cicli</h2>
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={allevatoriDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        errorRowEnabled={false}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <StateStoring enabled={true} type="localStorage" storageKey="cicliList" />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'ragioneSociale'} sortIndex={0} sortOrder="asc" />
        <Column dataField={'cognome'} visible={false} />
        <Column dataField={'nome'} visible={false} />
        <Column dataField={'indirizzo'} />
        <Column dataField={'numeroCivico'} />
        <Column dataField={'cap'} />
        <Column dataField={'localita'} />
        <Column dataField={'provincia'} />
        <Column dataField={'numeroAllevamenti'} />
        <Column dataField={'superficieTotale'} />
        <Column dataField={'codiceFiscale'} />
        <Column dataField={'partitaIva'} />
        <Column dataField={'sdi'} />
        <Column dataField={'email'} />
        <Column dataField={'idNazione'} caption={'Nazione'}>
          <RequiredRule />
          <Lookup dataSource={nazioneDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>
        <Column dataField={'banca.descrizioneBreve'} visible={false} />
        <Column dataField={'banca.abi'} visible={false} />
        <Column dataField={'banca.cab'} visible={false} />
        <Column dataField={'banca.iban'} visible={false} />
        <Column dataField={'banca.sia'} visible={false} />
        <MasterDetail
          enabled={true}
          component={AllevatoriDetailPanel}
        />
      </DataGrid>

    </React.Fragment>
  )
}

