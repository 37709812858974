import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ListiniApi, GetListiniAnimaliRequest } from "../data/apis/ListiniApi";

export function listiniAnimaliDataSource(idFiliera: number, showAll: boolean) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ListiniApi(apiConfiguration);

            const request: GetListiniAnimaliRequest = {
                idFiliera: idFiliera,
                showAll: showAll
            };

            return api.getListiniAnimali(request);
        },

        byKey: (key) => {
            const api = new ListiniApi(apiConfiguration);

            return api.getListinoAnimaliById({
                idFiliera: idFiliera,
                id: key
            });
        },

        insert: (values) => {
            const api = new ListiniApi(apiConfiguration);

            return api.insertListinoAnimali({
                idFiliera: idFiliera,
                listinoAnimaliCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new ListiniApi(apiConfiguration);
            return api.updateListinoAnimali({
                idFiliera:idFiliera,
                id: key,
                listinoAnimaliUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ListiniApi(apiConfiguration);
            return api.deleteListinoAnimali({
                id: key,
                idFiliera: idFiliera
            });
        }
    });
}