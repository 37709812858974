import React, { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import '../reportChiusuraViewer/reportChiusuraViewer.scss';
import { apiConfiguration } from '../../apiConfig';
import { PagamentiApi } from '../../data';

export default function PagamentiViewer(props) {
    const [loading, setLoading] = useState(false);
    const [instance, setInstance] = useState();
    const viewer = useRef();

    useEffect(() => {
        //console.log(loading);
        if (!instance) {
            //setLoading(true);

            WebViewer(
                {
                    path: '/webviewer/lib',
                    licenseKey: 'ElFQV4k0W9MNBaN9iTyT'
                },
                viewer.current,
            ).then((inst) => { setInstance(inst); });
        }
    }, []);

    useEffect(() => {
        if (instance) {
            if (props != null) {
                var api = new PagamentiApi(apiConfiguration);
                api.downloadFilePagamento({
                    idFiliera: props.idFiliera,
                    idCiclo: props.idCiclo,
                    idPagamento: props.idPagamento
                }).then((report) => {
                    instance.UI.loadDocument(report, 
                        { 
                            extension: 'pdf', 
                            onError: function(e){ console.error(e); } ,
                            documentId: props.idPagamento
                        });
                });
            }
            else {
                instance.UI.closeDocument().then(function () {
                    console.log('Document is closed');
                });
            }
        }
    }, [props, instance]);

    return (
        <div>
            <div className="webviewer" ref={viewer}></div>
        </div>
    )
}
