import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { TipiContattiApi } from "../data/apis/TipiContattiApi";

export const tipiContattiDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const razzeApi = new TipiContattiApi(apiConfiguration);
    
        return razzeApi.getTipiContatti();
    },
  
    byKey: (key) => {
      const razzeApi = new TipiContattiApi(apiConfiguration);
        return razzeApi.getTipoContattoById({id:key});
    },
  
    insert: (values) => {
      const razzeApi = new TipiContattiApi(apiConfiguration);
      return razzeApi.insertTipoContatto({
        tipoContattoCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const razzeApi = new TipiContattiApi(apiConfiguration);
      return razzeApi.updateTipoContatto({
        id: key,
        tipoContattoUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const razzeApi = new TipiContattiApi(apiConfiguration);
      return razzeApi.deleteTipoContatto({
        id: key
      });
     }
  });