import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis";

export const tecniciDataSource = new CustomStore({
  key: "id",
  loadMode: 'raw',
  load: (loadOptions) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);

    return razzeApi.getTecnici();
  },

  byKey: (key) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.getTecnicoById({ idTecnico: key });
  },

  insert: (values) => {

    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.insertTecnico({
      tecnicoCreateDTO: values
    });
  },

  update: (key, values) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);

    return razzeApi.updateTecnico({
      idTecnico: key,
      tecnicoUpdateDTO: values
    });
  },

  remove: (key) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.deleteTecnico({
      idTecnico: key
    });
  }
});