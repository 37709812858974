/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversioneCreateDTO
 */
export interface ConversioneCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof ConversioneCreateDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConversioneCreateDTO
     */
    idAllevatore?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ConversioneCreateDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversioneCreateDTO
     */
    fineValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ConversioneCreateDTO
     */
    valore?: number;
}

/**
 * Check if a given object implements the ConversioneCreateDTO interface.
 */
export function instanceOfConversioneCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversioneCreateDTOFromJSON(json: any): ConversioneCreateDTO {
    return ConversioneCreateDTOFromJSONTyped(json, false);
}

export function ConversioneCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversioneCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'idAllevatore': !exists(json, 'idAllevatore') ? undefined : json['idAllevatore'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'fineValidita': !exists(json, 'fineValidita') ? undefined : (new Date(json['fineValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
    };
}

export function ConversioneCreateDTOToJSON(value?: ConversioneCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'descrizione': value.descrizione,
        'idAllevatore': value.idAllevatore,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'fineValidita': value.fineValidita === undefined ? undefined : (value.fineValidita.toISOString()),
        'valore': value.valore,
    };
}

