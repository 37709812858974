import React, { useContext, useRef, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/form';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { MovimentiMangimiDataSource, tipiMangimiDataSource } from '../../../datasources/movimentiMangimiDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { CicloDTO } from '../../../data';

type MangimiTableProps = {
    ciclo: CicloDTO,
    idDocumento: number,
    onChanged: ()=>void,
    readonly?: boolean
};

const MangimiTable: React.FC<MangimiTableProps> = (params) => {
    const { currentFiliera } = useContext(FilieraContext);
    const tipoDocumento = "Mangimi";

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={MovimentiMangimiDataSource(currentFiliera.Id, Number(params.ciclo.id), params.idDocumento)}
                showBorders={false}
                focusedRowEnabled={true}
                errorRowEnabled={false}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
                onRowRemoved={params.onChanged}
                onRowUpdated={params.onChanged}
                onRowInserted={params.onChanged}
                onDataErrorOccurred={dataGridErrorHandling}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!params.readonly} allowDeleting={true}>
                    <Form labelMode='floating'>
                            <Item dataField="idTipoMangime" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }}/>
                            <Item dataField="quantita" />
                            <Item dataField="valoreFixed" />
                            <Item dataField="note" colSpan={2} editorType='dxTextArea' />
                           
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={params.readonly}/>
                    <Button name="delete" disabled={params.readonly}/>
                </Column>
                <Column dataField={'id'} width={90}  allowEditing={false} />
                <Column dataField={'quantita'} caption={'Quantità in Kg'}  />
                <Column dataField={'note'} caption={'Note'} hidingPriority={1} />
                <Column dataField={'idTipoMangime'} caption={'Tipo mangime'} visible={false}>
                    <Lookup dataSource={tipiMangimiDataSource} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column dataField={'valoreFixed'} caption={'Forzatura Prezzo'} dataType='number' />
                <Column dataField={'tipoMangime.descrizione'} caption={'Tipo mangime'} hidingPriority={2}   />
            </DataGrid>
        </React.Fragment>
    );
};

export default MangimiTable;