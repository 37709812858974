import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi } from "../data/apis/CicliApi";

export function CarichiRiscontriDataSource(
  idFiliera: number,
  idCiclo: number,
  idCarico: number
) {
  const cicliApi = new CicliApi(apiConfiguration);

  return new CustomStore({
    key: "id",
    load: (loadOptions) =>
      cicliApi.getCarichiRiscontri({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: idCarico
      }),

    insert: (values) =>
      cicliApi.insertCaricoRiscontro({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: idCarico,
        caricoRiscontroCreateDTO: values
      }),

    update: (key, values) =>
      cicliApi.updateCaricoRiscontro({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: idCarico,
        idRiscontro: key,
        caricoRiscontroUpdateDTO: values
      }),

    remove: (key) =>
      cicliApi.deleteCaricoRiscontro({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: idCarico,
        idRiscontro: key
      })

  });
};