/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CicloCreateDTO
 */
export interface CicloCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof CicloCreateDTO
     */
    idAllevamento?: number;
    /**
     * 
     * @type {number}
     * @memberof CicloCreateDTO
     */
    idConversione?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CicloCreateDTO
     */
    soccida?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CicloCreateDTO
     */
    vaccinoCaricoAllevatore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CicloCreateDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CicloCreateDTO
     */
    giacenzaIniziale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CicloCreateDTO
     */
    giacenzaInizialeValore?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CicloCreateDTO
     */
    escludiRegistroCS?: boolean;
}

/**
 * Check if a given object implements the CicloCreateDTO interface.
 */
export function instanceOfCicloCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CicloCreateDTOFromJSON(json: any): CicloCreateDTO {
    return CicloCreateDTOFromJSONTyped(json, false);
}

export function CicloCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CicloCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idAllevamento': !exists(json, 'idAllevamento') ? undefined : json['idAllevamento'],
        'idConversione': !exists(json, 'idConversione') ? undefined : json['idConversione'],
        'soccida': !exists(json, 'soccida') ? undefined : json['soccida'],
        'vaccinoCaricoAllevatore': !exists(json, 'vaccinoCaricoAllevatore') ? undefined : json['vaccinoCaricoAllevatore'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'giacenzaIniziale': !exists(json, 'giacenzaIniziale') ? undefined : json['giacenzaIniziale'],
        'giacenzaInizialeValore': !exists(json, 'giacenzaInizialeValore') ? undefined : json['giacenzaInizialeValore'],
        'escludiRegistroCS': !exists(json, 'escludiRegistroCS') ? undefined : json['escludiRegistroCS'],
    };
}

export function CicloCreateDTOToJSON(value?: CicloCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idAllevamento': value.idAllevamento,
        'idConversione': value.idConversione,
        'soccida': value.soccida,
        'vaccinoCaricoAllevatore': value.vaccinoCaricoAllevatore,
        'descrizione': value.descrizione,
        'giacenzaIniziale': value.giacenzaIniziale,
        'giacenzaInizialeValore': value.giacenzaInizialeValore,
        'escludiRegistroCS': value.escludiRegistroCS,
    };
}

