/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TipoAnimaleDTO } from './TipoAnimaleDTO';
import {
    TipoAnimaleDTOFromJSON,
    TipoAnimaleDTOFromJSONTyped,
    TipoAnimaleDTOToJSON,
} from './TipoAnimaleDTO';

/**
 * 
 * @export
 * @interface MovimentoMacelloDTO
 */
export interface MovimentoMacelloDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    idDocumento?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoMacelloDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {Date}
     * @memberof MovimentoMacelloDTO
     */
    dataMacellazione?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    capi?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMacelloDTO
     */
    peso?: number;
    /**
     * 
     * @type {TipoAnimaleDTO}
     * @memberof MovimentoMacelloDTO
     */
    tipoAnimale?: TipoAnimaleDTO;
}

/**
 * Check if a given object implements the MovimentoMacelloDTO interface.
 */
export function instanceOfMovimentoMacelloDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoMacelloDTOFromJSON(json: any): MovimentoMacelloDTO {
    return MovimentoMacelloDTOFromJSONTyped(json, false);
}

export function MovimentoMacelloDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoMacelloDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idDocumento': !exists(json, 'idDocumento') ? undefined : json['idDocumento'],
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'dataMacellazione': !exists(json, 'dataMacellazione') ? undefined : (json['dataMacellazione'] === null ? null : new Date(json['dataMacellazione'])),
        'capi': !exists(json, 'capi') ? undefined : json['capi'],
        'peso': !exists(json, 'peso') ? undefined : json['peso'],
        'tipoAnimale': !exists(json, 'tipoAnimale') ? undefined : TipoAnimaleDTOFromJSON(json['tipoAnimale']),
    };
}

export function MovimentoMacelloDTOToJSON(value?: MovimentoMacelloDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idDocumento': value.idDocumento,
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoAnimale': value.idTipoAnimale,
        'dataMacellazione': value.dataMacellazione === undefined ? undefined : (value.dataMacellazione === null ? null : value.dataMacellazione.toISOString()),
        'capi': value.capi,
        'peso': value.peso,
        'tipoAnimale': TipoAnimaleDTOToJSON(value.tipoAnimale),
    };
}

