/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListinoAnimaliCreateDTO,
  ListinoAnimaliDTO,
  ListinoAnimaliUpdateDTO,
  ListinoMangimiCreateDTO,
  ListinoMangimiDTO,
  ListinoMangimiUpdateDTO,
  ListinoPulciniCreateDTO,
  ListinoPulciniDTO,
  ListinoPulciniUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    ListinoAnimaliCreateDTOFromJSON,
    ListinoAnimaliCreateDTOToJSON,
    ListinoAnimaliDTOFromJSON,
    ListinoAnimaliDTOToJSON,
    ListinoAnimaliUpdateDTOFromJSON,
    ListinoAnimaliUpdateDTOToJSON,
    ListinoMangimiCreateDTOFromJSON,
    ListinoMangimiCreateDTOToJSON,
    ListinoMangimiDTOFromJSON,
    ListinoMangimiDTOToJSON,
    ListinoMangimiUpdateDTOFromJSON,
    ListinoMangimiUpdateDTOToJSON,
    ListinoPulciniCreateDTOFromJSON,
    ListinoPulciniCreateDTOToJSON,
    ListinoPulciniDTOFromJSON,
    ListinoPulciniDTOToJSON,
    ListinoPulciniUpdateDTOFromJSON,
    ListinoPulciniUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteListinoAnimaliRequest {
    idFiliera: number;
    id: number;
}

export interface DeleteListinoMangimiRequest {
    idFiliera: number;
    id: number;
}

export interface DeleteListinoPulciniRequest {
    idFiliera: number;
    id: number;
}

export interface GetListiniAnimaliRequest {
    idFiliera: number;
    showAll?: boolean;
}

export interface GetListiniMangimiRequest {
    idFiliera: number;
    showAll?: boolean;
}

export interface GetListiniPulciniRequest {
    idFiliera: number;
    showAll?: boolean;
}

export interface GetListinoAnimaliByIdRequest {
    idFiliera: number;
    id: number;
}

export interface GetListinoMangimiByIdRequest {
    idFiliera: number;
    id: number;
}

export interface GetListinoPulciniByIdRequest {
    idFiliera: number;
    id: number;
}

export interface InsertListinoAnimaliRequest {
    idFiliera: number;
    listinoAnimaliCreateDTO?: ListinoAnimaliCreateDTO;
}

export interface InsertListinoMangimiRequest {
    idFiliera: number;
    listinoMangimiCreateDTO?: ListinoMangimiCreateDTO;
}

export interface InsertListinoPulciniRequest {
    idFiliera: number;
    listinoPulciniCreateDTO?: ListinoPulciniCreateDTO;
}

export interface UpdateListinoAnimaliRequest {
    idFiliera: number;
    id: number;
    listinoAnimaliUpdateDTO?: ListinoAnimaliUpdateDTO;
}

export interface UpdateListinoMangimiRequest {
    idFiliera: number;
    id: number;
    listinoMangimiUpdateDTO?: ListinoMangimiUpdateDTO;
}

export interface UpdateListinoPulciniRequest {
    idFiliera: number;
    id: number;
    listinoPulciniUpdateDTO?: ListinoPulciniUpdateDTO;
}

/**
 * 
 */
export class ListiniApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino animali esistente
     */
    async deleteListinoAnimaliRaw(requestParameters: DeleteListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteListinoAnimali.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteListinoAnimali.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Animali/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino animali esistente
     */
    async deleteListinoAnimali(requestParameters: DeleteListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteListinoAnimaliRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino animali esistente
     */
    async deleteListinoMangimiRaw(requestParameters: DeleteListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteListinoMangimi.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteListinoMangimi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino animali esistente
     */
    async deleteListinoMangimi(requestParameters: DeleteListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteListinoMangimiRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino pulcini esistente
     */
    async deleteListinoPulciniRaw(requestParameters: DeleteListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteListinoPulcini.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteListinoPulcini.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Pulcini/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un listino pulcini esistente
     */
    async deleteListinoPulcini(requestParameters: DeleteListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteListinoPulciniRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutti i listini animali
     */
    async getListiniAnimaliRaw(requestParameters: GetListiniAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListinoAnimaliDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListiniAnimali.');
        }

        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Animali`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListinoAnimaliDTOFromJSON));
    }

    /**
     * Elenco di tutti i listini animali
     */
    async getListiniAnimali(requestParameters: GetListiniAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListinoAnimaliDTO>> {
        const response = await this.getListiniAnimaliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i listini mangimi
     */
    async getListiniMangimiRaw(requestParameters: GetListiniMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListinoMangimiDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListiniMangimi.');
        }

        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Mangimi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListinoMangimiDTOFromJSON));
    }

    /**
     * Elenco di tutti i listini mangimi
     */
    async getListiniMangimi(requestParameters: GetListiniMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListinoMangimiDTO>> {
        const response = await this.getListiniMangimiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i listini pulcini
     */
    async getListiniPulciniRaw(requestParameters: GetListiniPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListinoPulciniDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListiniPulcini.');
        }

        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Pulcini`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListinoPulciniDTOFromJSON));
    }

    /**
     * Elenco di tutti i listini pulcini
     */
    async getListiniPulcini(requestParameters: GetListiniPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListinoPulciniDTO>> {
        const response = await this.getListiniPulciniRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un listino animali per id
     */
    async getListinoAnimaliByIdRaw(requestParameters: GetListinoAnimaliByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoAnimaliDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListinoAnimaliById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getListinoAnimaliById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Animali/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoAnimaliDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un listino animali per id
     */
    async getListinoAnimaliById(requestParameters: GetListinoAnimaliByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoAnimaliDTO> {
        const response = await this.getListinoAnimaliByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un listino animali per id
     */
    async getListinoMangimiByIdRaw(requestParameters: GetListinoMangimiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoMangimiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListinoMangimiById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getListinoMangimiById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoMangimiDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un listino animali per id
     */
    async getListinoMangimiById(requestParameters: GetListinoMangimiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoMangimiDTO> {
        const response = await this.getListinoMangimiByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un listino pulcini per id
     */
    async getListinoPulciniByIdRaw(requestParameters: GetListinoPulciniByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoPulciniDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getListinoPulciniById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getListinoPulciniById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Pulcini/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoPulciniDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un listino pulcini per id
     */
    async getListinoPulciniById(requestParameters: GetListinoPulciniByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoPulciniDTO> {
        const response = await this.getListinoPulciniByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino animali in anagrafica
     */
    async insertListinoAnimaliRaw(requestParameters: InsertListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoAnimaliDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertListinoAnimali.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Animali`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoAnimaliCreateDTOToJSON(requestParameters.listinoAnimaliCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoAnimaliDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino animali in anagrafica
     */
    async insertListinoAnimali(requestParameters: InsertListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoAnimaliDTO> {
        const response = await this.insertListinoAnimaliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino animali in anagrafica
     */
    async insertListinoMangimiRaw(requestParameters: InsertListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoMangimiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertListinoMangimi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Mangimi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoMangimiCreateDTOToJSON(requestParameters.listinoMangimiCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoMangimiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino animali in anagrafica
     */
    async insertListinoMangimi(requestParameters: InsertListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoMangimiDTO> {
        const response = await this.insertListinoMangimiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino pulcini in anagrafica
     */
    async insertListinoPulciniRaw(requestParameters: InsertListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListinoPulciniDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertListinoPulcini.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Pulcini`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoPulciniCreateDTOToJSON(requestParameters.listinoPulciniCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListinoPulciniDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo listino pulcini in anagrafica
     */
    async insertListinoPulcini(requestParameters: InsertListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListinoPulciniDTO> {
        const response = await this.insertListinoPulciniRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino animali esistente
     */
    async updateListinoAnimaliRaw(requestParameters: UpdateListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateListinoAnimali.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateListinoAnimali.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Animali/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoAnimaliUpdateDTOToJSON(requestParameters.listinoAnimaliUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino animali esistente
     */
    async updateListinoAnimali(requestParameters: UpdateListinoAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateListinoAnimaliRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino animali esistente
     */
    async updateListinoMangimiRaw(requestParameters: UpdateListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateListinoMangimi.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateListinoMangimi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoMangimiUpdateDTOToJSON(requestParameters.listinoMangimiUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino animali esistente
     */
    async updateListinoMangimi(requestParameters: UpdateListinoMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateListinoMangimiRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino pulcini esistente
     */
    async updateListinoPulciniRaw(requestParameters: UpdateListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateListinoPulcini.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateListinoPulcini.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Listini/Pulcini/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListinoPulciniUpdateDTOToJSON(requestParameters.listinoPulciniUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un listino pulcini esistente
     */
    async updateListinoPulcini(requestParameters: UpdateListinoPulciniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateListinoPulciniRaw(requestParameters, initOverrides);
    }

}
