/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentoMacelloUpdateDTO
 */
export interface DocumentoMacelloUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloUpdateDTO
     */
    idAnagrafeMittente?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloUpdateDTO
     */
    idAnagrafeDestinatario?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoMacelloUpdateDTO
     */
    dataDocumento?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoMacelloUpdateDTO
     */
    numeroDocumento?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoMacelloUpdateDTO
     */
    dataOperazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoMacelloUpdateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloUpdateDTO
     */
    idAnagrafePrimario?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoMacelloUpdateDTO
     */
    idAnagrafeSecondario?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentoMacelloUpdateDTO
     */
    fineCarico?: boolean;
}

/**
 * Check if a given object implements the DocumentoMacelloUpdateDTO interface.
 */
export function instanceOfDocumentoMacelloUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentoMacelloUpdateDTOFromJSON(json: any): DocumentoMacelloUpdateDTO {
    return DocumentoMacelloUpdateDTOFromJSONTyped(json, false);
}

export function DocumentoMacelloUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentoMacelloUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idAnagrafeMittente': !exists(json, 'idAnagrafeMittente') ? undefined : json['idAnagrafeMittente'],
        'idAnagrafeDestinatario': !exists(json, 'idAnagrafeDestinatario') ? undefined : json['idAnagrafeDestinatario'],
        'dataDocumento': !exists(json, 'dataDocumento') ? undefined : (new Date(json['dataDocumento'])),
        'numeroDocumento': !exists(json, 'numeroDocumento') ? undefined : json['numeroDocumento'],
        'dataOperazione': !exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'idAnagrafePrimario': !exists(json, 'idAnagrafePrimario') ? undefined : json['idAnagrafePrimario'],
        'idAnagrafeSecondario': !exists(json, 'idAnagrafeSecondario') ? undefined : json['idAnagrafeSecondario'],
        'fineCarico': !exists(json, 'fineCarico') ? undefined : json['fineCarico'],
    };
}

export function DocumentoMacelloUpdateDTOToJSON(value?: DocumentoMacelloUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idAnagrafeMittente': value.idAnagrafeMittente,
        'idAnagrafeDestinatario': value.idAnagrafeDestinatario,
        'dataDocumento': value.dataDocumento === undefined ? undefined : (value.dataDocumento.toISOString()),
        'numeroDocumento': value.numeroDocumento,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'note': value.note,
        'idAnagrafePrimario': value.idAnagrafePrimario,
        'idAnagrafeSecondario': value.idAnagrafeSecondario,
        'fineCarico': value.fineCarico,
    };
}

