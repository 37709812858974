import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis/AnagraficheApi";

export const allevatoriDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const api = new AnagraficheApi(apiConfiguration);
    
        return api.getAllevatori();
    },
  
    byKey: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
        return api.getAllevatoreById({idAllevatore:key});
    },
  
    insert: (values) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.insertAllevatore({
        allevatoreCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const api = new AnagraficheApi(apiConfiguration);

      return api.updateAllevatore({
        idAllevatore: key,
        allevatoreUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.deleteAllevatore({
        idAllevatore: key
      });
     }
  });