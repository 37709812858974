import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi, GetCapannoniRequest } from "../data/apis/AnagraficheApi";

export function capannoniDataSource(idAllevatore: number,idAllevamento: number) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new AnagraficheApi(apiConfiguration);

            const request: GetCapannoniRequest = {
                idAllevamento: idAllevamento
            };

            return api.getCapannoni(request);
        },

        byKey: (key) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.getCapannoneById({
                idCapannone:key,
                idAllevamento:idAllevamento
            });
        },

        insert: (values) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.insertCapannone({
                idAllevamento: idAllevamento,
                capannoneCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.updateCapannone({
                idAllevamento:idAllevamento,
                idCapannone: key,
                capannoneUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.deleteCapannone({
                idAllevamento: idAllevamento,
                idCapannone: key
            });
        }
    });
}