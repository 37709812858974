import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis/AnagraficheApi";

export function incubatoiByCicloDataSource( 
  idCiclo: number
) {
  const api = new AnagraficheApi(apiConfiguration);

  return new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {   
        return api.getIncubatoiByciclo({ idCiclo: idCiclo});
    },
  
    byKey: (key) => api.getIncubatoioById({idIncubatoio:key})
  });
}