import React from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Popup,
  Form
} from 'devextreme-react/data-grid';
import { razzeDataSource } from '../../../datasources/razzeDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { Item } from 'devextreme-react/form';

export default function Task() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Razze</h2>

      <DataGrid
        className={'dx-card wide-card'}
        //dataSource={dataSource as any}
        dataSource={razzeDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Tipo mangime" showTitle={true} />
          <Form>
            <Item dataField="descrizione"/>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column  type='buttons' />
        <Column dataField={'id'} width={90} hidingPriority={2} allowEditing={false} sortOrder="asc" visible={false} showEditorAlways={false}/>
        <Column
          dataField={'descrizione'}
          caption={'Descrizione'}
          hidingPriority={8}
          sortIndex={0} 
        />
      </DataGrid>
    </React.Fragment>
)}

