/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SummaryAccasamentiDTO } from './SummaryAccasamentiDTO';
import {
    SummaryAccasamentiDTOFromJSON,
    SummaryAccasamentiDTOFromJSONTyped,
    SummaryAccasamentiDTOToJSON,
} from './SummaryAccasamentiDTO';
import type { SummaryCarichiDTO } from './SummaryCarichiDTO';
import {
    SummaryCarichiDTOFromJSON,
    SummaryCarichiDTOFromJSONTyped,
    SummaryCarichiDTOToJSON,
} from './SummaryCarichiDTO';
import type { SummaryConversioneDTO } from './SummaryConversioneDTO';
import {
    SummaryConversioneDTOFromJSON,
    SummaryConversioneDTOFromJSONTyped,
    SummaryConversioneDTOToJSON,
} from './SummaryConversioneDTO';
import type { SummaryMangimiDTO } from './SummaryMangimiDTO';
import {
    SummaryMangimiDTOFromJSON,
    SummaryMangimiDTOFromJSONTyped,
    SummaryMangimiDTOToJSON,
} from './SummaryMangimiDTO';
import type { SummaryMortiDTO } from './SummaryMortiDTO';
import {
    SummaryMortiDTOFromJSON,
    SummaryMortiDTOFromJSONTyped,
    SummaryMortiDTOToJSON,
} from './SummaryMortiDTO';

/**
 * 
 * @export
 * @interface CicloSummaryDTO
 */
export interface CicloSummaryDTO {
    /**
     * 
     * @type {SummaryAccasamentiDTO}
     * @memberof CicloSummaryDTO
     */
    accasamenti?: SummaryAccasamentiDTO;
    /**
     * 
     * @type {SummaryCarichiDTO}
     * @memberof CicloSummaryDTO
     */
    carichi?: SummaryCarichiDTO;
    /**
     * 
     * @type {SummaryConversioneDTO}
     * @memberof CicloSummaryDTO
     */
    conversione?: SummaryConversioneDTO;
    /**
     * 
     * @type {SummaryMangimiDTO}
     * @memberof CicloSummaryDTO
     */
    mangimi?: SummaryMangimiDTO;
    /**
     * 
     * @type {SummaryMortiDTO}
     * @memberof CicloSummaryDTO
     */
    morti?: SummaryMortiDTO;
}

/**
 * Check if a given object implements the CicloSummaryDTO interface.
 */
export function instanceOfCicloSummaryDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CicloSummaryDTOFromJSON(json: any): CicloSummaryDTO {
    return CicloSummaryDTOFromJSONTyped(json, false);
}

export function CicloSummaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CicloSummaryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accasamenti': !exists(json, 'accasamenti') ? undefined : SummaryAccasamentiDTOFromJSON(json['accasamenti']),
        'carichi': !exists(json, 'carichi') ? undefined : SummaryCarichiDTOFromJSON(json['carichi']),
        'conversione': !exists(json, 'conversione') ? undefined : SummaryConversioneDTOFromJSON(json['conversione']),
        'mangimi': !exists(json, 'mangimi') ? undefined : SummaryMangimiDTOFromJSON(json['mangimi']),
        'morti': !exists(json, 'morti') ? undefined : SummaryMortiDTOFromJSON(json['morti']),
    };
}

export function CicloSummaryDTOToJSON(value?: CicloSummaryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accasamenti': SummaryAccasamentiDTOToJSON(value.accasamenti),
        'carichi': SummaryCarichiDTOToJSON(value.carichi),
        'conversione': SummaryConversioneDTOToJSON(value.conversione),
        'mangimi': SummaryMangimiDTOToJSON(value.mangimi),
        'morti': SummaryMortiDTOToJSON(value.morti),
    };
}

