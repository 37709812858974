import { HomePage, UtentiPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import { NazioniPage, RazzePage, TipiAnimaliPage, TipiContattiPage, TipiMangimiPage, TipiTrattamentiPage } from './pages/tabelle';
import AnagraficaTecniciPage from './pages/anagrafiche/tecnici/tecnici';
import AnagraficaIncubatoiPage from './pages/anagrafiche/incubatoi/incubatoi';
import AnagraficaAllevatoriPage from './pages/anagrafiche/allevatori/allevatori';
import AnagraficaMacelliPage from './pages/anagrafiche/macelli/macelli';
import CicliPage from './pages/cicli/cicli';
import CicloDashboard from './pages/cicli/cicloDashboard';
import ListinoAnimaliPage from './pages/listini/animali/animali';
import TestPage from './pages/TestPage';
import ListiniPulciniPage from './pages/listini/pulcini/pulcini';
import ListinoMangimiPage from './pages/listini/mangimi/mangimi';
import ConversionePage from './pages/listini/conversioni/conversioni';
import AnagraficaMangimificiPage from './pages/anagrafiche/mangimifici/mangimifici';
import AnagraficaFornitoriPage from './pages/anagrafiche/fornitori/fornitori';
import CarichiPage from './pages/bi/carichi';
import MediePage from './pages/bi/medie';
import ChiusurePage from './pages/bi/chiusure';
import RegistroRiepilogoPage from './pages/registri/riepilogo';
import RegistroCaricoScaricoPage from './pages/registri/registro';

const routes = [
    {
        path: '/home',
        element: HomePage
    },
    {
        path : '/anagrafiche/tecnici',
        element: AnagraficaTecniciPage
    },
    {
        path : '/anagrafiche/macelli',
        element: AnagraficaMacelliPage
    },
    {
        path : '/anagrafiche/allevatori',
        element: AnagraficaAllevatoriPage
    },
    {
        path : '/anagrafiche/incubatoi',
        element: AnagraficaIncubatoiPage
    },
    {
        path : '/anagrafiche/mangimifici',
        element: AnagraficaMangimificiPage
    },
    {
        path : '/anagrafiche/fornitori',
        element: AnagraficaFornitoriPage
    },
    {
        path: '/tabelle/razze',
        element: RazzePage
    },
    {
        path: '/tabelle/tipitrattamenti',
        element: TipiTrattamentiPage
    },
    {
        path: '/tabelle/tipimangimi',
        element: TipiMangimiPage
    },
    {
        path: '/tabelle/tipicontatti',
        element: TipiContattiPage
    },
    {
        path: '/tabelle/tipianimali',
        element: TipiAnimaliPage
    },
    {
        path: '/tabelle/nazioni',
        element: NazioniPage
    },
    {
        path: '/cicli',
        element: CicliPage
    },
    {
        path: '/cicli/dashboard/:id/:numeroCiclo/:idAllevamento',
        element: CicloDashboard
    },
    {
        path: '/listini/animali',
        element: ListinoAnimaliPage
    },
    {
        path: '/listini/pulcini',
        element: ListiniPulciniPage
    },
    {
        path: '/listini/mangimi',
        element: ListinoMangimiPage
    },
    {
        path: '/listini/conversioni',
        element: ConversionePage
    },
    {
        path: '/registri/riepilogo',
        element: RegistroRiepilogoPage
    },
    {
        path: '/registri/registro',
        element: RegistroCaricoScaricoPage
    },
    {
        path: '/test',
        element: TestPage
    },
    {
        path: '/utenti',
        element: UtentiPage
    },
    {
        path: '/bi/carichi',
        element: CarichiPage
    },
    {
        path: '/bi/chiusure',
        element: ChiusurePage
    },
    {
        path: '/bi/medie',
        element: MediePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
