import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetMovimentiMortiRequest, UpdateMovimentoMortoRequest, DeleteMovimentoMortoRequest, InsertMovimentoMortoRequest } from '../data/apis/DocumentiApi';

const documentiApi = new DocumentiApi(apiConfiguration);

export function MovimentiMortalitaDataSource(
    idFiliera: number,
    idCiclo: number,
    idDocumento: number
) {
    const cicliApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            var accasamentoRequest: GetMovimentiMortiRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento
            };
            return documentiApi.getMovimentiMorti(accasamentoRequest);
        },

        insert: (values) => {
            var InsertAccasamentoRequest: InsertMovimentoMortoRequest = {

                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                movimentoMortoCreateDTO: values
            };

            return documentiApi.insertMovimentoMorto(InsertAccasamentoRequest);
        },

        update: (key, values) => {
            var UpdateAccasamentoRequest: UpdateMovimentoMortoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key,
                movimentoMortoUpdateDTO: values
            };

            return documentiApi.updateMovimentoMorto(UpdateAccasamentoRequest);
        },

        remove: (key) => {
            var deleteAccasamentoRequest: DeleteMovimentoMortoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key
            };

            return documentiApi.deleteMovimentoMorto(deleteAccasamentoRequest);
        }
    });
};