import React, { useContext, useEffect, useRef } from 'react';
import FilieraContext from '../../contexts/filieraContext';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Button as GridButton,
  Item as GridItem,
  Form,
  Toolbar,
  Sorting,
  RowDragging,
  Lookup
} from 'devextreme-react/data-grid';
import { EmptyItem, Item } from 'devextreme-react/form';
import { dataGridErrorHandling } from '../../utils/errorHandling';
import { chiusuraCompensiDataSource } from '../../datasources/chiusuraCompensiDataSource';
import { CicliApi } from '../../data/apis/CicliApi';
import { apiConfiguration } from '../../apiConfig';
import { chiusuraExtraDataSource } from '../../datasources/chiusuraExtraDataSource';
import { TipiAnimaliDataSource } from '../../datasources/tipiAnimaliDataSource';
import { chiusuraVariazioniCapiDataSource } from '../../datasources/chiusuraVariazioniCapiDataSource';


type Props = {
  idCiclo: number,
  readonly?: boolean
};

export default function ChiusuraVariazioneCapiPanel(params: Props) {
  const { currentFiliera } = useContext(FilieraContext);

  return (
    <DataGrid
      className={'dx-card wide-card'}
      dataSource={chiusuraVariazioniCapiDataSource(currentFiliera.Id, params.idCiclo)}
      showBorders={false}
      focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
      onDataErrorOccurred={dataGridErrorHandling}
    >
      <Editing
        mode="popup"
        allowUpdating={true}
        allowAdding={true}
        allowDeleting={true}>
        <Form labelMode='floating'>
          <Item dataField="idTipoAnimale" isRequired={true} />
          <Item dataField="capi" />
          <Item dataField="peso" />
          <EmptyItem/>
          <Item dataField="note" colSpan={2} editorType='dxTextArea' />
        </Form>
      </Editing>
      <Sorting mode="none" />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} showInfo={true} />
      <FilterRow visible={true} />

      <Column type='buttons' />
      <Column dataField={'id'} width={90} visible={false} />
      <Column dataField={'idTipoAnimale'} caption={'Tipo Animale'} visible={false}>
        <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr={"descrizione"} />
      </Column>
      <Column dataField={'capi'} />
      <Column dataField={'peso'} />
      <Column dataField={'note'} />
    </DataGrid>

  );
}
