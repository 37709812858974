/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TipoContattoCreateDTO
 */
export interface TipoContattoCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof TipoContattoCreateDTO
     */
    descrizione: string;
}

/**
 * Check if a given object implements the TipoContattoCreateDTO interface.
 */
export function instanceOfTipoContattoCreateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "descrizione" in value;

    return isInstance;
}

export function TipoContattoCreateDTOFromJSON(json: any): TipoContattoCreateDTO {
    return TipoContattoCreateDTOFromJSONTyped(json, false);
}

export function TipoContattoCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipoContattoCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'descrizione': json['descrizione'],
    };
}

export function TipoContattoCreateDTOToJSON(value?: TipoContattoCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'descrizione': value.descrizione,
    };
}

