/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaricoRiscontroCreateDTO
 */
export interface CaricoRiscontroCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroCreateDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroCreateDTO
     */
    peso?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroCreateDTO
     */
    capi?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroCreateDTO
     */
    idCategoriaCarico?: number | null;
}

/**
 * Check if a given object implements the CaricoRiscontroCreateDTO interface.
 */
export function instanceOfCaricoRiscontroCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CaricoRiscontroCreateDTOFromJSON(json: any): CaricoRiscontroCreateDTO {
    return CaricoRiscontroCreateDTOFromJSONTyped(json, false);
}

export function CaricoRiscontroCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaricoRiscontroCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'peso': !exists(json, 'peso') ? undefined : json['peso'],
        'capi': !exists(json, 'capi') ? undefined : json['capi'],
        'idCategoriaCarico': !exists(json, 'idCategoriaCarico') ? undefined : json['idCategoriaCarico'],
    };
}

export function CaricoRiscontroCreateDTOToJSON(value?: CaricoRiscontroCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idTipoAnimale': value.idTipoAnimale,
        'peso': value.peso,
        'capi': value.capi,
        'idCategoriaCarico': value.idCategoriaCarico,
    };
}

