import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi, GetCicliRequest, InsertCicloRequest, UpdateCicloRequest, DeleteCicloRequest } from '../data/apis/CicliApi';

export function cicliDataSource( 
    idFiliera : number, 
    stato?: string,
    idAllevatore?: number
) {
    const cicliApi = new CicliApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return cicliApi.getCicli({ idFiliera: idFiliera, stato: stato, idAllevatore:idAllevatore });
        },

        byKey: (key) => {
            return cicliApi.getCicloById({idFiliera:idFiliera, idCiclo:key});
        },

        insert: (values) => {
            return cicliApi.insertCiclo({
                idFiliera: idFiliera,
                cicloCreateDTO: values
            });
        },

        update: (key, values) => {
            return cicliApi.updateCiclo({
                idFiliera: idFiliera,
                idCiclo: key,
                cicloUpdateDTO: values
            });
        },

        remove: (key) => {
            return cicliApi.deleteCiclo({
                idFiliera: idFiliera,
                idCiclo: key
            });
        }
    });
};
