/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentoAccasamentoCreateDTO
 */
export interface DocumentoAccasamentoCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    idAnagrafeMittente?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    idAnagrafeDestinatario?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    dataDocumento?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    numeroDocumento?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    dataOperazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    tipo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoCreateDTO
     */
    idFornitore?: number | null;
}

/**
 * Check if a given object implements the DocumentoAccasamentoCreateDTO interface.
 */
export function instanceOfDocumentoAccasamentoCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentoAccasamentoCreateDTOFromJSON(json: any): DocumentoAccasamentoCreateDTO {
    return DocumentoAccasamentoCreateDTOFromJSONTyped(json, false);
}

export function DocumentoAccasamentoCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentoAccasamentoCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idAnagrafeMittente': !exists(json, 'idAnagrafeMittente') ? undefined : json['idAnagrafeMittente'],
        'idAnagrafeDestinatario': !exists(json, 'idAnagrafeDestinatario') ? undefined : json['idAnagrafeDestinatario'],
        'dataDocumento': !exists(json, 'dataDocumento') ? undefined : (new Date(json['dataDocumento'])),
        'numeroDocumento': !exists(json, 'numeroDocumento') ? undefined : json['numeroDocumento'],
        'dataOperazione': !exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'idFornitore': !exists(json, 'idFornitore') ? undefined : json['idFornitore'],
    };
}

export function DocumentoAccasamentoCreateDTOToJSON(value?: DocumentoAccasamentoCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idAnagrafeMittente': value.idAnagrafeMittente,
        'idAnagrafeDestinatario': value.idAnagrafeDestinatario,
        'dataDocumento': value.dataDocumento === undefined ? undefined : (value.dataDocumento.toISOString()),
        'numeroDocumento': value.numeroDocumento,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'note': value.note,
        'tipo': value.tipo,
        'idFornitore': value.idFornitore,
    };
}

