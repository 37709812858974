import Form, { ButtonItem, EmptyItem, Item, Label, SimpleItem } from 'devextreme-react/form';
import { incubatoiDataSource } from '../../../datasources/incubatoiDataSource';
import { useContext, useEffect, useRef, useState } from 'react';
import { DocumentiApi } from '../../../data';
import { apiConfiguration } from '../../../apiConfig';
import FilieraContext from '../../../contexts/filieraContext';
import { ClickEvent } from 'devextreme/ui/button';
import { showError } from '../../../utils/errorHandling';
import { LoadPanel } from 'devextreme-react';
import React from 'react';
import { macelliDataSource } from '../../../datasources/macelliDataSource';
import { FieldDataChangedEvent } from 'devextreme/ui/form';

type Props = {
    idCiclo: number,
    idCarico: number,
    idDocumento?: number,
    onUpdated: () => void,
    onInserted: (idDocumento: number) => void,
    readonly?: boolean
}

const MacelliDocumentForm = (props: Props) => {
    const macelliEditorOptions = { dataSource: macelliDataSource, valueExpr: "id", displayExpr: "ragioneSociale", searchEnabled: true };
    const { currentFiliera } = useContext(FilieraContext);
    const [formData, setFormData] = useState<any>();
    const formRef = useRef<Form>(null);
    const [loadPanelVisible, setLoadPanelVisible] = useState<boolean>(false);

    useEffect(() => {
        setFormData(null);

        const api = new DocumentiApi(apiConfiguration);
        if (props.idDocumento) {
            api.getDocumentoMacelloById({ idFiliera: currentFiliera.Id, idCiclo: props.idCiclo, idCarico: props.idCarico, idDocumento: props.idDocumento })
                .then(function (data) {
                    setFormData(data);
                })
                .catch(showError);
        }
        else if (props.idCarico) {
            console.log('Recupero i dati di default');
            api.getDocumentoMacelloDefault({ idFiliera: currentFiliera.Id, idCiclo: props.idCiclo, idCarico: props.idCarico })
                .then(function (data) {
                    console.log(data);
                    setFormData(data);
                })
                .catch(showError);
        }
    }, [currentFiliera, props]);

    function save_click(e: ClickEvent) {
        var validate = formRef.current?.instance.validate();

        if (validate?.isValid) {
            const api = new DocumentiApi(apiConfiguration);

            var data = formRef.current?.instance.option("formData");

            if (!data.id || data.id === 0) {
                setLoadPanelVisible(true);
                api.insertDocumentoMacello({
                    idCiclo: props.idCiclo,
                    idFiliera: currentFiliera.Id,
                    idCarico: props.idCarico,
                    documentoMacelloCreateDTO: data
                })
                    .then(function (data) {
                        if (data) {
                            setFormData(data);
                            if (data?.id)
                                props.onInserted(data?.id);
                        }
                    })
                    .catch(showError)
                    .finally(() => setLoadPanelVisible(false));
            }
            else {
                setLoadPanelVisible(true);
                api.updateDocumentoMacello({
                    idCiclo: props.idCiclo,
                    idFiliera: currentFiliera.Id,
                    idCarico: props.idCarico,
                    idDocumento: data.id,
                    documentoMacelloUpdateDTO: data
                })
                    .then((props.onUpdated))
                    .catch(showError)
                    .finally(() => setLoadPanelVisible(false));
            }
        }
    }

    function onFieldDataChanged(e: FieldDataChangedEvent) {
        if (e.dataField==="dataDocumento" && e.component.getEditor('dataOperazione')?.option('value')==null){
            e.component.updateData("dataOperazione", e.value);
        }   
    }

    return (
        <React.Fragment>
            <Form id="form" ref={formRef} colCount={3} formData={formData} onFieldDataChanged={onFieldDataChanged} labelMode='floating' >
                <Item dataField="dataOperazione" editorType='dxDateBox' isRequired={true}  >
                    <Label text='Data macellazione' />
                </Item>
                <Item dataField="numeroDocumento" />
                <Item dataField="dataDocumento" editorType='dxDateBox' isRequired={true} />
                <Item dataField="idAnagrafeDestinatario"  editorType="dxSelectBox" editorOptions={macelliEditorOptions} isRequired={true} >
                    <Label text='Macello' />
                </Item>
                <Item dataField="idAnagrafePrimario"  editorType="dxSelectBox" editorOptions={macelliEditorOptions}  >
                    <Label text='Primario' />
                </Item>
                <Item dataField="idAnagrafeSecondario"  editorType="dxSelectBox" editorOptions={macelliEditorOptions}  >
                    <Label text='Secondario' />
                </Item>
                <Item dataField="fineCarico" editorType='dxCheckBox' />
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                <Item dataField="note" colSpan={3} editorType='dxTextArea' />
                <ButtonItem horizontalAlignment="right" colSpan={3}
                    buttonOptions={{
                        text: 'Salva',
                        type: 'success',
                        onClick: save_click,
                        disabled: props.readonly
                    }}
                />
            </Form>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: '#form' }}
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />
        </React.Fragment>
    );
};

export default MacelliDocumentForm;
