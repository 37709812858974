/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChiusuraVariazioneCapiDTO
 */
export interface ChiusuraVariazioneCapiDTO {
    /**
     * 
     * @type {number}
     * @memberof ChiusuraVariazioneCapiDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraVariazioneCapiDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraVariazioneCapiDTO
     */
    capi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraVariazioneCapiDTO
     */
    peso?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChiusuraVariazioneCapiDTO
     */
    note?: string | null;
}

/**
 * Check if a given object implements the ChiusuraVariazioneCapiDTO interface.
 */
export function instanceOfChiusuraVariazioneCapiDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChiusuraVariazioneCapiDTOFromJSON(json: any): ChiusuraVariazioneCapiDTO {
    return ChiusuraVariazioneCapiDTOFromJSONTyped(json, false);
}

export function ChiusuraVariazioneCapiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChiusuraVariazioneCapiDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'capi': !exists(json, 'capi') ? undefined : json['capi'],
        'peso': !exists(json, 'peso') ? undefined : json['peso'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function ChiusuraVariazioneCapiDTOToJSON(value?: ChiusuraVariazioneCapiDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idTipoAnimale': value.idTipoAnimale,
        'capi': value.capi,
        'peso': value.peso,
        'note': value.note,
    };
}

