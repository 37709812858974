import Form, { ButtonItem, EmptyItem, Item, Label, SimpleItem } from 'devextreme-react/form';
import { ClickEvent } from 'devextreme/ui/button';
import React, { useContext, useRef, useState } from 'react';
import PopupReportRegistroCaricoScarico, { ReportRegistroCaricoPopupHandle } from '../../components/popupReportRegistroCaricoScarico/popupReportRegistroCaricoScarico';
import FilieraContext from '../../contexts/filieraContext';
import { alert, confirm } from 'devextreme/ui/dialog';

type data = {
    dataDa: Date,
    dataA: Date,
    provvisorio: boolean
}

export default function RegistroCaricoScaricoPage() {
    const [formData, setFormData] = useState<data>({ dataDa: new Date(new Date().getFullYear(), 0, 1), dataA: new Date(new Date().getFullYear(), 11, 31), provvisorio: true });
    const reportRegistroCaricoScaricoRef = useRef<ReportRegistroCaricoPopupHandle>(null);
    const { currentFiliera } = useContext(FilieraContext);

    async function print_click(e: ClickEvent) {
        if (!formData.provvisorio) {
            if (!await confirm("Attenzione.<br>Stampa definitiva del registro di carico e scarico per il periodo selezionato.<br>Confermi di procedere?", "Stampa registro")) {
                return;
            }
        }
        reportRegistroCaricoScaricoRef.current?.show(currentFiliera.Id, formData.dataDa, formData.dataA, formData.provvisorio);
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Registro carico/scarico</h2>
            <div className={'dx-card responsive-paddings buttonContainer'} style={{ textAlign: "center" }}>
                <Form id="form" colCount={2} formData={formData} labelMode='floating'>
                    <Item dataField="dataDa" editorType='dxDateBox' isRequired={true} />
                    <Item dataField="dataA" editorType='dxDateBox' isRequired={true} />
                    <Item dataField="provvisorio" editorType='dxCheckBox' />
                    <EmptyItem />

                    <ButtonItem horizontalAlignment="center" colSpan={2}
                        buttonOptions={{
                            text: 'Stampa',
                            type: 'default',
                            onClick: print_click
                        }}
                    />
                </Form>
            </div>

            <PopupReportRegistroCaricoScarico ref={reportRegistroCaricoScaricoRef}></PopupReportRegistroCaricoScarico>
        </React.Fragment>
    )
}

