import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { UtentiApi } from "../data/apis";

export const utentiDataSource = new CustomStore({
    key: "userId",
    loadMode: 'raw',
    load: (loadOptions) => {
        const api = new UtentiApi(apiConfiguration);
        return api.getUtenti();
    },

    byKey: (key) => {
        const api = new UtentiApi(apiConfiguration);
        return api.getUtenteById({
            id: key
        });
    },

    insert: (values) => {
        const api = new UtentiApi(apiConfiguration);
        return api.insertUtente({ utenteCreateDTO: values });
    },
    
    remove: (key) => {
        const api = new UtentiApi(apiConfiguration);
        return api.deleteUtente({ id: key });
    }
});
