/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentoAccasamentoCreateDTO,
  DocumentoAccasamentoDTO,
  DocumentoAccasamentoUpdateDTO,
  DocumentoCreateDTO,
  DocumentoDTO,
  DocumentoDefaultDTO,
  DocumentoMacelloCreateDTO,
  DocumentoMacelloDTO,
  DocumentoMacelloUpdateDTO,
  DocumentoMangimeCreateDTO,
  DocumentoMangimeDTO,
  DocumentoMangimeUpdateDTO,
  DocumentoUpdateDTO,
  MovimentoAccasamentoCreateDTO,
  MovimentoAccasamentoDTO,
  MovimentoAccasamentoUpdateDTO,
  MovimentoMacelloCreateDTO,
  MovimentoMacelloDTO,
  MovimentoMacelloUpdateDTO,
  MovimentoMangimeCreateDTO,
  MovimentoMangimeDTO,
  MovimentoMangimeUpdateDTO,
  MovimentoMortoCreateDTO,
  MovimentoMortoDTO,
  MovimentoMortoUpdateDTO,
  MovimentoTrattamentoCreateDTO,
  MovimentoTrattamentoDTO,
  MovimentoTrattamentoUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    DocumentoAccasamentoCreateDTOFromJSON,
    DocumentoAccasamentoCreateDTOToJSON,
    DocumentoAccasamentoDTOFromJSON,
    DocumentoAccasamentoDTOToJSON,
    DocumentoAccasamentoUpdateDTOFromJSON,
    DocumentoAccasamentoUpdateDTOToJSON,
    DocumentoCreateDTOFromJSON,
    DocumentoCreateDTOToJSON,
    DocumentoDTOFromJSON,
    DocumentoDTOToJSON,
    DocumentoDefaultDTOFromJSON,
    DocumentoDefaultDTOToJSON,
    DocumentoMacelloCreateDTOFromJSON,
    DocumentoMacelloCreateDTOToJSON,
    DocumentoMacelloDTOFromJSON,
    DocumentoMacelloDTOToJSON,
    DocumentoMacelloUpdateDTOFromJSON,
    DocumentoMacelloUpdateDTOToJSON,
    DocumentoMangimeCreateDTOFromJSON,
    DocumentoMangimeCreateDTOToJSON,
    DocumentoMangimeDTOFromJSON,
    DocumentoMangimeDTOToJSON,
    DocumentoMangimeUpdateDTOFromJSON,
    DocumentoMangimeUpdateDTOToJSON,
    DocumentoUpdateDTOFromJSON,
    DocumentoUpdateDTOToJSON,
    MovimentoAccasamentoCreateDTOFromJSON,
    MovimentoAccasamentoCreateDTOToJSON,
    MovimentoAccasamentoDTOFromJSON,
    MovimentoAccasamentoDTOToJSON,
    MovimentoAccasamentoUpdateDTOFromJSON,
    MovimentoAccasamentoUpdateDTOToJSON,
    MovimentoMacelloCreateDTOFromJSON,
    MovimentoMacelloCreateDTOToJSON,
    MovimentoMacelloDTOFromJSON,
    MovimentoMacelloDTOToJSON,
    MovimentoMacelloUpdateDTOFromJSON,
    MovimentoMacelloUpdateDTOToJSON,
    MovimentoMangimeCreateDTOFromJSON,
    MovimentoMangimeCreateDTOToJSON,
    MovimentoMangimeDTOFromJSON,
    MovimentoMangimeDTOToJSON,
    MovimentoMangimeUpdateDTOFromJSON,
    MovimentoMangimeUpdateDTOToJSON,
    MovimentoMortoCreateDTOFromJSON,
    MovimentoMortoCreateDTOToJSON,
    MovimentoMortoDTOFromJSON,
    MovimentoMortoDTOToJSON,
    MovimentoMortoUpdateDTOFromJSON,
    MovimentoMortoUpdateDTOToJSON,
    MovimentoTrattamentoCreateDTOFromJSON,
    MovimentoTrattamentoCreateDTOToJSON,
    MovimentoTrattamentoDTOFromJSON,
    MovimentoTrattamentoDTOToJSON,
    MovimentoTrattamentoUpdateDTOFromJSON,
    MovimentoTrattamentoUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteDocumentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface DeleteDocumentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface DeleteDocumentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
}

export interface DeleteDocumentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface DeleteMovimentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface DeleteMovimentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
    id: number;
}

export interface DeleteMovimentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface DeleteMovimentoMortoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface DeleteMovimentoTrattamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface DownloadFileDocumentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetDocumentiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetDocumentiAccasamentiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetDocumentiByTipoRequest {
    idFiliera: number;
    idCiclo: number;
    tipo: string;
}

export interface GetDocumentiMacelliRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
}

export interface GetDocumentiMangimeRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetDocumentoAccasamentoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetDocumentoAccasamentoDefaultRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetDocumentoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetDocumentoDefaultRequest {
    idFiliera: number;
    idCiclo: number;
    tipo: string;
}

export interface GetDocumentoMacelloByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
}

export interface GetDocumentoMacelloDefaultRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
}

export interface GetDocumentoMangimeByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetDocumentoMangimeDefaultRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetMovimentiAccasamentiRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetMovimentiMacelliRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
}

export interface GetMovimentiMangimiRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetMovimentiMortiRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetMovimentiTrattamentiRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
}

export interface GetMovimentoAccasamentoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface GetMovimentoMacelloByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
    id: number;
}

export interface GetMovimentoMangimeByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface GetMovimentoMortoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface GetMovimentoTrattamentoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
}

export interface InsertDocumentoRequest {
    idFiliera: number;
    idCiclo: number;
    documentoCreateDTO?: DocumentoCreateDTO;
}

export interface InsertDocumentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    documentoAccasamentoCreateDTO?: DocumentoAccasamentoCreateDTO;
}

export interface InsertDocumentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    documentoMacelloCreateDTO?: DocumentoMacelloCreateDTO;
}

export interface InsertDocumentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    documentoMangimeCreateDTO?: DocumentoMangimeCreateDTO;
}

export interface InsertMovimentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    movimentoAccasamentoCreateDTO?: MovimentoAccasamentoCreateDTO;
}

export interface InsertMovimentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
    movimentoMacelloCreateDTO?: MovimentoMacelloCreateDTO;
}

export interface InsertMovimentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    movimentoMangimeCreateDTO?: MovimentoMangimeCreateDTO;
}

export interface InsertMovimentoMortoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    movimentoMortoCreateDTO?: MovimentoMortoCreateDTO;
}

export interface InsertMovimentoTrattamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    movimentoTrattamentoCreateDTO?: MovimentoTrattamentoCreateDTO;
}

export interface UpdateDocumentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    documentoUpdateDTO?: DocumentoUpdateDTO;
}

export interface UpdateDocumentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    documentoAccasamentoUpdateDTO?: DocumentoAccasamentoUpdateDTO;
}

export interface UpdateDocumentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
    documentoMacelloUpdateDTO?: DocumentoMacelloUpdateDTO;
}

export interface UpdateDocumentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    documentoMangimeUpdateDTO?: DocumentoMangimeUpdateDTO;
}

export interface UpdateMovimentoAccasamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
    movimentoAccasamentoUpdateDTO?: MovimentoAccasamentoUpdateDTO;
}

export interface UpdateMovimentoMacelloRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idDocumento: number;
    id: number;
    movimentoMacelloUpdateDTO?: MovimentoMacelloUpdateDTO;
}

export interface UpdateMovimentoMangimeRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
    movimentoMangimeUpdateDTO?: MovimentoMangimeUpdateDTO;
}

export interface UpdateMovimentoMortoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
    movimentoMortoUpdateDTO?: MovimentoMortoUpdateDTO;
}

export interface UpdateMovimentoTrattamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    id: number;
    movimentoTrattamentoUpdateDTO?: MovimentoTrattamentoUpdateDTO;
}

export interface UploadFileDocumentoRequest {
    idFiliera: number;
    idCiclo: number;
    idDocumento: number;
    file?: Blob;
}

/**
 * 
 */
export class DocumentiApi extends runtime.BaseAPI {

    /**
     * Elimina un documento esistente
     */
    async deleteDocumentoRaw(requestParameters: DeleteDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteDocumento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteDocumento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteDocumento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un documento esistente
     */
    async deleteDocumento(requestParameters: DeleteDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento accasamento esistente
     */
    async deleteDocumentoAccasamentoRaw(requestParameters: DeleteDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteDocumentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteDocumentoAccasamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteDocumentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento accasamento esistente
     */
    async deleteDocumentoAccasamento(requestParameters: DeleteDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentoAccasamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento macello esistente
     */
    async deleteDocumentoMacelloRaw(requestParameters: DeleteDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteDocumentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteDocumentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling deleteDocumentoMacello.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteDocumentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento macello esistente
     */
    async deleteDocumentoMacello(requestParameters: DeleteDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentoMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento di mangime esistente
     */
    async deleteDocumentoMangimeRaw(requestParameters: DeleteDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteDocumentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteDocumentoMangime.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteDocumentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un documento di mangime esistente
     */
    async deleteDocumentoMangime(requestParameters: DeleteDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentoMangimeRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento accasamento esistente
     */
    async deleteMovimentoAccasamentoRaw(requestParameters: DeleteMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteMovimentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteMovimentoAccasamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteMovimentoAccasamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMovimentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Accasamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento accasamento esistente
     */
    async deleteMovimentoAccasamento(requestParameters: DeleteMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMovimentoAccasamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Macello esistente
     */
    async deleteMovimentoMacelloRaw(requestParameters: DeleteMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteMovimentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteMovimentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling deleteMovimentoMacello.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteMovimentoMacello.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMovimentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}/Dettagli/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Macello esistente
     */
    async deleteMovimentoMacello(requestParameters: DeleteMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMovimentoMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Mangime esistente
     */
    async deleteMovimentoMangimeRaw(requestParameters: DeleteMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteMovimentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteMovimentoMangime.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteMovimentoMangime.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMovimentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Mangime esistente
     */
    async deleteMovimentoMangime(requestParameters: DeleteMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMovimentoMangimeRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Morto esistente
     */
    async deleteMovimentoMortoRaw(requestParameters: DeleteMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteMovimentoMorto.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteMovimentoMorto.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteMovimentoMorto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMovimentoMorto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Morti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Morto esistente
     */
    async deleteMovimentoMorto(requestParameters: DeleteMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMovimentoMortoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Trattamento esistente
     */
    async deleteMovimentoTrattamentoRaw(requestParameters: DeleteMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteMovimentoTrattamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteMovimentoTrattamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling deleteMovimentoTrattamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMovimentoTrattamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Trattamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un movimento Trattamento esistente
     */
    async deleteMovimentoTrattamento(requestParameters: DeleteMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMovimentoTrattamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera un file legato al documento
     */
    async downloadFileDocumentoRaw(requestParameters: DownloadFileDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling downloadFileDocumento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling downloadFileDocumento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling downloadFileDocumento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/File`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera un file legato al documento
     */
    async downloadFileDocumento(requestParameters: DownloadFileDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFileDocumentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i documenti di un ciclo di una filiera
     */
    async getDocumentiRaw(requestParameters: GetDocumentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i documenti di un ciclo di una filiera
     */
    async getDocumenti(requestParameters: GetDocumentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentoDTO>> {
        const response = await this.getDocumentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i DocumentiAccasamenti di un ciclo di una filiera
     */
    async getDocumentiAccasamentiRaw(requestParameters: GetDocumentiAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentoAccasamentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentiAccasamenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentiAccasamenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentoAccasamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i DocumentiAccasamenti di un ciclo di una filiera
     */
    async getDocumentiAccasamenti(requestParameters: GetDocumentiAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentoAccasamentoDTO>> {
        const response = await this.getDocumentiAccasamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i documenti di un ciclo di una filiera
     */
    async getDocumentiByTipoRaw(requestParameters: GetDocumentiByTipoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentiByTipo.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentiByTipo.');
        }

        if (requestParameters.tipo === null || requestParameters.tipo === undefined) {
            throw new runtime.RequiredError('tipo','Required parameter requestParameters.tipo was null or undefined when calling getDocumentiByTipo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{tipo}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"tipo"}}`, encodeURIComponent(String(requestParameters.tipo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i documenti di un ciclo di una filiera
     */
    async getDocumentiByTipo(requestParameters: GetDocumentiByTipoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentoDTO>> {
        const response = await this.getDocumentiByTipoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i DocumentiMacelli di un carico di un ciclo di una filiera
     */
    async getDocumentiMacelliRaw(requestParameters: GetDocumentiMacelliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentoMacelloDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentiMacelli.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentiMacelli.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getDocumentiMacelli.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentoMacelloDTOFromJSON));
    }

    /**
     * Elenco di tutti i DocumentiMacelli di un carico di un ciclo di una filiera
     */
    async getDocumentiMacelli(requestParameters: GetDocumentiMacelliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentoMacelloDTO>> {
        const response = await this.getDocumentiMacelliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i documenti di mangime di un ciclo di una filiera
     */
    async getDocumentiMangimeRaw(requestParameters: GetDocumentiMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentoMangimeDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentiMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentiMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentoMangimeDTOFromJSON));
    }

    /**
     * Elenco di tutti i documenti di mangime di un ciclo di una filiera
     */
    async getDocumentiMangime(requestParameters: GetDocumentiMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentoMangimeDTO>> {
        const response = await this.getDocumentiMangimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un documento accasamento per idDocumento
     */
    async getDocumentoAccasamentoByIdRaw(requestParameters: GetDocumentoAccasamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoAccasamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoAccasamentoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoAccasamentoById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getDocumentoAccasamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoAccasamentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un documento accasamento per idDocumento
     */
    async getDocumentoAccasamentoById(requestParameters: GetDocumentoAccasamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoAccasamentoDTO> {
        const response = await this.getDocumentoAccasamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoAccasamentoDefaultRaw(requestParameters: GetDocumentoAccasamentoDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoAccasamentoCreateDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoAccasamentoDefault.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoAccasamentoDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti/default`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoAccasamentoCreateDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoAccasamentoDefault(requestParameters: GetDocumentoAccasamentoDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoAccasamentoCreateDTO> {
        const response = await this.getDocumentoAccasamentoDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un documento per id
     */
    async getDocumentoByIdRaw(requestParameters: GetDocumentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getDocumentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un documento per id
     */
    async getDocumentoById(requestParameters: GetDocumentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoDTO> {
        const response = await this.getDocumentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoDefaultRaw(requestParameters: GetDocumentoDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoDefaultDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoDefault.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoDefault.');
        }

        if (requestParameters.tipo === null || requestParameters.tipo === undefined) {
            throw new runtime.RequiredError('tipo','Required parameter requestParameters.tipo was null or undefined when calling getDocumentoDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{tipo}/default`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"tipo"}}`, encodeURIComponent(String(requestParameters.tipo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoDefaultDTOFromJSON(jsonValue));
    }

    /**
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoDefault(requestParameters: GetDocumentoDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoDefaultDTO> {
        const response = await this.getDocumentoDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un documento macello per idDocumento
     */
    async getDocumentoMacelloByIdRaw(requestParameters: GetDocumentoMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMacelloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoMacelloById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoMacelloById.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getDocumentoMacelloById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getDocumentoMacelloById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMacelloDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un documento macello per idDocumento
     */
    async getDocumentoMacelloById(requestParameters: GetDocumentoMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMacelloDTO> {
        const response = await this.getDocumentoMacelloByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoMacelloDefaultRaw(requestParameters: GetDocumentoMacelloDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMacelloCreateDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoMacelloDefault.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoMacelloDefault.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getDocumentoMacelloDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/default`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMacelloCreateDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoMacelloDefault(requestParameters: GetDocumentoMacelloDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMacelloCreateDTO> {
        const response = await this.getDocumentoMacelloDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un documento di mangime per idDocumento
     */
    async getDocumentoMangimeByIdRaw(requestParameters: GetDocumentoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMangimeDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoMangimeById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoMangimeById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getDocumentoMangimeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un documento di mangime per idDocumento
     */
    async getDocumentoMangimeById(requestParameters: GetDocumentoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMangimeDTO> {
        const response = await this.getDocumentoMangimeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoMangimeDefaultRaw(requestParameters: GetDocumentoMangimeDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMangimeCreateDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getDocumentoMangimeDefault.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getDocumentoMangimeDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime/default`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMangimeCreateDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Restituisce i dati predefiniti per un nuovo documento
     */
    async getDocumentoMangimeDefault(requestParameters: GetDocumentoMangimeDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMangimeCreateDTO> {
        const response = await this.getDocumentoMangimeDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i movimenti accasamenti di un ciclo di una filiera
     */
    async getMovimentiAccasamentiRaw(requestParameters: GetMovimentiAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MovimentoAccasamentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentiAccasamenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentiAccasamenti.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentiAccasamenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Accasamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MovimentoAccasamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i movimenti accasamenti di un ciclo di una filiera
     */
    async getMovimentiAccasamenti(requestParameters: GetMovimentiAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MovimentoAccasamentoDTO>> {
        const response = await this.getMovimentiAccasamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i movimenti Macelli di un ciclo di una filiera
     */
    async getMovimentiMacelliRaw(requestParameters: GetMovimentiMacelliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MovimentoMacelloDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentiMacelli.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentiMacelli.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getMovimentiMacelli.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentiMacelli.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}/Dettagli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MovimentoMacelloDTOFromJSON));
    }

    /**
     * Elenco di tutti i movimenti Macelli di un ciclo di una filiera
     */
    async getMovimentiMacelli(requestParameters: GetMovimentiMacelliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MovimentoMacelloDTO>> {
        const response = await this.getMovimentiMacelliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i movimenti Mangimi di un ciclo di una filiera
     */
    async getMovimentiMangimiRaw(requestParameters: GetMovimentiMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MovimentoMangimeDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentiMangimi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentiMangimi.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentiMangimi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Mangimi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MovimentoMangimeDTOFromJSON));
    }

    /**
     * Elenco di tutti i movimenti Mangimi di un ciclo di una filiera
     */
    async getMovimentiMangimi(requestParameters: GetMovimentiMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MovimentoMangimeDTO>> {
        const response = await this.getMovimentiMangimiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i movimenti Morti di un ciclo di una filiera
     */
    async getMovimentiMortiRaw(requestParameters: GetMovimentiMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MovimentoMortoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentiMorti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentiMorti.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentiMorti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Morti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MovimentoMortoDTOFromJSON));
    }

    /**
     * Elenco di tutti i movimenti Morti di un ciclo di una filiera
     */
    async getMovimentiMorti(requestParameters: GetMovimentiMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MovimentoMortoDTO>> {
        const response = await this.getMovimentiMortiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i movimenti Trattamenti di un ciclo di una filiera
     */
    async getMovimentiTrattamentiRaw(requestParameters: GetMovimentiTrattamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MovimentoTrattamentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentiTrattamenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentiTrattamenti.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentiTrattamenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Trattamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MovimentoTrattamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i movimenti Trattamenti di un ciclo di una filiera
     */
    async getMovimentiTrattamenti(requestParameters: GetMovimentiTrattamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MovimentoTrattamentoDTO>> {
        const response = await this.getMovimentiTrattamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un movimento accasamento per id
     */
    async getMovimentoAccasamentoByIdRaw(requestParameters: GetMovimentoAccasamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoAccasamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentoAccasamentoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentoAccasamentoById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentoAccasamentoById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMovimentoAccasamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Accasamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoAccasamentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un movimento accasamento per id
     */
    async getMovimentoAccasamentoById(requestParameters: GetMovimentoAccasamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoAccasamentoDTO> {
        const response = await this.getMovimentoAccasamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un movimento Macello per id
     */
    async getMovimentoMacelloByIdRaw(requestParameters: GetMovimentoMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMacelloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentoMacelloById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentoMacelloById.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getMovimentoMacelloById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentoMacelloById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMovimentoMacelloById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}/Dettagli/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMacelloDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un movimento Macello per id
     */
    async getMovimentoMacelloById(requestParameters: GetMovimentoMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMacelloDTO> {
        const response = await this.getMovimentoMacelloByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un movimento Mangime per id
     */
    async getMovimentoMangimeByIdRaw(requestParameters: GetMovimentoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMangimeDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentoMangimeById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentoMangimeById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentoMangimeById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMovimentoMangimeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un movimento Mangime per id
     */
    async getMovimentoMangimeById(requestParameters: GetMovimentoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMangimeDTO> {
        const response = await this.getMovimentoMangimeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un movimento Morto per id
     */
    async getMovimentoMortoByIdRaw(requestParameters: GetMovimentoMortoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMortoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentoMortoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentoMortoById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentoMortoById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMovimentoMortoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Morti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMortoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un movimento Morto per id
     */
    async getMovimentoMortoById(requestParameters: GetMovimentoMortoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMortoDTO> {
        const response = await this.getMovimentoMortoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un movimento Trattamento per id
     */
    async getMovimentoTrattamentoByIdRaw(requestParameters: GetMovimentoTrattamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoTrattamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getMovimentoTrattamentoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getMovimentoTrattamentoById.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling getMovimentoTrattamentoById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMovimentoTrattamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Trattamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoTrattamentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un movimento Trattamento per id
     */
    async getMovimentoTrattamentoById(requestParameters: GetMovimentoTrattamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoTrattamentoDTO> {
        const response = await this.getMovimentoTrattamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo documento
     */
    async insertDocumentoRaw(requestParameters: InsertDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertDocumento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertDocumento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoCreateDTOToJSON(requestParameters.documentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo documento
     */
    async insertDocumento(requestParameters: InsertDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoDTO> {
        const response = await this.insertDocumentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento accasamento
     */
    async insertDocumentoAccasamentoRaw(requestParameters: InsertDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoAccasamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertDocumentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertDocumentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoAccasamentoCreateDTOToJSON(requestParameters.documentoAccasamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoAccasamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento accasamento
     */
    async insertDocumentoAccasamento(requestParameters: InsertDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoAccasamentoDTO> {
        const response = await this.insertDocumentoAccasamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento macello
     */
    async insertDocumentoMacelloRaw(requestParameters: InsertDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMacelloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertDocumentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertDocumentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling insertDocumentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoMacelloCreateDTOToJSON(requestParameters.documentoMacelloCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMacelloDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento macello
     */
    async insertDocumentoMacello(requestParameters: InsertDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMacelloDTO> {
        const response = await this.insertDocumentoMacelloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento di mangime
     */
    async insertDocumentoMangimeRaw(requestParameters: InsertDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentoMangimeDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertDocumentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertDocumentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoMangimeCreateDTOToJSON(requestParameters.documentoMangimeCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo documento di mangime
     */
    async insertDocumentoMangime(requestParameters: InsertDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentoMangimeDTO> {
        const response = await this.insertDocumentoMangimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento accasamento in anagrafica
     */
    async insertMovimentoAccasamentoRaw(requestParameters: InsertMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoAccasamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertMovimentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertMovimentoAccasamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling insertMovimentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Accasamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoAccasamentoCreateDTOToJSON(requestParameters.movimentoAccasamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoAccasamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento accasamento in anagrafica
     */
    async insertMovimentoAccasamento(requestParameters: InsertMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoAccasamentoDTO> {
        const response = await this.insertMovimentoAccasamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Macello in anagrafica
     */
    async insertMovimentoMacelloRaw(requestParameters: InsertMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMacelloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertMovimentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertMovimentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling insertMovimentoMacello.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling insertMovimentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}/Dettagli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMacelloCreateDTOToJSON(requestParameters.movimentoMacelloCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMacelloDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Macello in anagrafica
     */
    async insertMovimentoMacello(requestParameters: InsertMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMacelloDTO> {
        const response = await this.insertMovimentoMacelloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Mangime in anagrafica
     */
    async insertMovimentoMangimeRaw(requestParameters: InsertMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMangimeDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertMovimentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertMovimentoMangime.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling insertMovimentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Mangimi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMangimeCreateDTOToJSON(requestParameters.movimentoMangimeCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Mangime in anagrafica
     */
    async insertMovimentoMangime(requestParameters: InsertMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMangimeDTO> {
        const response = await this.insertMovimentoMangimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Morto in anagrafica
     */
    async insertMovimentoMortoRaw(requestParameters: InsertMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoMortoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertMovimentoMorto.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertMovimentoMorto.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling insertMovimentoMorto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Morti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMortoCreateDTOToJSON(requestParameters.movimentoMortoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoMortoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Morto in anagrafica
     */
    async insertMovimentoMorto(requestParameters: InsertMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoMortoDTO> {
        const response = await this.insertMovimentoMortoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Trattamento in anagrafica
     */
    async insertMovimentoTrattamentoRaw(requestParameters: InsertMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MovimentoTrattamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertMovimentoTrattamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertMovimentoTrattamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling insertMovimentoTrattamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Trattamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoTrattamentoCreateDTOToJSON(requestParameters.movimentoTrattamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovimentoTrattamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo movimento Trattamento in anagrafica
     */
    async insertMovimentoTrattamento(requestParameters: InsertMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MovimentoTrattamentoDTO> {
        const response = await this.insertMovimentoTrattamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aggiorna un documento esistente
     */
    async updateDocumentoRaw(requestParameters: UpdateDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateDocumento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateDocumento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateDocumento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoUpdateDTOToJSON(requestParameters.documentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un documento esistente
     */
    async updateDocumento(requestParameters: UpdateDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDocumentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento accasamento esistente
     */
    async updateDocumentoAccasamentoRaw(requestParameters: UpdateDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateDocumentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateDocumentoAccasamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateDocumentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Accasamenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoAccasamentoUpdateDTOToJSON(requestParameters.documentoAccasamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento accasamento esistente
     */
    async updateDocumentoAccasamento(requestParameters: UpdateDocumentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDocumentoAccasamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento macello esistente
     */
    async updateDocumentoMacelloRaw(requestParameters: UpdateDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateDocumentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateDocumentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling updateDocumentoMacello.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateDocumentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoMacelloUpdateDTOToJSON(requestParameters.documentoMacelloUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento macello esistente
     */
    async updateDocumentoMacello(requestParameters: UpdateDocumentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDocumentoMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento di mangime esistente
     */
    async updateDocumentoMangimeRaw(requestParameters: UpdateDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateDocumentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateDocumentoMangime.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateDocumentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/Mangime/{idDocumento}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentoMangimeUpdateDTOToJSON(requestParameters.documentoMangimeUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un documento di mangime esistente
     */
    async updateDocumentoMangime(requestParameters: UpdateDocumentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDocumentoMangimeRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento accasamento esistente
     */
    async updateMovimentoAccasamentoRaw(requestParameters: UpdateMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateMovimentoAccasamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateMovimentoAccasamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateMovimentoAccasamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMovimentoAccasamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Accasamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoAccasamentoUpdateDTOToJSON(requestParameters.movimentoAccasamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento accasamento esistente
     */
    async updateMovimentoAccasamento(requestParameters: UpdateMovimentoAccasamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMovimentoAccasamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Macello esistente
     */
    async updateMovimentoMacelloRaw(requestParameters: UpdateMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateMovimentoMacello.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateMovimentoMacello.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling updateMovimentoMacello.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateMovimentoMacello.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMovimentoMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Documenti/{idDocumento}/Dettagli/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMacelloUpdateDTOToJSON(requestParameters.movimentoMacelloUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Macello esistente
     */
    async updateMovimentoMacello(requestParameters: UpdateMovimentoMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMovimentoMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Mangime esistente
     */
    async updateMovimentoMangimeRaw(requestParameters: UpdateMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateMovimentoMangime.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateMovimentoMangime.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateMovimentoMangime.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMovimentoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Mangimi/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMangimeUpdateDTOToJSON(requestParameters.movimentoMangimeUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Mangime esistente
     */
    async updateMovimentoMangime(requestParameters: UpdateMovimentoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMovimentoMangimeRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Morto esistente
     */
    async updateMovimentoMortoRaw(requestParameters: UpdateMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateMovimentoMorto.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateMovimentoMorto.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateMovimentoMorto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMovimentoMorto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Morti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoMortoUpdateDTOToJSON(requestParameters.movimentoMortoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Morto esistente
     */
    async updateMovimentoMorto(requestParameters: UpdateMovimentoMortoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMovimentoMortoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Trattamento esistente
     */
    async updateMovimentoTrattamentoRaw(requestParameters: UpdateMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateMovimentoTrattamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateMovimentoTrattamento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling updateMovimentoTrattamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMovimentoTrattamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/Dettagli/Trattamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MovimentoTrattamentoUpdateDTOToJSON(requestParameters.movimentoTrattamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un movimento Trattamento esistente
     */
    async updateMovimentoTrattamento(requestParameters: UpdateMovimentoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMovimentoTrattamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Carica un file legato al documento, se è già presente lo sostituisce
     */
    async uploadFileDocumentoRaw(requestParameters: UploadFileDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling uploadFileDocumento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling uploadFileDocumento.');
        }

        if (requestParameters.idDocumento === null || requestParameters.idDocumento === undefined) {
            throw new runtime.RequiredError('idDocumento','Required parameter requestParameters.idDocumento was null or undefined when calling uploadFileDocumento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Documenti/{idDocumento}/File`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idDocumento"}}`, encodeURIComponent(String(requestParameters.idDocumento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Carica un file legato al documento, se è già presente lo sostituisce
     */
    async uploadFileDocumento(requestParameters: UploadFileDocumentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadFileDocumentoRaw(requestParameters, initOverrides);
    }

}
