import React, { useContext, useRef, useEffect, useState } from 'react';
import { EmptyItem, Item } from 'devextreme-react/form';
import PopupUploadFile, { UploadFilePopupHandle } from '../../../components/popupUploadFile/popupUploadFile';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import { tecniciDataSource } from '../../../datasources/tecniciDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { MovimentiTrattamentiDataSource } from '../../../datasources/movimentiTrattamentiDataSource';
import { tipiTrattamentiDataSource } from '../../../datasources/tipiTrattamentiDataSource';
import { CicloDTO } from '../../../data';
import { capannoniDataSource } from '../../../datasources/capannoniDataSource';


type TrattamentiTableProps = {
    ciclo: CicloDTO,
    idDocumento: number,
    onChanged: ()=>void,
    readonly?: boolean
};

const TrattamentiTable: React.FC<TrattamentiTableProps> = (params) => {
    const { currentFiliera } = useContext(FilieraContext);

    function grid_InitNewRow(e: any) {
        console.log(e);
        e.data.idTipoAnimale = 1;
        e.data.quantita = 0;
        e.data.valore = 0;
    }

    return (
        <React.Fragment>

            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={MovimentiTrattamentiDataSource(currentFiliera.Id, Number(params.ciclo.id), params.idDocumento)}
                showBorders={false}
                focusedRowEnabled={true}
                errorRowEnabled={false}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
                onRowRemoved={params.onChanged}
                onRowUpdated={params.onChanged}
                onRowInserted={params.onChanged}
                onDataErrorOccurred={dataGridErrorHandling}
                onInitNewRow={grid_InitNewRow}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!params.readonly} allowDeleting={true}>
                    <Form labelMode='floating'>
                        <Item itemType="group" caption="Informazioni Trattamento" colCount={2} colSpan={2}>
                            {/* <Item dataField="idTipoAnimale" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} isRequired={true} /> */}
                            <Item dataField="idTipoTrattamento" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} isRequired={true} />
                            {/* <Item dataField="quantita" editorType='dxNumberBox' isRequired={true}/> */}
                            {/* <Item dataField="valore" editorType='dxNumberBox' isRequired={true} /> */}
                            <Item dataField="idTecnico" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} isRequired={true} />
                            <Item dataField="dataInizio" editorType='dxDateBox' isRequired={true}/>
                            <Item dataField="dataFine" editorType='dxDateBox' isRequired={true}/>
                            {/* <Item dataField="valoreFixed" /> */}
                            <Item dataField="idCapannone" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                            <EmptyItem/>
                            <Item dataField="note" colSpan={2} editorType='dxTextArea' />
                        </Item>
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={params.readonly} />
                    <Button name="delete" disabled={params.readonly} />
                </Column>
                <Column dataField={'id'} width={90} hidingPriority={2} allowEditing={false} />
                {/* <Column dataField={'quantita'} caption={'Quantità'} hidingPriority={14} /> */}
                <Column dataField={'note'} caption={'Note'} hidingPriority={13} />
                {/* <Column dataField={'idTipoAnimale'} caption={'TipoAnimale'} hidingPriority={12}>
                    <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr="descrizione" />
                </Column> */}
                <Column dataField={'idCapannone'} caption={'Capannone'} >
                    <Lookup dataSource={capannoniDataSource(Number(params.ciclo.allevamento?.idAllevatore),Number(params.ciclo.idAllevamento))} valueExpr="id" displayExpr={"numero"} allowClearing={true}/>
                </Column>
                <Column dataField={'idTipoTrattamento'} caption={'Tipo trattamento'} hidingPriority={11}>
                    <Lookup dataSource={tipiTrattamentiDataSource} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column dataField={'idTecnico'} caption={'Tecnico'} hidingPriority={10} visible={false}>
                    <Lookup dataSource={tecniciDataSource} valueExpr="id" displayExpr="ragioneSociale" />
                </Column>
                {/* <Column dataField={'valore'} caption={'Valore'} hidingPriority={9} /> */}
                <Column dataField={'dataInizio'} caption={'Data inizio'} dataType='date' hidingPriority={8} />
                <Column dataField={'dataFine'} caption={'Data fine'} dataType='date' hidingPriority={7} />
                {/* <Column dataField={'valoreFixed'} caption={'Forzatura Prezzo'} dataType='number' /> */}

                {/* <Column dataField={'tipoAnimale.codice'} caption={'Codice'} hidingPriority={6} /> */}
                {/* <Column dataField={'tipoAnimale.descrizione'} caption={'Descrizione animale'} hidingPriority={5} /> */}
                {/* <Column dataField={'tipoTrattamento.descrizione'} caption={'Descrizione trattamento'} hidingPriority={4} /> */}
                <Column dataField={'tecnico.ragioneSociale'} caption={'Ragione sociale tecnico'} hidingPriority={3} />
                <Column dataField={'tecnico.cognome'} caption={'Cognome tecnico'} hidingPriority={2} />
                <Column dataField={'tecnico.nome'} caption={'Nome tecnico'} hidingPriority={1} />
            </DataGrid>
        </React.Fragment>
    );
};

export default TrattamentiTable;