import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Column, Editing, FilterRow, Form, Lookup, Pager, Paging, RequiredRule } from "devextreme-react/data-grid";
import { contattiDataSource } from "../../datasources/contattiDataSource";
import { tipiContattiDataSource } from "../../datasources/tipiContattiDataSource";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import { Item } from "devextreme-react/form";
import { useScreenSize } from "../../utils/media-query";

type Props={}

export type ContattiPopupHandle = {
  show: (idAnagrafica:number) => void,
}

type state ={
  idAnagrafica:number|undefined,
  visible:boolean,
  datasource:any
}

const PopupContatti: React.ForwardRefRenderFunction<ContattiPopupHandle, Props> = (props,ref) =>
{
  const [state, setState] = useState<state>({idAnagrafica:undefined, visible:false, datasource:null});
  const { isXSmall, isSmall } = useScreenSize();
  
  function hideInfo() {
    setState({idAnagrafica:undefined, visible:false, datasource:null});
  }

  useImperativeHandle(ref, () => ({
    show(idAnagrafica) {
      setState({
        idAnagrafica:idAnagrafica, 
        visible:true, 
        datasource:contattiDataSource(idAnagrafica)
      });
    }
  }));
  
  return (<React.Fragment>
      <Popup
        visible={state.visible}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        fullScreen={isXSmall || isSmall}
        onHidden={hideInfo}
        title="Contatti"
        container=".dx-viewport"
      >
        <Position
          at="center"
          my="center"
          of=""
          collision="fit"
        />
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            options={{
              text: 'Chiudi',
              onClick: hideInfo,
            }}
          />
        <DataGrid
        className={'dx-card wide-card'}
        dataSource={state.datasource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Contatto" showTitle={true}  />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="idTipoContatto" />
                <Item dataField="valore" />
              </Item>
            </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />

        <Column  type='buttons' />
        <Column dataField={'id'} width={90}  allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'idTipoContatto'} caption={'Tipo contatto'} hidingPriority={1}>
          <RequiredRule />
          <Lookup dataSource={tipiContattiDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>
        <Column dataField={'valore'} />
      </DataGrid>
      </Popup>

  </React.Fragment>
  );
}
    
export default React.forwardRef(PopupContatti);
