import Form, { ButtonItem, EmptyItem, Item, Label, SimpleItem } from 'devextreme-react/form';
import { ClickEvent } from 'devextreme/ui/button';
import React, { useContext, useRef, useState } from 'react';
import PopupReportRiepilogoCapi, { ReportRiepilogoCapiPopupHandle } from '../../components/popupReportRiepilogoCapi/popupReportRiepilogoCapi';
import FilieraContext from '../../contexts/filieraContext';

type data = {
    anno: number
}

export default function RegistroRiepilogoPage() {
    const [formData, setFormData] = useState<data>({ anno: new Date().getFullYear() });
    const reportRiepilogoCapiRef = useRef<ReportRiepilogoCapiPopupHandle>(null);
    const { currentFiliera } = useContext(FilieraContext);

    function print_click(e: ClickEvent) {
        //console.log(formData.anno);

        reportRiepilogoCapiRef.current?.show(currentFiliera.Id, formData.anno);
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Riepilogo capi</h2>
            <div className={'dx-card responsive-paddings buttonContainer'} style={{ textAlign: "center" }}>
                <Form id="form" colCount={3} formData={formData} labelMode='floating'>
                    <EmptyItem />
                    <Item dataField="anno" editorType='dxNumberBox' isRequired={true} />
                    <EmptyItem />
                    <EmptyItem />
                    <ButtonItem horizontalAlignment="center" colSpan={1}
                        buttonOptions={{
                            text: 'Stampa',
                            type: 'default',
                            onClick: print_click
                        }}
                    />
                </Form>
            </div>

            <PopupReportRiepilogoCapi ref={reportRiepilogoCapiRef}></PopupReportRiepilogoCapi>
        </React.Fragment>
    )
}

