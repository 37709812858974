import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "b52a3f4a-109f-407e-b6ac-9d6e898b4a54",
        authority: "https://login.microsoftonline.com/9a6bb95e-2985-4dfd-b3bf-18638a129013",
        redirectUri: `${window.location.origin}`,
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["https://api.gestionale.avigel.it/access_as_user"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
