import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi } from "../data/apis/CicliApi";

export function CarichiDataSource(
  idFiliera: number,
  idCiclo: number
) {
  const cicliApi = new CicliApi(apiConfiguration);

  return new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return cicliApi.getCarichi({
        idFiliera: idFiliera,
        idCiclo: idCiclo
      });
    },

    byKey:(key)=> {
      return cicliApi.getCaricoById({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico:key
      });
    },

    insert: (values) => {     
      return cicliApi.insertCarico({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        caricoCreateDTO: values
      });
    },

    update: (key, values) => {
      return cicliApi.updateCarico({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: key,
        caricoUpdateDTO: values
      });
    },

    remove: (key) => {
      return cicliApi.deleteCarico({
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        idCarico: key
      });
    }
  });
};

