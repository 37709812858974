import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { TipiAnimaliApi } from "../data/apis";

export function TipiAnimaliDataSource(
  idCiclo?: number
) {
  const tipiAnimaliApi = new TipiAnimaliApi(apiConfiguration);

  return new CustomStore({
    key: "id",
    load: (loadOptions) => {
      if (idCiclo)
      return tipiAnimaliApi.getTipoAnimaliByIdCiclo({id:idCiclo});
    else
    return tipiAnimaliApi.getTipiAnimali();
    },

    byKey: (key) => tipiAnimaliApi.getTipoAnimaliById({ id: key }),

    insert: (values) =>
      tipiAnimaliApi.insertTipoAnimale({
        tipoAnimaleCreateDTO: values
      }),

    update: (key, values) =>
      tipiAnimaliApi.updateTipoAnimale({
        id: key,
        tipoAnimaleUpdateDTO: values
      }),

    remove: (key) =>
      tipiAnimaliApi.deleteTipoAnimale({
        id: key
      })
  });
};