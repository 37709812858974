/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CapannoneUpdateDTO
 */
export interface CapannoneUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof CapannoneUpdateDTO
     */
    numero?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CapannoneUpdateDTO
     */
    superficie?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CapannoneUpdateDTO
     */
    doppioSilos?: boolean;
}

/**
 * Check if a given object implements the CapannoneUpdateDTO interface.
 */
export function instanceOfCapannoneUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CapannoneUpdateDTOFromJSON(json: any): CapannoneUpdateDTO {
    return CapannoneUpdateDTOFromJSONTyped(json, false);
}

export function CapannoneUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapannoneUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'superficie': !exists(json, 'superficie') ? undefined : json['superficie'],
        'doppioSilos': !exists(json, 'doppioSilos') ? undefined : json['doppioSilos'],
    };
}

export function CapannoneUpdateDTOToJSON(value?: CapannoneUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numero': value.numero,
        'superficie': value.superficie,
        'doppioSilos': value.doppioSilos,
    };
}

