import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useScreenSize } from "../../utils/media-query";
import DocumentAttachmentViewer from "../documentAttachmentViewer/documentAttachmentViewer";

// pdfjs.GlobalWorkerOptions.workerPort = new Worker(
//   'node_modules/pdfjs-dist/build/pdf.worker.entry.js',
// );

type Props = {}

export type DocumentAttachmentPopupHandle = {
    show: (idFiliera: number, idCiclo: number, idDocumento: number) => void,
}

type state = {
    idFiliera: number | undefined,
    idCiclo: number | undefined,
    idDocumento: number | undefined,
    visible: boolean
}

const PopupDocumentAttachment: React.ForwardRefRenderFunction<DocumentAttachmentPopupHandle, Props> = (props, ref) => {
    const [state, setState] = useState<state>({ idFiliera: undefined, idCiclo: undefined, idDocumento: undefined, visible: false });
    const { isXSmall, isSmall } = useScreenSize();

    function hideInfo() {
        setState({ idFiliera: undefined, idCiclo: undefined, idDocumento: undefined, visible: false });
    }

    useImperativeHandle(ref, () => ({
        async show(idFiliera, idCiclo, idDocumento) {

            setState({
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                idFiliera: idFiliera,
                visible: true
            });
        }
    }));


    return (<React.Fragment>
        <Popup
            visible={state.visible}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            fullScreen={isXSmall || isSmall}
            onHidden={hideInfo}
            title="Carica file"
            container=".dx-viewport"
        >
            <Position
                at="center"
                my="center"
                of=""
                collision="fit"
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="center"
                options={{
                    text: 'Chiudi',
                    onClick: hideInfo,
                }}
            />

            {state.visible &&
                <DocumentAttachmentViewer idFiliera={state.idFiliera} idCiclo={state.idCiclo} idDocumento={state.idDocumento} />
            }
        </Popup>
    </React.Fragment>
    );
}

export default React.forwardRef(PopupDocumentAttachment);
