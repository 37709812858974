/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContattoUpdateDTO
 */
export interface ContattoUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof ContattoUpdateDTO
     */
    idAnagrafe?: number;
    /**
     * 
     * @type {number}
     * @memberof ContattoUpdateDTO
     */
    idTipoContatto?: number;
    /**
     * 
     * @type {string}
     * @memberof ContattoUpdateDTO
     */
    valore?: string | null;
}

/**
 * Check if a given object implements the ContattoUpdateDTO interface.
 */
export function instanceOfContattoUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContattoUpdateDTOFromJSON(json: any): ContattoUpdateDTO {
    return ContattoUpdateDTOFromJSONTyped(json, false);
}

export function ContattoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContattoUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idAnagrafe': !exists(json, 'idAnagrafe') ? undefined : json['idAnagrafe'],
        'idTipoContatto': !exists(json, 'idTipoContatto') ? undefined : json['idTipoContatto'],
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
    };
}

export function ContattoUpdateDTOToJSON(value?: ContattoUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idAnagrafe': value.idAnagrafe,
        'idTipoContatto': value.idTipoContatto,
        'valore': value.valore,
    };
}

