import React, { useContext, useRef } from 'react';
import PopupUploadFile, { UploadFilePopupHandle } from '../../components/popupUploadFile/popupUploadFile';
import FilieraContext from '../../contexts/filieraContext';
import PopupDocumentAttachment, { DocumentAttachmentPopupHandle } from '../../components/popupDocumentAttachment/popupDocumentAttachment';
import { apiConfiguration } from '../../apiConfig';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Lookup,
  Toolbar,
  Item,
  Button as GridButton
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

import { dataGridErrorHandling } from '../../utils/errorHandling';
import { macelliDataSource } from '../../datasources/macelliDataSource';
import { mangimificiDataSource } from '../../datasources/mangimificiDataSource';
import PopupDocumento, { DocumentoPopupHandle } from '../../components/popupDocumento/popupDocumento';
import { documentiDataSource } from '../../datasources/documentiDataSource';
import { CicloDTO } from '../../data';

type Props = {
  ciclo: CicloDTO,
  tipo: string,
  readonly?: boolean
};

export default function DocumentsTable(params: Props) {
  const uploadRef = useRef<UploadFilePopupHandle>(null);
  const pdfViewerRef = useRef<DocumentAttachmentPopupHandle>(null);
  const documentoRef = useRef<DocumentoPopupHandle>(null);
  const gridRef = useRef<DataGrid>(null);
  const { currentFiliera } = useContext(FilieraContext);

  function openUploadFilePopup(e: any) {
    const uploadUrl: string = apiConfiguration.basePath + "/Filiere/" + currentFiliera.Id + "/Cicli/" + e.row.data.idCiclo + "/Documenti/" + e.row.data.id + "/File";

    uploadRef.current?.show(currentFiliera.Id, e.row.data.idCiclo, e.row.data.id, uploadUrl);
  }

  function HasAttachment(e: any) {
      return e.row.data.idArxivar && e.row.data.idArxivar !== 0;
  }
 
  function ShowAttachment(e: any) {
    pdfViewerRef.current?.show(currentFiliera.Id, Number(params.ciclo.id), e.row.data.id);
}

  function new_click(e: any) {
    if (params.tipo) {
      documentoRef.current?.new();
    }
  }

  function edit_click(e: any) {
    documentoRef.current?.edit(e.row.data.id);
  }

  function refreshGrid() {
    gridRef.current?.instance.refresh();
  }

  function allowAddingDeleting() { 
    return params.tipo=="Morti" && params.ciclo.registroCSDefinitivo ? false : true;
  }

  return (
    <React.Fragment>
      {params.ciclo.registroCSDefinitivo &&
        <div className={'dx-card'} style={{textAlign:"center", color:"red"}}>
            <h5>Registro C/S definitivo</h5>
        </div>
      }

      <DataGrid
        ref={gridRef}
        key={params.tipo}
        className={'dx-card wide-card'}
        dataSource={documentiDataSource(Number(currentFiliera.Id), Number(params.ciclo.id), params.tipo)}
        showBorders={false}
        focusedRowEnabled={true}
        errorRowEnabled={false}
        defaultFocusedRowIndex={0}
        defaultSelectionFilter=""
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onDataErrorOccurred={dataGridErrorHandling}
        // onInitNewRow={dataGridInitNewRow}
        onRowUpdating={function (e) {
          e.newData = Object.assign({}, e.oldData, e.newData);
        }}
      >
        <Editing allowUpdating={true} allowAdding={true} allowDeleting={true}>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column type="buttons" alignment='left'>
          <GridButton name="edit" onClick={edit_click}  />
          <GridButton name="delete" disabled={params.readonly || !allowAddingDeleting()} />
          <GridButton hint="Upload documento" icon="upload" onClick={openUploadFilePopup} disabled={params.readonly || !allowAddingDeleting()} />
          <GridButton hint="Visualizza documento" icon="doc" visible={HasAttachment} onClick={ShowAttachment} />
          {/* <Button hint="Dettagli Documento" icon="search" onClick={openDettagli} /> */}
        </Column>
        <Column dataField={'id'} width={90} hidingPriority={2} allowEditing={false} visible={false} />
        <Column dataField={'numeroDocumento'} caption={'Numero'} hidingPriority={8} />
        <Column dataField={'dataDocumento'} caption={'Data'} hidingPriority={7} />

        <Column dataField={'dataOperazione'} caption={'Data operazione'} hidingPriority={6} />
        <Column dataField={'note'} caption={'note'} hidingPriority={5} />

        {(() => {
          switch (params.tipo) {
            case 'Mangime':
              return (
                <Column dataField={'idAnagrafeMittente'} caption={'Magimificio'}>
                  <Lookup dataSource={mangimificiDataSource} valueExpr="id" displayExpr="ragioneSociale" />
                </Column>);
          }
        })()}

        {(() => {
          switch (params.tipo) {
            case 'Carichi':
              return (
                <Column dataField={'idAnagrafeDestinatario'} caption={'Macello'}>
                  <Lookup dataSource={macelliDataSource} valueExpr="id" displayExpr="ragioneSociale" />
                </Column>);
          }
        })()}

        {/* <Column dataField={'idTipoAnimale'} caption={'tipo Animale'} visible={false}>
          <Lookup dataSource={tipiAnimaliDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>

        <Column dataField={'quantita'} caption={'quantita'} visible={false} />
        <Column dataField={'valore'} caption={'valore'} visible={false} />
        <Column dataField={'dataInizio'} caption={'data inizio'} visible={false} />
        <Column dataField={'dataFine'} caption={'data fine'} visible={false} />

        <Column dataField={'idTipoTrattamento'} caption={'tipo trattamento'} visible={false}>
          <Lookup dataSource={tipiTrattamentiDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>

        <Column dataField={'idTecnico'} caption={'tecnico'} visible={false}>
          <Lookup dataSource={tecniciDataSource} valueExpr="id" displayExpr="ragioneSociale" />
        </Column> */}

        <Toolbar>
          <Item location="after">
            <Button
              icon='refresh'
              onClick={refreshGrid}
            />
            <Button
              icon='add'
              onClick={new_click}
              disabled={params.readonly || !allowAddingDeleting()}
            />
          </Item>
        </Toolbar>
      </DataGrid>

      <PopupDocumentAttachment ref={pdfViewerRef}/>

      <PopupUploadFile ref={uploadRef} onUploadCompleted={refreshGrid} allowedFileExtensions={[".pdf"]}></PopupUploadFile>
      <PopupDocumento ref={documentoRef} ciclo={params.ciclo} tipoDocumento={params.tipo} onChange={refreshGrid } readonly={params.readonly}></PopupDocumento>
    </React.Fragment>
  );
}
