import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import { useScreenSize } from "../../utils/media-query";
import ReportRegistroCaricoViewer from "../reportRegistroCaricoScaricoViewer/reportRegistroCaricoScaricoViewer";

// pdfjs.GlobalWorkerOptions.workerPort = new Worker(
//   'node_modules/pdfjs-dist/build/pdf.worker.entry.js',
// );

type Props = {}

export type ReportRegistroCaricoPopupHandle = {
  show: (idFiliera: number, dataDa: Date, dataA: Date, provvisorio: boolean) => void,
}

type state = {
  visible: boolean,
  idFiliera: number | undefined,
  dataDa: Date | undefined,
  dataA: Date | undefined,
  provvisorio: boolean | undefined
}

// const fileToBase64 = (file: File | Blob): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//      resolve(reader.result as string);
//     };

//     reader.readAsDataURL(file);
//     reader.onerror = reject;
//   });

const PopupReportRegistroCarico: React.ForwardRefRenderFunction<ReportRegistroCaricoPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ dataDa: undefined, dataA: undefined, visible: false, idFiliera: undefined, provvisorio: undefined });
  const { isXSmall, isSmall } = useScreenSize();

  function hideInfo() {
    //setState((prevState) => ({ ...prevState, visible: false }));
    setState({ dataDa: undefined, dataA: undefined, visible: false, idFiliera: undefined, provvisorio: undefined });
  }

  useImperativeHandle(ref, () => ({
    async show(idFiliera, dataDa, dataA, provvisorio) {
      console.log(idFiliera, dataDa, dataA, provvisorio);
      setState({
        visible: true,
        idFiliera: idFiliera,
        dataDa: dataDa,
        dataA: dataA,
        provvisorio: provvisorio
      });
    }
  }));


  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      fullScreen={isXSmall || isSmall}
      onHidden={hideInfo}
      title="Registro carico/scarico"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      {state.visible &&
        <ReportRegistroCaricoViewer key={Date.now()} idFiliera={state.idFiliera} dataDa={state.dataDa} dataA={state.dataA} provvisorio={state.provvisorio}/>
      }
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupReportRegistroCarico);
