/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovimentoMangimeUpdateDTO
 */
export interface MovimentoMangimeUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeUpdateDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoMangimeUpdateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeUpdateDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeUpdateDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeUpdateDTO
     */
    idTipoMangime?: number;
}

/**
 * Check if a given object implements the MovimentoMangimeUpdateDTO interface.
 */
export function instanceOfMovimentoMangimeUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoMangimeUpdateDTOFromJSON(json: any): MovimentoMangimeUpdateDTO {
    return MovimentoMangimeUpdateDTOFromJSONTyped(json, false);
}

export function MovimentoMangimeUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoMangimeUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoMangime': !exists(json, 'idTipoMangime') ? undefined : json['idTipoMangime'],
    };
}

export function MovimentoMangimeUpdateDTOToJSON(value?: MovimentoMangimeUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoMangime': value.idTipoMangime,
    };
}

