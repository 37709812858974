import React, { useImperativeHandle, useRef, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Button, Column, Editing, FilterRow, Form, Lookup, Pager, Paging, RequiredRule } from "devextreme-react/data-grid";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import { Item, Label, Tab, TabPanelOptions, TabbedItem } from "devextreme-react/form";
import { allevamentiByAllevatoreDataSource } from "../../datasources/allevamentiDataSource";
import { nazioneDataSource } from "../../datasources/nazioneDataSource";
import 'devextreme-react/text-area';
import { tecniciDataSource } from "../../datasources/tecniciDataSource";
import PopupCapannoni, { CapannoniPopupHandle } from "../popupCapannoni/popupCapannoni";
import { AnagraficheApi } from "../../data";
import { apiConfiguration } from "../../apiConfig";

type Props = {}

export type AllevamentiPopupHandle = {
  show: (idAllevatore: number) => void,
}

type state = {
  idAllevatore: number | undefined,
  visible: boolean,
  datasource: any,
  title:string
}

const PopupAllevamenti: React.ForwardRefRenderFunction<AllevamentiPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idAllevatore: undefined, visible: false, datasource: null, title:"" });
  const capannoniRef = useRef<CapannoniPopupHandle>(null);

  function hideInfo() {
    setState({ idAllevatore: undefined, visible: false, datasource: null, title:"" });
  }

  function openCapannoniPopup(e:any){
    capannoniRef.current?.show(state.idAllevatore as number, e.row.key);
  }

  useImperativeHandle(ref, () => ({
    show(idAllevatore) {
      const api = new AnagraficheApi(apiConfiguration);
      api.getAllevatoreById({idAllevatore}).then(data=>{
        setState({
          idAllevatore: idAllevatore,
          visible: true,
          datasource: allevamentiByAllevatoreDataSource(idAllevatore),
          title:"Allevamenti di " + data.ragioneSociale
        });
      });
    }
  }));

  function dataGridInitNewRow(e:any){
    e.data.idNazione="ITA";
    e.data.caricoGiorno=false;
    e.data.caricoMezzo= {
      autotreno:false,
      bilico:false,
      motrice:false
    };
    e.data.caricoOra={

    };
    e.data.idAllevatore=state.idAllevatore;
  }

  return (<React.Fragment>

    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      fullScreen={true}
      showTitle={true}
      onHidden={hideInfo}
      title={state.title}
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={state.datasource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
        onInitNewRow={dataGridInitNewRow}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Allevamento" showTitle={true} />
          <Form colCount={1}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              <Tab title="Dati allevamento" colCount={2}>
                <Item dataField="codiceAllevamento" />
                <Item dataField="descrizioneBreve" isRequired={true} />
                <Item dataField="personaRiferimento" />
                <Item dataField="idTecnico" />
                
                </Tab>
              <Tab title="Indirizzo">
                {/* <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="ragioneSociale" colSpan={2} />
                  <Item dataField="cognome" />
                  <Item dataField="nome" />
                </Item>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="partitaIva" />
                  <Item dataField="codiceFiscale" />
                </Item> */}

                <Item itemType="group" caption="Indirizzo" colCount={2} colSpan={2}>
                  <Item dataField="indirizzo" />
                  <Item dataField="numeroCivico" />
                  <Item dataField="cap" />
                  <Item dataField="localita" />
                  <Item dataField="provincia" />
                  <Item dataField="idNazione" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                </Item>
              </Tab>

              <Tab title="Carico" colCount={1}>
                <Item dataField="caricoGiorno" />
                <Item dataField="maxCaricoGiorno"  />
                <Item dataField="minutiTrasferimento" />
                <Item dataField="pesa" />
                <Item dataField="incompatibile" />
                <Item dataField="note" colSpan={2} editorType="dxTextArea" />

                <Item itemType="group" caption="Carico/ora" colCount={3} >
                  <Item dataField="caricoOra.rosticceria" />
                  <Item dataField="caricoOra.medi" />
                  <Item dataField="caricoOra.pesanti" />
                </Item>

                <Item itemType="group" caption="Carico/mezzo" colCount={3} >
                  <Item dataField="caricoMezzo.autotreno" editorType="dxCheckBox"><Label location="left" alignment="right" /></Item>
                  <Item dataField="caricoMezzo.bilico" editorType="dxCheckBox"><Label location="left" alignment="right" /></Item>
                  <Item dataField="caricoMezzo.motrice" editorType="dxCheckBox"><Label location="left" alignment="right" /></Item>
                </Item>
              </Tab>
            </TabbedItem>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />

        <Column  type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
          <Button hint="Capannoni" icon="home" onClick={openCapannoniPopup} />
        </Column>
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'codiceAllevamento'} />
        <Column dataField={'descrizioneBreve'}/>
        <Column dataField={'ragioneSociale'} visible={false}/>
        <Column dataField={'cognome'} visible={false}/>
        <Column dataField={'nome'} visible={false}/>
        <Column dataField={'indirizzo'} />
        <Column dataField={'numeroCivico'} />
        <Column dataField={'cap'} />
        <Column dataField={'localita'} />
        <Column dataField={'provincia'} />
        <Column dataField={'codiceFiscale'} />
        <Column dataField={'partitaIva'} />
        <Column dataField={'idNazione'} caption={'Nazione'}>
          <RequiredRule />
          <Lookup dataSource={nazioneDataSource} valueExpr="id" displayExpr="descrizione" />
        </Column>
        
        <Column dataField={'personaRiferimento'} visible={false} />
        <Column dataField={'idTecnico'} visible={false} caption={'Tecnico'} >
          <Lookup dataSource={tecniciDataSource} valueExpr={"id"} displayExpr={"ragioneSociale"} />
        </Column>
        <Column dataField={'caricoGiorno'} visible={false} dataType="boolean" />
        <Column dataField={'maxCaricoGiorno'} visible={false} />
        <Column dataField={'minutiTrasferimento'} visible={false} dataType="number" />
        <Column dataField={'pesa'} visible={false} />
        <Column dataField={'incompatibile'} visible={false} />
        <Column dataField={'note'} visible={false} />
        <Column dataField={'caricoOra.rosticceria'} visible={false} />
        <Column dataField={'caricoOra.medi'} visible={false} />
        <Column dataField={'caricoOra.pesanti'} visible={false} />
        <Column dataField={'caricoMezzo.autotreno'} visible={false} dataType="boolean"/>
        <Column dataField={'caricoMezzo.bilico'} visible={false} dataType="boolean"/>
        <Column dataField={'caricoMezzo.motrice'} visible={false} dataType="boolean"/>
      </DataGrid>
    </Popup>

    <PopupCapannoni ref={capannoniRef} ></PopupCapannoni>
  </React.Fragment>
  );
}

export default React.forwardRef(PopupAllevamenti);
