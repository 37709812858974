/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllevamentoCreateDTO,
  AllevamentoDTO,
  AllevamentoUpdateDTO,
  AllevatoreCreateDTO,
  AllevatoreDTO,
  AllevatoreUpdateDTO,
  CapannoneCreateDTO,
  CapannoneDTO,
  CapannoneUpdateDTO,
  FornitoreCreateDTO,
  FornitoreDTO,
  FornitoreUpdateDTO,
  IncubatoioCreateDTO,
  IncubatoioDTO,
  IncubatoioUpdateDTO,
  MacelloCreateDTO,
  MacelloDTO,
  MacelloUpdateDTO,
  MangimificioCreateDTO,
  MangimificioDTO,
  MangimificioUpdateDTO,
  ProblemDetails,
  TecnicoCreateDTO,
  TecnicoDTO,
  TecnicoUpdateDTO,
} from '../models';
import {
    AllevamentoCreateDTOFromJSON,
    AllevamentoCreateDTOToJSON,
    AllevamentoDTOFromJSON,
    AllevamentoDTOToJSON,
    AllevamentoUpdateDTOFromJSON,
    AllevamentoUpdateDTOToJSON,
    AllevatoreCreateDTOFromJSON,
    AllevatoreCreateDTOToJSON,
    AllevatoreDTOFromJSON,
    AllevatoreDTOToJSON,
    AllevatoreUpdateDTOFromJSON,
    AllevatoreUpdateDTOToJSON,
    CapannoneCreateDTOFromJSON,
    CapannoneCreateDTOToJSON,
    CapannoneDTOFromJSON,
    CapannoneDTOToJSON,
    CapannoneUpdateDTOFromJSON,
    CapannoneUpdateDTOToJSON,
    FornitoreCreateDTOFromJSON,
    FornitoreCreateDTOToJSON,
    FornitoreDTOFromJSON,
    FornitoreDTOToJSON,
    FornitoreUpdateDTOFromJSON,
    FornitoreUpdateDTOToJSON,
    IncubatoioCreateDTOFromJSON,
    IncubatoioCreateDTOToJSON,
    IncubatoioDTOFromJSON,
    IncubatoioDTOToJSON,
    IncubatoioUpdateDTOFromJSON,
    IncubatoioUpdateDTOToJSON,
    MacelloCreateDTOFromJSON,
    MacelloCreateDTOToJSON,
    MacelloDTOFromJSON,
    MacelloDTOToJSON,
    MacelloUpdateDTOFromJSON,
    MacelloUpdateDTOToJSON,
    MangimificioCreateDTOFromJSON,
    MangimificioCreateDTOToJSON,
    MangimificioDTOFromJSON,
    MangimificioDTOToJSON,
    MangimificioUpdateDTOFromJSON,
    MangimificioUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TecnicoCreateDTOFromJSON,
    TecnicoCreateDTOToJSON,
    TecnicoDTOFromJSON,
    TecnicoDTOToJSON,
    TecnicoUpdateDTOFromJSON,
    TecnicoUpdateDTOToJSON,
} from '../models';

export interface DeleteAllevamentoRequest {
    idAllevamento: number;
}

export interface DeleteAllevatoreRequest {
    idAllevatore: number;
}

export interface DeleteCapannoneRequest {
    idAllevamento: number;
    idCapannone: number;
}

export interface DeleteFornitoreRequest {
    idFornitore: number;
}

export interface DeleteIncubatoioRequest {
    idIncubatoio: number;
}

export interface DeleteMacelloRequest {
    idMacello: number;
}

export interface DeleteMangimificioRequest {
    idMangimificio: number;
}

export interface DeleteTecnicoRequest {
    idTecnico: number;
}

export interface GetAllevamentiRequest {
    showAll?: boolean;
}

export interface GetAllevamentiByAllevatoreRequest {
    idAllevatore: number;
    showAll?: boolean;
}

export interface GetAllevamentoByIdRequest {
    idAllevamento: number;
}

export interface GetAllevatoreByIdRequest {
    idAllevatore: number;
}

export interface GetAllevatoriRequest {
    showAll?: boolean;
}

export interface GetCapannoneByIdRequest {
    idAllevamento: number;
    idCapannone: number;
}

export interface GetCapannoniRequest {
    idAllevamento: number;
}

export interface GetFornitoreByIdRequest {
    idFornitore: number;
}

export interface GetFornitoriRequest {
    showAll?: boolean;
}

export interface GetIncubatoiRequest {
    showAll?: boolean;
}

export interface GetIncubatoiBycicloRequest {
    idCiclo: number;
    showAll?: boolean;
}

export interface GetIncubatoioByIdRequest {
    idIncubatoio: number;
}

export interface GetMacelliRequest {
    showAll?: boolean;
}

export interface GetMacelloByIdRequest {
    idMacello: number;
}

export interface GetMangimificiRequest {
    showAll?: boolean;
}

export interface GetMangimificioByIdRequest {
    idMangimificio: number;
}

export interface GetTecniciRequest {
    showAll?: boolean;
}

export interface GetTecnicoByIdRequest {
    idTecnico: number;
}

export interface InsertAllevamentoRequest {
    allevamentoCreateDTO?: AllevamentoCreateDTO;
}

export interface InsertAllevatoreRequest {
    allevatoreCreateDTO?: AllevatoreCreateDTO;
}

export interface InsertCapannoneRequest {
    idAllevamento: number;
    capannoneCreateDTO?: CapannoneCreateDTO;
}

export interface InsertFornitoreRequest {
    fornitoreCreateDTO?: FornitoreCreateDTO;
}

export interface InsertIncubatoioRequest {
    incubatoioCreateDTO?: IncubatoioCreateDTO;
}

export interface InsertMacelloRequest {
    macelloCreateDTO?: MacelloCreateDTO;
}

export interface InsertMangimificioRequest {
    mangimificioCreateDTO?: MangimificioCreateDTO;
}

export interface InsertTecnicoRequest {
    tecnicoCreateDTO?: TecnicoCreateDTO;
}

export interface UpdateAllevamentoRequest {
    idAllevamento: number;
    allevamentoUpdateDTO?: AllevamentoUpdateDTO;
}

export interface UpdateAllevatoreRequest {
    idAllevatore: number;
    allevatoreUpdateDTO?: AllevatoreUpdateDTO;
}

export interface UpdateCapannoneRequest {
    idAllevamento: number;
    idCapannone: number;
    capannoneUpdateDTO?: CapannoneUpdateDTO;
}

export interface UpdateFornitoreRequest {
    idFornitore: number;
    fornitoreUpdateDTO?: FornitoreUpdateDTO;
}

export interface UpdateIncubatoioRequest {
    idIncubatoio: number;
    incubatoioUpdateDTO?: IncubatoioUpdateDTO;
}

export interface UpdateMacelloRequest {
    idMacello: number;
    macelloUpdateDTO?: MacelloUpdateDTO;
}

export interface UpdateMangimificioRequest {
    idMangimificio: number;
    mangimificioUpdateDTO?: MangimificioUpdateDTO;
}

export interface UpdateTecnicoRequest {
    idTecnico: number;
    tecnicoUpdateDTO?: TecnicoUpdateDTO;
}

/**
 * 
 */
export class AnagraficheApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Allevamento esistente
     */
    async deleteAllevamentoRaw(requestParameters: DeleteAllevamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling deleteAllevamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Allevamento esistente
     */
    async deleteAllevamento(requestParameters: DeleteAllevamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllevamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un allevatore esistente
     */
    async deleteAllevatoreRaw(requestParameters: DeleteAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevatore === null || requestParameters.idAllevatore === undefined) {
            throw new runtime.RequiredError('idAllevatore','Required parameter requestParameters.idAllevatore was null or undefined when calling deleteAllevatore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori/{idAllevatore}`.replace(`{${"idAllevatore"}}`, encodeURIComponent(String(requestParameters.idAllevatore))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un allevatore esistente
     */
    async deleteAllevatore(requestParameters: DeleteAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllevatoreRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina una Capannone esistente
     */
    async deleteCapannoneRaw(requestParameters: DeleteCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling deleteCapannone.');
        }

        if (requestParameters.idCapannone === null || requestParameters.idCapannone === undefined) {
            throw new runtime.RequiredError('idCapannone','Required parameter requestParameters.idCapannone was null or undefined when calling deleteCapannone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}/Capannoni/{idCapannone}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))).replace(`{${"idCapannone"}}`, encodeURIComponent(String(requestParameters.idCapannone))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina una Capannone esistente
     */
    async deleteCapannone(requestParameters: DeleteCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCapannoneRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un fornitore esistente
     */
    async deleteFornitoreRaw(requestParameters: DeleteFornitoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFornitore === null || requestParameters.idFornitore === undefined) {
            throw new runtime.RequiredError('idFornitore','Required parameter requestParameters.idFornitore was null or undefined when calling deleteFornitore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Fornitori/{idFornitore}`.replace(`{${"idFornitore"}}`, encodeURIComponent(String(requestParameters.idFornitore))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un fornitore esistente
     */
    async deleteFornitore(requestParameters: DeleteFornitoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFornitoreRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un incubatoio esistente
     */
    async deleteIncubatoioRaw(requestParameters: DeleteIncubatoioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idIncubatoio === null || requestParameters.idIncubatoio === undefined) {
            throw new runtime.RequiredError('idIncubatoio','Required parameter requestParameters.idIncubatoio was null or undefined when calling deleteIncubatoio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi/{idIncubatoio}`.replace(`{${"idIncubatoio"}}`, encodeURIComponent(String(requestParameters.idIncubatoio))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un incubatoio esistente
     */
    async deleteIncubatoio(requestParameters: DeleteIncubatoioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIncubatoioRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un macello esistente
     */
    async deleteMacelloRaw(requestParameters: DeleteMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idMacello === null || requestParameters.idMacello === undefined) {
            throw new runtime.RequiredError('idMacello','Required parameter requestParameters.idMacello was null or undefined when calling deleteMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Macelli/{idMacello}`.replace(`{${"idMacello"}}`, encodeURIComponent(String(requestParameters.idMacello))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un macello esistente
     */
    async deleteMacello(requestParameters: DeleteMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un mangimificio esistente
     */
    async deleteMangimificioRaw(requestParameters: DeleteMangimificioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idMangimificio === null || requestParameters.idMangimificio === undefined) {
            throw new runtime.RequiredError('idMangimificio','Required parameter requestParameters.idMangimificio was null or undefined when calling deleteMangimificio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Mangimifici/{idMangimificio}`.replace(`{${"idMangimificio"}}`, encodeURIComponent(String(requestParameters.idMangimificio))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un mangimificio esistente
     */
    async deleteMangimificio(requestParameters: DeleteMangimificioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMangimificioRaw(requestParameters, initOverrides);
    }

    /**
     * Elimina un tecnico esistente
     */
    async deleteTecnicoRaw(requestParameters: DeleteTecnicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idTecnico === null || requestParameters.idTecnico === undefined) {
            throw new runtime.RequiredError('idTecnico','Required parameter requestParameters.idTecnico was null or undefined when calling deleteTecnico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Tecnici/{idTecnico}`.replace(`{${"idTecnico"}}`, encodeURIComponent(String(requestParameters.idTecnico))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina un tecnico esistente
     */
    async deleteTecnico(requestParameters: DeleteTecnicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTecnicoRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutti gli allevamenti
     */
    async getAllevamentiRaw(requestParameters: GetAllevamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AllevamentoDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AllevamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti gli allevamenti
     */
    async getAllevamenti(requestParameters: GetAllevamentiRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AllevamentoDTO>> {
        const response = await this.getAllevamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti gli allevamenti di un allevatore
     */
    async getAllevamentiByAllevatoreRaw(requestParameters: GetAllevamentiByAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AllevamentoDTO>>> {
        if (requestParameters.idAllevatore === null || requestParameters.idAllevatore === undefined) {
            throw new runtime.RequiredError('idAllevatore','Required parameter requestParameters.idAllevatore was null or undefined when calling getAllevamentiByAllevatore.');
        }

        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori/{idAllevatore}/Allevamenti`.replace(`{${"idAllevatore"}}`, encodeURIComponent(String(requestParameters.idAllevatore))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AllevamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti gli allevamenti di un allevatore
     */
    async getAllevamentiByAllevatore(requestParameters: GetAllevamentiByAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AllevamentoDTO>> {
        const response = await this.getAllevamentiByAllevatoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera una Allevamento per idAllevamento
     */
    async getAllevamentoByIdRaw(requestParameters: GetAllevamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllevamentoDTO>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling getAllevamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllevamentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una Allevamento per idAllevamento
     */
    async getAllevamentoById(requestParameters: GetAllevamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllevamentoDTO> {
        const response = await this.getAllevamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un allevatore per id
     */
    async getAllevatoreByIdRaw(requestParameters: GetAllevatoreByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllevatoreDTO>> {
        if (requestParameters.idAllevatore === null || requestParameters.idAllevatore === undefined) {
            throw new runtime.RequiredError('idAllevatore','Required parameter requestParameters.idAllevatore was null or undefined when calling getAllevatoreById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori/{idAllevatore}`.replace(`{${"idAllevatore"}}`, encodeURIComponent(String(requestParameters.idAllevatore))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllevatoreDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un allevatore per id
     */
    async getAllevatoreById(requestParameters: GetAllevatoreByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllevatoreDTO> {
        const response = await this.getAllevatoreByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti gli allevatori
     */
    async getAllevatoriRaw(requestParameters: GetAllevatoriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AllevatoreDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AllevatoreDTOFromJSON));
    }

    /**
     * Elenco di tutti gli allevatori
     */
    async getAllevatori(requestParameters: GetAllevatoriRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AllevatoreDTO>> {
        const response = await this.getAllevatoriRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera una Capannone per id
     */
    async getCapannoneByIdRaw(requestParameters: GetCapannoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CapannoneDTO>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling getCapannoneById.');
        }

        if (requestParameters.idCapannone === null || requestParameters.idCapannone === undefined) {
            throw new runtime.RequiredError('idCapannone','Required parameter requestParameters.idCapannone was null or undefined when calling getCapannoneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}/Capannoni/{idCapannone}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))).replace(`{${"idCapannone"}}`, encodeURIComponent(String(requestParameters.idCapannone))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CapannoneDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una Capannone per id
     */
    async getCapannoneById(requestParameters: GetCapannoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CapannoneDTO> {
        const response = await this.getCapannoneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte le Capannoni
     */
    async getCapannoniRaw(requestParameters: GetCapannoniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CapannoneDTO>>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling getCapannoni.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}/Capannoni`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CapannoneDTOFromJSON));
    }

    /**
     * Elenco di tutte le Capannoni
     */
    async getCapannoni(requestParameters: GetCapannoniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CapannoneDTO>> {
        const response = await this.getCapannoniRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un fornitore per idFornitore
     */
    async getFornitoreByIdRaw(requestParameters: GetFornitoreByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FornitoreDTO>> {
        if (requestParameters.idFornitore === null || requestParameters.idFornitore === undefined) {
            throw new runtime.RequiredError('idFornitore','Required parameter requestParameters.idFornitore was null or undefined when calling getFornitoreById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Fornitori/{idFornitore}`.replace(`{${"idFornitore"}}`, encodeURIComponent(String(requestParameters.idFornitore))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FornitoreDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un fornitore per idFornitore
     */
    async getFornitoreById(requestParameters: GetFornitoreByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FornitoreDTO> {
        const response = await this.getFornitoreByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i fornitori
     */
    async getFornitoriRaw(requestParameters: GetFornitoriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FornitoreDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Fornitori`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FornitoreDTOFromJSON));
    }

    /**
     * Elenco di tutti i fornitori
     */
    async getFornitori(requestParameters: GetFornitoriRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FornitoreDTO>> {
        const response = await this.getFornitoriRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti gli Incubatoi
     */
    async getIncubatoiRaw(requestParameters: GetIncubatoiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IncubatoioDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IncubatoioDTOFromJSON));
    }

    /**
     * Elenco di tutti gli Incubatoi
     */
    async getIncubatoi(requestParameters: GetIncubatoiRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IncubatoioDTO>> {
        const response = await this.getIncubatoiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti gli incubatoi legati a un ciclo
     */
    async getIncubatoiBycicloRaw(requestParameters: GetIncubatoiBycicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IncubatoioDTO>>> {
        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getIncubatoiByciclo.');
        }

        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi/ciclo/{idCiclo}`.replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IncubatoioDTOFromJSON));
    }

    /**
     * Elenco di tutti gli incubatoi legati a un ciclo
     */
    async getIncubatoiByciclo(requestParameters: GetIncubatoiBycicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IncubatoioDTO>> {
        const response = await this.getIncubatoiBycicloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un Incubatoio per id
     */
    async getIncubatoioByIdRaw(requestParameters: GetIncubatoioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncubatoioDTO>> {
        if (requestParameters.idIncubatoio === null || requestParameters.idIncubatoio === undefined) {
            throw new runtime.RequiredError('idIncubatoio','Required parameter requestParameters.idIncubatoio was null or undefined when calling getIncubatoioById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi/{idIncubatoio}`.replace(`{${"idIncubatoio"}}`, encodeURIComponent(String(requestParameters.idIncubatoio))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncubatoioDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un Incubatoio per id
     */
    async getIncubatoioById(requestParameters: GetIncubatoioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncubatoioDTO> {
        const response = await this.getIncubatoioByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i macelli
     */
    async getMacelliRaw(requestParameters: GetMacelliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MacelloDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Macelli`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MacelloDTOFromJSON));
    }

    /**
     * Elenco di tutti i macelli
     */
    async getMacelli(requestParameters: GetMacelliRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MacelloDTO>> {
        const response = await this.getMacelliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un macello per id
     */
    async getMacelloByIdRaw(requestParameters: GetMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MacelloDTO>> {
        if (requestParameters.idMacello === null || requestParameters.idMacello === undefined) {
            throw new runtime.RequiredError('idMacello','Required parameter requestParameters.idMacello was null or undefined when calling getMacelloById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Macelli/{idMacello}`.replace(`{${"idMacello"}}`, encodeURIComponent(String(requestParameters.idMacello))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MacelloDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un macello per id
     */
    async getMacelloById(requestParameters: GetMacelloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MacelloDTO> {
        const response = await this.getMacelloByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tuttiie mangimifici
     */
    async getMangimificiRaw(requestParameters: GetMangimificiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MangimificioDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Mangimifici`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MangimificioDTOFromJSON));
    }

    /**
     * Elenco di tuttiie mangimifici
     */
    async getMangimifici(requestParameters: GetMangimificiRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MangimificioDTO>> {
        const response = await this.getMangimificiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera una mangimificio per id
     */
    async getMangimificioByIdRaw(requestParameters: GetMangimificioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MangimificioDTO>> {
        if (requestParameters.idMangimificio === null || requestParameters.idMangimificio === undefined) {
            throw new runtime.RequiredError('idMangimificio','Required parameter requestParameters.idMangimificio was null or undefined when calling getMangimificioById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Mangimifici/{idMangimificio}`.replace(`{${"idMangimificio"}}`, encodeURIComponent(String(requestParameters.idMangimificio))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MangimificioDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una mangimificio per id
     */
    async getMangimificioById(requestParameters: GetMangimificioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MangimificioDTO> {
        const response = await this.getMangimificioByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i tecnici
     */
    async getTecniciRaw(requestParameters: GetTecniciRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TecnicoDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Tecnici`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TecnicoDTOFromJSON));
    }

    /**
     * Elenco di tutti i tecnici
     */
    async getTecnici(requestParameters: GetTecniciRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TecnicoDTO>> {
        const response = await this.getTecniciRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un Tecnico per idTecnico
     */
    async getTecnicoByIdRaw(requestParameters: GetTecnicoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TecnicoDTO>> {
        if (requestParameters.idTecnico === null || requestParameters.idTecnico === undefined) {
            throw new runtime.RequiredError('idTecnico','Required parameter requestParameters.idTecnico was null or undefined when calling getTecnicoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Tecnici/{idTecnico}`.replace(`{${"idTecnico"}}`, encodeURIComponent(String(requestParameters.idTecnico))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TecnicoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un Tecnico per idTecnico
     */
    async getTecnicoById(requestParameters: GetTecnicoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TecnicoDTO> {
        const response = await this.getTecnicoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di Allevamento in anagrafica
     */
    async insertAllevamentoRaw(requestParameters: InsertAllevamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllevamentoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllevamentoCreateDTOToJSON(requestParameters.allevamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllevamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di Allevamento in anagrafica
     */
    async insertAllevamento(requestParameters: InsertAllevamentoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllevamentoDTO> {
        const response = await this.insertAllevamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo allevatore
     */
    async insertAllevatoreRaw(requestParameters: InsertAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllevatoreDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllevatoreCreateDTOToJSON(requestParameters.allevatoreCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllevatoreDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo allevatore
     */
    async insertAllevatore(requestParameters: InsertAllevatoreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllevatoreDTO> {
        const response = await this.insertAllevatoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea una nuova Capannone
     */
    async insertCapannoneRaw(requestParameters: InsertCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CapannoneDTO>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling insertCapannone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}/Capannoni`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CapannoneCreateDTOToJSON(requestParameters.capannoneCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CapannoneDTOFromJSON(jsonValue));
    }

    /**
     * Crea una nuova Capannone
     */
    async insertCapannone(requestParameters: InsertCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CapannoneDTO> {
        const response = await this.insertCapannoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo fornitore
     */
    async insertFornitoreRaw(requestParameters: InsertFornitoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FornitoreDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Fornitori`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FornitoreCreateDTOToJSON(requestParameters.fornitoreCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FornitoreDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo fornitore
     */
    async insertFornitore(requestParameters: InsertFornitoreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FornitoreDTO> {
        const response = await this.insertFornitoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo Incubatoio
     */
    async insertIncubatoioRaw(requestParameters: InsertIncubatoioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncubatoioDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncubatoioCreateDTOToJSON(requestParameters.incubatoioCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncubatoioDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo Incubatoio
     */
    async insertIncubatoio(requestParameters: InsertIncubatoioRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncubatoioDTO> {
        const response = await this.insertIncubatoioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo macello
     */
    async insertMacelloRaw(requestParameters: InsertMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MacelloDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Macelli`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MacelloCreateDTOToJSON(requestParameters.macelloCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MacelloDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo macello
     */
    async insertMacello(requestParameters: InsertMacelloRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MacelloDTO> {
        const response = await this.insertMacelloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo mangimificio
     */
    async insertMangimificioRaw(requestParameters: InsertMangimificioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MangimificioDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Mangimifici`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MangimificioCreateDTOToJSON(requestParameters.mangimificioCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MangimificioDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo mangimificio
     */
    async insertMangimificio(requestParameters: InsertMangimificioRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MangimificioDTO> {
        const response = await this.insertMangimificioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo tecnico
     */
    async insertTecnicoRaw(requestParameters: InsertTecnicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TecnicoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Tecnici`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TecnicoCreateDTOToJSON(requestParameters.tecnicoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TecnicoDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo tecnico
     */
    async insertTecnico(requestParameters: InsertTecnicoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TecnicoDTO> {
        const response = await this.insertTecnicoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di Allevamento esistente
     */
    async updateAllevamentoRaw(requestParameters: UpdateAllevamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling updateAllevamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllevamentoUpdateDTOToJSON(requestParameters.allevamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di Allevamento esistente
     */
    async updateAllevamento(requestParameters: UpdateAllevamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAllevamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un Allevatore esistente
     */
    async updateAllevatoreRaw(requestParameters: UpdateAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevatore === null || requestParameters.idAllevatore === undefined) {
            throw new runtime.RequiredError('idAllevatore','Required parameter requestParameters.idAllevatore was null or undefined when calling updateAllevatore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevatori/{idAllevatore}`.replace(`{${"idAllevatore"}}`, encodeURIComponent(String(requestParameters.idAllevatore))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllevatoreUpdateDTOToJSON(requestParameters.allevatoreUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un Allevatore esistente
     */
    async updateAllevatore(requestParameters: UpdateAllevatoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAllevatoreRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna una filiare esistente
     */
    async updateCapannoneRaw(requestParameters: UpdateCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAllevamento === null || requestParameters.idAllevamento === undefined) {
            throw new runtime.RequiredError('idAllevamento','Required parameter requestParameters.idAllevamento was null or undefined when calling updateCapannone.');
        }

        if (requestParameters.idCapannone === null || requestParameters.idCapannone === undefined) {
            throw new runtime.RequiredError('idCapannone','Required parameter requestParameters.idCapannone was null or undefined when calling updateCapannone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Allevamenti/{idAllevamento}/Capannoni/{idCapannone}`.replace(`{${"idAllevamento"}}`, encodeURIComponent(String(requestParameters.idAllevamento))).replace(`{${"idCapannone"}}`, encodeURIComponent(String(requestParameters.idCapannone))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CapannoneUpdateDTOToJSON(requestParameters.capannoneUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna una filiare esistente
     */
    async updateCapannone(requestParameters: UpdateCapannoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCapannoneRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un fornitore esistente
     */
    async updateFornitoreRaw(requestParameters: UpdateFornitoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFornitore === null || requestParameters.idFornitore === undefined) {
            throw new runtime.RequiredError('idFornitore','Required parameter requestParameters.idFornitore was null or undefined when calling updateFornitore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Fornitori/{idFornitore}`.replace(`{${"idFornitore"}}`, encodeURIComponent(String(requestParameters.idFornitore))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FornitoreUpdateDTOToJSON(requestParameters.fornitoreUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un fornitore esistente
     */
    async updateFornitore(requestParameters: UpdateFornitoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFornitoreRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un incubatoio esistente
     */
    async updateIncubatoioRaw(requestParameters: UpdateIncubatoioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idIncubatoio === null || requestParameters.idIncubatoio === undefined) {
            throw new runtime.RequiredError('idIncubatoio','Required parameter requestParameters.idIncubatoio was null or undefined when calling updateIncubatoio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Incubatoi/{idIncubatoio}`.replace(`{${"idIncubatoio"}}`, encodeURIComponent(String(requestParameters.idIncubatoio))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncubatoioUpdateDTOToJSON(requestParameters.incubatoioUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un incubatoio esistente
     */
    async updateIncubatoio(requestParameters: UpdateIncubatoioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIncubatoioRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un macello esistente
     */
    async updateMacelloRaw(requestParameters: UpdateMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idMacello === null || requestParameters.idMacello === undefined) {
            throw new runtime.RequiredError('idMacello','Required parameter requestParameters.idMacello was null or undefined when calling updateMacello.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Macelli/{idMacello}`.replace(`{${"idMacello"}}`, encodeURIComponent(String(requestParameters.idMacello))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MacelloUpdateDTOToJSON(requestParameters.macelloUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un macello esistente
     */
    async updateMacello(requestParameters: UpdateMacelloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMacelloRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un mangimificio esistente
     */
    async updateMangimificioRaw(requestParameters: UpdateMangimificioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idMangimificio === null || requestParameters.idMangimificio === undefined) {
            throw new runtime.RequiredError('idMangimificio','Required parameter requestParameters.idMangimificio was null or undefined when calling updateMangimificio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Mangimifici/{idMangimificio}`.replace(`{${"idMangimificio"}}`, encodeURIComponent(String(requestParameters.idMangimificio))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MangimificioUpdateDTOToJSON(requestParameters.mangimificioUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un mangimificio esistente
     */
    async updateMangimificio(requestParameters: UpdateMangimificioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMangimificioRaw(requestParameters, initOverrides);
    }

    /**
     * Aggiorna un tecnico esistente
     */
    async updateTecnicoRaw(requestParameters: UpdateTecnicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idTecnico === null || requestParameters.idTecnico === undefined) {
            throw new runtime.RequiredError('idTecnico','Required parameter requestParameters.idTecnico was null or undefined when calling updateTecnico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/Tecnici/{idTecnico}`.replace(`{${"idTecnico"}}`, encodeURIComponent(String(requestParameters.idTecnico))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TecnicoUpdateDTOToJSON(requestParameters.tecnicoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna un tecnico esistente
     */
    async updateTecnico(requestParameters: UpdateTecnicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTecnicoRaw(requestParameters, initOverrides);
    }

}
