/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListinoMangimiUpdateDTO
 */
export interface ListinoMangimiUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiUpdateDTO
     */
    idTipoMangime?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListinoMangimiUpdateDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiUpdateDTO
     */
    valore?: number;
}

/**
 * Check if a given object implements the ListinoMangimiUpdateDTO interface.
 */
export function instanceOfListinoMangimiUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListinoMangimiUpdateDTOFromJSON(json: any): ListinoMangimiUpdateDTO {
    return ListinoMangimiUpdateDTOFromJSONTyped(json, false);
}

export function ListinoMangimiUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListinoMangimiUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idTipoMangime': !exists(json, 'idTipoMangime') ? undefined : json['idTipoMangime'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
    };
}

export function ListinoMangimiUpdateDTOToJSON(value?: ListinoMangimiUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idTipoMangime': value.idTipoMangime,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'valore': value.valore,
    };
}

