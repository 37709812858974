/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CaricoCreateDTO,
  CaricoDTO,
  CaricoRiscontroCreateDTO,
  CaricoRiscontroDTO,
  CaricoRiscontroUpdateDTO,
  CaricoUpdateDTO,
  ChiusuraCompensoCreateDTO,
  ChiusuraCompensoDTO,
  ChiusuraCompensoUpdateDTO,
  ChiusuraDTO,
  ChiusuraExtraCreateDTO,
  ChiusuraExtraDTO,
  ChiusuraExtraUpdateDTO,
  ChiusuraSaveDTO,
  ChiusuraVariazioneCapiCreateDTO,
  ChiusuraVariazioneCapiDTO,
  ChiusuraVariazioneCapiUpdateDTO,
  CicloCreateDTO,
  CicloDTO,
  CicloSummaryDTO,
  CicloUpdateDTO,
  ProblemDetails,
  SummaryAccasamentiDTO,
  SummaryCarichiDTO,
  SummaryConversioneDTO,
  SummaryMangimiDTO,
  SummaryMortiDTO,
} from '../models';
import {
    CaricoCreateDTOFromJSON,
    CaricoCreateDTOToJSON,
    CaricoDTOFromJSON,
    CaricoDTOToJSON,
    CaricoRiscontroCreateDTOFromJSON,
    CaricoRiscontroCreateDTOToJSON,
    CaricoRiscontroDTOFromJSON,
    CaricoRiscontroDTOToJSON,
    CaricoRiscontroUpdateDTOFromJSON,
    CaricoRiscontroUpdateDTOToJSON,
    CaricoUpdateDTOFromJSON,
    CaricoUpdateDTOToJSON,
    ChiusuraCompensoCreateDTOFromJSON,
    ChiusuraCompensoCreateDTOToJSON,
    ChiusuraCompensoDTOFromJSON,
    ChiusuraCompensoDTOToJSON,
    ChiusuraCompensoUpdateDTOFromJSON,
    ChiusuraCompensoUpdateDTOToJSON,
    ChiusuraDTOFromJSON,
    ChiusuraDTOToJSON,
    ChiusuraExtraCreateDTOFromJSON,
    ChiusuraExtraCreateDTOToJSON,
    ChiusuraExtraDTOFromJSON,
    ChiusuraExtraDTOToJSON,
    ChiusuraExtraUpdateDTOFromJSON,
    ChiusuraExtraUpdateDTOToJSON,
    ChiusuraSaveDTOFromJSON,
    ChiusuraSaveDTOToJSON,
    ChiusuraVariazioneCapiCreateDTOFromJSON,
    ChiusuraVariazioneCapiCreateDTOToJSON,
    ChiusuraVariazioneCapiDTOFromJSON,
    ChiusuraVariazioneCapiDTOToJSON,
    ChiusuraVariazioneCapiUpdateDTOFromJSON,
    ChiusuraVariazioneCapiUpdateDTOToJSON,
    CicloCreateDTOFromJSON,
    CicloCreateDTOToJSON,
    CicloDTOFromJSON,
    CicloDTOToJSON,
    CicloSummaryDTOFromJSON,
    CicloSummaryDTOToJSON,
    CicloUpdateDTOFromJSON,
    CicloUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SummaryAccasamentiDTOFromJSON,
    SummaryAccasamentiDTOToJSON,
    SummaryCarichiDTOFromJSON,
    SummaryCarichiDTOToJSON,
    SummaryConversioneDTOFromJSON,
    SummaryConversioneDTOToJSON,
    SummaryMangimiDTOFromJSON,
    SummaryMangimiDTOToJSON,
    SummaryMortiDTOFromJSON,
    SummaryMortiDTOToJSON,
} from '../models';

export interface ChiusuraApprovalRequestRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface DeleteCaricoRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
}

export interface DeleteCaricoRiscontroRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idRiscontro: number;
}

export interface DeleteChiusuraRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface DeleteChiusuraCompensoRequest {
    idFiliera: number;
    idCiclo: number;
    idCompenso: number;
}

export interface DeleteChiusuraExtraRequest {
    idFiliera: number;
    idCiclo: number;
    idExtra: number;
}

export interface DeleteChiusuraVariazioneCapiRequest {
    idFiliera: number;
    idCiclo: number;
    idVariazioneCapi: number;
}

export interface DeleteCicloRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetCarichiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetCarichiRiscontriRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
}

export interface GetCaricoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
}

export interface GetCaricoRiscontroByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idRiscontro: number;
}

export interface GetChiusuraRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetChiusuraCompensiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetChiusuraCompensiByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idCompenso: number;
}

export interface GetChiusuraDefaultRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetChiusuraExtraRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetChiusuraExtraByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idExtra: number;
}

export interface GetChiusuraVariazioneCapiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetChiusuraVariazioneCapiByIdRequest {
    idFiliera: number;
    idCiclo: number;
    idVariazioneCapi: number;
}

export interface GetCicliRequest {
    idFiliera: number;
    stato?: string;
    idAllevatore?: number;
}

export interface GetCicloByIdRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetCicloSummaryRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetReportChiusuraRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetReportContoEconomicoRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetReportRegistroMortiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetStatiCicliRequest {
    idFiliera: number;
}

export interface GetSummaryAccasamentiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetSummaryCarichiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetSummaryConversioneRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetSummaryMangimiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetSummaryMortiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface InsertCaricoRequest {
    idFiliera: number;
    idCiclo: number;
    caricoCreateDTO?: CaricoCreateDTO;
}

export interface InsertCaricoRiscontroRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    caricoRiscontroCreateDTO?: CaricoRiscontroCreateDTO;
}

export interface InsertChiusuraCompensoRequest {
    idFiliera: number;
    idCiclo: number;
    chiusuraCompensoCreateDTO?: ChiusuraCompensoCreateDTO;
}

export interface InsertChiusuraExtraRequest {
    idFiliera: number;
    idCiclo: number;
    chiusuraExtraCreateDTO?: ChiusuraExtraCreateDTO;
}

export interface InsertChiusuraVariazioneCapiRequest {
    idFiliera: number;
    idCiclo: number;
    chiusuraVariazioneCapiCreateDTO?: ChiusuraVariazioneCapiCreateDTO;
}

export interface InsertCicloRequest {
    idFiliera: number;
    cicloCreateDTO?: CicloCreateDTO;
}

export interface SaveChiusuraRequest {
    idFiliera: number;
    idCiclo: number;
    chiusuraSaveDTO?: ChiusuraSaveDTO;
}

export interface UpdateCaricoRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    caricoUpdateDTO?: CaricoUpdateDTO;
}

export interface UpdateCaricoRiscontroRequest {
    idFiliera: number;
    idCiclo: number;
    idCarico: number;
    idRiscontro: number;
    caricoRiscontroUpdateDTO?: CaricoRiscontroUpdateDTO;
}

export interface UpdateChiusuraCompensoRequest {
    idFiliera: number;
    idCiclo: number;
    idCompenso: number;
    chiusuraCompensoUpdateDTO?: ChiusuraCompensoUpdateDTO;
}

export interface UpdateChiusuraCompensoSequenzaRequest {
    idFiliera: number;
    idCiclo: number;
    idCompenso: number;
    sequenza: number;
}

export interface UpdateChiusuraExtraRequest {
    idFiliera: number;
    idCiclo: number;
    idExtra: number;
    chiusuraExtraUpdateDTO?: ChiusuraExtraUpdateDTO;
}

export interface UpdateChiusuraExtraSequenzaRequest {
    idFiliera: number;
    idCiclo: number;
    idExtra: number;
    sequenza: number;
}

export interface UpdateChiusuraVariazioneCapiRequest {
    idFiliera: number;
    idCiclo: number;
    idVariazioneCapi: number;
    chiusuraVariazioneCapiUpdateDTO?: ChiusuraVariazioneCapiUpdateDTO;
}

export interface UpdateCicloRequest {
    idFiliera: number;
    idCiclo: number;
    cicloUpdateDTO?: CicloUpdateDTO;
}

/**
 * 
 */
export class CicliApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/RichiestaApprovazione &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Invia la chiusura in approvazione
     */
    async chiusuraApprovalRequestRaw(requestParameters: ChiusuraApprovalRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling chiusuraApprovalRequest.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling chiusuraApprovalRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/ApprovalRequest`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/RichiestaApprovazione &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Invia la chiusura in approvazione
     */
    async chiusuraApprovalRequest(requestParameters: ChiusuraApprovalRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chiusuraApprovalRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un carico esistente
     */
    async deleteCaricoRaw(requestParameters: DeleteCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteCarico.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteCarico.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling deleteCarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un carico esistente
     */
    async deleteCarico(requestParameters: DeleteCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCaricoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un CaricoRiscontro esistente
     */
    async deleteCaricoRiscontroRaw(requestParameters: DeleteCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteCaricoRiscontro.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteCaricoRiscontro.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling deleteCaricoRiscontro.');
        }

        if (requestParameters.idRiscontro === null || requestParameters.idRiscontro === undefined) {
            throw new runtime.RequiredError('idRiscontro','Required parameter requestParameters.idRiscontro was null or undefined when calling deleteCaricoRiscontro.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Riscontri/{idRiscontro}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idRiscontro"}}`, encodeURIComponent(String(requestParameters.idRiscontro))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un CaricoRiscontro esistente
     */
    async deleteCaricoRiscontro(requestParameters: DeleteCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCaricoRiscontroRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un Chiusura esistente
     */
    async deleteChiusuraRaw(requestParameters: DeleteChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteChiusura.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteChiusura.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un Chiusura esistente
     */
    async deleteChiusura(requestParameters: DeleteChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteChiusuraRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un compenso
     */
    async deleteChiusuraCompensoRaw(requestParameters: DeleteChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteChiusuraCompenso.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteChiusuraCompenso.');
        }

        if (requestParameters.idCompenso === null || requestParameters.idCompenso === undefined) {
            throw new runtime.RequiredError('idCompenso','Required parameter requestParameters.idCompenso was null or undefined when calling deleteChiusuraCompenso.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi/{idCompenso}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCompenso"}}`, encodeURIComponent(String(requestParameters.idCompenso))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un compenso
     */
    async deleteChiusuraCompenso(requestParameters: DeleteChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteChiusuraCompensoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un Extra
     */
    async deleteChiusuraExtraRaw(requestParameters: DeleteChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteChiusuraExtra.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteChiusuraExtra.');
        }

        if (requestParameters.idExtra === null || requestParameters.idExtra === undefined) {
            throw new runtime.RequiredError('idExtra','Required parameter requestParameters.idExtra was null or undefined when calling deleteChiusuraExtra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra/{idExtra}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idExtra"}}`, encodeURIComponent(String(requestParameters.idExtra))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un Extra
     */
    async deleteChiusuraExtra(requestParameters: DeleteChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteChiusuraExtraRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una VariazioneCapi
     */
    async deleteChiusuraVariazioneCapiRaw(requestParameters: DeleteChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteChiusuraVariazioneCapi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteChiusuraVariazioneCapi.');
        }

        if (requestParameters.idVariazioneCapi === null || requestParameters.idVariazioneCapi === undefined) {
            throw new runtime.RequiredError('idVariazioneCapi','Required parameter requestParameters.idVariazioneCapi was null or undefined when calling deleteChiusuraVariazioneCapi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/VariazioniCapi/{idVariazioneCapi}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idVariazioneCapi"}}`, encodeURIComponent(String(requestParameters.idVariazioneCapi))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una VariazioneCapi
     */
    async deleteChiusuraVariazioneCapi(requestParameters: DeleteChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteChiusuraVariazioneCapiRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un ciclo esistente
     */
    async deleteCicloRaw(requestParameters: DeleteCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteCiclo.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deleteCiclo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un ciclo esistente
     */
    async deleteCiclo(requestParameters: DeleteCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCicloRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutti i carichi
     */
    async getCarichiRaw(requestParameters: GetCarichiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CaricoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCarichi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCarichi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaricoDTOFromJSON));
    }

    /**
     * Elenco di tutti i carichi
     */
    async getCarichi(requestParameters: GetCarichiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CaricoDTO>> {
        const response = await this.getCarichiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i CarichiRiscontri
     */
    async getCarichiRiscontriRaw(requestParameters: GetCarichiRiscontriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CaricoRiscontroDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCarichiRiscontri.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCarichiRiscontri.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getCarichiRiscontri.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Riscontri`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaricoRiscontroDTOFromJSON));
    }

    /**
     * Elenco di tutti i CarichiRiscontri
     */
    async getCarichiRiscontri(requestParameters: GetCarichiRiscontriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CaricoRiscontroDTO>> {
        const response = await this.getCarichiRiscontriRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un carico per idCarico
     */
    async getCaricoByIdRaw(requestParameters: GetCaricoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaricoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCaricoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCaricoById.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getCaricoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaricoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un carico per idCarico
     */
    async getCaricoById(requestParameters: GetCaricoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaricoDTO> {
        const response = await this.getCaricoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un CaricoRiscontro per idRiscontro
     */
    async getCaricoRiscontroByIdRaw(requestParameters: GetCaricoRiscontroByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaricoRiscontroDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCaricoRiscontroById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCaricoRiscontroById.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling getCaricoRiscontroById.');
        }

        if (requestParameters.idRiscontro === null || requestParameters.idRiscontro === undefined) {
            throw new runtime.RequiredError('idRiscontro','Required parameter requestParameters.idRiscontro was null or undefined when calling getCaricoRiscontroById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Riscontri/{idRiscontro}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idRiscontro"}}`, encodeURIComponent(String(requestParameters.idRiscontro))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaricoRiscontroDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un CaricoRiscontro per idRiscontro
     */
    async getCaricoRiscontroById(requestParameters: GetCaricoRiscontroByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaricoRiscontroDTO> {
        const response = await this.getCaricoRiscontroByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero della chiusura
     */
    async getChiusuraRaw(requestParameters: GetChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusura.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusura.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero della chiusura
     */
    async getChiusura(requestParameters: GetChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraDTO> {
        const response = await this.getChiusuraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero dei compensi di chiusura
     */
    async getChiusuraCompensiRaw(requestParameters: GetChiusuraCompensiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChiusuraCompensoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraCompensi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraCompensi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChiusuraCompensoDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero dei compensi di chiusura
     */
    async getChiusuraCompensi(requestParameters: GetChiusuraCompensiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChiusuraCompensoDTO>> {
        const response = await this.getChiusuraCompensiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un compenso per idCompenso
     */
    async getChiusuraCompensiByIdRaw(requestParameters: GetChiusuraCompensiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraCompensoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraCompensiById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraCompensiById.');
        }

        if (requestParameters.idCompenso === null || requestParameters.idCompenso === undefined) {
            throw new runtime.RequiredError('idCompenso','Required parameter requestParameters.idCompenso was null or undefined when calling getChiusuraCompensiById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi/{idCompenso}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCompenso"}}`, encodeURIComponent(String(requestParameters.idCompenso))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraCompensoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un compenso per idCompenso
     */
    async getChiusuraCompensiById(requestParameters: GetChiusuraCompensiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraCompensoDTO> {
        const response = await this.getChiusuraCompensiByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero dei dati predefiniti della chiusura
     */
    async getChiusuraDefaultRaw(requestParameters: GetChiusuraDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraSaveDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraDefault.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/default`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraSaveDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero dei dati predefiniti della chiusura
     */
    async getChiusuraDefault(requestParameters: GetChiusuraDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraSaveDTO> {
        const response = await this.getChiusuraDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero gli extra di chiusura
     */
    async getChiusuraExtraRaw(requestParameters: GetChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChiusuraExtraDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraExtra.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraExtra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChiusuraExtraDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero gli extra di chiusura
     */
    async getChiusuraExtra(requestParameters: GetChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChiusuraExtraDTO>> {
        const response = await this.getChiusuraExtraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un extra per idExtra
     */
    async getChiusuraExtraByIdRaw(requestParameters: GetChiusuraExtraByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraExtraDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraExtraById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraExtraById.');
        }

        if (requestParameters.idExtra === null || requestParameters.idExtra === undefined) {
            throw new runtime.RequiredError('idExtra','Required parameter requestParameters.idExtra was null or undefined when calling getChiusuraExtraById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra/{idExtra}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idExtra"}}`, encodeURIComponent(String(requestParameters.idExtra))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraExtraDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un extra per idExtra
     */
    async getChiusuraExtraById(requestParameters: GetChiusuraExtraByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraExtraDTO> {
        const response = await this.getChiusuraExtraByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero variazioni capi di chiusura
     */
    async getChiusuraVariazioneCapiRaw(requestParameters: GetChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChiusuraVariazioneCapiDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraVariazioneCapi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraVariazioneCapi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/VariazioniCapi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChiusuraVariazioneCapiDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero variazioni capi di chiusura
     */
    async getChiusuraVariazioneCapi(requestParameters: GetChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChiusuraVariazioneCapiDTO>> {
        const response = await this.getChiusuraVariazioneCapiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera una VariazioneCapi per idVariazioneCapi
     */
    async getChiusuraVariazioneCapiByIdRaw(requestParameters: GetChiusuraVariazioneCapiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraVariazioneCapiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getChiusuraVariazioneCapiById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getChiusuraVariazioneCapiById.');
        }

        if (requestParameters.idVariazioneCapi === null || requestParameters.idVariazioneCapi === undefined) {
            throw new runtime.RequiredError('idVariazioneCapi','Required parameter requestParameters.idVariazioneCapi was null or undefined when calling getChiusuraVariazioneCapiById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/VariazioniCapi/{idVariazioneCapi}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idVariazioneCapi"}}`, encodeURIComponent(String(requestParameters.idVariazioneCapi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraVariazioneCapiDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una VariazioneCapi per idVariazioneCapi
     */
    async getChiusuraVariazioneCapiById(requestParameters: GetChiusuraVariazioneCapiByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraVariazioneCapiDTO> {
        const response = await this.getChiusuraVariazioneCapiByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i cicli
     */
    async getCicliRaw(requestParameters: GetCicliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CicloDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCicli.');
        }

        const queryParameters: any = {};

        if (requestParameters.stato !== undefined) {
            queryParameters['stato'] = requestParameters.stato;
        }

        if (requestParameters.idAllevatore !== undefined) {
            queryParameters['idAllevatore'] = requestParameters.idAllevatore;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CicloDTOFromJSON));
    }

    /**
     * Elenco di tutti i cicli
     */
    async getCicli(requestParameters: GetCicliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CicloDTO>> {
        const response = await this.getCicliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un ciclo per idCiclo
     */
    async getCicloByIdRaw(requestParameters: GetCicloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CicloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCicloById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCicloById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CicloDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un ciclo per idCiclo
     */
    async getCicloById(requestParameters: GetCicloByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CicloDTO> {
        const response = await this.getCicloByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo di tutto il ciclo
     */
    async getCicloSummaryRaw(requestParameters: GetCicloSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CicloSummaryDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getCicloSummary.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getCicloSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CicloSummaryDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo di tutto il ciclo
     */
    async getCicloSummary(requestParameters: GetCicloSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CicloSummaryDTO> {
        const response = await this.getCicloSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera la chiusura del ciclo per idCiclo
     */
    async getReportChiusuraRaw(requestParameters: GetReportChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getReportChiusura.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getReportChiusura.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Report`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera la chiusura del ciclo per idCiclo
     */
    async getReportChiusura(requestParameters: GetReportChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportChiusuraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/ContoEconomico &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera il conto economico del ciclo per idCiclo
     */
    async getReportContoEconomicoRaw(requestParameters: GetReportContoEconomicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getReportContoEconomico.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getReportContoEconomico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/ContoEconomico/Report`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/ContoEconomico &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera il conto economico del ciclo per idCiclo
     */
    async getReportContoEconomico(requestParameters: GetReportContoEconomicoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportContoEconomicoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera il Registro Mortalità del ciclo per idCiclo
     */
    async getReportRegistroMortiRaw(requestParameters: GetReportRegistroMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getReportRegistroMorti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getReportRegistroMorti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/RegistroMorti/Report`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera il Registro Mortalità del ciclo per idCiclo
     */
    async getReportRegistroMorti(requestParameters: GetReportRegistroMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportRegistroMortiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco degli stati disponibili per la filiera
     */
    async getStatiCicliRaw(requestParameters: GetStatiCicliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getStatiCicli.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/Stati`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Elenco degli stati disponibili per la filiera
     */
    async getStatiCicli(requestParameters: GetStatiCicliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getStatiCicliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo degli accasamenti
     */
    async getSummaryAccasamentiRaw(requestParameters: GetSummaryAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummaryAccasamentiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getSummaryAccasamenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getSummaryAccasamenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo/Accasamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryAccasamentiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo degli accasamenti
     */
    async getSummaryAccasamenti(requestParameters: GetSummaryAccasamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummaryAccasamentiDTO> {
        const response = await this.getSummaryAccasamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo delle sessioni di carico
     */
    async getSummaryCarichiRaw(requestParameters: GetSummaryCarichiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummaryCarichiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getSummaryCarichi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getSummaryCarichi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo/Carichi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryCarichiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo delle sessioni di carico
     */
    async getSummaryCarichi(requestParameters: GetSummaryCarichiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummaryCarichiDTO> {
        const response = await this.getSummaryCarichiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo della conversione
     */
    async getSummaryConversioneRaw(requestParameters: GetSummaryConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummaryConversioneDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getSummaryConversione.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getSummaryConversione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo/Conversione`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryConversioneDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo della conversione
     */
    async getSummaryConversione(requestParameters: GetSummaryConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummaryConversioneDTO> {
        const response = await this.getSummaryConversioneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo dei mangimi
     */
    async getSummaryMangimiRaw(requestParameters: GetSummaryMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummaryMangimiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getSummaryMangimi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getSummaryMangimi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo/Mangimi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryMangimiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo dei mangimi
     */
    async getSummaryMangimi(requestParameters: GetSummaryMangimiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummaryMangimiDTO> {
        const response = await this.getSummaryMangimiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo della mortalità
     */
    async getSummaryMortiRaw(requestParameters: GetSummaryMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummaryMortiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getSummaryMorti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getSummaryMorti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Riepilogo/Morti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryMortiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupero del riepilogo della mortalità
     */
    async getSummaryMorti(requestParameters: GetSummaryMortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummaryMortiDTO> {
        const response = await this.getSummaryMortiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo carico in anagrafica
     */
    async insertCaricoRaw(requestParameters: InsertCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaricoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertCarico.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertCarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaricoCreateDTOToJSON(requestParameters.caricoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaricoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo carico in anagrafica
     */
    async insertCarico(requestParameters: InsertCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaricoDTO> {
        const response = await this.insertCaricoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo CaricoRiscontro in anagrafica
     */
    async insertCaricoRiscontroRaw(requestParameters: InsertCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaricoRiscontroDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertCaricoRiscontro.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertCaricoRiscontro.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling insertCaricoRiscontro.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Riscontri`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaricoRiscontroCreateDTOToJSON(requestParameters.caricoRiscontroCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaricoRiscontroDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo CaricoRiscontro in anagrafica
     */
    async insertCaricoRiscontro(requestParameters: InsertCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaricoRiscontroDTO> {
        const response = await this.insertCaricoRiscontroRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo compenso
     */
    async insertChiusuraCompensoRaw(requestParameters: InsertChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraCompensoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertChiusuraCompenso.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertChiusuraCompenso.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraCompensoCreateDTOToJSON(requestParameters.chiusuraCompensoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraCompensoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo compenso
     */
    async insertChiusuraCompenso(requestParameters: InsertChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraCompensoDTO> {
        const response = await this.insertChiusuraCompensoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo Extra
     */
    async insertChiusuraExtraRaw(requestParameters: InsertChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraExtraDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertChiusuraExtra.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertChiusuraExtra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraExtraCreateDTOToJSON(requestParameters.chiusuraExtraCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraExtraDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo Extra
     */
    async insertChiusuraExtra(requestParameters: InsertChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraExtraDTO> {
        const response = await this.insertChiusuraExtraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea una nuova VariazioneCapi
     */
    async insertChiusuraVariazioneCapiRaw(requestParameters: InsertChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChiusuraVariazioneCapiDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertChiusuraVariazioneCapi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertChiusuraVariazioneCapi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/VariazioniCapi`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraVariazioneCapiCreateDTOToJSON(requestParameters.chiusuraVariazioneCapiCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChiusuraVariazioneCapiDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea una nuova VariazioneCapi
     */
    async insertChiusuraVariazioneCapi(requestParameters: InsertChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChiusuraVariazioneCapiDTO> {
        const response = await this.insertChiusuraVariazioneCapiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo ciclo in anagrafica
     */
    async insertCicloRaw(requestParameters: InsertCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CicloDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertCiclo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CicloCreateDTOToJSON(requestParameters.cicloCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CicloDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo ciclo in anagrafica
     */
    async insertCiclo(requestParameters: InsertCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CicloDTO> {
        const response = await this.insertCicloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Chiusura esistente
     */
    async saveChiusuraRaw(requestParameters: SaveChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling saveChiusura.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling saveChiusura.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraSaveDTOToJSON(requestParameters.chiusuraSaveDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Chiusura &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Chiusura esistente
     */
    async saveChiusura(requestParameters: SaveChiusuraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveChiusuraRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Carico esistente
     */
    async updateCaricoRaw(requestParameters: UpdateCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateCarico.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateCarico.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling updateCarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CaricoUpdateDTOToJSON(requestParameters.caricoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Carico esistente
     */
    async updateCarico(requestParameters: UpdateCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCaricoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un CaricoRiscontro esistente
     */
    async updateCaricoRiscontroRaw(requestParameters: UpdateCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateCaricoRiscontro.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateCaricoRiscontro.');
        }

        if (requestParameters.idCarico === null || requestParameters.idCarico === undefined) {
            throw new runtime.RequiredError('idCarico','Required parameter requestParameters.idCarico was null or undefined when calling updateCaricoRiscontro.');
        }

        if (requestParameters.idRiscontro === null || requestParameters.idRiscontro === undefined) {
            throw new runtime.RequiredError('idRiscontro','Required parameter requestParameters.idRiscontro was null or undefined when calling updateCaricoRiscontro.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Carichi/{idCarico}/Riscontri/{idRiscontro}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCarico"}}`, encodeURIComponent(String(requestParameters.idCarico))).replace(`{${"idRiscontro"}}`, encodeURIComponent(String(requestParameters.idRiscontro))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CaricoRiscontroUpdateDTOToJSON(requestParameters.caricoRiscontroUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un CaricoRiscontro esistente
     */
    async updateCaricoRiscontro(requestParameters: UpdateCaricoRiscontroRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCaricoRiscontroRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un compenso esistente
     */
    async updateChiusuraCompensoRaw(requestParameters: UpdateChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateChiusuraCompenso.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateChiusuraCompenso.');
        }

        if (requestParameters.idCompenso === null || requestParameters.idCompenso === undefined) {
            throw new runtime.RequiredError('idCompenso','Required parameter requestParameters.idCompenso was null or undefined when calling updateChiusuraCompenso.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi/{idCompenso}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCompenso"}}`, encodeURIComponent(String(requestParameters.idCompenso))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraCompensoUpdateDTOToJSON(requestParameters.chiusuraCompensoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un compenso esistente
     */
    async updateChiusuraCompenso(requestParameters: UpdateChiusuraCompensoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChiusuraCompensoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna la sequenza
     */
    async updateChiusuraCompensoSequenzaRaw(requestParameters: UpdateChiusuraCompensoSequenzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateChiusuraCompensoSequenza.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateChiusuraCompensoSequenza.');
        }

        if (requestParameters.idCompenso === null || requestParameters.idCompenso === undefined) {
            throw new runtime.RequiredError('idCompenso','Required parameter requestParameters.idCompenso was null or undefined when calling updateChiusuraCompensoSequenza.');
        }

        if (requestParameters.sequenza === null || requestParameters.sequenza === undefined) {
            throw new runtime.RequiredError('sequenza','Required parameter requestParameters.sequenza was null or undefined when calling updateChiusuraCompensoSequenza.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Compensi/{idCompenso}/sequenza/{sequenza}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idCompenso"}}`, encodeURIComponent(String(requestParameters.idCompenso))).replace(`{${"sequenza"}}`, encodeURIComponent(String(requestParameters.sequenza))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna la sequenza
     */
    async updateChiusuraCompensoSequenza(requestParameters: UpdateChiusuraCompensoSequenzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChiusuraCompensoSequenzaRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Extra esistente
     */
    async updateChiusuraExtraRaw(requestParameters: UpdateChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateChiusuraExtra.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateChiusuraExtra.');
        }

        if (requestParameters.idExtra === null || requestParameters.idExtra === undefined) {
            throw new runtime.RequiredError('idExtra','Required parameter requestParameters.idExtra was null or undefined when calling updateChiusuraExtra.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra/{idExtra}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idExtra"}}`, encodeURIComponent(String(requestParameters.idExtra))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraExtraUpdateDTOToJSON(requestParameters.chiusuraExtraUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un Extra esistente
     */
    async updateChiusuraExtra(requestParameters: UpdateChiusuraExtraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChiusuraExtraRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna la sequenza
     */
    async updateChiusuraExtraSequenzaRaw(requestParameters: UpdateChiusuraExtraSequenzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateChiusuraExtraSequenza.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateChiusuraExtraSequenza.');
        }

        if (requestParameters.idExtra === null || requestParameters.idExtra === undefined) {
            throw new runtime.RequiredError('idExtra','Required parameter requestParameters.idExtra was null or undefined when calling updateChiusuraExtraSequenza.');
        }

        if (requestParameters.sequenza === null || requestParameters.sequenza === undefined) {
            throw new runtime.RequiredError('sequenza','Required parameter requestParameters.sequenza was null or undefined when calling updateChiusuraExtraSequenza.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/Extra/{idExtra}/sequenza/{sequenza}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idExtra"}}`, encodeURIComponent(String(requestParameters.idExtra))).replace(`{${"sequenza"}}`, encodeURIComponent(String(requestParameters.sequenza))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna la sequenza
     */
    async updateChiusuraExtraSequenza(requestParameters: UpdateChiusuraExtraSequenzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChiusuraExtraSequenzaRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una VariazioneCapi esistente
     */
    async updateChiusuraVariazioneCapiRaw(requestParameters: UpdateChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateChiusuraVariazioneCapi.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateChiusuraVariazioneCapi.');
        }

        if (requestParameters.idVariazioneCapi === null || requestParameters.idVariazioneCapi === undefined) {
            throw new runtime.RequiredError('idVariazioneCapi','Required parameter requestParameters.idVariazioneCapi was null or undefined when calling updateChiusuraVariazioneCapi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Chiusura/VariazioniCapi/{idVariazioneCapi}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idVariazioneCapi"}}`, encodeURIComponent(String(requestParameters.idVariazioneCapi))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChiusuraVariazioneCapiUpdateDTOToJSON(requestParameters.chiusuraVariazioneCapiUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una VariazioneCapi esistente
     */
    async updateChiusuraVariazioneCapi(requestParameters: UpdateChiusuraVariazioneCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateChiusuraVariazioneCapiRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un ciclo esistente
     */
    async updateCicloRaw(requestParameters: UpdateCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateCiclo.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updateCiclo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CicloUpdateDTOToJSON(requestParameters.cicloUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un ciclo esistente
     */
    async updateCiclo(requestParameters: UpdateCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCicloRaw(requestParameters, initOverrides);
    }

}
