import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ListiniApi, GetListiniAnimaliRequest, GetListiniPulciniRequest } from "../data/apis/ListiniApi";
import { useContext } from "react";
import FilieraContext from "../contexts/filieraContext";

export function listiniPulciniDataSource(idFiliera: number, showAll: boolean) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ListiniApi(apiConfiguration);

            return api.getListiniPulcini({
                idFiliera: idFiliera,
                showAll: showAll
            });
        },

        byKey: (key) => {
            const api = new ListiniApi(apiConfiguration);

            return api.getListinoPulciniById({
                idFiliera: idFiliera,
                id: key
            });
        },

        insert: (values) => {
            const api = new ListiniApi(apiConfiguration);

            return api.insertListinoPulcini({
                idFiliera: idFiliera,
                listinoPulciniCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new ListiniApi(apiConfiguration);
            return api.updateListinoPulcini({
                idFiliera:idFiliera,
                id: key,
                listinoPulciniUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ListiniApi(apiConfiguration);
            return api.deleteListinoPulcini({
                id: key,
                idFiliera: idFiliera
            });
        }
    });
}
