/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryAccasamentiDTO
 */
export interface SummaryAccasamentiDTO {
    /**
     * 
     * @type {Date}
     * @memberof SummaryAccasamentiDTO
     */
    dataConsegna?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryAccasamentiDTO
     */
    totaleMaschiAccasati?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryAccasamentiDTO
     */
    totaleFemmineAccasate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SummaryAccasamentiDTO
     */
    provenienza?: string | null;
}

/**
 * Check if a given object implements the SummaryAccasamentiDTO interface.
 */
export function instanceOfSummaryAccasamentiDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SummaryAccasamentiDTOFromJSON(json: any): SummaryAccasamentiDTO {
    return SummaryAccasamentiDTOFromJSONTyped(json, false);
}

export function SummaryAccasamentiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryAccasamentiDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataConsegna': !exists(json, 'dataConsegna') ? undefined : (json['dataConsegna'] === null ? null : new Date(json['dataConsegna'])),
        'totaleMaschiAccasati': !exists(json, 'totaleMaschiAccasati') ? undefined : json['totaleMaschiAccasati'],
        'totaleFemmineAccasate': !exists(json, 'totaleFemmineAccasate') ? undefined : json['totaleFemmineAccasate'],
        'provenienza': !exists(json, 'provenienza') ? undefined : json['provenienza'],
    };
}

export function SummaryAccasamentiDTOToJSON(value?: SummaryAccasamentiDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataConsegna': value.dataConsegna === undefined ? undefined : (value.dataConsegna === null ? null : value.dataConsegna.toISOString()),
        'totaleMaschiAccasati': value.totaleMaschiAccasati,
        'totaleFemmineAccasate': value.totaleFemmineAccasate,
        'provenienza': value.provenienza,
    };
}

