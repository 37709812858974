import React, { useRef, useContext} from 'react';
import FilieraContext from '../../../contexts/filieraContext';
import { conversioniDataSource } from '../../../datasources/conversioniDataSource';
import { Item as FormItem  } from 'devextreme-react/form';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { allevatoriDataSource } from '../../../datasources/allevatoriDataSource';
import PopupDettaglioConversione, {ConversioniDettagliPopupHandle} from '../../../components/popupDettaglioConversione/popupDettaglioConversione';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Popup,
  Form,
  Lookup,
  Button,
  Format
} from 'devextreme-react/data-grid';
import Box, {
  Item
} from 'devextreme-react/box';

const styles = {
  alignTitle: {
    marginLeft: "40px",
    marginRight: "40px",
  },
  fixSwitch: {
    width: "unset"
  }
};

export default function ConversionePage() {
const { currentFiliera } = useContext(FilieraContext);
const dettagliRef = useRef<ConversioniDettagliPopupHandle>(null);

function openDettaglioPopup(e: any) {
  dettagliRef.current?.show(e.row.key);
}

function addCustomOption(e: any){
  e.editorOptions.showClearButton = true;
}

  return (
    <React.Fragment>
      <Box
        direction="row"
        width="100%"
        height={75}
        align="center"
        crossAlign="center">
        <Item ratio={2}>
          <div className="rect">
          <h2 style={styles.alignTitle}>Quote riparto</h2>
          </div>
        </Item>
      </Box>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={conversioniDataSource(currentFiliera.Id)}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        errorRowEnabled={false}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
         <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Conversioni" showTitle={true} />
          <Form>
            <FormItem dataField="descrizione"/>
            <FormItem dataField="idAllevatore" editorType='dxSelectBox' editorOptions={{showClearButton : true}}/>
            <FormItem dataField="inizioValidita"/>
            <FormItem dataField="fineValidita"/>
            <FormItem dataField="valore"/>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
          <Button hint="Dettaglio" icon="more" onClick={openDettaglioPopup}/>
        </Column>
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'descrizione'} caption='Descrizione' />
        <Column dataField={'idAllevatore'} caption='Allevatore' >
          <Lookup dataSource={allevatoriDataSource} valueExpr="id" displayExpr="ragioneSociale" />
        </Column>
        <Column dataField={'inizioValidita'} />
        <Column dataField={'fineValidita'} />
        <Column dataField={'valore'} caption='Prezzo/Kg' dataType='number'  >
        <Format type='currency' precision={4}></Format>
        </Column>
      </DataGrid>

      <PopupDettaglioConversione ref={dettagliRef}></PopupDettaglioConversione>
    </React.Fragment>
)}

