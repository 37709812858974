/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  TipoAnimaleCreateDTO,
  TipoAnimaleDTO,
  TipoAnimaleUpdateDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TipoAnimaleCreateDTOFromJSON,
    TipoAnimaleCreateDTOToJSON,
    TipoAnimaleDTOFromJSON,
    TipoAnimaleDTOToJSON,
    TipoAnimaleUpdateDTOFromJSON,
    TipoAnimaleUpdateDTOToJSON,
} from '../models';

export interface DeleteTipoAnimaleRequest {
    id: number;
}

export interface GetTipiAnimaliRequest {
    showAll?: boolean;
}

export interface GetTipoAnimaliByIdRequest {
    id: number;
}

export interface GetTipoAnimaliByIdCicloRequest {
    id: number;
}

export interface InsertTipoAnimaleRequest {
    tipoAnimaleCreateDTO?: TipoAnimaleCreateDTO;
}

export interface UpdateTipoAnimaleRequest {
    id: number;
    tipoAnimaleUpdateDTO?: TipoAnimaleUpdateDTO;
}

/**
 * 
 */
export class TipiAnimaliApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una tipologia di animale esistente
     */
    async deleteTipoAnimaleRaw(requestParameters: DeleteTipoAnimaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTipoAnimale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una tipologia di animale esistente
     */
    async deleteTipoAnimale(requestParameters: DeleteTipoAnimaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTipoAnimaleRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutti i tipi di animali
     */
    async getTipiAnimaliRaw(requestParameters: GetTipiAnimaliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TipoAnimaleDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoAnimaleDTOFromJSON));
    }

    /**
     * Elenco di tutti i tipi di animali
     */
    async getTipiAnimali(requestParameters: GetTipiAnimaliRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TipoAnimaleDTO>> {
        const response = await this.getTipiAnimaliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera un tipo di animale per id
     */
    async getTipoAnimaliByIdRaw(requestParameters: GetTipoAnimaliByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoAnimaleDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTipoAnimaliById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoAnimaleDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un tipo di animale per id
     */
    async getTipoAnimaliById(requestParameters: GetTipoAnimaliByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoAnimaleDTO> {
        const response = await this.getTipoAnimaliByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera i tipi di animali presenti nel ciclo per id
     */
    async getTipoAnimaliByIdCicloRaw(requestParameters: GetTipoAnimaliByIdCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TipoAnimaleDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTipoAnimaliByIdCiclo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali/byCiclo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoAnimaleDTOFromJSON));
    }

    /**
     * Recupera i tipi di animali presenti nel ciclo per id
     */
    async getTipoAnimaliByIdCiclo(requestParameters: GetTipoAnimaliByIdCicloRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TipoAnimaleDTO>> {
        const response = await this.getTipoAnimaliByIdCicloRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di animale in anagrafica
     */
    async insertTipoAnimaleRaw(requestParameters: InsertTipoAnimaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoAnimaleDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TipoAnimaleCreateDTOToJSON(requestParameters.tipoAnimaleCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoAnimaleDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di animale in anagrafica
     */
    async insertTipoAnimale(requestParameters: InsertTipoAnimaleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoAnimaleDTO> {
        const response = await this.insertTipoAnimaleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di animale esistente
     */
    async updateTipoAnimaleRaw(requestParameters: UpdateTipoAnimaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTipoAnimale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiAnimali/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TipoAnimaleUpdateDTOToJSON(requestParameters.tipoAnimaleUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di animale esistente
     */
    async updateTipoAnimale(requestParameters: UpdateTipoAnimaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTipoAnimaleRaw(requestParameters, initOverrides);
    }

}
