import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { RazzeApi } from "../data/apis/RazzeApi";

export const razzeDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const razzeApi = new RazzeApi(apiConfiguration);
    
        return razzeApi.getRazze();
    },
  
    byKey: (key) => {
      const razzeApi = new RazzeApi(apiConfiguration);
        return razzeApi.getRazzaById({id:key});
    },
  
    insert: (values) => {
      const razzeApi = new RazzeApi(apiConfiguration);
      return razzeApi.insertRazza({
        razzaCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const razzeApi = new RazzeApi(apiConfiguration);
      return razzeApi.updateRazza({
        id: key,
        razzaUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const razzeApi = new RazzeApi(apiConfiguration);
      return razzeApi.deleteRazza({
        id: key
      });
     }
  });