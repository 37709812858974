import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis/AnagraficheApi";

export const mangimificiDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const api = new AnagraficheApi(apiConfiguration);
    
        return api.getMangimifici();
    },
  
    byKey: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
        return api.getMangimificioById({idMangimificio:key});
    },
  
    insert: (values) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.insertMangimificio({
        mangimificioCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const api = new AnagraficheApi(apiConfiguration);

      return api.updateMangimificio({
        idMangimificio: key,
        mangimificioUpdateDTO: values
      });
    },
     remove: (key) => {
      const api = new AnagraficheApi(apiConfiguration);
      return api.deleteMangimificio({
        idMangimificio: key
      });
     }
  });