/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  TipoContattoCreateDTO,
  TipoContattoDTO,
  TipoContattoUpdateDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TipoContattoCreateDTOFromJSON,
    TipoContattoCreateDTOToJSON,
    TipoContattoDTOFromJSON,
    TipoContattoDTOToJSON,
    TipoContattoUpdateDTOFromJSON,
    TipoContattoUpdateDTOToJSON,
} from '../models';

export interface DeleteTipoContattoRequest {
    id: number;
}

export interface GetTipoContattoByIdRequest {
    id: number;
}

export interface InsertTipoContattoRequest {
    tipoContattoCreateDTO?: TipoContattoCreateDTO;
}

export interface UpdateTipoContattoRequest {
    id: number;
    tipoContattoUpdateDTO?: TipoContattoUpdateDTO;
}

/**
 * 
 */
export class TipiContattiApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo di contatto esistente
     */
    async deleteTipoContattoRaw(requestParameters: DeleteTipoContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTipoContatto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiContatti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo di contatto esistente
     */
    async deleteTipoContatto(requestParameters: DeleteTipoContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTipoContattoRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutte le tipologie di contatti
     */
    async getTipiContattiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TipoContattoDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiContatti`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoContattoDTOFromJSON));
    }

    /**
     * Elenco di tutte le tipologie di contatti
     */
    async getTipiContatti(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TipoContattoDTO>> {
        const response = await this.getTipiContattiRaw(initOverrides);
        return await response.value();
    }

    /**
     * Recupera un tipo di contatto per id
     */
    async getTipoContattoByIdRaw(requestParameters: GetTipoContattoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoContattoDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTipoContattoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiContatti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoContattoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un tipo di contatto per id
     */
    async getTipoContattoById(requestParameters: GetTipoContattoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoContattoDTO> {
        const response = await this.getTipoContattoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di contatto in anagrafica
     */
    async insertTipoContattoRaw(requestParameters: InsertTipoContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoContattoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiContatti`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TipoContattoCreateDTOToJSON(requestParameters.tipoContattoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoContattoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di contatto in anagrafica
     */
    async insertTipoContatto(requestParameters: InsertTipoContattoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoContattoDTO> {
        const response = await this.insertTipoContattoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di contatto esistente
     */
    async updateTipoContattoRaw(requestParameters: UpdateTipoContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTipoContatto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiContatti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TipoContattoUpdateDTOToJSON(requestParameters.tipoContattoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di contatto esistente
     */
    async updateTipoContatto(requestParameters: UpdateTipoContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTipoContattoRaw(requestParameters, initOverrides);
    }

}
