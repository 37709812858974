import React, { useContext, useEffect, useRef, useState } from 'react';
import { CicliApi } from '../../data/apis/CicliApi';

import FilieraContext from '../../contexts/filieraContext';

import { useNavigate, useParams } from 'react-router-dom';

import PopupChiudiCiclo, { ChiudiCicloPopupHandle } from '../../components/popupChiudiCiclo/popupChiudiCiclo';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { apiConfiguration } from '../../apiConfig';
import { formatDate } from 'devextreme/localization';
import { alert, confirm } from 'devextreme/ui/dialog';
import { showError } from '../../utils/errorHandling';
import { Tabs } from 'devextreme-react';
import CicloSummaryPanel from '../../components/cicliPanels/cicloSummaryPanel';
import DocumentsTable from './documentsTable';
import CarichiTable from './carichiTable';
import PopupReportChiusura, { ReportChiusuraPopupHandle } from '../../components/popupReportChiusura/popupReportChiusura';
import PopupReportContoEconomico, { ReportContoEconomicoPopupHandle } from '../../components/popupReportContoEconomico/popupReportContoEconomico';
import { CicloDTO } from '../../data';
import DocumentiAccasamentiTable from './documentiAccasamentiTable';
import DocumentiMangimeTable from './documentiMangimeTable';
import PopupReportRegistroMortalita, { ReportRegistroMortalitaPopupHandle } from '../../components/popupReportRegistroMortalita/popupReportRegistroMortalita';

type state = {
    caption: string,
    approveButtonEnabled: boolean,
    readonly: boolean,
    ciclo: CicloDTO
}

export default function CicloDashboard() {
    const tabs = ["Dashboard", "Accasamenti", "Mangimi", "Carichi", "Mortalità", "Trattamenti"];
    const chiudiCicloRef = useRef<ChiudiCicloPopupHandle>(null);
    const [state, setState] = useState<state | null>(null);
    const [currentTab, setCurrentTab] = useState<string>("Dashboard");
    const navigate = useNavigate();
    const { currentFiliera } = useContext(FilieraContext);
    const params = useParams<Props>();
    const reportChiusuraRef = useRef<ReportChiusuraPopupHandle>(null);
    const reportContoEconomicoRef = useRef<ReportContoEconomicoPopupHandle>(null);
    const reportRegistroMortalitaRef = useRef<ReportRegistroMortalitaPopupHandle>(null);

    type Props = {
        id: string,
        numeroCiclo: string,
        idAllevamento: string
    }

    const handleCloseCycle = () => {
        chiudiCicloRef.current?.show(Number(params.id));
    };

    function openReportChiusuraPopup(e: any) {
        reportChiusuraRef.current?.show(currentFiliera.Id, Number(params.id));
    }

    function openReportContoEconomicoPopup(e: any) {
        reportContoEconomicoRef.current?.show(currentFiliera.Id, Number(params.id));
    }

    function openReportRegistroMortalitaPopup(e: any) {
        reportRegistroMortalitaRef.current?.show(currentFiliera.Id, Number(params.id));
    }

    async function RefreshState() {
        try {
            var api = new CicliApi(apiConfiguration);

            var ciclo = await api.getCicloById({ idFiliera: currentFiliera.Id, idCiclo: Number(params.id) });

            //console.log(ciclo);
            var cap = "Ciclo Numero " + ciclo.cicloNumero + " su " + (ciclo.allevamento?.descrizioneBreve ?? ciclo.allevamento?.codiceAllevamento ?? "(senza nome)") + " di " + ciclo.allevamento?.allevatore?.ragioneSociale;
            if (ciclo.dataInizio)
                cap += " - Data inizio " + formatDate(ciclo.dataInizio as Date, "dd/MM/yyyy");
            setState({ ...state, caption: cap, approveButtonEnabled: ciclo.stato === "02 - CHIUSURA IN CORSO", readonly: ciclo.readOnly ?? false, ciclo: ciclo });
        }
        catch {
            navigate(-1); // Se il ciclo non viene trovato sulla filiera allora torno alla lista dei cicli
        }
    }

    const sentToApprove = async () => {
        if (await confirm("A seguito di questa richiesta non sarà più possibile modificare il ciclo.<br>Continuare?", "Richiesta di approvazione")) {
            var api = new CicliApi(apiConfiguration);
            api.chiusuraApprovalRequest({
                idFiliera: currentFiliera.Id,
                idCiclo: Number(params.id)
            })
                .then(() => {
                    alert("Richiesta di approvazione inviata", "Richiesta di approvazione");
                    RefreshState();
                })
                .catch(showError);
        }
    };

    useEffect(() => {
        RefreshState();
    }, [params.id, currentFiliera]);

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                        text: 'Elenco cicli',
                        icon: 'back',
                        onClick: () => navigate(-1)
                    }}
                />
                <Item location="center"
                    locateInMenu="never"
                    text={state?.caption}
                />
                <Item location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                        text: 'Chiudi ciclo',
                        onClick: handleCloseCycle,
                        disabled: state?.readonly
                    }}
                />
                <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={{
                    text: 'Stampa verbale',
                    onClick: openReportChiusuraPopup
                }}
            />
            <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={{
                    text: 'Stampa conto economico',
                    onClick: openReportContoEconomicoPopup
                }}
            />
            <Item location="after"
                locateInMenu="auto"
                widget="dxButton"
                options={{
                    text: 'Registro C/S',
                    onClick: openReportRegistroMortalitaPopup
                }}
            />
                <Item location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                        text: 'Validazione e invio',
                        onClick: sentToApprove,
                        disabled: !state?.approveButtonEnabled
                    }}
                />
            </Toolbar>
            <div className="dx-d-flex dx-justify-content-around dx-mx-2">
                <Tabs
                    dataSource={tabs}
                    selectedItem={currentTab}
                    onSelectedItemChange={e => setCurrentTab(e)}
                />

                {currentTab === "Dashboard" && <CicloSummaryPanel idCiclo={Number(params.id)} />}

                {currentTab === "Accasamenti" && state?.ciclo &&
                    <div className={'dx-card'} style={{textAlign:"center"}}>
                        <h3>Accasamenti</h3>
                        <DocumentiAccasamentiTable ciclo={state?.ciclo} readonly={state?.readonly} />
                    </div>
                }

                {currentTab === "Mangimi" && state?.ciclo &&
                    <div className={'dx-card'} style={{textAlign:"center"}}>
                        <h3>Mangimi</h3>
                        <DocumentiMangimeTable ciclo={state.ciclo} readonly={state?.readonly} />
                    </div>
                }

                {currentTab === "Carichi" && state?.ciclo &&
                    <div className={'dx-card'} style={{textAlign:"center"}}>
                        <h3>Carichi</h3>
                        <CarichiTable ciclo={state.ciclo} readonly={state?.readonly} />
                    </div>
                }

                {currentTab === "Mortalità" && state?.ciclo &&
                    <div className={'dx-card'} style={{textAlign:"center"}}>
                        <h3>Mortalità</h3>
                        <DocumentsTable tipo={"Morti"} ciclo={state.ciclo} />
                    </div>
                }

                {currentTab === "Trattamenti" && state?.ciclo &&
                    <div className={'dx-card'} style={{textAlign:"center"}}>
                        <h3>Trattamenti</h3>
                        <DocumentsTable tipo={"Trattamenti"} ciclo={state.ciclo} readonly={state?.readonly} />
                    </div>
                }
            </div>

            <PopupReportChiusura ref={reportChiusuraRef}></PopupReportChiusura>
            <PopupReportContoEconomico ref={reportContoEconomicoRef}></PopupReportContoEconomico>
            <PopupReportRegistroMortalita ref={reportRegistroMortalitaRef}></PopupReportRegistroMortalita>
            <PopupChiudiCiclo ref={chiudiCicloRef} onChanged={RefreshState}></PopupChiudiCiclo>
        </React.Fragment >
    );
};
