import React, { useImperativeHandle, useState, useContext, useRef } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Button, Column, Editing, FilterRow, Form, Pager, Paging } from "devextreme-react/data-grid";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import { EmptyItem, Item } from "devextreme-react/form";
import { pagamentiDataSource } from "../../datasources/pagamentiDataSource";
import 'devextreme-react/text-area';
import FilieraContext from "../../contexts/filieraContext";
import PopupUploadFile, {UploadFilePopupHandle} from '../../components/popupUploadFile/popupUploadFile';
import { apiConfiguration } from "../../apiConfig";
import { CicliApi } from "../../data";
import PopupDocumentAttachment, { DocumentAttachmentPopupHandle } from "../popupDocumentAttachment/popupDocumentAttachment";
import PopupShowPagamentoAttachment, { PopupShowPagamentoAttachmentHandle } from "../popupShowPagamentoAttachment/popupShowPagamentoAttachment";

type Props = {}

export type PagamentiPopupHandle = {
  show: (idCiclo: number) => void,
}

type state = {
  idCiclo: number | undefined,
  visible: boolean,
  datasource: any,
  title:string,
  readonly: boolean
}

const PopupPagamenti: React.ForwardRefRenderFunction<PagamentiPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idCiclo: undefined, visible: false, datasource: null, title:"",readonly: false });
  const { currentFiliera } = useContext(FilieraContext);
  const uploadRef = useRef<UploadFilePopupHandle>(null);
  const pdfViewerRef = useRef<PopupShowPagamentoAttachmentHandle>(null);

  function openUploadFilePopup(e: any) {
    var uploadUrl: string = apiConfiguration.basePath + "/Filiere/"+currentFiliera.Id+"/Cicli/"+e.row.data.idCiclo+"/Pagamenti/"+e.row.data.id+"/File"
    uploadRef.current?.show(currentFiliera.Id,e.row.data.idCiclo,e.row.data.id,uploadUrl);
  }

  function hideInfo() {
    setState({ idCiclo: undefined, visible: false, datasource: null, title:"",readonly: false });
  }

  function grid_InitNewRow(e:any){
    e.data.acconto=false;
  }

  function HasAttachment(e: any) {
    return e.row.data.idArxivar && e.row.data.idArxivar !== 0;
  }

  function ShowAttachment(e: any) {
    pdfViewerRef.current?.show(currentFiliera.Id, Number(state.idCiclo), e.row.data.id);
  }

  useImperativeHandle(ref, () => ({
    async show(idCiclo) {
      var api = new CicliApi(apiConfiguration);

        var ciclo = await api.getCicloById({idFiliera: currentFiliera.Id, idCiclo:idCiclo});
        
        setState({
          idCiclo:idCiclo,
          visible:true,
          datasource:pagamentiDataSource(currentFiliera.Id, idCiclo),
          title: "Pagamenti",
          readonly: ciclo.readOnly ?? false
        });
    }
  }));

  return (<React.Fragment>

    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      fullScreen={true}
      showTitle={true}
      onHidden={hideInfo}
      title={state.title}
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={state.datasource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
        onInitNewRow={grid_InitNewRow}
      >
        <Editing
          mode="popup"
          allowUpdating={true} //{!state.readonly}
          allowAdding={true} //{!state.readonly}
          allowDeleting={true} //{!state.readonly}
          >
          <Popup title="Pagamento" showTitle={true} />
          <Form colCount={2}>
              
              <Item dataField="data"  />
              <Item dataField="importo"  />
              <Item dataField="acconto"  />
              <EmptyItem/>
              <Item dataField="note" editorType="dxTextArea" colSpan={2} />
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />

        <Column  type='buttons' >
          <Button name="edit" />
          <Button name="delete" />
          <Button hint="Upload documento" icon="upload" onClick={openUploadFilePopup} />
          <Button hint="Visualizza documento" icon="doc" visible={HasAttachment} onClick={ShowAttachment} />
        </Column>
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'acconto'} hidingPriority={2} dataType="boolean"/>
        <Column dataField={'data'} hidingPriority={6} dataType="date"/>
        <Column dataField={'importo'} format='currency' dataType="number" hidingPriority={7}/>
        <Column dataField={'note'} hidingPriority={3}/>
        <Column dataField={'autorizzato'} hidingPriority={5}/>
        <Column dataField={'dataAutorizzazione'} hidingPriority={4}/>
      </DataGrid>
    </Popup>

    <PopupShowPagamentoAttachment ref={pdfViewerRef}/>
    
    <PopupUploadFile ref={uploadRef} allowedFileExtensions={[".pdf"]}></PopupUploadFile>
  </React.Fragment>
  );
}

export default React.forwardRef(PopupPagamenti);
