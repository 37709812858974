import Form, { ButtonItem, Item, Label, SimpleItem } from 'devextreme-react/form';
import { incubatoiDataSource } from '../../../datasources/incubatoiDataSource';
import { useContext, useEffect, useRef, useState } from 'react';
import { DocumentiApi } from '../../../data';
import { apiConfiguration } from '../../../apiConfig';
import FilieraContext from '../../../contexts/filieraContext';
import { ClickEvent } from 'devextreme/ui/button';
import { showError } from '../../../utils/errorHandling';
import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { FieldDataChangedEvent } from 'devextreme/ui/form';

type Props = {
    idCiclo: number,
    idDocumento?: number,
    tipoDocumento?: string,
    onUpdated: () => void,
    onInserted: (idDocumento: number) => void,
    readonly?: boolean
}

const MortiDocumentForm = (props: Props) => {
    const positionEditorOptions = { dataSource: incubatoiDataSource, valueExpr: "id", displayExpr: "ragioneSociale", searchEnabled: true };
    const { currentFiliera } = useContext(FilieraContext);
    const [formData, setFormData] = useState<any>();
    const formRef = useRef<Form>(null);
    const [loadPanelVisible, setLoadPanelVisible] = useState<boolean>(false);

    useEffect(() => {
        setFormData(null);

        const api = new DocumentiApi(apiConfiguration);
        if (props.idDocumento) {
            api.getDocumentoById({ idFiliera: currentFiliera.Id, idCiclo: props.idCiclo, idDocumento: props.idDocumento })
                .then(function (data) {
                    setFormData(data);
                });
        }
        else if (props.tipoDocumento) {
            //console.log({idFiliera:currentIdFiliera, idCiclo:props.idCiclo, tipo: props.tipoDocumento });
            api.getDocumentoDefault({ idFiliera: currentFiliera.Id, idCiclo: props.idCiclo, tipo: props.tipoDocumento })
                .then(function (data) {
                    console.log(data);
                    setFormData(data);
                });
        }
    }, [currentFiliera, props]);

    function save_click(e: ClickEvent) {
        var validate = formRef.current?.instance.validate();

        if (validate?.isValid) {
            const api = new DocumentiApi(apiConfiguration);

            var data = formRef.current?.instance.option("formData");

            if (!data.id || data.id === 0) {
                setLoadPanelVisible(true);
                api.insertDocumento({
                    idCiclo: props.idCiclo,
                    idFiliera: currentFiliera.Id,
                    documentoCreateDTO: data
                })
                    .catch(showError)
                    .then(function (data) {
                        if (data) {
                            setFormData(data);
                            if (data?.id)
                                props.onInserted(data?.id);
                        }
                    })
                    .finally(() => setLoadPanelVisible(false));
            }
            else {

                setLoadPanelVisible(true);
                api.updateDocumento({
                    idCiclo: props.idCiclo,
                    idFiliera: currentFiliera.Id,
                    idDocumento: data.id,
                    documentoUpdateDTO: data
                })
                    .catch(showError)
                    .then(props.onUpdated)
                    .finally(() => setLoadPanelVisible(false));
            }
        }
    }

    function onFieldDataChanged(e: FieldDataChangedEvent) {
        if (e.dataField==="dataDocumento" && e.component.getEditor('dataOperazione')?.option('value')==null){
            e.component.updateData("dataOperazione", e.value);
        }   
    }

    return (
        <React.Fragment>
            <Form id="form" ref={formRef} colCount={3} formData={formData} onFieldDataChanged={onFieldDataChanged} labelMode='floating'>
                <Item dataField="dataDocumento" editorType='dxDateBox' isRequired={true} />
                <Item dataField="dataOperazione" editorType='dxDateBox' isRequired={true} />
                <Item dataField="numeroDocumento" />
                <Item dataField="note" colSpan={3} editorType='dxTextArea' />
                <ButtonItem horizontalAlignment="right" colSpan={3}
                    buttonOptions={{
                        text: 'Salva',
                        type: 'success',
                        onClick: save_click,
                        disabled: props.readonly
                    }}
                />
            </Form>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: '#form' }}
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />
        </React.Fragment>
    );
};

export default MortiDocumentForm;
