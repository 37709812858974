import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi, GetAllevamentiByAllevatoreRequest } from "../data/apis/AnagraficheApi";

export function allevamentiByAllevatoreDataSource(idAllevatore: number) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new AnagraficheApi(apiConfiguration);

            const request: GetAllevamentiByAllevatoreRequest = {
                idAllevatore: idAllevatore
            };

            return api.getAllevamentiByAllevatore(request);
        },

        byKey: (key) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.getAllevamentoById({
                idAllevamento:key
            });
        },

        insert: (values) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.insertAllevamento({
                allevamentoCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.updateAllevamento({
                //idAllevamento:idAnagrafe,
                idAllevamento: key,
                allevamentoUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.deleteAllevamento({
                idAllevamento: key
            });
        }
    });
}

export function allevamentiDataSource() {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new AnagraficheApi(apiConfiguration);

            // console.log("siamo passati nel data source di allevamenti");

            return api.getAllevamenti();
        },

        byKey: (key) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.getAllevamentoById({
                idAllevamento:key
            });
        },

        insert: (values) => {
            const api = new AnagraficheApi(apiConfiguration);

            return api.insertAllevamento({
                allevamentoCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.updateAllevamento({
                //idAllevamento:idAnagrafe,
                idAllevamento: key,
                allevamentoUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new AnagraficheApi(apiConfiguration);
            return api.deleteAllevamento({
                idAllevamento: key
            });
        }
    });
}
