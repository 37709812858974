import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { CollapsablePanel } from "../collapsablePanel/CollapsablePanel";
import DocumentsTable from "../../pages/cicli/documentsTable";
import { LinearGauge, LoadIndicator } from "devextreme-react";
import Form, {  SimpleItem, GroupItem, TabbedItem, TabPanelOptions, Tab,} from 'devextreme-react/form';
import { CicliApi, CicloSummaryDTO, SummaryAccasamentiDTO, SummaryCarichiDTO, SummaryConversioneDTO, SummaryMangimiDTO, SummaryMortiDTO } from "../../data";
import { apiConfiguration } from "../../apiConfig";
import FilieraContext from "../../contexts/filieraContext";
import { Scale, SubvalueIndicator } from "devextreme-react/linear-gauge";
import "./cicloSummaryPanel.scss"

type CicloSummaryPanelProps = {
  idCiclo: number
}

export default function CicloSummaryPanel(params: CicloSummaryPanelProps) {
  const { currentFiliera } = useContext(FilieraContext);
  const [state, setState] = useState<CicloSummaryDTO | null>(null);

  async function RefreshData() {
    try {
      var api = new CicliApi(apiConfiguration);

      setState(await api.getCicloSummary({ idFiliera: currentFiliera.Id, idCiclo: Number(params.idCiclo) }));
    }
    catch {

    }
  }

  useEffect(() => {
    RefreshData();
  }, [params.idCiclo]);

  return (
    <React.Fragment >
      {state?.conversione?.indiceDiConversione != null &&
        <div className={'dx-card'}>
          <h3>Conversione</h3>
          <LoadIndicator visible={state == null} />
          {state && state?.conversione?.indiceDiConversione != null &&
            <LinearGauge
              id="c4"
              value={Number(state?.conversione?.indiceDiConversione)}
              subvalues={[Number(state?.conversione?.indiceDiConversione)]}
            >
              <Scale
                startValue={0}
                endValue={2}
                tickInterval={0.25}
              >
              </Scale>
              <SubvalueIndicator
                type="textCloud"
                color="#734F96"
              ></SubvalueIndicator>
            </LinearGauge>
          }
        </div>
      }

      <div className={'dx-card'}>
        <h3>Accasamenti</h3>
        <LoadIndicator visible={state == null} />
        {state &&
          <Form
            id="form"
            labelMode={"outside"}
            formData={state?.accasamenti}
            readOnly={true}
            // showColonAfterLabel={showColon}
            labelLocation={"left"}
            // minColWidth={minColWidth}
            colCount={4}
          />
        }
      </div>

      <div className={'dx-card'}>
        <h3>Carichi</h3>
        <LoadIndicator visible={state == null} />
        {state &&
          <Form
            id="form"
            labelMode={"outside"}
            formData={state?.carichi}
            readOnly={true}
            // showColonAfterLabel={showColon}
            labelLocation={"left"}
            // minColWidth={minColWidth}
            colCount={4}
            >
             <SimpleItem dataField="numeroFemmineCaricate" />
             <SimpleItem dataField="kgFemmineCaricate" />
             <SimpleItem dataField="pesoMedioFemmine" />
             <SimpleItem dataField="numeroFemmineDaCaricare" />

             <SimpleItem dataField="numeroMaschiCaricati" />
             <SimpleItem dataField="kgMaschiCaricati" />
             <SimpleItem dataField="pesoMedioMaschi" />
             <SimpleItem dataField="numeroMaschiDaCaricare" />
         </Form>
        }
      </div>

      <div className={'dx-card'}>
        <h3>Mangimi</h3>
        <LoadIndicator visible={state == null} />
        {state &&
          <Form
            id="form"
            labelMode={"outside"}
            formData={state?.mangimi}
            readOnly={true}
            // showColonAfterLabel={showColon}
            labelLocation={"left"}
            // minColWidth={minColWidth}
            colCount={4}
          />
        }
      </div>

      <div className={'dx-card'}>
        <h3>Mortalità</h3>
        <LoadIndicator visible={state == null} />
        {state &&
          <Form
            id="form"
            labelMode={"outside"}
            formData={state?.morti}
            readOnly={true}
            // showColonAfterLabel={showColon}
            labelLocation={"left"}
            // minColWidth={minColWidth}
            colCount={4}
          />
        }
      </div>
    </React.Fragment >
  );
}
