import React, { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import './documentAttachmentViewer.scss';
import { apiConfiguration } from '../../apiConfig';
import { DocumentiApi } from '../../data/apis/DocumentiApi';

export default function DocumentAttachmentViewer(props) {
    const [loading, setLoading] = useState(false);
    const [instance, setInstance] = useState();
    const viewer = useRef();

    useEffect(() => {
        //console.log(loading);
        if (!instance) {
            //setLoading(true);

            WebViewer(
                {
                    path: '/webviewer/lib',
                    licenseKey: 'ElFQV4k0W9MNBaN9iTyT'
                },
                viewer.current,
                ).then((instance) => { 
                    setInstance(instance); 
                    const theme = instance.UI.Theme;
                    instance.UI.setTheme(theme.DARK);
                    instance.UI.setPrintQuality(5);
                    instance.UI.useEmbeddedPrint(true);
                    instance.UI.setLanguage('it');
                });
        }
    }, []);

    useEffect(() => {
        if (instance) {
            if (props.idCiclo && props.idFiliera) {
                var api = new DocumentiApi(apiConfiguration);
                api.downloadFileDocumento({
                    idFiliera: props.idFiliera,
                    idCiclo: props.idCiclo,
                    idDocumento: props.idDocumento
                }).then((report) => {
                    console.log("Loaded file");
                    instance.UI.loadDocument(report, 
                        { 
                            extension: 'pdf', 
                            onError: function(e){ console.error(e); } ,
                            documentId: props.idDocumento
                        });
                });
            }
            else {
                instance.UI.closeDocument().then(function () {
                    console.log('Document is closed');
                });
            }
        }
    }, [props, instance]);

    return (
        <div>
            <div className="webviewer" ref={viewer}></div>
        </div>
    )
}
