import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useScreenSize } from "../../utils/media-query";
import AccasamentiDocumentForm from "../../pages/cicli/formsDocumenti/AccasamentiDocumentForm";
import AccasamentiTable from "../../pages/cicli/dettagli/accasamentiTable";
import { CollapsablePanel } from "../collapsablePanel/CollapsablePanel";
import MortiDocumentForm from "../../pages/cicli/formsDocumenti/MortiDocumentForm";
import MangimeDocumentForm from "../../pages/cicli/formsDocumenti/MangimeDocumentForm";
import TrattamentiDocumentForm from "../../pages/cicli/formsDocumenti/TrattamentiDocumentForm";
import MangimiTable from "../../pages/cicli/dettagli/mangimiTable";
import TrattamentiTable from "../../pages/cicli/dettagli/trattamentiTable";
import MortiTable from "../../pages/cicli/dettagli/mortiTable";
import { CicloDTO } from "../../data";

type Props = {
  ciclo?: CicloDTO,
  tipoDocumento: string,
  onChange: () => void,
  readonly?:boolean
}

export type DocumentoPopupHandle = {
  edit: (idDocumento: number) => void,
  new: () => void,
}

type state = {
  idDocumento?: number,
  visible: boolean
}

const PopupDocumento: React.ForwardRefRenderFunction<DocumentoPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idDocumento: 0, visible: false });
  const { isXSmall, isSmall } = useScreenSize();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  function hideInfo() {
    console.log(isChanged);
    if (isChanged)
      props.onChange();
    setState({ idDocumento: 0, visible: false });
  }

  useImperativeHandle(ref, () => ({
    edit(idDocumento) {
      setState({
        idDocumento: idDocumento,
        visible: true
      });
    },
    new() {
      setState({
        visible: true
      });
    }
  }));

  function documento_inserted(e: number) {
    setState({
      ...state,
      idDocumento: e
    });
    setIsChanged(true);
  }

  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={false}
      fullScreen={true}
      onHidden={hideInfo}
      title="Documento"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />

      {(() => {
        if (state.visible && props.ciclo)
          switch (props.tipoDocumento) {
            case 'Accasamenti':
              return (
                <React.Fragment>
                  <CollapsablePanel title="Accasamenti - Testata del documento" >
                    <AccasamentiDocumentForm idCiclo={Number(props?.ciclo?.id)} idDocumento={state.idDocumento} tipoDocumento={props.tipoDocumento} onUpdated={() => setIsChanged(true)} onInserted={documento_inserted} readonly={props.readonly} />
                  </CollapsablePanel>
                  {state.idDocumento &&
                    <CollapsablePanel title="Dettagli del documento" >
                      <AccasamentiTable ciclo={props?.ciclo} idDocumento={state.idDocumento} onChanged={() => setIsChanged(true)} readonly={props.readonly}></AccasamentiTable>
                    </CollapsablePanel>
                  }
                </React.Fragment>);

            case 'Morti':
              return (
                <React.Fragment>
                  <CollapsablePanel title="Mortalità - Testata del documento" >
                    <MortiDocumentForm idCiclo={Number(props.ciclo.id)} idDocumento={state.idDocumento} tipoDocumento={props.tipoDocumento} onUpdated={() => setIsChanged(true)} onInserted={documento_inserted} readonly={props.readonly || props?.ciclo?.registroCSDefinitivo}/>
                  </CollapsablePanel>
                  {state.idDocumento &&
                    <CollapsablePanel title="Dettagli del documento" >
                      <MortiTable ciclo={props.ciclo} idDocumento={state.idDocumento} onChanged={() => setIsChanged(true)} readonly={props.readonly || props?.ciclo?.registroCSDefinitivo}/>
                    </CollapsablePanel>
                  }
                </React.Fragment>);

            case 'Mangime':
              return (
                <React.Fragment>
                  <CollapsablePanel title="Mangimi - Testata del documento" >
                    <MangimeDocumentForm idCiclo={Number(props.ciclo.id)} idDocumento={state.idDocumento} tipoDocumento={props.tipoDocumento} onUpdated={() => setIsChanged(true)} onInserted={documento_inserted} readonly={props.readonly}/>
                  </CollapsablePanel>
                  {state.idDocumento &&
                    <CollapsablePanel title="Dettagli del documento" >
                      <MangimiTable ciclo={props.ciclo} idDocumento={state.idDocumento} onChanged={() => setIsChanged(true)} readonly={props.readonly}/>
                    </CollapsablePanel>
                  }
                </React.Fragment>);

            case 'Trattamenti':
              return (
                <React.Fragment>
                  <CollapsablePanel title="Trattamenti - Testata del documento" >
                    <TrattamentiDocumentForm idCiclo={Number(props.ciclo.id)} idDocumento={state.idDocumento} tipoDocumento={props.tipoDocumento} onUpdated={() => setIsChanged(true)} onInserted={documento_inserted} readonly={props.readonly}/>
                  </CollapsablePanel>
                  {state.idDocumento &&
                    <CollapsablePanel title="Dettagli del documento" >
                      <TrattamentiTable ciclo={props.ciclo} idDocumento={state.idDocumento} onChanged={() => setIsChanged(true)} readonly={props.readonly}/>
                    </CollapsablePanel>
                  }
                </React.Fragment>);
          }
      })()}
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupDocumento);
