/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConversioneCreateDTO,
  ConversioneDTO,
  ConversioneDettaglioCreateDTO,
  ConversioneDettaglioDTO,
  ConversioneDettaglioUpdateDTO,
  ConversioneUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    ConversioneCreateDTOFromJSON,
    ConversioneCreateDTOToJSON,
    ConversioneDTOFromJSON,
    ConversioneDTOToJSON,
    ConversioneDettaglioCreateDTOFromJSON,
    ConversioneDettaglioCreateDTOToJSON,
    ConversioneDettaglioDTOFromJSON,
    ConversioneDettaglioDTOToJSON,
    ConversioneDettaglioUpdateDTOFromJSON,
    ConversioneDettaglioUpdateDTOToJSON,
    ConversioneUpdateDTOFromJSON,
    ConversioneUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteConversioneRequest {
    idFiliera: number;
    idConversione: number;
}

export interface DeleteConversioneDettaglioRequest {
    id: number;
    idFiliera: number;
    idConversione: number;
}

export interface GetConversioneByIdRequest {
    idFiliera: number;
    idConversione: number;
}

export interface GetConversioneDettaglioByIdRequest {
    id: number;
    idFiliera: number;
    idConversione: number;
}

export interface GetConversioniRequest {
    idFiliera: number;
}

export interface GetConversioniDettagliRequest {
    idFiliera: number;
    idConversione: number;
}

export interface InsertConversioneRequest {
    idFiliera: number;
    conversioneCreateDTO?: ConversioneCreateDTO;
}

export interface InsertConversioneDettaglioRequest {
    idFiliera: number;
    idConversione: number;
    conversioneDettaglioCreateDTO?: ConversioneDettaglioCreateDTO;
}

export interface UpdateConversioneRequest {
    idFiliera: number;
    idConversione: number;
    conversioneUpdateDTO?: ConversioneUpdateDTO;
}

export interface UpdateConversioneDettaglioRequest {
    idFiliera: number;
    idConversione: number;
    id: number;
    conversioneDettaglioUpdateDTO?: ConversioneDettaglioUpdateDTO;
}

/**
 * 
 */
export class ConversioniApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una conversione esistente
     */
    async deleteConversioneRaw(requestParameters: DeleteConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteConversione.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling deleteConversione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una conversione esistente
     */
    async deleteConversione(requestParameters: DeleteConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConversioneRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un dettaglio di conversione esistente
     */
    async deleteConversioneDettaglioRaw(requestParameters: DeleteConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConversioneDettaglio.');
        }

        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteConversioneDettaglio.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling deleteConversioneDettaglio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}/Dettagli/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un dettaglio di conversione esistente
     */
    async deleteConversioneDettaglio(requestParameters: DeleteConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConversioneDettaglioRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera una conversione per id
     */
    async getConversioneByIdRaw(requestParameters: GetConversioneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversioneDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getConversioneById.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling getConversioneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversioneDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera una conversione per id
     */
    async getConversioneById(requestParameters: GetConversioneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversioneDTO> {
        const response = await this.getConversioneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera il dettaglio di una conversione per id
     */
    async getConversioneDettaglioByIdRaw(requestParameters: GetConversioneDettaglioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversioneDettaglioDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConversioneDettaglioById.');
        }

        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getConversioneDettaglioById.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling getConversioneDettaglioById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}/Dettagli/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversioneDettaglioDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera il dettaglio di una conversione per id
     */
    async getConversioneDettaglioById(requestParameters: GetConversioneDettaglioByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversioneDettaglioDTO> {
        const response = await this.getConversioneDettaglioByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutte le conversioni
     */
    async getConversioniRaw(requestParameters: GetConversioniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConversioneDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getConversioni.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConversioneDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutte le conversioni
     */
    async getConversioni(requestParameters: GetConversioniRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConversioneDTO>> {
        const response = await this.getConversioniRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutti i dettagli della conversione
     */
    async getConversioniDettagliRaw(requestParameters: GetConversioniDettagliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConversioneDettaglioDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getConversioniDettagli.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling getConversioniDettagli.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}/Dettagli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConversioneDettaglioDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutti i dettagli della conversione
     */
    async getConversioniDettagli(requestParameters: GetConversioniDettagliRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConversioneDettaglioDTO>> {
        const response = await this.getConversioniDettagliRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea una nuova conversione in anagrafica
     */
    async insertConversioneRaw(requestParameters: InsertConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversioneDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertConversione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConversioneCreateDTOToJSON(requestParameters.conversioneCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversioneDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea una nuova conversione in anagrafica
     */
    async insertConversione(requestParameters: InsertConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversioneDTO> {
        const response = await this.insertConversioneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     */
    async insertConversioneDettaglioRaw(requestParameters: InsertConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConversioneDettaglioDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertConversioneDettaglio.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling insertConversioneDettaglio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}/Dettagli`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConversioneDettaglioCreateDTOToJSON(requestParameters.conversioneDettaglioCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversioneDettaglioDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     */
    async insertConversioneDettaglio(requestParameters: InsertConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConversioneDettaglioDTO> {
        const response = await this.insertConversioneDettaglioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una conversione esistente
     */
    async updateConversioneRaw(requestParameters: UpdateConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateConversione.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling updateConversione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConversioneUpdateDTOToJSON(requestParameters.conversioneUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una conversione esistente
     */
    async updateConversione(requestParameters: UpdateConversioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConversioneRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un dettaglio di conversione esistente
     */
    async updateConversioneDettaglioRaw(requestParameters: UpdateConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateConversioneDettaglio.');
        }

        if (requestParameters.idConversione === null || requestParameters.idConversione === undefined) {
            throw new runtime.RequiredError('idConversione','Required parameter requestParameters.idConversione was null or undefined when calling updateConversioneDettaglio.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConversioneDettaglio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Conversioni/{idConversione}/Dettagli/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idConversione"}}`, encodeURIComponent(String(requestParameters.idConversione))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConversioneDettaglioUpdateDTOToJSON(requestParameters.conversioneDettaglioUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li><li>Filiera/Conversioni/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un dettaglio di conversione esistente
     */
    async updateConversioneDettaglio(requestParameters: UpdateConversioneDettaglioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConversioneDettaglioRaw(requestParameters, initOverrides);
    }

}
