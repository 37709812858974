/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RazzaDTO } from './RazzaDTO';
import {
    RazzaDTOFromJSON,
    RazzaDTOFromJSONTyped,
    RazzaDTOToJSON,
} from './RazzaDTO';
import type { TipoAnimaleDTO } from './TipoAnimaleDTO';
import {
    TipoAnimaleDTOFromJSON,
    TipoAnimaleDTOFromJSONTyped,
    TipoAnimaleDTOToJSON,
} from './TipoAnimaleDTO';

/**
 * 
 * @export
 * @interface MovimentoAccasamentoDTO
 */
export interface MovimentoAccasamentoDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    idDocumento?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoAccasamentoDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoAccasamentoDTO
     */
    costoVaccino?: number;
    /**
     * 
     * @type {TipoAnimaleDTO}
     * @memberof MovimentoAccasamentoDTO
     */
    tipoAnimale?: TipoAnimaleDTO;
    /**
     * 
     * @type {RazzaDTO}
     * @memberof MovimentoAccasamentoDTO
     */
    razza?: RazzaDTO;
}

/**
 * Check if a given object implements the MovimentoAccasamentoDTO interface.
 */
export function instanceOfMovimentoAccasamentoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoAccasamentoDTOFromJSON(json: any): MovimentoAccasamentoDTO {
    return MovimentoAccasamentoDTOFromJSONTyped(json, false);
}

export function MovimentoAccasamentoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoAccasamentoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idDocumento': !exists(json, 'idDocumento') ? undefined : json['idDocumento'],
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'costoVaccino': !exists(json, 'costoVaccino') ? undefined : json['costoVaccino'],
        'tipoAnimale': !exists(json, 'tipoAnimale') ? undefined : TipoAnimaleDTOFromJSON(json['tipoAnimale']),
        'razza': !exists(json, 'razza') ? undefined : RazzaDTOFromJSON(json['razza']),
    };
}

export function MovimentoAccasamentoDTOToJSON(value?: MovimentoAccasamentoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idDocumento': value.idDocumento,
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoAnimale': value.idTipoAnimale,
        'costoVaccino': value.costoVaccino,
        'tipoAnimale': TipoAnimaleDTOToJSON(value.tipoAnimale),
        'razza': RazzaDTOToJSON(value.razza),
    };
}

