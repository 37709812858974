/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListinoAnimaliCreateDTO
 */
export interface ListinoAnimaliCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliCreateDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListinoAnimaliCreateDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliCreateDTO
     */
    valore?: number;
}

/**
 * Check if a given object implements the ListinoAnimaliCreateDTO interface.
 */
export function instanceOfListinoAnimaliCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListinoAnimaliCreateDTOFromJSON(json: any): ListinoAnimaliCreateDTO {
    return ListinoAnimaliCreateDTOFromJSONTyped(json, false);
}

export function ListinoAnimaliCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListinoAnimaliCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
    };
}

export function ListinoAnimaliCreateDTOToJSON(value?: ListinoAnimaliCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idTipoAnimale': value.idTipoAnimale,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'valore': value.valore,
    };
}

