/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovimentoMortoCreateDTO
 */
export interface MovimentoMortoCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoCreateDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoMortoCreateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoCreateDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoCreateDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoCreateDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoCreateDTO
     */
    idIncubatoio?: number | null;
}

/**
 * Check if a given object implements the MovimentoMortoCreateDTO interface.
 */
export function instanceOfMovimentoMortoCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoMortoCreateDTOFromJSON(json: any): MovimentoMortoCreateDTO {
    return MovimentoMortoCreateDTOFromJSONTyped(json, false);
}

export function MovimentoMortoCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoMortoCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'idIncubatoio': !exists(json, 'idIncubatoio') ? undefined : json['idIncubatoio'],
    };
}

export function MovimentoMortoCreateDTOToJSON(value?: MovimentoMortoCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoAnimale': value.idTipoAnimale,
        'idIncubatoio': value.idIncubatoio,
    };
}

