import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetMovimentiMangimiRequest, UpdateMovimentoMangimeRequest, DeleteMovimentoMangimeRequest, InsertMovimentoMangimeRequest } from '../data/apis/DocumentiApi';
import { TipiMangimiApi } from "../data/apis";

const documentiApi = new DocumentiApi(apiConfiguration);

export function MovimentiMangimiDataSource(
    idFiliera: number,
    idCiclo: number,
    idDocumento: number
) {
    const cicliApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            var accasamentoRequest: GetMovimentiMangimiRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento
            };
            return documentiApi.getMovimentiMangimi(accasamentoRequest);
        },

        insert: (values) => {
            var InsertAccasamentoRequest: InsertMovimentoMangimeRequest = {

                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                movimentoMangimeCreateDTO: values
            };

            return documentiApi.insertMovimentoMangime(InsertAccasamentoRequest);
        },

        update: (key, values) => {
            var UpdateAccasamentoRequest: UpdateMovimentoMangimeRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key,
                movimentoMangimeUpdateDTO: values
            };

            return documentiApi.updateMovimentoMangime(UpdateAccasamentoRequest);
        },

        remove: (key) => {
            var deleteAccasamentoRequest: DeleteMovimentoMangimeRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key
            };

            return documentiApi.deleteMovimentoMangime(deleteAccasamentoRequest);
        }
    });
};

const api = new TipiMangimiApi(apiConfiguration);

export const tipiMangimiDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
        return api.getTipiMangimi();
    },
  
    byKey: (key) => {
        return api.getTipoMangimeById({id:key});
    },
  
    insert: (values) => {
      return api.insertTipoMangime({
        tipoMangimeCreateDTO: values
      });
    },
  
    update: (key, values) => {

      return api.updateTipoMangime({
        id: key,
        tipoMangimeUpdateDTO: values
      });
    },
  
     remove: (key) => {
      return api.deleteTipoMangime({
        id: key
      });
     }
  });