/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UtenteFilieraPermissionDTO
 */
export interface UtenteFilieraPermissionDTO {
    /**
     * 
     * @type {number}
     * @memberof UtenteFilieraPermissionDTO
     */
    idFiliera?: number;
    /**
     * 
     * @type {string}
     * @memberof UtenteFilieraPermissionDTO
     */
    nomeFiliera?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtenteFilieraPermissionDTO
     */
    filieraPermissionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtenteFilieraPermissionDTO
     */
    description?: string | null;
}

/**
 * Check if a given object implements the UtenteFilieraPermissionDTO interface.
 */
export function instanceOfUtenteFilieraPermissionDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UtenteFilieraPermissionDTOFromJSON(json: any): UtenteFilieraPermissionDTO {
    return UtenteFilieraPermissionDTOFromJSONTyped(json, false);
}

export function UtenteFilieraPermissionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtenteFilieraPermissionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idFiliera': !exists(json, 'idFiliera') ? undefined : json['idFiliera'],
        'nomeFiliera': !exists(json, 'nomeFiliera') ? undefined : json['nomeFiliera'],
        'filieraPermissionId': !exists(json, 'filieraPermissionId') ? undefined : json['filieraPermissionId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UtenteFilieraPermissionDTOToJSON(value?: UtenteFilieraPermissionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idFiliera': value.idFiliera,
        'nomeFiliera': value.nomeFiliera,
        'filieraPermissionId': value.filieraPermissionId,
        'description': value.description,
    };
}

