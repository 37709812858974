import { DataErrorOccurredInfo, GridBase } from "devextreme/common/grids";
import { EventInfo } from "devextreme/events";
import { alert } from 'devextreme/ui/dialog';

export function dataGridErrorHandling(e: EventInfo<GridBase> & DataErrorOccurredInfo) {
  console.log(e);
  if (e.error?.cause) {
    alert((e.error as any).cause.message, "Errore");
  }
  else if ((e.error as any).response) {
    var response = ((e.error as any).response as Response);
    
    if (response.status == 403) {
      if (response.statusText)
        alert(response.statusText, "Errore");
      else
        alert("Autorizzazione non valida per questa operazione", "Errore");
    }
    else
      return response.json()
        .then(function (data) {
          console.log(data);
          if (data.title) {
            let text: string = data.title + " <br>";

            if (data.errors) {
              for (var key in data.errors) {
                text += data.errors[key] + " <br>";
              }
            }

            alert(text, "Errore");
          }
          else
            alert(data, "Errore");
        });
  }
}

export function showError(e: Error) {
  if (e?.cause) {
    alert((e as any).cause.message, "Errore");
  }
  else if ((e as any).response) {
    var response = ((e as any).response as Response);
    if (response.status == 403) {
      if (response.statusText)
        alert(response.statusText, "Errore");
      else
        alert("Autorizzazione non valida per questa operazione", "Errore");
    }
    else
      return response.json()
        .then(function (data) {
          console.log(data);
          if (data.title) {
            let text: string = data.title + " <br>";

            if (data.errors) {
              for (var key in data.errors) {
                text += data.errors[key] + " <br>";
              }
            }

            alert(text, "Errore");
          }
          else
            alert(data, "Errore");
        });
  }
}