/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaricoRiscontroDTO
 */
export interface CaricoRiscontroDTO {
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    idCarico?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    peso?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    capi?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoRiscontroDTO
     */
    idCategoriaCarico?: number | null;
}

/**
 * Check if a given object implements the CaricoRiscontroDTO interface.
 */
export function instanceOfCaricoRiscontroDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CaricoRiscontroDTOFromJSON(json: any): CaricoRiscontroDTO {
    return CaricoRiscontroDTOFromJSONTyped(json, false);
}

export function CaricoRiscontroDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaricoRiscontroDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idCarico': !exists(json, 'idCarico') ? undefined : json['idCarico'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'peso': !exists(json, 'peso') ? undefined : json['peso'],
        'capi': !exists(json, 'capi') ? undefined : json['capi'],
        'idCategoriaCarico': !exists(json, 'idCategoriaCarico') ? undefined : json['idCategoriaCarico'],
    };
}

export function CaricoRiscontroDTOToJSON(value?: CaricoRiscontroDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idCarico': value.idCarico,
        'idTipoAnimale': value.idTipoAnimale,
        'peso': value.peso,
        'capi': value.capi,
        'idCategoriaCarico': value.idCategoriaCarico,
    };
}

