/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  TipoMangimeCreateDTO,
  TipoMangimeDTO,
  TipoMangimeUpdateDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TipoMangimeCreateDTOFromJSON,
    TipoMangimeCreateDTOToJSON,
    TipoMangimeDTOFromJSON,
    TipoMangimeDTOToJSON,
    TipoMangimeUpdateDTOFromJSON,
    TipoMangimeUpdateDTOToJSON,
} from '../models';

export interface DeleteTipoMangimeRequest {
    id: number;
}

export interface GetTipoMangimeByIdRequest {
    id: number;
}

export interface InsertTipoMangimeRequest {
    tipoMangimeCreateDTO?: TipoMangimeCreateDTO;
}

export interface UpdateTipoMangimeRequest {
    id: number;
    tipoMangimeUpdateDTO?: TipoMangimeUpdateDTO;
}

/**
 * 
 */
export class TipiMangimiApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo di mangime esistente
     */
    async deleteTipoMangimeRaw(requestParameters: DeleteTipoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTipoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiMangimi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo di mangime esistente
     */
    async deleteTipoMangime(requestParameters: DeleteTipoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTipoMangimeRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutte le tipologie di mangimi
     */
    async getTipiMangimiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TipoMangimeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiMangimi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoMangimeDTOFromJSON));
    }

    /**
     * Elenco di tutte le tipologie di mangimi
     */
    async getTipiMangimi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TipoMangimeDTO>> {
        const response = await this.getTipiMangimiRaw(initOverrides);
        return await response.value();
    }

    /**
     * Recupera un tipo di mangime per id
     */
    async getTipoMangimeByIdRaw(requestParameters: GetTipoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoMangimeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTipoMangimeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiMangimi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un tipo di mangime per id
     */
    async getTipoMangimeById(requestParameters: GetTipoMangimeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoMangimeDTO> {
        const response = await this.getTipoMangimeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di mangime in anagrafica
     */
    async insertTipoMangimeRaw(requestParameters: InsertTipoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoMangimeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiMangimi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TipoMangimeCreateDTOToJSON(requestParameters.tipoMangimeCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoMangimeDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di mangime in anagrafica
     */
    async insertTipoMangime(requestParameters: InsertTipoMangimeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoMangimeDTO> {
        const response = await this.insertTipoMangimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di mangime esistente
     */
    async updateTipoMangimeRaw(requestParameters: UpdateTipoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTipoMangime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiMangimi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TipoMangimeUpdateDTOToJSON(requestParameters.tipoMangimeUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di mangime esistente
     */
    async updateTipoMangime(requestParameters: UpdateTipoMangimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTipoMangimeRaw(requestParameters, initOverrides);
    }

}
