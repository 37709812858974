import React, { useContext, useEffect, useRef } from 'react';
import FilieraContext from '../../contexts/filieraContext';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Button as GridButton,
  Item as GridItem,
  Form,
  Toolbar,
  Sorting,
  RowDragging
} from 'devextreme-react/data-grid';
import { EmptyItem, Item } from 'devextreme-react/form';
import { dataGridErrorHandling } from '../../utils/errorHandling';
import { chiusuraCompensiDataSource } from '../../datasources/chiusuraCompensiDataSource';
import { CicliApi } from '../../data/apis/CicliApi';
import { apiConfiguration } from '../../apiConfig';
import { chiusuraExtraDataSource } from '../../datasources/chiusuraExtraDataSource';


type Props = {
  idCiclo: number,
  readonly?: boolean
};

export default function ChiusuraExtraPanel(params: Props) {
  const { currentFiliera } = useContext(FilieraContext);

  async function  processReorder(e:any) {
    const visibleRows = e.component.getVisibleRows();
    const ds= e.component.getDataSource();
    const newOrderIndex = visibleRows[e.toIndex].data.sequenza;

    const cicliApi = new CicliApi(apiConfiguration);

    await cicliApi.updateChiusuraExtraSequenza({ 
      idFiliera: currentFiliera.Id, 
      idCiclo: params.idCiclo, 
      idExtra: e.itemData.id,  
      sequenza: newOrderIndex
    });
    await e.component.refresh();
  }

  return (
    <DataGrid
    className={'dx-card wide-card'}
    dataSource={chiusuraExtraDataSource(currentFiliera.Id, params.idCiclo)}
    showBorders={false}
    focusedRowEnabled={true}
    defaultFocusedRowIndex={0}
    columnAutoWidth={true}
    columnHidingEnabled={true}
    onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
    onDataErrorOccurred={dataGridErrorHandling}
  >
    <Editing
      mode="popup"
      allowUpdating={true}
      allowAdding={true}
      allowDeleting={true}>
        <Form labelMode='floating'>
            <Item dataField="descrizione" colSpan={2} />
            <Item dataField="importo" />
    </Form>
    </Editing>
    <RowDragging
      allowReordering={true}
      onReorder={processReorder}
      dropFeedbackMode="push"
    />
    <Sorting mode="none" />
    <Paging defaultPageSize={10} />
    <Pager showPageSizeSelector={true} showInfo={true} />
    <FilterRow visible={true} />

    <Column type='buttons' />
    <Column dataField={'id'} width={90} visible={false} />
    <Column dataField={'descrizione'}  />
    <Column dataField={'importo'}  />
  </DataGrid>
   
  );
}
