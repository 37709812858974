/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NazioneDTO
 */
export interface NazioneDTO {
    /**
     * 
     * @type {string}
     * @memberof NazioneDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NazioneDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NazioneDTO
     */
    paeseCee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NazioneDTO
     */
    codiceCee?: string | null;
}

/**
 * Check if a given object implements the NazioneDTO interface.
 */
export function instanceOfNazioneDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function NazioneDTOFromJSON(json: any): NazioneDTO {
    return NazioneDTOFromJSONTyped(json, false);
}

export function NazioneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NazioneDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'paeseCee': !exists(json, 'paeseCee') ? undefined : json['paeseCee'],
        'codiceCee': !exists(json, 'codiceCee') ? undefined : json['codiceCee'],
    };
}

export function NazioneDTOToJSON(value?: NazioneDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'descrizione': value.descrizione,
        'paeseCee': value.paeseCee,
        'codiceCee': value.codiceCee,
    };
}

