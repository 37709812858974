import React from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing
} from 'devextreme-react/data-grid';
import { nazioneDataSource } from '../../../datasources/nazioneDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';

export default function Task() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Nazioni</h2>

      <DataGrid
        className={'dx-card wide-card'}
        //dataSource={dataSource as any}
        dataSource={nazioneDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column  type='buttons' />
        <Column dataField={'id'} width={90} hidingPriority={2} />
        <Column dataField={'descrizione'} hidingPriority={8} sortIndex={0} sortOrder="asc" />
        <Column dataField={'paeseCee'} hidingPriority={8} />
        <Column dataField={'codiceCee'} hidingPriority={8} />
      </DataGrid>
    </React.Fragment>
)}

