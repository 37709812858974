/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovimentoTrattamentoCreateDTO
 */
export interface MovimentoTrattamentoCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    idTipoTrattamento?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    idTecnico?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    valore?: number;
    /**
     * 
     * @type {Date}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    dataInizio?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MovimentoTrattamentoCreateDTO
     */
    dataFine?: Date;
}

/**
 * Check if a given object implements the MovimentoTrattamentoCreateDTO interface.
 */
export function instanceOfMovimentoTrattamentoCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoTrattamentoCreateDTOFromJSON(json: any): MovimentoTrattamentoCreateDTO {
    return MovimentoTrattamentoCreateDTOFromJSONTyped(json, false);
}

export function MovimentoTrattamentoCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoTrattamentoCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'idTipoTrattamento': !exists(json, 'idTipoTrattamento') ? undefined : json['idTipoTrattamento'],
        'idTecnico': !exists(json, 'idTecnico') ? undefined : json['idTecnico'],
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
        'dataInizio': !exists(json, 'dataInizio') ? undefined : (new Date(json['dataInizio'])),
        'dataFine': !exists(json, 'dataFine') ? undefined : (new Date(json['dataFine'])),
    };
}

export function MovimentoTrattamentoCreateDTOToJSON(value?: MovimentoTrattamentoCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoAnimale': value.idTipoAnimale,
        'idTipoTrattamento': value.idTipoTrattamento,
        'idTecnico': value.idTecnico,
        'valore': value.valore,
        'dataInizio': value.dataInizio === undefined ? undefined : (value.dataInizio.toISOString()),
        'dataFine': value.dataFine === undefined ? undefined : (value.dataFine.toISOString()),
    };
}

