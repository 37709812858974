import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ListiniApi, GetListiniMangimiRequest } from "../data/apis/ListiniApi";

export function listiniMangimiDataSource(idFiliera: number, showAll: boolean) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ListiniApi(apiConfiguration);

            const request: GetListiniMangimiRequest = {
                idFiliera: idFiliera,
                showAll: showAll
            };

            return api.getListiniMangimi(request);
        },

        byKey: (key) => {
            const api = new ListiniApi(apiConfiguration);

            return api.getListinoMangimiById({
                idFiliera: idFiliera,
                id: key
            });
        },

        insert: (values) => {
            const api = new ListiniApi(apiConfiguration);

            return api.insertListinoMangimi({
                idFiliera: idFiliera,
                listinoMangimiCreateDTO: values
            });
        },

        update: (key, values) => {
            const api = new ListiniApi(apiConfiguration);
            return api.updateListinoMangimi({
                idFiliera:idFiliera,
                id: key,
                listinoMangimiUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ListiniApi(apiConfiguration);
            return api.deleteListinoMangimi({
                id: key,
                idFiliera: idFiliera
            });
        }
    });
}