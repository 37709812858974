import React, { useRef, useContext, useState, useEffect } from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup,
    RequiredRule
} from 'devextreme-react/data-grid';
import Box, {
    Item as ItemBox
} from 'devextreme-react/box';
import { SelectBox, Switch } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Item } from 'devextreme-react/form';
import { cicliDataSource } from '../../datasources/cicliDataSource';
import PopupReportChiusura, { ReportChiusuraPopupHandle } from '../popupReportChiusura/popupReportChiusura';
import { useNavigate } from 'react-router-dom';
import FilieraContext from '../../contexts/filieraContext';
import { PagamentiPopupHandle } from '../popupPagamenti/popupPagamenti';
import PopupPagamenti from '../popupPagamenti/popupPagamenti';

import { allevamentiByAllevatoreDataSource, allevamentiDataSource } from '../../datasources/allevamentiDataSource';
import { conversioniDataSource } from '../../datasources/conversioniDataSource';
import { CicliApi } from '../../data';
import { apiConfiguration } from '../../apiConfig';
import { dataGridErrorHandling, showError } from '../../utils/errorHandling';

type Props = {
  idAllevatore?: number
};

export default function CicliListPanel(params:Props) {
    const reportChiusuraRef = useRef<ReportChiusuraPopupHandle>(null);
    const pagamentiRef = useRef<PagamentiPopupHandle>(null);
    const { currentFiliera } = useContext(FilieraContext);
    const [statoFilter, setStatoFilter] = useState<string | undefined>(localStorage.getItem('cicli/filter/stato') ?? undefined);
    const [stati, setStati] = useState<string[]>();

    const navigate = useNavigate();

    const handleCicliDashboardButtonClick = (e: any) => {

        let id = e.row.key;

        let cicloNumero = e.row.data.cicloNumero;

        let idAllevamento = e.row.data.idAllevamento;

        navigate(`/cicli/dashboard/${id}/${cicloNumero}/${idAllevamento}`);
    };

    function openReportChiusuraPopup(e: any) {
        reportChiusuraRef.current?.show(currentFiliera.Id, e.row.key);
    }

    function openPopupPagamenti(e: any) {
        pagamentiRef.current?.show(e.row.key);
    }

    function grid_InitNewRow(e: any) {
        e.data.soccida = false;
        e.data.vaccinoCaricoAllevatore = false;
        e.data.escludiRegistroCS=false;
    }

    function switchShowAll_ValueChange(e:string){
        setStatoFilter(e ?? undefined );
        if (e)
            localStorage.setItem('cicli/filter/stato', e);
        else
            localStorage.removeItem('cicli/filter/stato');
    }

    useEffect(() => {
        const api = new CicliApi(apiConfiguration);
        api.getStatiCicli({ idFiliera: currentFiliera.Id })
            .then(function (data) {
                setStati(data);
            })
            .catch(showError);
       
    }, [currentFiliera]);

    const styles = {
        alignTitle: {
            marginLeft: "40px",
            marginRight: "40px",
        },
        fixSwitch: {
            width: "unset"
        }
    };

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={cicliDataSource(currentFiliera.Id, statoFilter,params.idAllevatore)}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData); }}
                onInitNewRow={grid_InitNewRow}
                onDataErrorOccurred={dataGridErrorHandling}
                errorRowEnabled={false}
            >
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                    <Popup title="Ciclo" showTitle={true} />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="idAllevamento" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                            <Item dataField="soccida" editorType='dxCheckBox' />
                            <Item dataField="vaccinoCaricoAllevatore" editorType='dxCheckBox' />
                            <Item dataField="descrizione" />
                            <Item dataField="giacenzaIniziale" editorType='dxNumberBox' />
                            <Item dataField="giacenzaInizialeValore" editorType='dxNumberBox' />
                            <Item dataField="idConversione" editorType="dxSelectBox" />
                            <Item dataField="escludiRegistroCS" editorType='dxCheckBox' />
                        </Item>
                    </Form>

                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type='buttons' alignment='left'>
                    <Button name="edit" disabled={(e) => e.row.data.readOnly } />
                    <Button hint="Dashboard" icon="chart" onClick={handleCicliDashboardButtonClick} />
                    <Button hint="Stampa chiusura" icon="print" onClick={openReportChiusuraPopup} visible={(e) => e.row.data.chiusura } />
                    <Button hint="Pagamenti" icon="money" onClick={openPopupPagamenti} />
                    <Button name="delete" disabled={(e) => e.row.data.readOnly } />
                </Column>
                <Column dataField={'id'} width={90} hidingPriority={2} allowEditing={false} visible={false} />
                <Column
                    dataField={'stato'}
                    caption={'Stato'}
                    groupIndex={1}
                />
                <Column dataField={'idAllevamento'} caption={'Allevamento'}>
                    <RequiredRule />
                    <Lookup dataSource={allevamentiByAllevatoreDataSource(Number(params.idAllevatore))} valueExpr="id" displayExpr="descrizioneBreve" />
                </Column>
                <Column
                    dataField={'dataInizio'}
                    caption={'Data inizio'}
                    dataType='date'
                    hidingPriority={6}
                />
                <Column
                    dataField={'chiusura.dataFineCiclo'}
                    caption={'Data fine ciclo'}
                    dataType='date'
                    hidingPriority={5}
                />
                 <Column
                    dataField={'chiusura.conversioneIndice'}
                    caption={'Indice di conversione'}
                    dataType='number'
                    hidingPriority={4}
                />
                <Column
                    dataField={'allevamento.codiceAllevamento'}
                    caption={'Codice allevamento'}
                    hidingPriority={3}
                />
                <Column
                    dataField={'descrizione'}
                    caption={'Descrizione'}
                    hidingPriority={2}
                />
                <Column dataField={'idConversione'} caption={'Tabella allegata al contratto di soccida'} hidingPriority={1}>
                    <RequiredRule />
                    <Lookup dataSource={conversioniDataSource(currentFiliera.Id)} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column
                    dataField={'soccida'}
                    caption={'Soccida'}
                    visible={false}
                />
                <Column
                    dataField={'vaccinoCaricoAllevatore'}
                    caption={'Vaccino a carico dell\'allevatore'}
                    visible={false}
                />
                <Column
                    dataField={'escludiRegistroCS'}
                    caption={'Escludi Registro CS'}
                    visible={false}
                />
            </DataGrid>

            <PopupReportChiusura ref={reportChiusuraRef}></PopupReportChiusura>
            <PopupPagamenti ref={pagamentiRef}></PopupPagamenti>
        </React.Fragment>
    )
}

