/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PagamentoUpdateDTO
 */
export interface PagamentoUpdateDTO {
    /**
     * 
     * @type {boolean}
     * @memberof PagamentoUpdateDTO
     */
    acconto?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PagamentoUpdateDTO
     */
    data?: Date;
    /**
     * 
     * @type {number}
     * @memberof PagamentoUpdateDTO
     */
    importo?: number;
    /**
     * 
     * @type {string}
     * @memberof PagamentoUpdateDTO
     */
    note?: string | null;
}

/**
 * Check if a given object implements the PagamentoUpdateDTO interface.
 */
export function instanceOfPagamentoUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PagamentoUpdateDTOFromJSON(json: any): PagamentoUpdateDTO {
    return PagamentoUpdateDTOFromJSONTyped(json, false);
}

export function PagamentoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagamentoUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acconto': !exists(json, 'acconto') ? undefined : json['acconto'],
        'data': !exists(json, 'data') ? undefined : (new Date(json['data'])),
        'importo': !exists(json, 'importo') ? undefined : json['importo'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function PagamentoUpdateDTOToJSON(value?: PagamentoUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acconto': value.acconto,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'importo': value.importo,
        'note': value.note,
    };
}

