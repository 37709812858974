/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BancaDTO } from './BancaDTO';
import {
    BancaDTOFromJSON,
    BancaDTOFromJSONTyped,
    BancaDTOToJSON,
} from './BancaDTO';

/**
 * 
 * @export
 * @interface AllevatoreCreateDTO
 */
export interface AllevatoreCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    ragioneSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    cognome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    nome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    indirizzo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    numeroCivico?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    cap?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    localita?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    provincia?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    idNazione: string;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    partitaIva?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    codiceFiscale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    sdi?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevatoreCreateDTO
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllevatoreCreateDTO
     */
    attivo?: boolean;
    /**
     * 
     * @type {BancaDTO}
     * @memberof AllevatoreCreateDTO
     */
    banca?: BancaDTO;
}

/**
 * Check if a given object implements the AllevatoreCreateDTO interface.
 */
export function instanceOfAllevatoreCreateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idNazione" in value;

    return isInstance;
}

export function AllevatoreCreateDTOFromJSON(json: any): AllevatoreCreateDTO {
    return AllevatoreCreateDTOFromJSONTyped(json, false);
}

export function AllevatoreCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllevatoreCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ragioneSociale': !exists(json, 'ragioneSociale') ? undefined : json['ragioneSociale'],
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'indirizzo': !exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'numeroCivico': !exists(json, 'numeroCivico') ? undefined : json['numeroCivico'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
        'localita': !exists(json, 'localita') ? undefined : json['localita'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'idNazione': json['idNazione'],
        'partitaIva': !exists(json, 'partitaIva') ? undefined : json['partitaIva'],
        'codiceFiscale': !exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'sdi': !exists(json, 'sdi') ? undefined : json['sdi'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'attivo': !exists(json, 'attivo') ? undefined : json['attivo'],
        'banca': !exists(json, 'banca') ? undefined : BancaDTOFromJSON(json['banca']),
    };
}

export function AllevatoreCreateDTOToJSON(value?: AllevatoreCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ragioneSociale': value.ragioneSociale,
        'cognome': value.cognome,
        'nome': value.nome,
        'indirizzo': value.indirizzo,
        'numeroCivico': value.numeroCivico,
        'cap': value.cap,
        'localita': value.localita,
        'provincia': value.provincia,
        'idNazione': value.idNazione,
        'partitaIva': value.partitaIva,
        'codiceFiscale': value.codiceFiscale,
        'sdi': value.sdi,
        'email': value.email,
        'attivo': value.attivo,
        'banca': BancaDTOToJSON(value.banca),
    };
}

