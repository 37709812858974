import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

import './UserPanel.scss';
import type { UserPanelProps } from '../../types';
import { useAccount, useMsal } from '@azure/msal-react';

export default function UserPanel({ menuMode }: UserPanelProps) {
  const navigate = useNavigate();
  const { instance,accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

    // useEffect(() => {
    //     if (account && account.name) {
    //         setName(account.name.split(" ")[0]);
    //     } else {
    //         setName("");
    //     }
    // }, [account]);

  function navigateToProfile() {
    navigate("/profile");
  }
  function logOut() { 
    instance.logoutRedirect();
  }

  const menuItems = useMemo(() => ([
    // {
    //   text: 'Profile',
    //   icon: 'user',
    //   onClick: navigateToProfile
    // },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: logOut
    }
  ]), []);
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(/images/silhouette.png) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{account?.name}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={{x:'center', y:'bottom'}} at={'top'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
