import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import { useScreenSize } from "../../utils/media-query";
import ReportRegistroCaricoViewer from "../reportRegistroCaricoScaricoViewer/reportRegistroCaricoScaricoViewer";
import ReportRiepilogoCapiViewer from "../reportRiepilogoCapiViewer/reportRiepilogoCapiViewer";

type Props = {}

export type ReportRiepilogoCapiPopupHandle = {
  show: (idFiliera: number, anno: number) => void,
}

type state = {
  visible: boolean,
  idFiliera: number | undefined,
  anno: number | undefined
}

const PopupReportRiepilogoCapi: React.ForwardRefRenderFunction<ReportRiepilogoCapiPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ anno: undefined, visible: false, idFiliera: undefined});
  const { isXSmall, isSmall } = useScreenSize();

  function hideInfo() {
    //setState((prevState) => ({ ...prevState, visible: false }));
    setState({ anno: undefined, visible: false, idFiliera: undefined });
  }

  useImperativeHandle(ref, () => ({
    async show(idFiliera, anno) {
      console.log(idFiliera, anno);
      setState({
        visible: true,
        idFiliera: idFiliera,
        anno: anno
      });
    }
  }));


  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      fullScreen={isXSmall || isSmall}
      onHidden={hideInfo}
      title="Riepilogo capi"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      {state.visible &&
        <ReportRiepilogoCapiViewer key={Date.now()} idFiliera={state.idFiliera} anno={state.anno} />
      }
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupReportRiepilogoCapi);
