/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentoCreateDTO
 */
export interface DocumentoCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentoCreateDTO
     */
    idAnagrafeMittente?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoCreateDTO
     */
    idAnagrafeDestinatario?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoCreateDTO
     */
    dataDocumento?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoCreateDTO
     */
    numeroDocumento?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoCreateDTO
     */
    dataOperazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoCreateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentoCreateDTO
     */
    tipo?: string | null;
}

/**
 * Check if a given object implements the DocumentoCreateDTO interface.
 */
export function instanceOfDocumentoCreateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentoCreateDTOFromJSON(json: any): DocumentoCreateDTO {
    return DocumentoCreateDTOFromJSONTyped(json, false);
}

export function DocumentoCreateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentoCreateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idAnagrafeMittente': !exists(json, 'idAnagrafeMittente') ? undefined : json['idAnagrafeMittente'],
        'idAnagrafeDestinatario': !exists(json, 'idAnagrafeDestinatario') ? undefined : json['idAnagrafeDestinatario'],
        'dataDocumento': !exists(json, 'dataDocumento') ? undefined : (new Date(json['dataDocumento'])),
        'numeroDocumento': !exists(json, 'numeroDocumento') ? undefined : json['numeroDocumento'],
        'dataOperazione': !exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
    };
}

export function DocumentoCreateDTOToJSON(value?: DocumentoCreateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idAnagrafeMittente': value.idAnagrafeMittente,
        'idAnagrafeDestinatario': value.idAnagrafeDestinatario,
        'dataDocumento': value.dataDocumento === undefined ? undefined : (value.dataDocumento.toISOString()),
        'numeroDocumento': value.numeroDocumento,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'note': value.note,
        'tipo': value.tipo,
    };
}

