/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  RazzaCreateDTO,
  RazzaDTO,
  RazzaUpdateDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RazzaCreateDTOFromJSON,
    RazzaCreateDTOToJSON,
    RazzaDTOFromJSON,
    RazzaDTOToJSON,
    RazzaUpdateDTOFromJSON,
    RazzaUpdateDTOToJSON,
} from '../models';

export interface DeleteRazzaRequest {
    id: number;
}

export interface GetRazzaByIdRequest {
    id: number;
}

export interface InsertRazzaRequest {
    razzaCreateDTO?: RazzaCreateDTO;
}

export interface UpdateRazzaRequest {
    id: number;
    razzaUpdateDTO?: RazzaUpdateDTO;
}

/**
 * 
 */
export class RazzeApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una tipologia di razza esistente
     */
    async deleteRazzaRaw(requestParameters: DeleteRazzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRazza.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Razze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una tipologia di razza esistente
     */
    async deleteRazza(requestParameters: DeleteRazzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRazzaRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera un tipo di animale per id
     */
    async getRazzaByIdRaw(requestParameters: GetRazzaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RazzaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRazzaById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Razze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RazzaDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un tipo di animale per id
     */
    async getRazzaById(requestParameters: GetRazzaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RazzaDTO> {
        const response = await this.getRazzaByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte le razze
     */
    async getRazzeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RazzaDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Razze`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RazzaDTOFromJSON));
    }

    /**
     * Elenco di tutte le razze
     */
    async getRazze(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RazzaDTO>> {
        const response = await this.getRazzeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di razza in anagrafica
     */
    async insertRazzaRaw(requestParameters: InsertRazzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RazzaDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Razze`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RazzaCreateDTOToJSON(requestParameters.razzaCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RazzaDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di razza in anagrafica
     */
    async insertRazza(requestParameters: InsertRazzaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RazzaDTO> {
        const response = await this.insertRazzaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di razza esistente
     */
    async updateRazzaRaw(requestParameters: UpdateRazzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRazza.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Razze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RazzaUpdateDTOToJSON(requestParameters.razzaUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di razza esistente
     */
    async updateRazza(requestParameters: UpdateRazzaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRazzaRaw(requestParameters, initOverrides);
    }

}
