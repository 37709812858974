import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ContattiApi, GetContattiRequest, GetContattoByIdRequest, InsertContattoRequest } from "../data/apis/ContattiApi";

export function contattiDataSource(idAnagrafe: number) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ContattiApi(apiConfiguration);

            const request: GetContattiRequest = {
                idAnagrafe: idAnagrafe
            };

            return api.getContatti(request);
        },

        byKey: (key) => {
            const api = new ContattiApi(apiConfiguration);

            const request: GetContattoByIdRequest = {
                idAnagrafe: idAnagrafe,
                idContatto: key
            };

            return api.getContattoById(request);
        },

        insert: (values) => {
            const api = new ContattiApi(apiConfiguration);

            const request: InsertContattoRequest = {
                idAnagrafe: idAnagrafe,
                contattoCreateDTO: values
            };

            return api.insertContatto(request);
        },

        update: (key, values) => {
            const api = new ContattiApi(apiConfiguration);
            return api.updateContatto({
                idAnagrafe:idAnagrafe,
                idContatto: key,
                contattoUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ContattiApi(apiConfiguration);
            return api.deleteContatto({
                idAnagrafe: idAnagrafe,
                idContatto:key
            });
        }
    });
}