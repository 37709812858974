import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { TipiTrattamentiApi } from "../data/apis";

export const tipiTrattamentiDataSource = new CustomStore({
    key: "id",
    loadMode: 'raw',
    load: (loadOptions) => {
        const api = new TipiTrattamentiApi(apiConfiguration);
        return api.getTipoTrattamenti();
    },

    byKey: (key) => {
        const api = new TipiTrattamentiApi(apiConfiguration);
        return api.getTipoTrattamentoById({ 
            id:key
        });
    },

    insert: (values) => {
        const api = new TipiTrattamentiApi(apiConfiguration);
        return api.insertTipoTrattamento({
            tipoTrattamentoCreateDTO: values
        });
    },

    update: (key, values) => {
        const api = new TipiTrattamentiApi(apiConfiguration);
        return api.updateTipoTrattamento({
            id: key,
            tipoTrattamentoUpdateDTO: values
        });
    },

    remove: (key) => {
        const api = new TipiTrattamentiApi(apiConfiguration);
        return api.deleteTipoTrattamento({
            id: key
        });
    }
});