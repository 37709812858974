import React, { useRef } from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    RequiredRule,
    StateStoring,
    MasterDetail
} from 'devextreme-react/data-grid';
import { dataGridErrorHandling, showError } from '../../utils/errorHandling';
import { allevatoriDataSource } from '../../datasources/allevatoriDataSource';
import { nazioneDataSource } from '../../datasources/nazioneDataSource';
import AllevatoriDetailPanel from '../../components/allevatoriDetailPanel/allevatoriDetailPanel';


export default function CicliPage() {
    const gridRef = useRef<DataGrid>(null);
    
    function DataGridRowExpanded(e: any) {
        // console.log(e);
        // var json = sessionStorage.getItem('cicliList.expandedRows');
        // var list = [];
        // if (json) {
        //     list = JSON.parse(json);
        // }
        // if (e.expanded){
        //     if (!list.includes(e.key))
        //     list.push(e.key);
        // }
        // else {
        //     list=list.filter((value:number)=> value!=e.key);
        // }
        
        // sessionStorage.setItem('cicliList.expandedRows', JSON.stringify(list));
    }

    function DataGridContentReady(e: any) {
        // console.log(e);
        // var json = sessionStorage.getItem('cicliList.expandedRows');
        // if (json) {
        //     const list = JSON.parse(json);

        //     list.forEach((currentValue:number)=> gridRef.current?.instance.expandRow(currentValue));
        // }
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Cicli</h2>
            <DataGrid
                ref={gridRef}
                className={'dx-card wide-card'}
                dataSource={allevatoriDataSource}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                errorRowEnabled={false}
                columnHidingEnabled={true}
                onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
                onDataErrorOccurred={dataGridErrorHandling}
                onRowExpanding={DataGridRowExpanded}
                onRowCollapsing={DataGridRowExpanded}
                onContentReady={DataGridContentReady}
            >
                <StateStoring enabled={true} type="localStorage" storageKey="cicliList" />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
                <Column dataField={'ragioneSociale'} sortIndex={0} sortOrder="asc" />
                <Column dataField={'cognome'} visible={false} />
                <Column dataField={'nome'} visible={false} />
                <Column dataField={'indirizzo'} />
                <Column dataField={'numeroCivico'} />
                <Column dataField={'cap'} />
                <Column dataField={'localita'} />
                <Column dataField={'provincia'} />
                <Column dataField={'numeroAllevamenti'} />
                <Column dataField={'superficieTotale'} />
                <Column dataField={'codiceFiscale'} />
                <Column dataField={'partitaIva'} />
                <Column dataField={'sdi'} />
                <Column dataField={'email'} />
                <Column dataField={'idNazione'} caption={'Nazione'}>
                    <RequiredRule />
                    <Lookup dataSource={nazioneDataSource} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column dataField={'banca.descrizioneBreve'} visible={false} />
                <Column dataField={'banca.abi'} visible={false} />
                <Column dataField={'banca.cab'} visible={false} />
                <Column dataField={'banca.iban'} visible={false} />
                <Column dataField={'banca.sia'} visible={false} />
                <MasterDetail
                    enabled={true}
                    component={AllevatoriDetailPanel}
                />
            </DataGrid>

        </React.Fragment>
    )
}

