import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ConversioniApi, GetConversioniRequest, GetConversioneByIdRequest, InsertConversioneRequest } from "../data/apis";

export function conversioniDataSource(idFiliera: number) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: GetConversioniRequest = {
                idFiliera: idFiliera
            };

            return api.getConversioni(request);
        },

        byKey: (key) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: GetConversioneByIdRequest = {
                idFiliera: idFiliera,
                idConversione: key
            };

            return api.getConversioneById(request);
        },

        insert: (values) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: InsertConversioneRequest = {
                idFiliera: idFiliera,
                conversioneCreateDTO: values
            };

            return api.insertConversione(request);
        },

        update: (key, values) => {
            const api = new ConversioniApi(apiConfiguration);
            return api.updateConversione({
                idFiliera:idFiliera,
                idConversione: key,
                conversioneUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ConversioniApi(apiConfiguration);
            return api.deleteConversione({
                idFiliera: idFiliera,
                idConversione:key
            });
        }
    });
}