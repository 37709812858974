import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { ConversioniApi, GetConversioniDettagliRequest, GetConversioneDettaglioByIdRequest, InsertConversioneDettaglioRequest } from "../data/apis";

export function ConversioniDettagliDataSource(idFiliera: number, idConversione: number) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: GetConversioniDettagliRequest = {
                idFiliera : idFiliera,
                idConversione : idConversione
            };

            return api.getConversioniDettagli(request);
        },

        byKey: (key) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: GetConversioneDettaglioByIdRequest = {
                id: key,
                idFiliera: idFiliera,
                idConversione: idConversione
            };

            return api.getConversioneDettaglioById(request);
        },

        insert: (values) => {
            const api = new ConversioniApi(apiConfiguration);

            const request: InsertConversioneDettaglioRequest = {
                idFiliera: idFiliera,
                idConversione: idConversione,
                conversioneDettaglioCreateDTO: values
            };

            return api.insertConversioneDettaglio(request);
        },

        update: (key, values) => {
            const api = new ConversioniApi(apiConfiguration);
            return api.updateConversioneDettaglio({
                id: key,
                idConversione: idConversione,
                idFiliera: idFiliera,
                conversioneDettaglioUpdateDTO: values
            });
        },

        remove: (key) => {
            const api = new ConversioniApi(apiConfiguration);
            return api.deleteConversioneDettaglio({
                id: key,
                idFiliera: idFiliera,
                idConversione: idConversione
            });
        }
    });
}