import React, { useImperativeHandle, useState, useContext, useRef } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Button, Column, Editing, FilterRow, Form, Pager, Paging } from "devextreme-react/data-grid";
import { dataGridErrorHandling, showError } from "../../utils/errorHandling";
import { EmptyItem, Item } from "devextreme-react/form";
import { pagamentiDataSource } from "../../datasources/pagamentiDataSource";
import 'devextreme-react/text-area';
import FilieraContext from "../../contexts/filieraContext";
import PopupUploadFile, { UploadFilePopupHandle } from '../popupUploadFile/popupUploadFile';
import { apiConfiguration } from "../../apiConfig";
import { CicliApi, CicloDTO } from "../../data";
import { formatDate } from "devextreme/localization";
import DocumentsTable from "../../pages/cicli/documentsTable";
import { CollapsablePanel } from "../collapsablePanel/CollapsablePanel";
import DocumentiMacelliTable from "../../pages/cicli/documentiMacelliTable";
import CarichiRiscontriTable from "../../pages/cicli/dettagli/carichiRiscontriTable";

type Props = {
  ciclo: CicloDTO
}

export type CaricoPopupHandle = {
  show: (idCarico: number, readonly:boolean) => void,
}

type state = {
  idCarico: number | undefined,
  visible: boolean,
  title: string,
  readonly: boolean
}

const PopupCarico: React.ForwardRefRenderFunction<CaricoPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idCarico: undefined, visible: false, title: "", readonly:false });
  const { currentFiliera } = useContext(FilieraContext);
  //const uploadRef = useRef<UploadFilePopupHandle>(null);

  //function openUploadFilePopup(e: any) {
    // var uploadUrl: string = apiConfiguration.basePath + "/Filiere/"+currentIdFiliera+"/Cicli/"+e.row.data.idCiclo+"/Pagamenti/"+e.row.data.id+"/UploadFile"
    // uploadRef.current?.show(currentIdFiliera,e.row.data.idCiclo,e.row.data.id,uploadUrl);
  //}

  function hideInfo() {
    setState({ idCarico: undefined, visible: false, title: "", readonly:false });
  }

  function grid_InitNewRow(e: any) {
    e.data.acconto = false;
  }

  useImperativeHandle(ref, () => ({
    show(idCarico,readonly) {
      const api = new CicliApi(apiConfiguration);
      api.getCaricoById({ idFiliera: currentFiliera.Id, idCiclo: Number(props.ciclo.id), idCarico: idCarico })
        .then(data =>
          setState({
            idCarico: idCarico,
            visible: true,
            title: "Giornata di macellazione del " + formatDate(data.data as Date, "dd/MM/yyyy"),
            readonly:readonly
          }))
        .catch(showError);
    }
  }));

  return (<React.Fragment>

    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      fullScreen={true}
      showTitle={true}
      onHidden={hideInfo}
      title={state.title}
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      { state.idCarico &&
        <CollapsablePanel title='Documenti di carico'>
          <DocumentiMacelliTable ciclo={props.ciclo} idCarico={state.idCarico} readonly={state.readonly} />
        </CollapsablePanel>
      }
      
      { state.idCarico &&
      <CollapsablePanel title='Riscontri'>
        <CarichiRiscontriTable idCiclo={Number(props.ciclo.id)} idCarico={state.idCarico} readonly={state.readonly} />
      </CollapsablePanel> 
      }

    </Popup>

    {/* <PopupUploadFile ref={uploadRef}></PopupUploadFile> */}
  </React.Fragment>
  );
}

export default React.forwardRef(PopupCarico);
