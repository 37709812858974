import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetDocumentiRequest, InsertDocumentoRequest, UpdateDocumentoRequest, DeleteDocumentoRequest, GetDocumentiByTipoRequest } from '../data/apis/DocumentiApi';


export function DocumentiMacelliDataSource(idFiliera: number, idCiclo: number, idCarico: number) {
    const documentiApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return documentiApi.getDocumentiMacelli({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico
            });
        },

        byKey: (key) => {
            return documentiApi.getDocumentoMacelloById({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: key
            });
        },

        insert: (values) => {
            return documentiApi.insertDocumentoMacello({
                idCiclo: idCiclo,
                idFiliera: idFiliera,
                idCarico: idCarico,
                documentoMacelloCreateDTO: values
            });
        },

        update: (key, values) => {
            return documentiApi.updateDocumentoMacello({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: key,
                documentoMacelloUpdateDTO: values
            });
        },

        remove: (key) => {
            return documentiApi.deleteDocumentoMacello({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: key
            });
        }
    });
};

