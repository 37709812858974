/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BancaDTO } from './BancaDTO';
import {
    BancaDTOFromJSON,
    BancaDTOFromJSONTyped,
    BancaDTOToJSON,
} from './BancaDTO';
import type { CaricoMezzoDTO } from './CaricoMezzoDTO';
import {
    CaricoMezzoDTOFromJSON,
    CaricoMezzoDTOFromJSONTyped,
    CaricoMezzoDTOToJSON,
} from './CaricoMezzoDTO';
import type { CaricoOraDTO } from './CaricoOraDTO';
import {
    CaricoOraDTOFromJSON,
    CaricoOraDTOFromJSONTyped,
    CaricoOraDTOToJSON,
} from './CaricoOraDTO';

/**
 * 
 * @export
 * @interface AllevamentoUpdateDTO
 */
export interface AllevamentoUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    ragioneSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    cognome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    nome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    indirizzo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    numeroCivico?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    cap?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    localita?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    provincia?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    idNazione: string;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    partitaIva?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    codiceFiscale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    sdi?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllevamentoUpdateDTO
     */
    attivo?: boolean;
    /**
     * 
     * @type {BancaDTO}
     * @memberof AllevamentoUpdateDTO
     */
    banca?: BancaDTO;
    /**
     * 
     * @type {number}
     * @memberof AllevamentoUpdateDTO
     */
    idAllevatore?: number;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    codiceAllevamento?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    descrizioneBreve?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    personaRiferimento?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AllevamentoUpdateDTO
     */
    idTecnico?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllevamentoUpdateDTO
     */
    caricoGiorno?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    maxCaricoGiorno?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AllevamentoUpdateDTO
     */
    minutiTrasferimento?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    pesa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    incompatibile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllevamentoUpdateDTO
     */
    note?: string | null;
    /**
     * 
     * @type {CaricoOraDTO}
     * @memberof AllevamentoUpdateDTO
     */
    caricoOra?: CaricoOraDTO;
    /**
     * 
     * @type {CaricoMezzoDTO}
     * @memberof AllevamentoUpdateDTO
     */
    caricoMezzo?: CaricoMezzoDTO;
}

/**
 * Check if a given object implements the AllevamentoUpdateDTO interface.
 */
export function instanceOfAllevamentoUpdateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idNazione" in value;

    return isInstance;
}

export function AllevamentoUpdateDTOFromJSON(json: any): AllevamentoUpdateDTO {
    return AllevamentoUpdateDTOFromJSONTyped(json, false);
}

export function AllevamentoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllevamentoUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ragioneSociale': !exists(json, 'ragioneSociale') ? undefined : json['ragioneSociale'],
        'cognome': !exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !exists(json, 'nome') ? undefined : json['nome'],
        'indirizzo': !exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'numeroCivico': !exists(json, 'numeroCivico') ? undefined : json['numeroCivico'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
        'localita': !exists(json, 'localita') ? undefined : json['localita'],
        'provincia': !exists(json, 'provincia') ? undefined : json['provincia'],
        'idNazione': json['idNazione'],
        'partitaIva': !exists(json, 'partitaIva') ? undefined : json['partitaIva'],
        'codiceFiscale': !exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'sdi': !exists(json, 'sdi') ? undefined : json['sdi'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'attivo': !exists(json, 'attivo') ? undefined : json['attivo'],
        'banca': !exists(json, 'banca') ? undefined : BancaDTOFromJSON(json['banca']),
        'idAllevatore': !exists(json, 'idAllevatore') ? undefined : json['idAllevatore'],
        'codiceAllevamento': !exists(json, 'codiceAllevamento') ? undefined : json['codiceAllevamento'],
        'descrizioneBreve': !exists(json, 'descrizioneBreve') ? undefined : json['descrizioneBreve'],
        'personaRiferimento': !exists(json, 'personaRiferimento') ? undefined : json['personaRiferimento'],
        'idTecnico': !exists(json, 'idTecnico') ? undefined : json['idTecnico'],
        'caricoGiorno': !exists(json, 'caricoGiorno') ? undefined : json['caricoGiorno'],
        'maxCaricoGiorno': !exists(json, 'maxCaricoGiorno') ? undefined : json['maxCaricoGiorno'],
        'minutiTrasferimento': !exists(json, 'minutiTrasferimento') ? undefined : json['minutiTrasferimento'],
        'pesa': !exists(json, 'pesa') ? undefined : json['pesa'],
        'incompatibile': !exists(json, 'incompatibile') ? undefined : json['incompatibile'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'caricoOra': !exists(json, 'caricoOra') ? undefined : CaricoOraDTOFromJSON(json['caricoOra']),
        'caricoMezzo': !exists(json, 'caricoMezzo') ? undefined : CaricoMezzoDTOFromJSON(json['caricoMezzo']),
    };
}

export function AllevamentoUpdateDTOToJSON(value?: AllevamentoUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ragioneSociale': value.ragioneSociale,
        'cognome': value.cognome,
        'nome': value.nome,
        'indirizzo': value.indirizzo,
        'numeroCivico': value.numeroCivico,
        'cap': value.cap,
        'localita': value.localita,
        'provincia': value.provincia,
        'idNazione': value.idNazione,
        'partitaIva': value.partitaIva,
        'codiceFiscale': value.codiceFiscale,
        'sdi': value.sdi,
        'email': value.email,
        'attivo': value.attivo,
        'banca': BancaDTOToJSON(value.banca),
        'idAllevatore': value.idAllevatore,
        'codiceAllevamento': value.codiceAllevamento,
        'descrizioneBreve': value.descrizioneBreve,
        'personaRiferimento': value.personaRiferimento,
        'idTecnico': value.idTecnico,
        'caricoGiorno': value.caricoGiorno,
        'maxCaricoGiorno': value.maxCaricoGiorno,
        'minutiTrasferimento': value.minutiTrasferimento,
        'pesa': value.pesa,
        'incompatibile': value.incompatibile,
        'note': value.note,
        'caricoOra': CaricoOraDTOToJSON(value.caricoOra),
        'caricoMezzo': CaricoMezzoDTOToJSON(value.caricoMezzo),
    };
}

