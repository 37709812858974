/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IncubatoioDTO } from './IncubatoioDTO';
import {
    IncubatoioDTOFromJSON,
    IncubatoioDTOFromJSONTyped,
    IncubatoioDTOToJSON,
} from './IncubatoioDTO';
import type { TipoAnimaleDTO } from './TipoAnimaleDTO';
import {
    TipoAnimaleDTOFromJSON,
    TipoAnimaleDTOFromJSONTyped,
    TipoAnimaleDTOToJSON,
} from './TipoAnimaleDTO';

/**
 * 
 * @export
 * @interface MovimentoMortoDTO
 */
export interface MovimentoMortoDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    idDocumento?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoMortoDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {TipoAnimaleDTO}
     * @memberof MovimentoMortoDTO
     */
    tipoAnimale?: TipoAnimaleDTO;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMortoDTO
     */
    idIncubatoio?: number | null;
    /**
     * 
     * @type {IncubatoioDTO}
     * @memberof MovimentoMortoDTO
     */
    incubatoio?: IncubatoioDTO;
}

/**
 * Check if a given object implements the MovimentoMortoDTO interface.
 */
export function instanceOfMovimentoMortoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoMortoDTOFromJSON(json: any): MovimentoMortoDTO {
    return MovimentoMortoDTOFromJSONTyped(json, false);
}

export function MovimentoMortoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoMortoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idDocumento': !exists(json, 'idDocumento') ? undefined : json['idDocumento'],
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'tipoAnimale': !exists(json, 'tipoAnimale') ? undefined : TipoAnimaleDTOFromJSON(json['tipoAnimale']),
        'idIncubatoio': !exists(json, 'idIncubatoio') ? undefined : json['idIncubatoio'],
        'incubatoio': !exists(json, 'incubatoio') ? undefined : IncubatoioDTOFromJSON(json['incubatoio']),
    };
}

export function MovimentoMortoDTOToJSON(value?: MovimentoMortoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idDocumento': value.idDocumento,
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoAnimale': value.idTipoAnimale,
        'tipoAnimale': TipoAnimaleDTOToJSON(value.tipoAnimale),
        'idIncubatoio': value.idIncubatoio,
        'incubatoio': IncubatoioDTOToJSON(value.incubatoio),
    };
}

