import React, { useContext, useRef, useState } from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Popup,
  Form,
  Lookup,
  Button,
  RequiredRule,
  Format
} from 'devextreme-react/data-grid';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import FilieraContext from '../../../contexts/filieraContext';
import { listiniPulciniDataSource } from '../../../datasources/listiniPulciniDataSource';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import Box, {
  Item
} from 'devextreme-react/box';
import { Switch } from 'devextreme-react';
import { Item as FormItem } from 'devextreme-react/form';


export default function ListiniPulciniPage() {
  const { currentFiliera } = useContext(FilieraContext);
  const [ showAll, setShowAll ] = useState <boolean> (false);

  const styles = {
    alignTitle: {
      marginLeft: "40px",
      marginRight: "40px",
    },
    fixSwitch: {
      width: "unset"
    }
  };

  return (
    <React.Fragment>
      <Box
        direction="row"
        width="100%"
        height={75}
        align="center"
        crossAlign="center">
        <Item ratio={2}>
          <div className="rect">
            <h2 style={styles.alignTitle}>Listino Pulcini</h2>
          </div>
        </Item>
        <Item ratio={1}>
          <div className="rect">
            <div className="dx-field">
              <div className="dx-field-label" style={styles.fixSwitch}>Mostra tutti</div>
              <div className="dx-field-value" style={styles.fixSwitch}>
                <Switch defaultValue={showAll} onValueChange={setShowAll} />
              </div>
            </div>
          </div>
        </Item>
      </Box>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={listiniPulciniDataSource(currentFiliera.Id, showAll)}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        errorRowEnabled={false}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Pulcino" showTitle={true} />
          <Form>

            <FormItem dataField="idTipoAnimale" editorType='dxSelectBox' />
            <FormItem dataField="inizioValidita"/>
            <FormItem dataField="valore" editorType='dxNumberBox' editorOptions={{ format: '#.####'}} />

          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column type='buttons' />
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'idTipoAnimale'} caption='Tipo animale' >
          <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr="descrizione" />
        </Column>
        <Column dataField={'inizioValidita'} />
        <Column dataField={'valore'} caption='Prezzo/Capo' >
        <Format type='currency' precision={4}></Format>
        </Column>
      </DataGrid>
    </React.Fragment>
  )
}

