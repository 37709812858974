import React, { useContext, useEffect, useState } from 'react';
import FilieraContext from '../../contexts/filieraContext';
import { useAccount, useMsal } from '@azure/msal-react';

export default function CarichiPage() {
  const { currentFiliera } = useContext(FilieraContext);
  const [link, setLink] = useState<string>('');
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account?.username == 'info@avigel.it') {
      if (currentFiliera.Id == 1)
        setLink('https://app.powerbi.com/reportEmbed?reportId=a98b2c5f-d462-4154-8fc6-8da659150f60&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSectionc221919513f422bd3b0a');
      else if (currentFiliera.Id == 2)
        setLink('https://app.powerbi.com/reportEmbed?reportId=a98b2c5f-d462-4154-8fc6-8da659150f60&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSection47311b9d07dbe9ffae91');
    }
    else if (account?.username == 'damilano@avigel.it') {
      if (currentFiliera.Id == 1)
        setLink('https://app.powerbi.com/reportEmbed?reportId=0fd62d51-da98-445b-8d6c-8c3988022c7c&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSectionc221919513f422bd3b0a');
      else if (currentFiliera.Id == 2)
        setLink('https://app.powerbi.com/reportEmbed?reportId=0fd62d51-da98-445b-8d6c-8c3988022c7c&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSection47311b9d07dbe9ffae91');
    }
    else {
      if (currentFiliera.Id == 1)
        setLink('https://app.powerbi.com/reportEmbed?reportId=4fd6df77-1631-4d56-af27-5060c85cc561&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSectionc221919513f422bd3b0a');
      else if (currentFiliera.Id == 2) {
        setLink('https://app.powerbi.com/reportEmbed?reportId=4fd6df77-1631-4d56-af27-5060c85cc561&autoAuth=true&ctid=9a6bb95e-2985-4dfd-b3bf-18638a129013&pageName=ReportSection47311b9d07dbe9ffae91');
      }
    }
  }, [currentFiliera]);

  return (
    <React.Fragment>
      {link !== "" &&
        <iframe src={link} style={{
          display: "block",       /* iframes are inline by default */
          background: "#000",
          border: "none",         /* Reset default border */
          height: "85vh",        /* Viewport-relative units */
          width: "100%"
        }} allowFullScreen />
      }

      {link === "" &&
        <label>Nessun report per questa filiera</label>
      }
    </React.Fragment>
  )
}

