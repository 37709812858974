import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetMovimentiTrattamentiRequest, UpdateMovimentoTrattamentoRequest, DeleteMovimentoTrattamentoRequest, InsertMovimentoTrattamentoRequest } from '../data/apis/DocumentiApi';

import { TipiTrattamentiApi } from "../data/apis";

const documentiApi = new DocumentiApi(apiConfiguration);

export function MovimentiMacelliDataSource(
    idFiliera: number,
    idCiclo: number,
    idCarico: number,
    idDocumento: number
) {
    return new CustomStore({
        key: "id",
        loadMode: 'raw',
        load: (loadOptions) =>
            documentiApi.getMovimentiMacelli({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: idDocumento
            }),

        byKey: (key) =>
            documentiApi.getMovimentoMacelloById({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: idDocumento,
                id: key
            }),

        insert: (values) =>
            documentiApi.insertMovimentoMacello({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: idDocumento,
                movimentoMacelloCreateDTO: values
            }),

        update: (key, values) =>
            documentiApi.updateMovimentoMacello({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: idDocumento,
                id: key,
                movimentoMacelloUpdateDTO: values
            }),

        remove: (key) =>
            documentiApi.deleteMovimentoMacello({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCarico: idCarico,
                idDocumento: idDocumento,
                id: key
            })
    });
};

