import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useScreenSize } from "../../utils/media-query";
import { CollapsablePanel } from "../collapsablePanel/CollapsablePanel";
import MacelliTable from "../../pages/cicli/dettagli/macelliTable";
import MacelliDocumentForm from "../../pages/cicli/formsDocumenti/MacelliDocumentForm";
import { CicloDTO } from "../../data";

type Props = {
  ciclo: CicloDTO,
  idCarico: number,
  onChange: () => void,
  readonly?: boolean
}

export type DocumentoMacelliPopupHandle = {
  edit: (idDocumento: number) => void,
  new: () => void,
}

type state = {
  idDocumento?: number,
  visible: boolean
}

const PopupDocumentoMacelli: React.ForwardRefRenderFunction<DocumentoMacelliPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idDocumento: 0, visible: false });
  const { isXSmall, isSmall } = useScreenSize();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  function hideInfo() {
    console.log(isChanged);
    if (isChanged)
      props.onChange();
    setState({ idDocumento: 0, visible: false });
  }

  useImperativeHandle(ref, () => ({
    edit(idDocumento) {
      setState({
        idDocumento: idDocumento,
        visible: true
      });
    },
    new() {
      setState({
        visible: true
      });
    }
  }));

  function documento_inserted(e: number) {
    setState({
      ...state,
      idDocumento: e
    });
    setIsChanged(true);
  }

  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={false}
      fullScreen={true}
      onHidden={hideInfo}
      title="Documento"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />

      <React.Fragment>
        <CollapsablePanel title="Carico - Testata del documento" >
          <MacelliDocumentForm idCiclo={Number(props.ciclo.id)} idDocumento={state.idDocumento} idCarico={props.idCarico} onUpdated={() => setIsChanged(true)} onInserted={documento_inserted} readonly={props.readonly} />
        </CollapsablePanel>
        {state.idDocumento &&
          <CollapsablePanel title="Dettagli del documento" >
            <MacelliTable ciclo={props.ciclo} idCarico={props.idCarico} idDocumento={state.idDocumento} onChanged={() => setIsChanged(true)} readonly={props.readonly} />
          </CollapsablePanel>
        }
      </React.Fragment>
    </Popup>
  </React.Fragment>
  );
}

export default React.forwardRef(PopupDocumentoMacelli);
