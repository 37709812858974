import React, { useImperativeHandle, useState, useContext } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Column, Editing, FilterRow, Form, Pager, Paging } from "devextreme-react/data-grid";
import { ConversioniDettagliDataSource } from "../../datasources/conversioniDettagliDataSource";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import { Item } from "devextreme-react/form";
import { useScreenSize } from "../../utils/media-query";
import FilieraContext from "../../contexts/filieraContext";

type Props={}

export type ConversioniDettagliPopupHandle = {
  show: (idConversione:number) => void,
}

type state ={
  idConversione:number|undefined,
  visible:boolean,
  datasource:any
}

const PopupConversioniDettagli: React.ForwardRefRenderFunction<ConversioniDettagliPopupHandle, Props> = (props,ref) =>
{
  const [state, setState] = useState<state>({idConversione:undefined, visible:false, datasource:null});
  const { isXSmall, isSmall } = useScreenSize();
  const { currentFiliera } = useContext(FilieraContext);
  
  function hideInfo() {
    setState({idConversione:undefined, visible:false, datasource:null});
  }

  useImperativeHandle(ref, () => ({
    show(idConversione) {
      setState({
        idConversione:idConversione, 
        visible:true, 
        datasource:ConversioniDettagliDataSource(currentFiliera.Id,idConversione)
      });
    }
  }));
  
  return (<React.Fragment>
      <Popup
        visible={state.visible}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        fullScreen={isXSmall || isSmall}
        onHidden={hideInfo}
        title="Dettagli Conversione"
        container=".dx-viewport"
      >
        <Position
          at="center"
          my="center"
          of=""
          collision="fit"
        />
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            options={{
              text: 'Chiudi',
              onClick: hideInfo,
            }}
          />
        <DataGrid
        className={'dx-card wide-card'}
        dataSource={state.datasource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Dettaglio Conversione" showTitle={true}  />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="indiceConversione" />
                <Item dataField="percentuale" />
              </Item>
            </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />

        <Column  type='buttons' />
        <Column dataField={'id'} width={90}  allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'indiceConversione'} />
        <Column dataField={'percentuale'} />
      </DataGrid>
      </Popup>

  </React.Fragment>
  );
}
    
export default React.forwardRef(PopupConversioniDettagli);
