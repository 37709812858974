/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagamentoCreateDTO,
  PagamentoDTO,
  PagamentoUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    PagamentoCreateDTOFromJSON,
    PagamentoCreateDTOToJSON,
    PagamentoDTOFromJSON,
    PagamentoDTOToJSON,
    PagamentoUpdateDTOFromJSON,
    PagamentoUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeletePagamentoRequest {
    idFiliera: number;
    idCiclo: number;
    id: number;
}

export interface DownloadFilePagamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idPagamento: number;
}

export interface GetPagamentiRequest {
    idFiliera: number;
    idCiclo: number;
}

export interface GetPagamentoByIdRequest {
    idFiliera: number;
    idCiclo: number;
    id: number;
}

export interface InsertPagamentoRequest {
    idFiliera: number;
    idCiclo: number;
    pagamentoCreateDTO?: PagamentoCreateDTO;
}

export interface UpdatePagamentoRequest {
    idFiliera: number;
    idCiclo: number;
    id: number;
    pagamentoUpdateDTO?: PagamentoUpdateDTO;
}

export interface UploadFilePagamentoRequest {
    idFiliera: number;
    idCiclo: number;
    idPagamento: number;
    file?: Blob;
}

/**
 * 
 */
export class PagamentiApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un pagamento esistente
     */
    async deletePagamentoRaw(requestParameters: DeletePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deletePagamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling deletePagamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePagamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina un pagamento esistente
     */
    async deletePagamento(requestParameters: DeletePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePagamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera un file legato al documento
     */
    async downloadFilePagamentoRaw(requestParameters: DownloadFilePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling downloadFilePagamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling downloadFilePagamento.');
        }

        if (requestParameters.idPagamento === null || requestParameters.idPagamento === undefined) {
            throw new runtime.RequiredError('idPagamento','Required parameter requestParameters.idPagamento was null or undefined when calling downloadFilePagamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti/{idPagamento}/File`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idPagamento"}}`, encodeURIComponent(String(requestParameters.idPagamento))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera un file legato al documento
     */
    async downloadFilePagamento(requestParameters: DownloadFilePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadFilePagamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutti i pagamenti di un ciclo
     */
    async getPagamentiRaw(requestParameters: GetPagamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PagamentoDTO>>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getPagamenti.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getPagamenti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PagamentoDTOFromJSON));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elenco di tutti i pagamenti di un ciclo
     */
    async getPagamenti(requestParameters: GetPagamentiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PagamentoDTO>> {
        const response = await this.getPagamentiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera un pagamento per id
     */
    async getPagamentoByIdRaw(requestParameters: GetPagamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getPagamentoById.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling getPagamentoById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPagamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera un pagamento per id
     */
    async getPagamentoById(requestParameters: GetPagamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagamentoDTO> {
        const response = await this.getPagamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo pagamento in anagrafica
     */
    async insertPagamentoRaw(requestParameters: InsertPagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagamentoDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling insertPagamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling insertPagamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagamentoCreateDTOToJSON(requestParameters.pagamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Crea un nuovo pagamento in anagrafica
     */
    async insertPagamento(requestParameters: InsertPagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagamentoDTO> {
        const response = await this.insertPagamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un pagamento esistente
     */
    async updatePagamentoRaw(requestParameters: UpdatePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updatePagamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling updatePagamento.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePagamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti/{id}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PagamentoUpdateDTOToJSON(requestParameters.pagamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna un pagamento esistente
     */
    async updatePagamento(requestParameters: UpdatePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePagamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Carica un file legato al pagamento, se è già presente lo sostituisce
     */
    async uploadFilePagamentoRaw(requestParameters: UploadFilePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling uploadFilePagamento.');
        }

        if (requestParameters.idCiclo === null || requestParameters.idCiclo === undefined) {
            throw new runtime.RequiredError('idCiclo','Required parameter requestParameters.idCiclo was null or undefined when calling uploadFilePagamento.');
        }

        if (requestParameters.idPagamento === null || requestParameters.idPagamento === undefined) {
            throw new runtime.RequiredError('idPagamento','Required parameter requestParameters.idPagamento was null or undefined when calling uploadFilePagamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Cicli/{idCiclo}/Pagamenti/{idPagamento}/File`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"idCiclo"}}`, encodeURIComponent(String(requestParameters.idCiclo))).replace(`{${"idPagamento"}}`, encodeURIComponent(String(requestParameters.idPagamento))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Ciclo/Pagamenti/Write &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Carica un file legato al pagamento, se è già presente lo sostituisce
     */
    async uploadFilePagamento(requestParameters: UploadFilePagamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadFilePagamentoRaw(requestParameters, initOverrides);
    }

}
