/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryMangimiDTO
 */
export interface SummaryMangimiDTO {
    /**
     * 
     * @type {number}
     * @memberof SummaryMangimiDTO
     */
    giacenzaIniziale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryMangimiDTO
     */
    giacenzaFinale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryMangimiDTO
     */
    mangimeConsegnato?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryMangimiDTO
     */
    mangimeConsumato?: number | null;
}

/**
 * Check if a given object implements the SummaryMangimiDTO interface.
 */
export function instanceOfSummaryMangimiDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SummaryMangimiDTOFromJSON(json: any): SummaryMangimiDTO {
    return SummaryMangimiDTOFromJSONTyped(json, false);
}

export function SummaryMangimiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryMangimiDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'giacenzaIniziale': !exists(json, 'giacenzaIniziale') ? undefined : json['giacenzaIniziale'],
        'giacenzaFinale': !exists(json, 'giacenzaFinale') ? undefined : json['giacenzaFinale'],
        'mangimeConsegnato': !exists(json, 'mangimeConsegnato') ? undefined : json['mangimeConsegnato'],
        'mangimeConsumato': !exists(json, 'mangimeConsumato') ? undefined : json['mangimeConsumato'],
    };
}

export function SummaryMangimiDTOToJSON(value?: SummaryMangimiDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'giacenzaIniziale': value.giacenzaIniziale,
        'giacenzaFinale': value.giacenzaFinale,
        'mangimeConsegnato': value.mangimeConsegnato,
        'mangimeConsumato': value.mangimeConsumato,
    };
}

