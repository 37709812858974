/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContattoCreateDTO,
  ContattoDTO,
  ContattoUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    ContattoCreateDTOFromJSON,
    ContattoCreateDTOToJSON,
    ContattoDTOFromJSON,
    ContattoDTOToJSON,
    ContattoUpdateDTOFromJSON,
    ContattoUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteContattoRequest {
    idAnagrafe: number;
    idContatto: number;
}

export interface GetContattiRequest {
    idAnagrafe: number;
}

export interface GetContattoByIdRequest {
    idAnagrafe: number;
    idContatto: number;
}

export interface InsertContattoRequest {
    idAnagrafe: number;
    contattoCreateDTO?: ContattoCreateDTO;
}

export interface UpdateContattoRequest {
    idAnagrafe: number;
    idContatto: number;
    contattoUpdateDTO?: ContattoUpdateDTO;
}

/**
 * 
 */
export class ContattiApi extends runtime.BaseAPI {

    /**
     * Elimina una Contatto esistente
     */
    async deleteContattoRaw(requestParameters: DeleteContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAnagrafe === null || requestParameters.idAnagrafe === undefined) {
            throw new runtime.RequiredError('idAnagrafe','Required parameter requestParameters.idAnagrafe was null or undefined when calling deleteContatto.');
        }

        if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
            throw new runtime.RequiredError('idContatto','Required parameter requestParameters.idContatto was null or undefined when calling deleteContatto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/{idAnagrafe}/Contatti/{idContatto}`.replace(`{${"idAnagrafe"}}`, encodeURIComponent(String(requestParameters.idAnagrafe))).replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Elimina una Contatto esistente
     */
    async deleteContatto(requestParameters: DeleteContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContattoRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutte le Contatti
     */
    async getContattiRaw(requestParameters: GetContattiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContattoDTO>>> {
        if (requestParameters.idAnagrafe === null || requestParameters.idAnagrafe === undefined) {
            throw new runtime.RequiredError('idAnagrafe','Required parameter requestParameters.idAnagrafe was null or undefined when calling getContatti.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/{idAnagrafe}/Contatti`.replace(`{${"idAnagrafe"}}`, encodeURIComponent(String(requestParameters.idAnagrafe))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContattoDTOFromJSON));
    }

    /**
     * Elenco di tutte le Contatti
     */
    async getContatti(requestParameters: GetContattiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContattoDTO>> {
        const response = await this.getContattiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera una Contatto per id
     */
    async getContattoByIdRaw(requestParameters: GetContattoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContattoDTO>> {
        if (requestParameters.idAnagrafe === null || requestParameters.idAnagrafe === undefined) {
            throw new runtime.RequiredError('idAnagrafe','Required parameter requestParameters.idAnagrafe was null or undefined when calling getContattoById.');
        }

        if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
            throw new runtime.RequiredError('idContatto','Required parameter requestParameters.idContatto was null or undefined when calling getContattoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/{idAnagrafe}/Contatti/{idContatto}`.replace(`{${"idAnagrafe"}}`, encodeURIComponent(String(requestParameters.idAnagrafe))).replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContattoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una Contatto per id
     */
    async getContattoById(requestParameters: GetContattoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContattoDTO> {
        const response = await this.getContattoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Crea un nuovo Contatto
     */
    async insertContattoRaw(requestParameters: InsertContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContattoDTO>> {
        if (requestParameters.idAnagrafe === null || requestParameters.idAnagrafe === undefined) {
            throw new runtime.RequiredError('idAnagrafe','Required parameter requestParameters.idAnagrafe was null or undefined when calling insertContatto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/{idAnagrafe}/Contatti`.replace(`{${"idAnagrafe"}}`, encodeURIComponent(String(requestParameters.idAnagrafe))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContattoCreateDTOToJSON(requestParameters.contattoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContattoDTOFromJSON(jsonValue));
    }

    /**
     * Crea un nuovo Contatto
     */
    async insertContatto(requestParameters: InsertContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContattoDTO> {
        const response = await this.insertContattoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aggiorna una filiare esistente
     */
    async updateContattoRaw(requestParameters: UpdateContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idAnagrafe === null || requestParameters.idAnagrafe === undefined) {
            throw new runtime.RequiredError('idAnagrafe','Required parameter requestParameters.idAnagrafe was null or undefined when calling updateContatto.');
        }

        if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
            throw new runtime.RequiredError('idContatto','Required parameter requestParameters.idContatto was null or undefined when calling updateContatto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Anagrafiche/{idAnagrafe}/Contatti/{idContatto}`.replace(`{${"idAnagrafe"}}`, encodeURIComponent(String(requestParameters.idAnagrafe))).replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContattoUpdateDTOToJSON(requestParameters.contattoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Aggiorna una filiare esistente
     */
    async updateContatto(requestParameters: UpdateContattoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContattoRaw(requestParameters, initOverrides);
    }

}
