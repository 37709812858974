// DropdownProvider.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import DropdownContext, { IFiliera } from './filieraContext';
import { FiliereApi } from '../data';
import { apiConfiguration } from '../apiConfig';

interface FilieraProviderProps {
  children: ReactNode;
}

const FilieraProvider: React.FC<FilieraProviderProps> = ({ children }) => {
  const [currentFiliera, setCurrentFiliera] = useState<IFiliera>();

  useEffect(() => {
    const fetchData = async () => {
      var json = localStorage.getItem('selected');
      if (json) {
        const obj = JSON.parse(json);

        if (obj.Id && obj.Name) {
          setCurrentFiliera(obj);
          return;
        }
      }

      var api = new FiliereApi(apiConfiguration);
      const filiere = await api.getFiliere();

      if (filiere.length > 0)
        setCurrentFiliera({ Id: Number(filiere[0].id), Name: String(filiere[0].ragioneSociale) });
    }

    fetchData()
      .catch(console.error);
  }, []);



  useEffect(() => {
    if (currentFiliera) {
      console.log("useEffect cambio filiera");
      localStorage.setItem('selected', JSON.stringify(currentFiliera));
    }
  }, [currentFiliera]);

  if (currentFiliera)
    return (
      <DropdownContext.Provider value={{ currentFiliera, setCurrentFiliera }}>
        {children}
      </DropdownContext.Provider>
    );
  else
    return (
      <React.Fragment>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div>Caricamento in corso ...</div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default FilieraProvider;
