import { useState } from "react";
import { HiArrowSmDown, HiArrowSmUp } from "react-icons/hi";

type state = {
    idCiclo: number,
    idDocumento: number,
    visible: boolean
  }

type Props = {
    children: React.ReactElement,
    title: string,
    expanded?: boolean,
    visible?:boolean
}

export function CollapsablePanel(props: Props) {
    const [expandedItem, setExpandedItem] = useState<boolean>(props.expanded ?? true);

    function getStyle() {
        if (!expandedItem)
            return { display: 'none' };
        else
            return {};
    }

    return (
        <div className="dx-card" style={{visibility: (props.visible|| true) ? "visible": "hidden", textAlign:"center"}}>
            <div className="dx-card-body">
                <div className="border border-gray-300 rounded">
                    <div
                        className="flex items-center justify-between px-4 py-2 cursor-pointer bg-gray-200"
                        onClick={() => setExpandedItem(!expandedItem)}
                    >
                        <h5 className="text-gray-800 font-semibold" style={{ marginTop: '10px', marginBottom: '10px', paddingLeft: '10px' }}>{props.title}
                            <span style={{ fontSize: '24px', color: '#666', float: 'right', marginRight: '10px' }}>
                                {expandedItem ? <HiArrowSmUp /> : <HiArrowSmDown />}
                            </span>
                        </h5>
                    </div>
                    <div className="p-4" style={getStyle()}>
                        <div className="bg-white rounded shadow">
                            <div className="px-4 py-2" style={{ paddingLeft: '4px', paddingRight: '4px', display: '' }}>
                                {(expandedItem) && props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}