/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  TipoTrattamentoCreateDTO,
  TipoTrattamentoDTO,
  TipoTrattamentoUpdateDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TipoTrattamentoCreateDTOFromJSON,
    TipoTrattamentoCreateDTOToJSON,
    TipoTrattamentoDTOFromJSON,
    TipoTrattamentoDTOToJSON,
    TipoTrattamentoUpdateDTOFromJSON,
    TipoTrattamentoUpdateDTOToJSON,
} from '../models';

export interface DeleteTipoTrattamentoRequest {
    id: number;
}

export interface GetTipoTrattamentoByIdRequest {
    id: number;
}

export interface InsertTipoTrattamentoRequest {
    tipoTrattamentoCreateDTO?: TipoTrattamentoCreateDTO;
}

export interface UpdateTipoTrattamentoRequest {
    id: number;
    tipoTrattamentoUpdateDTO?: TipoTrattamentoUpdateDTO;
}

/**
 * 
 */
export class TipiTrattamentiApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo trattamento esistente
     */
    async deleteTipoTrattamentoRaw(requestParameters: DeleteTipoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTipoTrattamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiTrattamenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina un tipo trattamento esistente
     */
    async deleteTipoTrattamento(requestParameters: DeleteTipoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTipoTrattamentoRaw(requestParameters, initOverrides);
    }

    /**
     * Elenco di tutti i tipi trattamenti
     */
    async getTipoTrattamentiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TipoTrattamentoDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiTrattamenti`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TipoTrattamentoDTOFromJSON));
    }

    /**
     * Elenco di tutti i tipi trattamenti
     */
    async getTipoTrattamenti(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TipoTrattamentoDTO>> {
        const response = await this.getTipoTrattamentiRaw(initOverrides);
        return await response.value();
    }

    /**
     * Recupera un tipo trattamento per id
     */
    async getTipoTrattamentoByIdRaw(requestParameters: GetTipoTrattamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoTrattamentoDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTipoTrattamentoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiTrattamenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoTrattamentoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un tipo trattamento per id
     */
    async getTipoTrattamentoById(requestParameters: GetTipoTrattamentoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoTrattamentoDTO> {
        const response = await this.getTipoTrattamentoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo trattamento in anagrafica
     */
    async insertTipoTrattamentoRaw(requestParameters: InsertTipoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TipoTrattamentoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiTrattamenti`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TipoTrattamentoCreateDTOToJSON(requestParameters.tipoTrattamentoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TipoTrattamentoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo trattamento in anagrafica
     */
    async insertTipoTrattamento(requestParameters: InsertTipoTrattamentoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TipoTrattamentoDTO> {
        const response = await this.insertTipoTrattamentoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo trattamento esistente
     */
    async updateTipoTrattamentoRaw(requestParameters: UpdateTipoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTipoTrattamento.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/TipiTrattamenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TipoTrattamentoUpdateDTOToJSON(requestParameters.tipoTrattamentoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo trattamento esistente
     */
    async updateTipoTrattamento(requestParameters: UpdateTipoTrattamentoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTipoTrattamentoRaw(requestParameters, initOverrides);
    }

}
