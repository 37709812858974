/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnagrafeDTO } from './AnagrafeDTO';
import {
    AnagrafeDTOFromJSON,
    AnagrafeDTOFromJSONTyped,
    AnagrafeDTOToJSON,
} from './AnagrafeDTO';
import type { CicloDTO } from './CicloDTO';
import {
    CicloDTOFromJSON,
    CicloDTOFromJSONTyped,
    CicloDTOToJSON,
} from './CicloDTO';
import type { FornitoreDTO } from './FornitoreDTO';
import {
    FornitoreDTOFromJSON,
    FornitoreDTOFromJSONTyped,
    FornitoreDTOToJSON,
} from './FornitoreDTO';

/**
 * 
 * @export
 * @interface DocumentoAccasamentoDTO
 */
export interface DocumentoAccasamentoDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    idCiclo?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    idAnagrafeMittente?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    idAnagrafeDestinatario?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoAccasamentoDTO
     */
    dataDocumento?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoDTO
     */
    numeroDocumento?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentoAccasamentoDTO
     */
    dataOperazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoDTO
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentoAccasamentoDTO
     */
    tipo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    idArxivar?: number | null;
    /**
     * 
     * @type {CicloDTO}
     * @memberof DocumentoAccasamentoDTO
     */
    ciclo?: CicloDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoAccasamentoDTO
     */
    anagrafeMittente?: AnagrafeDTO;
    /**
     * 
     * @type {AnagrafeDTO}
     * @memberof DocumentoAccasamentoDTO
     */
    anagrafeDestinatario?: AnagrafeDTO;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    idFornitore?: number | null;
    /**
     * 
     * @type {FornitoreDTO}
     * @memberof DocumentoAccasamentoDTO
     */
    fornitore?: FornitoreDTO;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    maschiAccasati?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentoAccasamentoDTO
     */
    femmineAccasate?: number | null;
}

/**
 * Check if a given object implements the DocumentoAccasamentoDTO interface.
 */
export function instanceOfDocumentoAccasamentoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentoAccasamentoDTOFromJSON(json: any): DocumentoAccasamentoDTO {
    return DocumentoAccasamentoDTOFromJSONTyped(json, false);
}

export function DocumentoAccasamentoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentoAccasamentoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idCiclo': !exists(json, 'idCiclo') ? undefined : json['idCiclo'],
        'idAnagrafeMittente': !exists(json, 'idAnagrafeMittente') ? undefined : json['idAnagrafeMittente'],
        'idAnagrafeDestinatario': !exists(json, 'idAnagrafeDestinatario') ? undefined : json['idAnagrafeDestinatario'],
        'dataDocumento': !exists(json, 'dataDocumento') ? undefined : (new Date(json['dataDocumento'])),
        'numeroDocumento': !exists(json, 'numeroDocumento') ? undefined : json['numeroDocumento'],
        'dataOperazione': !exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'tipo': !exists(json, 'tipo') ? undefined : json['tipo'],
        'idArxivar': !exists(json, 'idArxivar') ? undefined : json['idArxivar'],
        'ciclo': !exists(json, 'ciclo') ? undefined : CicloDTOFromJSON(json['ciclo']),
        'anagrafeMittente': !exists(json, 'anagrafeMittente') ? undefined : AnagrafeDTOFromJSON(json['anagrafeMittente']),
        'anagrafeDestinatario': !exists(json, 'anagrafeDestinatario') ? undefined : AnagrafeDTOFromJSON(json['anagrafeDestinatario']),
        'idFornitore': !exists(json, 'idFornitore') ? undefined : json['idFornitore'],
        'fornitore': !exists(json, 'fornitore') ? undefined : FornitoreDTOFromJSON(json['fornitore']),
        'maschiAccasati': !exists(json, 'maschiAccasati') ? undefined : json['maschiAccasati'],
        'femmineAccasate': !exists(json, 'femmineAccasate') ? undefined : json['femmineAccasate'],
    };
}

export function DocumentoAccasamentoDTOToJSON(value?: DocumentoAccasamentoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idCiclo': value.idCiclo,
        'idAnagrafeMittente': value.idAnagrafeMittente,
        'idAnagrafeDestinatario': value.idAnagrafeDestinatario,
        'dataDocumento': value.dataDocumento === undefined ? undefined : (value.dataDocumento.toISOString()),
        'numeroDocumento': value.numeroDocumento,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'note': value.note,
        'tipo': value.tipo,
        'idArxivar': value.idArxivar,
        'ciclo': CicloDTOToJSON(value.ciclo),
        'anagrafeMittente': AnagrafeDTOToJSON(value.anagrafeMittente),
        'anagrafeDestinatario': AnagrafeDTOToJSON(value.anagrafeDestinatario),
        'idFornitore': value.idFornitore,
        'fornitore': FornitoreDTOToJSON(value.fornitore),
        'maschiAccasati': value.maschiAccasati,
        'femmineAccasate': value.femmineAccasate,
    };
}

