import React, { useContext, useRef, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/form';
import PopupUploadFile, { UploadFilePopupHandle } from '../../../components/popupUploadFile/popupUploadFile';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { MovimentiMortalitaDataSource } from '../../../datasources/movimentiMortalitaDataSource';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { CicloDTO } from '../../../data';
import { capannoniDataSource } from '../../../datasources/capannoniDataSource';
import { incubatoiDataSource } from '../../../datasources/incubatoiDataSource';
import { incubatoiByCicloDataSource } from '../../../datasources/incubatoiByCicloDataSource';

type MangimiTableProps = {
    ciclo: CicloDTO,
    idDocumento: number,
    onChanged: () => void,
    readonly?: boolean
};

const MortiTable: React.FC<MangimiTableProps> = (params) => {
    const { currentFiliera } = useContext(FilieraContext);

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={MovimentiMortalitaDataSource(currentFiliera.Id, Number(params.ciclo.id), params.idDocumento)}
                showBorders={false}
                focusedRowEnabled={true}
                errorRowEnabled={false}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
                onRowRemoved={params.onChanged}
                onRowUpdated={params.onChanged}
                onRowInserted={params.onChanged}
                onDataErrorOccurred={dataGridErrorHandling}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!params.readonly} allowDeleting={true}>
                    <Form labelMode='floating'>
                        <Item dataField="idTipoAnimale" editorType='dxSelectBox' />
                        <Item dataField="quantita" editorType='dxNumberBox' />
                        <Item dataField="valoreFixed" />
                        <Item dataField="idCapannone" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                        <Item dataField="idIncubatoio" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                        <Item dataField="note" colSpan={2} editorType='dxTextArea' />
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={params.readonly}/>
                    <Button name="delete" disabled={params.readonly}/>
                </Column>
                <Column dataField={'id'} width={90} allowEditing={false} visible={false} />
                <Column dataField={'quantita'} caption={'Quantità'} hidingPriority={6} />
                <Column dataField={'note'} caption={'Note'} hidingPriority={5} />
                <Column dataField={'idTipoAnimale'} caption={'Tipo Animale'}>
                    <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column dataField={'idCapannone'} caption={'Capannone'} >
                    <Lookup dataSource={capannoniDataSource(Number(params.ciclo.allevamento?.idAllevatore),Number(params.ciclo.idAllevamento))} valueExpr="id" displayExpr={"numero"} allowClearing={true}/>
                </Column>
                <Column dataField={'valoreFixed'} caption={'Forzatura Prezzo'} dataType='number' />
                <Column dataField={'idIncubatoio'} caption={'Incubatoio'}>
                    <Lookup dataSource={incubatoiByCicloDataSource(params.ciclo.id??0)} valueExpr="id" displayExpr={"ragioneSociale"} allowClearing={true}/>
                </Column>
                <Column dataField={'tipoAnimale.codice'} caption={'Codice animale'} hidingPriority={4} />
                <Column dataField={'tipoAnimale.descrizione'} caption={'Descrizione animale'} hidingPriority={3} />
                <Column dataField={'tipoAnimale.maschio'} caption={'Sesso animale'} hidingPriority={2} />
                <Column dataField={'tipoAnimale.razza.descrizione'} caption={'Descrizione animale'} hidingPriority={1} />
            </DataGrid>
        </React.Fragment>
    );
};


export default MortiTable;


