/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversioneDettaglioDTO
 */
export interface ConversioneDettaglioDTO {
    /**
     * 
     * @type {number}
     * @memberof ConversioneDettaglioDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversioneDettaglioDTO
     */
    idConversione?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversioneDettaglioDTO
     */
    indiceConversione?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversioneDettaglioDTO
     */
    percentuale?: number;
}

/**
 * Check if a given object implements the ConversioneDettaglioDTO interface.
 */
export function instanceOfConversioneDettaglioDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversioneDettaglioDTOFromJSON(json: any): ConversioneDettaglioDTO {
    return ConversioneDettaglioDTOFromJSONTyped(json, false);
}

export function ConversioneDettaglioDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversioneDettaglioDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idConversione': !exists(json, 'idConversione') ? undefined : json['idConversione'],
        'indiceConversione': !exists(json, 'indiceConversione') ? undefined : json['indiceConversione'],
        'percentuale': !exists(json, 'percentuale') ? undefined : json['percentuale'],
    };
}

export function ConversioneDettaglioDTOToJSON(value?: ConversioneDettaglioDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idConversione': value.idConversione,
        'indiceConversione': value.indiceConversione,
        'percentuale': value.percentuale,
    };
}

