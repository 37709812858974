/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllevamentoDTO } from './AllevamentoDTO';
import {
    AllevamentoDTOFromJSON,
    AllevamentoDTOFromJSONTyped,
    AllevamentoDTOToJSON,
} from './AllevamentoDTO';
import type { ChiusuraDTO } from './ChiusuraDTO';
import {
    ChiusuraDTOFromJSON,
    ChiusuraDTOFromJSONTyped,
    ChiusuraDTOToJSON,
} from './ChiusuraDTO';
import type { ConversioneDTO } from './ConversioneDTO';
import {
    ConversioneDTOFromJSON,
    ConversioneDTOFromJSONTyped,
    ConversioneDTOToJSON,
} from './ConversioneDTO';

/**
 * 
 * @export
 * @interface CicloDTO
 */
export interface CicloDTO {
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    idFiliera?: number;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    idAllevamento?: number;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    idConversione?: number;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    cicloNumero?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CicloDTO
     */
    soccida?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CicloDTO
     */
    vaccinoCaricoAllevatore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CicloDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CicloDTO
     */
    dataInizio?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    anno?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CicloDTO
     */
    stato?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    giacenzaIniziale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CicloDTO
     */
    giacenzaInizialeValore?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CicloDTO
     */
    escludiRegistroCS?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CicloDTO
     */
    registroCSDefinitivo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CicloDTO
     */
    readOnly?: boolean;
    /**
     * 
     * @type {AllevamentoDTO}
     * @memberof CicloDTO
     */
    allevamento?: AllevamentoDTO;
    /**
     * 
     * @type {ConversioneDTO}
     * @memberof CicloDTO
     */
    conversione?: ConversioneDTO;
    /**
     * 
     * @type {ChiusuraDTO}
     * @memberof CicloDTO
     */
    chiusura?: ChiusuraDTO;
}

/**
 * Check if a given object implements the CicloDTO interface.
 */
export function instanceOfCicloDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CicloDTOFromJSON(json: any): CicloDTO {
    return CicloDTOFromJSONTyped(json, false);
}

export function CicloDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CicloDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idFiliera': !exists(json, 'idFiliera') ? undefined : json['idFiliera'],
        'idAllevamento': !exists(json, 'idAllevamento') ? undefined : json['idAllevamento'],
        'idConversione': !exists(json, 'idConversione') ? undefined : json['idConversione'],
        'cicloNumero': !exists(json, 'cicloNumero') ? undefined : json['cicloNumero'],
        'soccida': !exists(json, 'soccida') ? undefined : json['soccida'],
        'vaccinoCaricoAllevatore': !exists(json, 'vaccinoCaricoAllevatore') ? undefined : json['vaccinoCaricoAllevatore'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'dataInizio': !exists(json, 'dataInizio') ? undefined : (json['dataInizio'] === null ? null : new Date(json['dataInizio'])),
        'anno': !exists(json, 'anno') ? undefined : json['anno'],
        'stato': !exists(json, 'stato') ? undefined : json['stato'],
        'giacenzaIniziale': !exists(json, 'giacenzaIniziale') ? undefined : json['giacenzaIniziale'],
        'giacenzaInizialeValore': !exists(json, 'giacenzaInizialeValore') ? undefined : json['giacenzaInizialeValore'],
        'escludiRegistroCS': !exists(json, 'escludiRegistroCS') ? undefined : json['escludiRegistroCS'],
        'registroCSDefinitivo': !exists(json, 'registroCSDefinitivo') ? undefined : json['registroCSDefinitivo'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'allevamento': !exists(json, 'allevamento') ? undefined : AllevamentoDTOFromJSON(json['allevamento']),
        'conversione': !exists(json, 'conversione') ? undefined : ConversioneDTOFromJSON(json['conversione']),
        'chiusura': !exists(json, 'chiusura') ? undefined : ChiusuraDTOFromJSON(json['chiusura']),
    };
}

export function CicloDTOToJSON(value?: CicloDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idFiliera': value.idFiliera,
        'idAllevamento': value.idAllevamento,
        'idConversione': value.idConversione,
        'cicloNumero': value.cicloNumero,
        'soccida': value.soccida,
        'vaccinoCaricoAllevatore': value.vaccinoCaricoAllevatore,
        'descrizione': value.descrizione,
        'dataInizio': value.dataInizio === undefined ? undefined : (value.dataInizio === null ? null : value.dataInizio.toISOString()),
        'anno': value.anno,
        'stato': value.stato,
        'giacenzaIniziale': value.giacenzaIniziale,
        'giacenzaInizialeValore': value.giacenzaInizialeValore,
        'escludiRegistroCS': value.escludiRegistroCS,
        'registroCSDefinitivo': value.registroCSDefinitivo,
        'readOnly': value.readOnly,
        'allevamento': AllevamentoDTOToJSON(value.allevamento),
        'conversione': ConversioneDTOToJSON(value.conversione),
        'chiusura': ChiusuraDTOToJSON(value.chiusura),
    };
}

