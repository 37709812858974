import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import { useScreenSize } from "../../utils/media-query";

// pdfjs.GlobalWorkerOptions.workerPort = new Worker(
//   'node_modules/pdfjs-dist/build/pdf.worker.entry.js',
// );

type Props = {
  onUploadCompleted?: ()=>void,
  allowedFileExtensions?: string[] | undefined
}

export type UploadFilePopupHandle = {
  show: (idFiliera: number, idCiclo: number, id: number, uploadUrl: string) => void,
}

type state = {
  idFiliera: number | undefined,
  idCiclo: number | undefined,
  id: number | undefined,
  visible: boolean
}

// const fileToBase64 = (file: File | Blob): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//      resolve(reader.result as string);
//     };

//     reader.readAsDataURL(file);
//     reader.onerror = reject;
//   });

const PopupUploadFile: React.ForwardRefRenderFunction<UploadFilePopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idFiliera: undefined, idCiclo: undefined, id: undefined, visible: false });
  const { isXSmall, isSmall } = useScreenSize();
  const [uploadUrl, setUploadUrl]= useState("");

  function hideInfo() {
    //setState((prevState) => ({ ...prevState, visible: false }));
    setState({ idFiliera: undefined, idCiclo: undefined, id: undefined, visible: false });
    
  }

  useImperativeHandle(ref, () => ({
    async show(idFiliera, idCiclo, id, uploadUrl) {

      setState({
        idCiclo: idCiclo,
        id: id,
        idFiliera: idFiliera,
        visible: true
      });
      
      setUploadUrl(uploadUrl);
    }
  }));

  
  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      maxWidth={500}
maxHeight={400}
      fullScreen={isXSmall || isSmall}
      onHidden={hideInfo}
      title="Carica file"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      <UploaderPanel uploadUrl={uploadUrl} onUploadCompleted={props.onUploadCompleted} allowedFileExtensions={props.allowedFileExtensions}></UploaderPanel>
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupUploadFile);
