/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TipoContattoDTO } from './TipoContattoDTO';
import {
    TipoContattoDTOFromJSON,
    TipoContattoDTOFromJSONTyped,
    TipoContattoDTOToJSON,
} from './TipoContattoDTO';

/**
 * 
 * @export
 * @interface ContattoDTO
 */
export interface ContattoDTO {
    /**
     * 
     * @type {number}
     * @memberof ContattoDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContattoDTO
     */
    idTipoContatto?: number;
    /**
     * 
     * @type {string}
     * @memberof ContattoDTO
     */
    valore?: string | null;
    /**
     * 
     * @type {TipoContattoDTO}
     * @memberof ContattoDTO
     */
    tipoContatto?: TipoContattoDTO;
}

/**
 * Check if a given object implements the ContattoDTO interface.
 */
export function instanceOfContattoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContattoDTOFromJSON(json: any): ContattoDTO {
    return ContattoDTOFromJSONTyped(json, false);
}

export function ContattoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContattoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idTipoContatto': !exists(json, 'idTipoContatto') ? undefined : json['idTipoContatto'],
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
        'tipoContatto': !exists(json, 'tipoContatto') ? undefined : TipoContattoDTOFromJSON(json['tipoContatto']),
    };
}

export function ContattoDTOToJSON(value?: ContattoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idTipoContatto': value.idTipoContatto,
        'valore': value.valore,
        'tipoContatto': TipoContattoDTOToJSON(value.tipoContatto),
    };
}

