import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi } from '../data/apis/CicliApi';

export function chiusuraCompensiDataSource( 
    idFiliera : number, 
    idCiclo: number
) {
    const cicliApi = new CicliApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return cicliApi.getChiusuraCompensi({ 
                idFiliera: idFiliera, 
                idCiclo:idCiclo 
            });
        },

        byKey: (key) => {
            return cicliApi.getChiusuraCompensiById({
                idFiliera:idFiliera, 
                idCiclo:idCiclo, 
                idCompenso: key
            });
        },

        insert: (values) => {
            return cicliApi.insertChiusuraCompenso({
                idFiliera: idFiliera,
                idCiclo:idCiclo,
                chiusuraCompensoCreateDTO: values
            });
        },

        update: (key, values) => {
            return cicliApi.updateChiusuraCompenso({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCompenso: key,
                chiusuraCompensoUpdateDTO: values
            });
        },

        remove: (key) => {
            return cicliApi.deleteChiusuraCompenso({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idCompenso: key
            });
        }
    });
};
