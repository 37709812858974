import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { AnagraficheApi } from "../data/apis";

export const fornitoriDataSource = new CustomStore({
  key: "id",
  loadMode: 'raw',
  load: (loadOptions) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);

    return razzeApi.getFornitori();
  },

  byKey: (key) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.getFornitoreById({ idFornitore: key });
  },

  insert: (values) => {

    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.insertFornitore({
      fornitoreCreateDTO: values
    });
  },

  update: (key, values) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);

    return razzeApi.updateFornitore({
      idFornitore: key,
      fornitoreUpdateDTO: values
    });
  },

  remove: (key) => {
    const razzeApi = new AnagraficheApi(apiConfiguration);
    return razzeApi.deleteFornitore({
      idFornitore: key
    });
  }
});