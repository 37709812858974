/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChiusuraDTO
 */
export interface ChiusuraDTO {
    /**
     * 
     * @type {number}
     * @memberof ChiusuraDTO
     */
    idCiclo?: number;
    /**
     * 
     * @type {Date}
     * @memberof ChiusuraDTO
     */
    data?: Date;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraDTO
     */
    allevatoreKg?: number;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraDTO
     */
    allevatoreImporto?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChiusuraDTO
     */
    autorizzato?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ChiusuraDTO
     */
    dataAutorizzazione?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChiusuraDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraDTO
     */
    idArxivar?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraDTO
     */
    conversioneIndice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ChiusuraDTO
     */
    dataFineCiclo?: Date | null;
}

/**
 * Check if a given object implements the ChiusuraDTO interface.
 */
export function instanceOfChiusuraDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChiusuraDTOFromJSON(json: any): ChiusuraDTO {
    return ChiusuraDTOFromJSONTyped(json, false);
}

export function ChiusuraDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChiusuraDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idCiclo': !exists(json, 'idCiclo') ? undefined : json['idCiclo'],
        'data': !exists(json, 'data') ? undefined : (new Date(json['data'])),
        'allevatoreKg': !exists(json, 'allevatoreKg') ? undefined : json['allevatoreKg'],
        'allevatoreImporto': !exists(json, 'allevatoreImporto') ? undefined : json['allevatoreImporto'],
        'autorizzato': !exists(json, 'autorizzato') ? undefined : json['autorizzato'],
        'dataAutorizzazione': !exists(json, 'dataAutorizzazione') ? undefined : (json['dataAutorizzazione'] === null ? null : new Date(json['dataAutorizzazione'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'idArxivar': !exists(json, 'idArxivar') ? undefined : json['idArxivar'],
        'conversioneIndice': !exists(json, 'conversioneIndice') ? undefined : json['conversioneIndice'],
        'dataFineCiclo': !exists(json, 'dataFineCiclo') ? undefined : (json['dataFineCiclo'] === null ? null : new Date(json['dataFineCiclo'])),
    };
}

export function ChiusuraDTOToJSON(value?: ChiusuraDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idCiclo': value.idCiclo,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'allevatoreKg': value.allevatoreKg,
        'allevatoreImporto': value.allevatoreImporto,
        'autorizzato': value.autorizzato,
        'dataAutorizzazione': value.dataAutorizzazione === undefined ? undefined : (value.dataAutorizzazione === null ? null : value.dataAutorizzazione.toISOString()),
        'note': value.note,
        'idArxivar': value.idArxivar,
        'conversioneIndice': value.conversioneIndice,
        'dataFineCiclo': value.dataFineCiclo === undefined ? undefined : (value.dataFineCiclo === null ? null : value.dataFineCiclo.toISOString()),
    };
}

