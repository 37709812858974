/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilieraCreateDTO,
  FilieraDTO,
  FilieraUpdateDTO,
  ProblemDetails,
  ReportRegistroCaricoScaricoDTO,
} from '../models';
import {
    FilieraCreateDTOFromJSON,
    FilieraCreateDTOToJSON,
    FilieraDTOFromJSON,
    FilieraDTOToJSON,
    FilieraUpdateDTOFromJSON,
    FilieraUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ReportRegistroCaricoScaricoDTOFromJSON,
    ReportRegistroCaricoScaricoDTOToJSON,
} from '../models';

export interface DeleteFilieraRequest {
    idFiliera: number;
}

export interface GetFilieraByIdRequest {
    idFiliera: number;
}

export interface GetFiliereRequest {
    showAll?: boolean;
}

export interface GetReportRegistroCaricoScaricoRequest {
    idFiliera: number;
    reportRegistroCaricoScaricoDTO?: ReportRegistroCaricoScaricoDTO;
}

export interface GetReportRiepilogoCapiRequest {
    idFiliera: number;
    anno: number;
}

export interface InsertFilieraRequest {
    filieraCreateDTO?: FilieraCreateDTO;
}

export interface UpdateFilieraRequest {
    idFiliera: number;
    filieraUpdateDTO?: FilieraUpdateDTO;
}

/**
 * 
 */
export class FiliereApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Filiera/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una filiera esistente
     */
    async deleteFilieraRaw(requestParameters: DeleteFilieraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling deleteFiliera.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Delete &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Elimina una filiera esistente
     */
    async deleteFiliera(requestParameters: DeleteFilieraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFilieraRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera una filiera per id
     */
    async getFilieraByIdRaw(requestParameters: GetFilieraByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilieraDTO>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getFilieraById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilieraDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Read &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Recupera una filiera per id
     */
    async getFilieraById(requestParameters: GetFilieraByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilieraDTO> {
        const response = await this.getFilieraByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte le filiere
     */
    async getFiliereRaw(requestParameters: GetFiliereRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilieraDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilieraDTOFromJSON));
    }

    /**
     * Elenco di tutte le filiere
     */
    async getFiliere(requestParameters: GetFiliereRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilieraDTO>> {
        const response = await this.getFiliereRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera il Registro di Carico e Scarico della Filiera per periodo
     */
    async getReportRegistroCaricoScaricoRaw(requestParameters: GetReportRegistroCaricoScaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getReportRegistroCaricoScarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Reports/RegistroCaricoScarico`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportRegistroCaricoScaricoDTOToJSON(requestParameters.reportRegistroCaricoScaricoDTO),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera il Registro di Carico e Scarico della Filiera per periodo
     */
    async getReportRegistroCaricoScarico(requestParameters: GetReportRegistroCaricoScaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportRegistroCaricoScaricoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recupera il Riepilogo Capi Annuale della Filiera per anno
     */
    async getReportRiepilogoCapiRaw(requestParameters: GetReportRiepilogoCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling getReportRiepilogoCapi.');
        }

        if (requestParameters.anno === null || requestParameters.anno === undefined) {
            throw new runtime.RequiredError('anno','Required parameter requestParameters.anno was null or undefined when calling getReportRiepilogoCapi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}/Reports/Riepilogo/{anno}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))).replace(`{${"anno"}}`, encodeURIComponent(String(requestParameters.anno))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Recupera il Riepilogo Capi Annuale della Filiera per anno
     */
    async getReportRiepilogoCapi(requestParameters: GetReportRiepilogoCapiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReportRiepilogoCapiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Insert </li></ul>
     * Crea una nuova filiera
     */
    async insertFilieraRaw(requestParameters: InsertFilieraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilieraDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilieraCreateDTOToJSON(requestParameters.filieraCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilieraDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Insert </li></ul>
     * Crea una nuova filiera
     */
    async insertFiliera(requestParameters: InsertFilieraRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilieraDTO> {
        const response = await this.insertFilieraRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Update &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una filiare esistente
     */
    async updateFilieraRaw(requestParameters: UpdateFilieraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idFiliera === null || requestParameters.idFiliera === undefined) {
            throw new runtime.RequiredError('idFiliera','Required parameter requestParameters.idFiliera was null or undefined when calling updateFiliera.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Filiere/{idFiliera}`.replace(`{${"idFiliera"}}`, encodeURIComponent(String(requestParameters.idFiliera))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilieraUpdateDTOToJSON(requestParameters.filieraUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Filiera/Update &#42;</li></ul><i>&#42; Permesso specifico per filiera</i>
     * Aggiorna una filiare esistente
     */
    async updateFiliera(requestParameters: UpdateFilieraRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateFilieraRaw(requestParameters, initOverrides);
    }

}
