import React, { useContext, useEffect, useState } from 'react';
import './home.scss';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { FilieraDTO, FiliereApi } from '../../data';
import { apiConfiguration } from '../../apiConfig';
import FilieraContext from '../../contexts/filieraContext';
import { LoadPanel } from 'devextreme-react/load-panel';

export default function Home() {
  const [filiere, setFiliere] = useState<FilieraDTO[] | null>(null);
  const { currentFiliera, setCurrentFiliera } = useContext(FilieraContext);

  useEffect(() => {
    const fetchData = async () => {
      var api = new FiliereApi(apiConfiguration);
      var filiere = await api.getFiliere();
      setFiliere(filiere);
    }

    fetchData()
      .catch(console.error);
  }, []);

  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings buttonContainer'} style={{ textAlign: "center" }}>

          {filiere != null &&
            <React.Fragment>
              {filiere.map(function (d, idx) {
                return (
                  <Button
                    key={idx}
                    text={d.ragioneSociale ?? ""}
                    type={d.id == currentFiliera.Id ? "default" : "normal"}
                    stylingMode="contained"
                    onClick={e => setCurrentFiliera({ Id: Number(d.id), Name: String(d.ragioneSociale) })}
                    className='buttonFiliera'
                  />
                )
              })}
            </React.Fragment>
          }

          {filiere == null &&
               <LoadPanel
                  position={{ of: '.buttonContainer' }}
                  visible={true}
                />
          }
        </div>
      </div>
    </React.Fragment>
  )
}
