/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TipoMangimeDTO } from './TipoMangimeDTO';
import {
    TipoMangimeDTOFromJSON,
    TipoMangimeDTOFromJSONTyped,
    TipoMangimeDTOToJSON,
} from './TipoMangimeDTO';

/**
 * 
 * @export
 * @interface ListinoMangimiDTO
 */
export interface ListinoMangimiDTO {
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiDTO
     */
    idFiliera?: number;
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiDTO
     */
    idTipoMangime?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListinoMangimiDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ListinoMangimiDTO
     */
    valore?: number;
    /**
     * 
     * @type {TipoMangimeDTO}
     * @memberof ListinoMangimiDTO
     */
    tipoMangime?: TipoMangimeDTO;
}

/**
 * Check if a given object implements the ListinoMangimiDTO interface.
 */
export function instanceOfListinoMangimiDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListinoMangimiDTOFromJSON(json: any): ListinoMangimiDTO {
    return ListinoMangimiDTOFromJSONTyped(json, false);
}

export function ListinoMangimiDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListinoMangimiDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idFiliera': !exists(json, 'idFiliera') ? undefined : json['idFiliera'],
        'idTipoMangime': !exists(json, 'idTipoMangime') ? undefined : json['idTipoMangime'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
        'tipoMangime': !exists(json, 'tipoMangime') ? undefined : TipoMangimeDTOFromJSON(json['tipoMangime']),
    };
}

export function ListinoMangimiDTOToJSON(value?: ListinoMangimiDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idFiliera': value.idFiliera,
        'idTipoMangime': value.idTipoMangime,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'valore': value.valore,
        'tipoMangime': TipoMangimeDTOToJSON(value.tipoMangime),
    };
}

