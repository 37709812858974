import { FileUploader } from "devextreme-react";
import { useEffect, useRef, useState } from "react";
import { msalInstance } from "../..";
import { loginRequest } from "../../authConfig";
import React from "react";

type UploaderPanelProps = {
  uploadUrl: string,
  onUploadCompleted?: () => void,
  allowedFileExtensions?: string[]
}

export default function UploaderPanel(params: UploaderPanelProps) {
  const [uploadHeader, setuploadHeader] = useState<any>(null);
  const uploader = useRef<any>(null);

  useEffect(() => {
    //console.log("ci passo");
    uploader.current.instance.reset();
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    }).then(function (response: { accessToken: string; }) {
      setuploadHeader({
        Authorization: 'Bearer ' + response.accessToken
      });
    });
  }, [params.uploadUrl]);

  return (
    <React.Fragment>
      {params.allowedFileExtensions && <span>File consentiti: {params.allowedFileExtensions}</span>}
      <FileUploader
        ref={uploader}
        name="file"
        uploadMode="instantly"
        uploadUrl={params.uploadUrl}
        uploadMethod="POST"
        uploadHeaders={uploadHeader}
        allowedFileExtensions={params.allowedFileExtensions}
        onUploaded={() => params.onUploadCompleted?.apply(null)}
      />
    </React.Fragment>
  );
}
