import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CategorieCaricoApi } from "../data/apis";

export function CategorieCaricoDataSource() {
  const categorieCaricoApi = new CategorieCaricoApi(apiConfiguration);

  return new CustomStore({
    key: "id",
    load: (loadOptions) => categorieCaricoApi.getCategorieCarico(),

    byKey: (key) => categorieCaricoApi.getCategoriaCaricoById({ id: key }),

    insert: (values) =>
      categorieCaricoApi.insertCategoriaCarico({
        categoriaCaricoCreateDTO: values
      }),

    update: (key, values) =>
      categorieCaricoApi.updateCategoriaCarico({
        id: key,
        categoriaCaricoUpdateDTO: values
      }),

    remove: (key) =>
      categorieCaricoApi.deleteCategoriaCarico({
        id: key
      })
  });
};