import React from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Popup,
  Form
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { TipiMangimiApi } from '../../../data';
import { apiConfiguration } from '../../../apiConfig';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { Item } from 'devextreme-react/form';

export default function Task() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Tipi mangimi</h2>

      <DataGrid
        className={'dx-card wide-card'}
        //dataSource={dataSource as any}
        dataSource={customDataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData)}}
        onDataErrorOccurred={dataGridErrorHandling}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Tipo mangime" showTitle={true} />
          <Form>
            <Item dataField="descrizione"/>
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <Column  type='buttons' />
        <Column dataField={'id'} width={90} hidingPriority={2} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column
          dataField={'descrizione'}
          sortIndex={0} 
          sortOrder="asc"
          caption={'Descrizione'}
          hidingPriority={8}
        />
      </DataGrid>
    </React.Fragment>
)}

const customDataSource = new CustomStore({
  key: "id",
  load: (loadOptions) => {
    const razzeApi = new TipiMangimiApi(apiConfiguration);
  
      return razzeApi.getTipiMangimi({mode: 'cors'});
  },

  byKey: (key) => {
    const razzeApi = new TipiMangimiApi(apiConfiguration);
      return razzeApi.getTipoMangimeById(key);
  },

  insert: (values) => {
    const razzeApi = new TipiMangimiApi(apiConfiguration);
    return razzeApi.insertTipoMangime({
      tipoMangimeCreateDTO: values
    });
  },

  update: (key, values) => {
    const razzeApi = new TipiMangimiApi(apiConfiguration);
    return razzeApi.updateTipoMangime({
      id: key,
      tipoMangimeUpdateDTO: values
    });
  },

   remove: (key) => {
    const razzeApi = new TipiMangimiApi(apiConfiguration);
    return razzeApi.deleteTipoMangime({
      id: key
    });
   }
});