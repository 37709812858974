/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NazioneCreateDTO,
  NazioneDTO,
  NazioneUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    NazioneCreateDTOFromJSON,
    NazioneCreateDTOToJSON,
    NazioneDTOFromJSON,
    NazioneDTOToJSON,
    NazioneUpdateDTOFromJSON,
    NazioneUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteNazioneRequest {
    id: string;
}

export interface GetNazioneByIdRequest {
    id: string;
}

export interface InsertNazioneRequest {
    nazioneCreateDTO?: NazioneCreateDTO;
}

export interface UpdateNazioneRequest {
    id: string;
    nazioneUpdateDTO?: NazioneUpdateDTO;
}

/**
 * 
 */
export class NazioniApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Nazione esistente
     */
    async deleteNazioneRaw(requestParameters: DeleteNazioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNazione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Nazioni/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Nazione esistente
     */
    async deleteNazione(requestParameters: DeleteNazioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNazioneRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera una nazione per id
     */
    async getNazioneByIdRaw(requestParameters: GetNazioneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NazioneDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNazioneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Nazioni/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NazioneDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una nazione per id
     */
    async getNazioneById(requestParameters: GetNazioneByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NazioneDTO> {
        const response = await this.getNazioneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte le Nazioni
     */
    async getNazioniRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NazioneDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Nazioni`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NazioneDTOFromJSON));
    }

    /**
     * Elenco di tutte le Nazioni
     */
    async getNazioni(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NazioneDTO>> {
        const response = await this.getNazioniRaw(initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di Nazione in anagrafica
     */
    async insertNazioneRaw(requestParameters: InsertNazioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NazioneDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Nazioni`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NazioneCreateDTOToJSON(requestParameters.nazioneCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NazioneDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea un nuovo tipo di Nazione in anagrafica
     */
    async insertNazione(requestParameters: InsertNazioneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NazioneDTO> {
        const response = await this.insertNazioneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di Nazione esistente
     */
    async updateNazioneRaw(requestParameters: UpdateNazioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNazione.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/Nazioni/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NazioneUpdateDTOToJSON(requestParameters.nazioneUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna un tipo di Nazione esistente
     */
    async updateNazione(requestParameters: UpdateNazioneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNazioneRaw(requestParameters, initOverrides);
    }

}
