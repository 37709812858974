import { msalInstance } from '.';
import { loginRequest } from './authConfig';
import { Configuration, ConfigurationParameters, ErrorContext, FetchParams, Middleware, ResponseContext } from './data';

// class APIMiddleware implements Middleware {
//     onError(context: ErrorContext): Promise<void | Response> {
//         console.log(context);
//         return Promise.resolve(context.response);
//     }
// }

const configParams: ConfigurationParameters = {
    basePath: (window as any).ENV.WebAPI,
    accessToken: async (s,scopes)=>{
      const account = msalInstance.getActiveAccount();
      if (!account) {
          throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }
  
      const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account
      });
  
      // const headers = new Headers();
      return 'Bearer ' + response.accessToken;
  },
//   middleware: [new APIMiddleware]
  };
  
  export const apiConfiguration = new Configuration(configParams);