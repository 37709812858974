import React, { useContext, useEffect, useRef } from 'react';
import PopupUploadFile, { UploadFilePopupHandle } from '../popupUploadFile/popupUploadFile';
import FilieraContext from '../../contexts/filieraContext';
import PopupDocumentAttachment, { DocumentAttachmentPopupHandle } from '../popupDocumentAttachment/popupDocumentAttachment';
import { apiConfiguration } from '../../apiConfig';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Lookup,
  Toolbar,
  Item,
  Button as GridButton
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

import { incubatoiDataSource } from '../../datasources/incubatoiDataSource';
import { dataGridErrorHandling } from '../../utils/errorHandling';
import { macelliDataSource } from '../../datasources/macelliDataSource';
import { mangimificiDataSource } from '../../datasources/mangimificiDataSource';
import PopupDocumento, { DocumentoPopupHandle } from '../popupDocumento/popupDocumento';
import { documentiDataSource } from '../../datasources/documentiDataSource';
import { CollapsablePanel } from '../collapsablePanel/CollapsablePanel';
import CicliListPanel from '../cicliListPanel/cicliListPanel';

// type Props = {
//   idCiclo: number,
//   tipo: string,
//   readonly?: boolean
// };

export default function AllevatoriDetailPanel(params: any) {
  const { currentFiliera } = useContext(FilieraContext);

  useEffect(() => {
    console.log(params.data.key);
   
}, []);

  return (
    <React.Fragment>
      <CicliListPanel idAllevatore={Number(params.data.key)}></CicliListPanel>
      {/* <CollapsablePanel title='Accasamenti' expanded={true}>
            {params.data.key}        
      </CollapsablePanel> */}
    </React.Fragment>
  );
}
