import React, { useContext, useRef, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/form';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { CarichiRiscontriDataSource } from '../../../datasources/CarichiRiscontriDataSource';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import { CategorieCaricoDataSource } from '../../../datasources/CategorieCaricoDataSource';

interface TableProps {
    idCiclo: number,
    idCarico: number,
    readonly?: boolean
}

export default function CarichiRiscontriTable(props: TableProps) {
    const { currentFiliera } = useContext(FilieraContext);

    return (
        <React.Fragment>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={CarichiRiscontriDataSource(currentFiliera.Id, props.idCiclo, props.idCarico)}
                showBorders={false}
                focusedRowEnabled={true}
                errorRowEnabled={false}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!props.readonly} allowDeleting={true}>
                    <Popup title="Nuovo Documento" showTitle={true} />
                    <Form labelMode='floating'>
                        <Item dataField="idTipoAnimale" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} isRequired={true} />
                        <Item dataField="idCategoriaCarico" editorType='dxSelectBox' isRequired={false} editorOptions={{ showClearButton: true }} />
                        <Item dataField="peso" editorType='dxNumberBox' isRequired={true} />
                        <Item dataField="capi" editorType='dxNumberBox' isRequired={true} />
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={props.readonly} />
                    <Button name="delete" disabled={props.readonly} />
                </Column>
                <Column dataField={'idTipoAnimale'} caption={'Tipo Animale'}>
                    <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr={"descrizione"} />
                </Column>
                <Column dataField={'peso'} caption={'Peso'} hidingPriority={8} />
                <Column dataField={'capi'} caption={'Capi'} hidingPriority={8} />
                <Column dataField={'idCategoriaCarico'} caption={'Categoria carico'}>
                    <Lookup dataSource={CategorieCaricoDataSource()} valueExpr="id" displayExpr={"descrizione"} />
                </Column>
            </DataGrid>
        </React.Fragment>
    );
};


