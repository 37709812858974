/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TipoMangimeDTO } from './TipoMangimeDTO';
import {
    TipoMangimeDTOFromJSON,
    TipoMangimeDTOFromJSONTyped,
    TipoMangimeDTOToJSON,
} from './TipoMangimeDTO';

/**
 * 
 * @export
 * @interface MovimentoMangimeDTO
 */
export interface MovimentoMangimeDTO {
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    idDocumento?: number;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    quantita?: number;
    /**
     * 
     * @type {string}
     * @memberof MovimentoMangimeDTO
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    valoreFixed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    idCapannone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MovimentoMangimeDTO
     */
    idTipoMangime?: number;
    /**
     * 
     * @type {TipoMangimeDTO}
     * @memberof MovimentoMangimeDTO
     */
    tipoMangime?: TipoMangimeDTO;
}

/**
 * Check if a given object implements the MovimentoMangimeDTO interface.
 */
export function instanceOfMovimentoMangimeDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovimentoMangimeDTOFromJSON(json: any): MovimentoMangimeDTO {
    return MovimentoMangimeDTOFromJSONTyped(json, false);
}

export function MovimentoMangimeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovimentoMangimeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idDocumento': !exists(json, 'idDocumento') ? undefined : json['idDocumento'],
        'quantita': !exists(json, 'quantita') ? undefined : json['quantita'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'valoreFixed': !exists(json, 'valoreFixed') ? undefined : json['valoreFixed'],
        'idCapannone': !exists(json, 'idCapannone') ? undefined : json['idCapannone'],
        'idTipoMangime': !exists(json, 'idTipoMangime') ? undefined : json['idTipoMangime'],
        'tipoMangime': !exists(json, 'tipoMangime') ? undefined : TipoMangimeDTOFromJSON(json['tipoMangime']),
    };
}

export function MovimentoMangimeDTOToJSON(value?: MovimentoMangimeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idDocumento': value.idDocumento,
        'quantita': value.quantita,
        'note': value.note,
        'valoreFixed': value.valoreFixed,
        'idCapannone': value.idCapannone,
        'idTipoMangime': value.idTipoMangime,
        'tipoMangime': TipoMangimeDTOToJSON(value.tipoMangime),
    };
}

