/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaricoMezzoDTO
 */
export interface CaricoMezzoDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CaricoMezzoDTO
     */
    autotreno?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaricoMezzoDTO
     */
    bilico?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CaricoMezzoDTO
     */
    motrice?: boolean;
}

/**
 * Check if a given object implements the CaricoMezzoDTO interface.
 */
export function instanceOfCaricoMezzoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CaricoMezzoDTOFromJSON(json: any): CaricoMezzoDTO {
    return CaricoMezzoDTOFromJSONTyped(json, false);
}

export function CaricoMezzoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaricoMezzoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autotreno': !exists(json, 'autotreno') ? undefined : json['autotreno'],
        'bilico': !exists(json, 'bilico') ? undefined : json['bilico'],
        'motrice': !exists(json, 'motrice') ? undefined : json['motrice'],
    };
}

export function CaricoMezzoDTOToJSON(value?: CaricoMezzoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autotreno': value.autotreno,
        'bilico': value.bilico,
        'motrice': value.motrice,
    };
}

