import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { showError } from "../../utils/errorHandling";
import Form, { EmptyItem, Label, SimpleItem } from "devextreme-react/form";
import { useScreenSize } from "../../utils/media-query";
import { ChiusuraSaveDTO, CicliApi } from "../../data";
import { apiConfiguration } from "../../apiConfig";
import FilieraContext from "../../contexts/filieraContext";
import ChiusuraCompensiPanel from "../chiusuraCompensiPanel/chiusuraCompensiPanel";
import ChiusuraExtraPanel from "../chiusuraExtraPanel/chiusuraExtraPanel";
import { Tabs } from "devextreme-react";
import ChiusuraVariazioneCapiPanel from "../chiusuraVariazioneCapiPanel/chiusuraVariazioneCapiPanel";

type Props = {
  onChanged?: () => void
}

export type ChiudiCicloPopupHandle = {
  show: (idCiclo: number) => void,
}

type state = {
  idCiclo: number | undefined,
  visible: boolean,
  datasource?: ChiusuraSaveDTO
}

const PopupChiudiCiclo: React.ForwardRefRenderFunction<ChiudiCicloPopupHandle, Props> = (props, ref) => {
  const tabs = ["Compensi", "Addebiti/Accrediti Extra", "Variazione Capi/Pesi"];
  const [currentTab, setCurrentTab] = useState<string>("Compensi");
  const [state, setState] = useState<state>({ idCiclo: undefined, visible: false, datasource: undefined });
  const { isXSmall, isSmall } = useScreenSize();
  const { currentFiliera } = useContext(FilieraContext);
  const form = useRef<any>(null);

  function hideInfo() {
    setState({ idCiclo: undefined, visible: false, datasource: undefined });
  }

  useImperativeHandle(ref, () => ({
    show(idCiclo) {
      var api = new CicliApi(apiConfiguration);

      api.getChiusuraDefault({
        idCiclo: idCiclo as number,
        idFiliera: currentFiliera.Id
      })
        .then(function (data) {
          setState({
            idCiclo: idCiclo,
            visible: true,
            datasource: data
          });
        })
        .catch(showError);
    }
  }));

  function saveData() {
    if (form.current.instance.validate().isValid) {
      var api = new CicliApi(apiConfiguration);
      var data = form.current.instance.option("formData") as ChiusuraSaveDTO;
      // console.log(data)
      api.saveChiusura({
        idCiclo: state.idCiclo as number,
        idFiliera: currentFiliera.Id,
        chiusuraSaveDTO: data
      })
        .then(function (data) {
          hideInfo();
          props.onChanged?.call(null);
        })
        .catch(function (ex) {
          showError(ex);
        })
    }
  }

  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      fullScreen={isXSmall || isSmall}
      onHidden={hideInfo}
      title="Chiusura ciclo"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Annulla',
          onClick: hideInfo,
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Salva',
          onClick: saveData,
        }}
      />

      <Form
        ref={form}
        formData={state.datasource}
        labelMode="floating"
        colCount={2}
      >
        <SimpleItem dataField="data" editorType="dxDateBox" isRequired={true} />
        <SimpleItem dataField="giacenzaFinale" editorType="dxNumberBox" isRequired={true} label={{ text: "Giacenza finale (Kg)" }} />
        <SimpleItem dataField="maggiorazioneUscite" editorOptions={{ showClearButton: true, mode: 'number' }}>
          <Label text="Maggiorazione uscite"></Label>
        </SimpleItem>
        <EmptyItem />
        <SimpleItem dataField="note" editorType="dxTextArea" colSpan={2} />


      </Form>
      {state.idCiclo &&
        <React.Fragment>
          <Tabs
            dataSource={tabs}
            selectedItem={currentTab}
            onSelectedItemChange={e => setCurrentTab(e)}
          />
          {currentTab === "Compensi" &&
            <ChiusuraCompensiPanel idCiclo={state.idCiclo}></ChiusuraCompensiPanel>
          }
          {currentTab === "Addebiti/Accrediti Extra" &&
            <ChiusuraExtraPanel idCiclo={state.idCiclo}></ChiusuraExtraPanel>
          }
          {currentTab === "Variazione Capi/Pesi" &&
            <ChiusuraVariazioneCapiPanel idCiclo={state.idCiclo}></ChiusuraVariazioneCapiPanel>
          }
        </React.Fragment>
      }
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupChiudiCiclo);
