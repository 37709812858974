import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, { Column, Editing, FilterRow, Form, Pager, Paging } from "devextreme-react/data-grid";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import { Item} from "devextreme-react/form";
import 'devextreme-react/text-area';
import { capannoniDataSource } from "../../datasources/capannoniDataSource";
import { useScreenSize } from "../../utils/media-query";

type Props = {}

export type CapannoniPopupHandle = {
  show: (idAllevatore: number, idAllevamento: number) => void,
}

type state = {
  idAllevatore: number | undefined,
  idAllevamento: number | undefined,
  visible: boolean,
  datasource: any
}

const PopupCapannoni: React.ForwardRefRenderFunction<CapannoniPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idAllevatore: undefined, idAllevamento: undefined, visible: false, datasource: null });
  const { isXSmall, isSmall } = useScreenSize();
  
  function hideInfo() {
    setState({ idAllevatore: undefined,idAllevamento: undefined, visible: false, datasource: null });
  }

  useImperativeHandle(ref, () => ({
    show(idAllevatore, idAllevamento ) {
      setState({
        idAllevatore: idAllevatore,
        idAllevamento: idAllevamento,
        visible: true,
        datasource: capannoniDataSource(idAllevatore, idAllevamento)
      });
    }
  }));

  function dataGridInitNewRow(e:any){
    e.data.doppioSilos=false;
  }


  return (<React.Fragment>

    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      fullScreen={isXSmall || isSmall}
      showTitle={true}
      onHidden={hideInfo}
      title="Capannoni"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={state.datasource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        errorRowEnabled={false}
        onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
        onDataErrorOccurred={dataGridErrorHandling}
        onInitNewRow={dataGridInitNewRow}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}>
          <Popup title="Allevamento" showTitle={true} />
          <Form colCount={2}>
            <Item dataField="numero" />
            <Item dataField="superficie" />
            <Item dataField="doppioSilos" />
          </Form>
        </Editing>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />

        <Column type='buttons' />
        <Column dataField={'id'} width={90} hidingPriority={1} allowEditing={false} visible={false} showEditorAlways={false} />
        <Column dataField={'numero'} />
        <Column dataField={'superficie'} dataType="number"/>
        <Column dataField={'doppioSilos'} dataType="boolean"/>
      </DataGrid>
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupCapannoni);
