import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetDocumentiRequest, InsertDocumentoRequest, UpdateDocumentoRequest, DeleteDocumentoRequest, GetDocumentiByTipoRequest } from '../data/apis/DocumentiApi';


export function DocumentiMangimeDataSource(idFiliera: number, idCiclo: number) {
    const documentiApi = new DocumentiApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return documentiApi.getDocumentiMangime({
                idFiliera: idFiliera,
                idCiclo: idCiclo
            });
        },

        byKey: (key) => {
            return documentiApi.getDocumentoMangimeById({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key
            });
        },

        insert: (values) => {
            return documentiApi.insertDocumentoMangime({
                idCiclo: idCiclo,
                idFiliera: idFiliera,
                documentoMangimeCreateDTO: values
            });
        },

        update: (key, values) => {
            return documentiApi.updateDocumentoMangime({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key,
                documentoMangimeUpdateDTO: values
            });
        },

        remove: (key) => {
            return documentiApi.deleteDocumentoMangime({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: key
            });
        }
    });
};

