/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CategoriaCaricoCreateDTO,
  CategoriaCaricoDTO,
  CategoriaCaricoUpdateDTO,
  ProblemDetails,
} from '../models';
import {
    CategoriaCaricoCreateDTOFromJSON,
    CategoriaCaricoCreateDTOToJSON,
    CategoriaCaricoDTOFromJSON,
    CategoriaCaricoDTOToJSON,
    CategoriaCaricoUpdateDTOFromJSON,
    CategoriaCaricoUpdateDTOToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface DeleteCategoriaCaricoRequest {
    id: number;
}

export interface GetCategoriaCaricoByIdRequest {
    id: number;
}

export interface GetCategorieCaricoRequest {
    showAll?: boolean;
}

export interface InsertCategoriaCaricoRequest {
    categoriaCaricoCreateDTO?: CategoriaCaricoCreateDTO;
}

export interface UpdateCategoriaCaricoRequest {
    id: number;
    categoriaCaricoUpdateDTO?: CategoriaCaricoUpdateDTO;
}

/**
 * 
 */
export class CategorieCaricoApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Categoria di Carico esistente
     */
    async deleteCategoriaCaricoRaw(requestParameters: DeleteCategoriaCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCategoriaCarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/CategorieCarico/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Elimina una Categoria di Carico esistente
     */
    async deleteCategoriaCarico(requestParameters: DeleteCategoriaCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCategoriaCaricoRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera una categoria di carico per id
     */
    async getCategoriaCaricoByIdRaw(requestParameters: GetCategoriaCaricoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoriaCaricoDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCategoriaCaricoById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/CategorieCarico/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoriaCaricoDTOFromJSON(jsonValue));
    }

    /**
     * Recupera una categoria di carico per id
     */
    async getCategoriaCaricoById(requestParameters: GetCategoriaCaricoByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoriaCaricoDTO> {
        const response = await this.getCategoriaCaricoByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutti i tipi di categorie di carico
     */
    async getCategorieCaricoRaw(requestParameters: GetCategorieCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoriaCaricoDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/CategorieCarico`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoriaCaricoDTOFromJSON));
    }

    /**
     * Elenco di tutti i tipi di categorie di carico
     */
    async getCategorieCarico(requestParameters: GetCategorieCaricoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoriaCaricoDTO>> {
        const response = await this.getCategorieCaricoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea una nuova categoria carico in anagrafica
     */
    async insertCategoriaCaricoRaw(requestParameters: InsertCategoriaCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoriaCaricoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/CategorieCarico`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoriaCaricoCreateDTOToJSON(requestParameters.categoriaCaricoCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoriaCaricoDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Crea una nuova categoria carico in anagrafica
     */
    async insertCategoriaCarico(requestParameters: InsertCategoriaCaricoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoriaCaricoDTO> {
        const response = await this.insertCategoriaCaricoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna una categoria di carico esistente
     */
    async updateCategoriaCaricoRaw(requestParameters: UpdateCategoriaCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCategoriaCarico.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Tabelle/CategorieCarico/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CategoriaCaricoUpdateDTOToJSON(requestParameters.categoriaCaricoUpdateDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Tabelle/Write </li></ul>
     * Aggiorna una categoria di carico esistente
     */
    async updateCategoriaCarico(requestParameters: UpdateCategoriaCaricoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCategoriaCaricoRaw(requestParameters, initOverrides);
    }

}
