import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { PermissionsApi } from "../data/apis";

export const permissionsDataSource = new CustomStore({
    key: "permissionId",
    loadMode : 'raw',
    load: (loadOptions) => {
      const permissionsApi = new PermissionsApi(apiConfiguration);
        return permissionsApi.getPermissions();
    },
  
    // byKey: (key) => {
    //   const permissionsApi = new PermissionsApi(apiConfiguration);
    //     return permissionsApi.getTipoMangimeById({id:key});
    // },
  
    // insert: (values) => {
    //   const permissionsApi = new PermissionsApi(apiConfiguration);
    //   return permissionsApi.insertTipoMangime({
    //     tipoMangimeCreateDTO: values
    //   });
    // },
  
    // update: (key, values) => {
    //   const permissionsApi = new PermissionsApi(apiConfiguration);
    //   return permissionsApi.updateTipoMangime({
    //     id: key,
    //     tipoMangimeUpdateDTO: values
    //   });
    // },
  
    //  remove: (key) => {
    //   const permissionsApi = new PermissionsApi(apiConfiguration);
    //   return permissionsApi.deleteTipoMangime({
    //     id: key
    //   });
    //  }
    update: (key, values) => { return Promise.resolve();}
  });