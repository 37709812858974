import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { CicliApi } from '../data/apis/CicliApi';

export function chiusuraExtraDataSource( 
    idFiliera : number, 
    idCiclo: number
) {
    const cicliApi = new CicliApi(apiConfiguration);

    return new CustomStore({
        key: "id",
        load: (loadOptions) => {
            return cicliApi.getChiusuraExtra({ 
                idFiliera: idFiliera, 
                idCiclo:idCiclo 
            });
        },

        byKey: (key) => {
            return cicliApi.getChiusuraExtraById({
                idFiliera:idFiliera, 
                idCiclo:idCiclo, 
                idExtra: key
            });
        },

        insert: (values) => {
            return cicliApi.insertChiusuraExtra({
                idFiliera: idFiliera,
                idCiclo:idCiclo,
                chiusuraExtraCreateDTO: values
            });
        },

        update: (key, values) => {
            return cicliApi.updateChiusuraExtra({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idExtra: key,
                chiusuraExtraUpdateDTO: values
            });
        },

        remove: (key) => {
            return cicliApi.deleteChiusuraExtra({
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idExtra: key
            });
        }
    });
};
