// FilieraContext.tsx
import React from "react";

export interface IFiliera {
  Id: number,
  Name: string
}

interface IFilieraContext {
  currentFiliera: IFiliera;
  setCurrentFiliera: (value: IFiliera) => void;
}

const FilieraContext = React.createContext<IFilieraContext>({
  currentFiliera: { Id:-1, Name:""},
  setCurrentFiliera: () => { },
});

export default FilieraContext;
