/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionDTO } from './PermissionDTO';
import {
    PermissionDTOFromJSON,
    PermissionDTOFromJSONTyped,
    PermissionDTOToJSON,
} from './PermissionDTO';
import type { UtenteFilieraPermissionDTO } from './UtenteFilieraPermissionDTO';
import {
    UtenteFilieraPermissionDTOFromJSON,
    UtenteFilieraPermissionDTOFromJSONTyped,
    UtenteFilieraPermissionDTOToJSON,
} from './UtenteFilieraPermissionDTO';

/**
 * 
 * @export
 * @interface UtenteDTO
 */
export interface UtenteDTO {
    /**
     * 
     * @type {string}
     * @memberof UtenteDTO
     */
    userId?: string | null;
    /**
     * 
     * @type {Array<PermissionDTO>}
     * @memberof UtenteDTO
     */
    permissions?: Array<PermissionDTO> | null;
    /**
     * 
     * @type {Array<UtenteFilieraPermissionDTO>}
     * @memberof UtenteDTO
     */
    filieraPermissions?: Array<UtenteFilieraPermissionDTO> | null;
}

/**
 * Check if a given object implements the UtenteDTO interface.
 */
export function instanceOfUtenteDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UtenteDTOFromJSON(json: any): UtenteDTO {
    return UtenteDTOFromJSONTyped(json, false);
}

export function UtenteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UtenteDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionDTOFromJSON)),
        'filieraPermissions': !exists(json, 'filieraPermissions') ? undefined : (json['filieraPermissions'] === null ? null : (json['filieraPermissions'] as Array<any>).map(UtenteFilieraPermissionDTOFromJSON)),
    };
}

export function UtenteDTOToJSON(value?: UtenteDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionDTOToJSON)),
        'filieraPermissions': value.filieraPermissions === undefined ? undefined : (value.filieraPermissions === null ? null : (value.filieraPermissions as Array<any>).map(UtenteFilieraPermissionDTOToJSON)),
    };
}

