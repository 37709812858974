/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TipoAnimaleDTO } from './TipoAnimaleDTO';
import {
    TipoAnimaleDTOFromJSON,
    TipoAnimaleDTOFromJSONTyped,
    TipoAnimaleDTOToJSON,
} from './TipoAnimaleDTO';

/**
 * 
 * @export
 * @interface ListinoAnimaliDTO
 */
export interface ListinoAnimaliDTO {
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliDTO
     */
    idFiliera?: number;
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliDTO
     */
    idTipoAnimale?: number;
    /**
     * 
     * @type {Date}
     * @memberof ListinoAnimaliDTO
     */
    inizioValidita?: Date;
    /**
     * 
     * @type {number}
     * @memberof ListinoAnimaliDTO
     */
    valore?: number;
    /**
     * 
     * @type {TipoAnimaleDTO}
     * @memberof ListinoAnimaliDTO
     */
    tipoAnimale?: TipoAnimaleDTO;
}

/**
 * Check if a given object implements the ListinoAnimaliDTO interface.
 */
export function instanceOfListinoAnimaliDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListinoAnimaliDTOFromJSON(json: any): ListinoAnimaliDTO {
    return ListinoAnimaliDTOFromJSONTyped(json, false);
}

export function ListinoAnimaliDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListinoAnimaliDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idFiliera': !exists(json, 'idFiliera') ? undefined : json['idFiliera'],
        'idTipoAnimale': !exists(json, 'idTipoAnimale') ? undefined : json['idTipoAnimale'],
        'inizioValidita': !exists(json, 'inizioValidita') ? undefined : (new Date(json['inizioValidita'])),
        'valore': !exists(json, 'valore') ? undefined : json['valore'],
        'tipoAnimale': !exists(json, 'tipoAnimale') ? undefined : TipoAnimaleDTOFromJSON(json['tipoAnimale']),
    };
}

export function ListinoAnimaliDTOToJSON(value?: ListinoAnimaliDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idFiliera': value.idFiliera,
        'idTipoAnimale': value.idTipoAnimale,
        'inizioValidita': value.inizioValidita === undefined ? undefined : (value.inizioValidita.toISOString()),
        'valore': value.valore,
        'tipoAnimale': TipoAnimaleDTOToJSON(value.tipoAnimale),
    };
}

