/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChiusuraExtraDTO
 */
export interface ChiusuraExtraDTO {
    /**
     * 
     * @type {number}
     * @memberof ChiusuraExtraDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraExtraDTO
     */
    sequenza?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChiusuraExtraDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChiusuraExtraDTO
     */
    importo?: number;
}

/**
 * Check if a given object implements the ChiusuraExtraDTO interface.
 */
export function instanceOfChiusuraExtraDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChiusuraExtraDTOFromJSON(json: any): ChiusuraExtraDTO {
    return ChiusuraExtraDTOFromJSONTyped(json, false);
}

export function ChiusuraExtraDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChiusuraExtraDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sequenza': !exists(json, 'sequenza') ? undefined : json['sequenza'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'importo': !exists(json, 'importo') ? undefined : json['importo'],
    };
}

export function ChiusuraExtraDTOToJSON(value?: ChiusuraExtraDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sequenza': value.sequenza,
        'descrizione': value.descrizione,
        'importo': value.importo,
    };
}

