/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  UtenteAddFilieraPermissionDTO,
  UtenteAddPermissionDTO,
  UtenteCreateDTO,
  UtenteDTO,
  UtenteRemoveFilieraPermissionDTO,
  UtenteRemovePermissionDTO,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UtenteAddFilieraPermissionDTOFromJSON,
    UtenteAddFilieraPermissionDTOToJSON,
    UtenteAddPermissionDTOFromJSON,
    UtenteAddPermissionDTOToJSON,
    UtenteCreateDTOFromJSON,
    UtenteCreateDTOToJSON,
    UtenteDTOFromJSON,
    UtenteDTOToJSON,
    UtenteRemoveFilieraPermissionDTOFromJSON,
    UtenteRemoveFilieraPermissionDTOToJSON,
    UtenteRemovePermissionDTOFromJSON,
    UtenteRemovePermissionDTOToJSON,
} from '../models';

export interface AddFilieraPermissionRequest {
    id: string;
    utenteAddFilieraPermissionDTO?: UtenteAddFilieraPermissionDTO;
}

export interface AddPermissionRequest {
    id: string;
    utenteAddPermissionDTO?: UtenteAddPermissionDTO;
}

export interface DeleteUtenteRequest {
    id: string;
}

export interface GetUtenteByIdRequest {
    id: string;
}

export interface InsertUtenteRequest {
    utenteCreateDTO?: UtenteCreateDTO;
}

export interface RemoveFilieraPermissionRequest {
    id: string;
    utenteRemoveFilieraPermissionDTO?: UtenteRemoveFilieraPermissionDTO;
}

export interface RemovePermissionRequest {
    id: string;
    utenteRemovePermissionDTO?: UtenteRemovePermissionDTO;
}

/**
 * 
 */
export class UtentiApi extends runtime.BaseAPI {

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Aggiunge una nuova permission di filiera
     */
    async addFilieraPermissionRaw(requestParameters: AddFilieraPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addFilieraPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}/FilieraPermissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteAddFilieraPermissionDTOToJSON(requestParameters.utenteAddFilieraPermissionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Aggiunge una nuova permission di filiera
     */
    async addFilieraPermission(requestParameters: AddFilieraPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addFilieraPermissionRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Aggiunge una nuova permission
     */
    async addPermissionRaw(requestParameters: AddPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}/Permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteAddPermissionDTOToJSON(requestParameters.utenteAddPermissionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Aggiunge una nuova permission
     */
    async addPermission(requestParameters: AddPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPermissionRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Elimina una utente esistente
     */
    async deleteUtenteRaw(requestParameters: DeleteUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUtente.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Elimina una utente esistente
     */
    async deleteUtente(requestParameters: DeleteUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUtenteRaw(requestParameters, initOverrides);
    }

    /**
     * Recupera un utente per id
     */
    async getUtenteByIdRaw(requestParameters: GetUtenteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUtenteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Recupera un utente per id
     */
    async getUtenteById(requestParameters: GetUtenteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.getUtenteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Elenco di tutte gli utenti
     */
    async getUtentiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UtenteDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UtenteDTOFromJSON));
    }

    /**
     * Elenco di tutte gli utenti
     */
    async getUtenti(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UtenteDTO>> {
        const response = await this.getUtentiRaw(initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Crea un nuovo utente
     */
    async insertUtenteRaw(requestParameters: InsertUtenteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UtenteDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteCreateDTOToJSON(requestParameters.utenteCreateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UtenteDTOFromJSON(jsonValue));
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Crea un nuovo utente
     */
    async insertUtente(requestParameters: InsertUtenteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UtenteDTO> {
        const response = await this.insertUtenteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Rimuove una permission di filiera
     */
    async removeFilieraPermissionRaw(requestParameters: RemoveFilieraPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeFilieraPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}/FilieraPermissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteRemoveFilieraPermissionDTOToJSON(requestParameters.utenteRemoveFilieraPermissionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Rimuove una permission di filiera
     */
    async removeFilieraPermission(requestParameters: RemoveFilieraPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeFilieraPermissionRaw(requestParameters, initOverrides);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Rimuove una permission
     */
    async removePermissionRaw(requestParameters: RemovePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["https://api.gestionale.avigel.it/access_as_user"]);
        }

        const response = await this.request({
            path: `/Security/Utenti/{id}/Permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UtenteRemovePermissionDTOToJSON(requestParameters.utenteRemovePermissionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permessi richiesti:<ul><li>Security/Write </li></ul>
     * Rimuove una permission
     */
    async removePermission(requestParameters: RemovePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePermissionRaw(requestParameters, initOverrides);
    }

}
