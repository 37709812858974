/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaricoDTO
 */
export interface CaricoDTO {
    /**
     * 
     * @type {number}
     * @memberof CaricoDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CaricoDTO
     */
    idCiclo?: number;
    /**
     * 
     * @type {Date}
     * @memberof CaricoDTO
     */
    data?: Date;
    /**
     * 
     * @type {string}
     * @memberof CaricoDTO
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaricoDTO
     */
    tipiAnimali?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaricoDTO
     */
    numeroCapi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaricoDTO
     */
    kgCaricati?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaricoDTO
     */
    pesoMedio?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaricoDTO
     */
    fineCarico?: boolean;
}

/**
 * Check if a given object implements the CaricoDTO interface.
 */
export function instanceOfCaricoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CaricoDTOFromJSON(json: any): CaricoDTO {
    return CaricoDTOFromJSONTyped(json, false);
}

export function CaricoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaricoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idCiclo': !exists(json, 'idCiclo') ? undefined : json['idCiclo'],
        'data': !exists(json, 'data') ? undefined : (new Date(json['data'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'tipiAnimali': !exists(json, 'tipiAnimali') ? undefined : json['tipiAnimali'],
        'numeroCapi': !exists(json, 'numeroCapi') ? undefined : json['numeroCapi'],
        'kgCaricati': !exists(json, 'kgCaricati') ? undefined : json['kgCaricati'],
        'pesoMedio': !exists(json, 'pesoMedio') ? undefined : json['pesoMedio'],
        'fineCarico': !exists(json, 'fineCarico') ? undefined : json['fineCarico'],
    };
}

export function CaricoDTOToJSON(value?: CaricoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idCiclo': value.idCiclo,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'note': value.note,
        'tipiAnimali': value.tipiAnimali,
        'numeroCapi': value.numeroCapi,
        'kgCaricati': value.kgCaricati,
        'pesoMedio': value.pesoMedio,
        'fineCarico': value.fineCarico,
    };
}

