import React, { useContext } from 'react';
import { Item } from 'devextreme-react/form';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { MovimentiAccasamentiDataSource } from '../../../datasources/movimentiAccasamentiDataSource';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import { dataGridErrorHandling } from '../../../utils/errorHandling';
import { capannoniDataSource } from '../../../datasources/capannoniDataSource';
import { CicloDTO } from '../../../data';

type AccasamentiTableProps = {
    ciclo: CicloDTO,
    idDocumento: number,
    onChanged: ()=>void,
    readonly?: boolean
};

const AccasamentiTable: React.FC<AccasamentiTableProps> = (params) => {
    const { currentFiliera } = useContext(FilieraContext);

    // useEffect(() => {
    //     console.log("Sono in Accasamenti table");

    //     console.log(idCiclo, idDocumento, currentIdFiliera);

    //     console.log(data);
    // }, []);

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={MovimentiAccasamentiDataSource(currentFiliera.Id, Number(params.ciclo.id), params.idDocumento)}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                errorRowEnabled={false}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
                onRowRemoved={params.onChanged}
                onRowUpdated={params.onChanged}
                onRowInserted={params.onChanged}
                onDataErrorOccurred={dataGridErrorHandling}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!params.readonly} allowDeleting={true}>
                    <Form labelMode='floating'>
                        <Item itemType="group" caption="Informazioni accasamento" colCount={3} colSpan={2}>
                            <Item dataField="quantita" editorType='dxNumberBox'/>
                            <Item dataField="note" colSpan={2} />
                            <Item dataField="idTipoAnimale" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                            <Item dataField="costoVaccino" />
                            <Item dataField="valoreFixed" />
                            <Item dataField="idCapannone" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                        </Item>
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={params.readonly}/>
                    <Button name="delete" disabled={params.readonly}/>
                </Column>
                <Column dataField={'id'} width={90} allowEditing={false} visible={false} />
                <Column dataField={'quantita'} caption={'Quantità'} hidingPriority={8} />
                <Column dataField={'note'} caption={'Note'} hidingPriority={6} />
                <Column dataField={'idTipoAnimale'} caption={'Tipo Animale'} visible={false} hidingPriority={7}>
                    <Lookup dataSource={TipiAnimaliDataSource()} valueExpr="id" displayExpr={"descrizione"}/>
                </Column>
                <Column dataField={'idCapannone'} caption={'Capannone'} >
                    <Lookup dataSource={capannoniDataSource(Number(params.ciclo.allevamento?.idAllevatore),Number(params.ciclo.idAllevamento))} valueExpr="id" displayExpr={"numero"} allowClearing={true}/>
                </Column>
                <Column dataField={'valoreFixed'} caption={'Forzatura Prezzo'} dataType='number' />
                 
                <Column dataField={'costoVaccino'} caption={'Costo Vaccino'} hidingPriority={5} />
                <Column dataField={'tipoAnimale.codice'} caption={'Codice Tipo Animale'} hidingPriority={4} />
                <Column dataField={'tipoAnimale.descrizione'} caption={'Descrizione Tipo Animale'} hidingPriority={3} />
                <Column dataField={'tipoAnimale.maschio'} caption={'Maschio'} hidingPriority={2} />
                <Column dataField={'tipoAnimale.razza.descrizione'} caption={'Descrizione Razza'} hidingPriority={1} />
            </DataGrid>
        </React.Fragment>
    );
};

export default AccasamentiTable;