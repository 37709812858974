import React, { useImperativeHandle, useState } from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import { useScreenSize } from "../../utils/media-query";
import ReportContoEconomicoViewer from "../reportContoEconomicoViewer/reportContoEconomicoViewer";

// pdfjs.GlobalWorkerOptions.workerPort = new Worker(
//   'node_modules/pdfjs-dist/build/pdf.worker.entry.js',
// );

type Props = {}

export type ReportContoEconomicoPopupHandle = {
  show: (idFiliera: number, idCiclo: number) => void,
}

type state = {
  idCiclo: number | undefined,
  visible: boolean,
  idFiliera: number | undefined
}

// const fileToBase64 = (file: File | Blob): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//      resolve(reader.result as string);
//     };

//     reader.readAsDataURL(file);
//     reader.onerror = reject;
//   });

const PopupReportContoEconomico: React.ForwardRefRenderFunction<ReportContoEconomicoPopupHandle, Props> = (props, ref) => {
  const [state, setState] = useState<state>({ idCiclo: undefined, visible: false, idFiliera: undefined });
  const { isXSmall, isSmall } = useScreenSize();

  function hideInfo() {
    //setState((prevState) => ({ ...prevState, visible: false }));
    setState({ idCiclo: undefined, visible: false, idFiliera: undefined });
  }

  useImperativeHandle(ref, () => ({
    async show(idFiliera, idCiclo) {
console.log(idFiliera, idCiclo);
      setState({
        idCiclo: idCiclo,
        visible: true,
        idFiliera: idFiliera
      });
    }
  }));


  return (<React.Fragment>
    <Popup
      visible={state.visible}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      fullScreen={isXSmall || isSmall}
      onHidden={hideInfo}
      title="Conto economico ciclo"
      container=".dx-viewport"
    >
      <Position
        at="center"
        my="center"
        of=""
        collision="fit"
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        options={{
          text: 'Chiudi',
          onClick: hideInfo,
        }}
      />
      {state.visible &&
        <ReportContoEconomicoViewer key={Date.now()} idFiliera={state.idFiliera} idCiclo={state.idCiclo} />
      }
    </Popup>

  </React.Fragment>
  );
}

export default React.forwardRef(PopupReportContoEconomico);
