import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Button
} from 'devextreme-react/data-grid';
import React, { useRef } from "react";
import { utentiDataSource } from "../../datasources/utentiDataSource";
import { dataGridErrorHandling } from "../../utils/errorHandling";
import PopupPermissions, { PermissionsPopupHandle } from '../../components/popupPermissions/popupPermissions';

export default function Utenti() {
    const permissionsRef = useRef<PermissionsPopupHandle>(null);

    function openPermissionPopup(e: any) {
        console.log(e);
        permissionsRef.current?.show(e.row.key);
      }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Utenti</h2>

            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={utentiDataSource}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) { e.newData = Object.assign({}, e.oldData, e.newData) }}
                onDataErrorOccurred={dataGridErrorHandling}
            >
                <Editing
                    mode="popup"
                    allowUpdating={false}
                    allowAdding={true}
                    allowDeleting={true}>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type='buttons' >
                    <Button name="delete" />
                     <Button hint="Permessi" icon="lock" onClick={openPermissionPopup} />
       {/*   <Button hint="Allevamenti" icon="home" onClick={openAllevamentiPopup} /> */}
                </Column>
                <Column dataField={'userId'} />
            </DataGrid>
            <PopupPermissions ref={permissionsRef}></PopupPermissions>
        </React.Fragment>
    )
}
