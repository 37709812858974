import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { DocumentiApi, GetMovimentiTrattamentiRequest, UpdateMovimentoTrattamentoRequest, DeleteMovimentoTrattamentoRequest, InsertMovimentoTrattamentoRequest } from '../data/apis/DocumentiApi';

import { TipiTrattamentiApi } from "../data/apis";

const documentiApi = new DocumentiApi(apiConfiguration);

export function MovimentiTrattamentiDataSource(
    idFiliera: number,
    idCiclo: number,
    idDocumento: number
) {
    return new CustomStore({
        key: "id",
        loadMode : 'raw',
        load: (loadOptions) => {
            var accasamentoRequest: GetMovimentiTrattamentiRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento
            };
            return documentiApi.getMovimentiTrattamenti(accasamentoRequest);
        },

        insert: (values) => {
            var InsertAccasamentoRequest: InsertMovimentoTrattamentoRequest = {

                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                movimentoTrattamentoCreateDTO: values
            };

            return documentiApi.insertMovimentoTrattamento(InsertAccasamentoRequest);
        },

        update: (key, values) => {
            var UpdateAccasamentoRequest: UpdateMovimentoTrattamentoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key,
                movimentoTrattamentoUpdateDTO: values
            };

            return documentiApi.updateMovimentoTrattamento(UpdateAccasamentoRequest);
        },

        remove: (key) => {
            var deleteAccasamentoRequest: DeleteMovimentoTrattamentoRequest = {
                idFiliera: idFiliera,
                idCiclo: idCiclo,
                idDocumento: idDocumento,
                id: key
            };

            return documentiApi.deleteMovimentoTrattamento(deleteAccasamentoRequest);
        }
    });
};

