import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { TipiMangimiApi } from "../data/apis";

export const tipiMangimiDataSource = new CustomStore({
    key: "id",
    loadMode : 'raw',
    load: (loadOptions) => {
      const tipiMangimiApi = new TipiMangimiApi(apiConfiguration);
        return tipiMangimiApi.getTipiMangimi();
    },
  
    byKey: (key) => {
      const tipiMangimiApi = new TipiMangimiApi(apiConfiguration);
        return tipiMangimiApi.getTipoMangimeById({id:key});
    },
  
    insert: (values) => {
      const tipiMangimiApi = new TipiMangimiApi(apiConfiguration);
      return tipiMangimiApi.insertTipoMangime({
        tipoMangimeCreateDTO: values
      });
    },
  
    update: (key, values) => {
      const tipiMangimiApi = new TipiMangimiApi(apiConfiguration);
      return tipiMangimiApi.updateTipoMangime({
        id: key,
        tipoMangimeUpdateDTO: values
      });
    },
  
     remove: (key) => {
      const tipiMangimiApi = new TipiMangimiApi(apiConfiguration);
      return tipiMangimiApi.deleteTipoMangime({
        id: key
      });
     }
  });