import React, { useEffect, useRef, useState } from 'react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import './reportRegistroCaricoScaricoViewer.scss';
import { apiConfiguration } from '../../apiConfig';
import { CicliApi } from '../../data/apis/CicliApi';
import { FiliereApi } from '../../data';
import { showError } from '../../utils/errorHandling';

export default function ReportRegistroCaricoViewer(props) {
    const [loading, setLoading] = useState(false);
    const [instance, setInstance] = useState();
    const viewer = useRef();

    useEffect(() => {
        console.log(loading);
        if (!instance) {
            setLoading(true);

            WebViewer(
                {
                    path: '/webviewer/lib',
                    licenseKey: 'ElFQV4k0W9MNBaN9iTyT'
                },
                viewer.current,
            ).then((instance) => { 
                setInstance(instance);
                const theme = instance.UI.Theme;
                instance.UI.setTheme(theme.DARK);
                instance.UI.setPrintQuality(5);
                instance.UI.useEmbeddedPrint(true);
                instance.UI.setLanguage('it');
                var FitMode = instance.UI.FitMode;
                instance.UI.setFitMode(FitMode.FitWidth);
                
                instance.addEventListener('documentLoaded', () => {
                    setLoading(false);
                  });
            });
        }
    }, []);

    useEffect(() => {
        if (instance) {
            if (props.idFiliera) {
                var api = new FiliereApi(apiConfiguration);
                api.getReportRegistroCaricoScarico({
                    idFiliera: props.idFiliera,
                    reportRegistroCaricoScaricoDTO: {
                        dataDa: props.dataDa,
                        dataA: props.dataA,
                        provvisorio: props.provvisorio
                    }
                }).then((report) => {
                    instance.UI.loadDocument(report,
                        {
                            extension: 'pdf',
                            onError: function (e) { console.error(e); },
                            documentId: props.idFiliera
                        });
                }).catch((error)=>{
                    showError(error);
                });
            }
            else {
                instance.UI.closeDocument().then(function () {
                    console.log('Document is closed');
                });
            }
        }
    }, [props, instance]);

    return (
        <div>
            {loading &&
                <div style={{ position:"relative", bottom:0, top:0, left:0,right:0, height:'auto', width:'auto', verticalAlign: 'middle', textAlign: 'center' }}>
                    <LoadIndicator id="medium-indicator" />
                </div>
            }
            <div className="webviewer" ref={viewer} hidden={loading}></div>
        </div>
    )
}
