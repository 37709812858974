/* tslint:disable */
/* eslint-disable */
/**
 * Gestionale.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RazzaDTO } from './RazzaDTO';
import {
    RazzaDTOFromJSON,
    RazzaDTOFromJSONTyped,
    RazzaDTOToJSON,
} from './RazzaDTO';

/**
 * 
 * @export
 * @interface TipoAnimaleDTO
 */
export interface TipoAnimaleDTO {
    /**
     * 
     * @type {number}
     * @memberof TipoAnimaleDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TipoAnimaleDTO
     */
    codice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TipoAnimaleDTO
     */
    descrizione?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TipoAnimaleDTO
     */
    idRazza?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TipoAnimaleDTO
     */
    maschio?: boolean;
    /**
     * 
     * @type {RazzaDTO}
     * @memberof TipoAnimaleDTO
     */
    razza?: RazzaDTO;
}

/**
 * Check if a given object implements the TipoAnimaleDTO interface.
 */
export function instanceOfTipoAnimaleDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TipoAnimaleDTOFromJSON(json: any): TipoAnimaleDTO {
    return TipoAnimaleDTOFromJSONTyped(json, false);
}

export function TipoAnimaleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TipoAnimaleDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'codice': !exists(json, 'codice') ? undefined : json['codice'],
        'descrizione': !exists(json, 'descrizione') ? undefined : json['descrizione'],
        'idRazza': !exists(json, 'idRazza') ? undefined : json['idRazza'],
        'maschio': !exists(json, 'maschio') ? undefined : json['maschio'],
        'razza': !exists(json, 'razza') ? undefined : RazzaDTOFromJSON(json['razza']),
    };
}

export function TipoAnimaleDTOToJSON(value?: TipoAnimaleDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'codice': value.codice,
        'descrizione': value.descrizione,
        'idRazza': value.idRazza,
        'maschio': value.maschio,
        'razza': RazzaDTOToJSON(value.razza),
    };
}

