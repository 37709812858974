export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Cicli',
    path: '/cicli',
    icon: 'refresh'
  },
  {
    text: 'Anagrafiche',
    icon: 'folder',
    isExpanded: true,
    items: [
      {
        text: 'Allevatori',
        path: '/anagrafiche/allevatori'
      },
      {
        text: 'Incubatoi',
        path: '/anagrafiche/incubatoi'
      },
      {
        text: 'Mangimifici',
        path: '/anagrafiche/mangimifici'
      },
      {
        text: 'Macelli',
        path: '/anagrafiche/macelli'
      },
      {
        text: 'Tecnici',
        path: '/anagrafiche/tecnici'
      },
      {
        text: 'Fornitori',
        path: '/anagrafiche/fornitori'
      }
    ]
  },
  {
    text: 'Registri',
    icon: 'print',
    isExpanded: true,
    items: [
      {
        text: 'Riepilogo capi',
        path: '/registri/riepilogo'
      },
      {
        text: 'Registro carico/scarico',
        path: '/registri/registro'
      }
    ]
  },
  {
    text: 'Listini',
    icon: 'folder',
    isExpanded: true,
    items: [
      {
        text: 'Listini Animali',
        path: '/listini/animali'
      },
      {
        text: 'Listini Mangimi',
        path: '/listini/mangimi'
      },
      {
        text: 'Listini Pulcini',
        path: '/listini/pulcini'
      },
      {
        text: 'Quote riparto',
        path: '/listini/conversioni'
      }
    ]
  },
  {
    text: 'Tabelle generiche',
    icon: 'folder',
    items: [
      {
        text: 'Razze',
        path: '/tabelle/razze'
      },
      {
        text: 'Tipi trattamenti',
        path: '/tabelle/tipitrattamenti'
      },
      {
        text: 'Tipi mangimi',
        path: '/tabelle/tipimangimi'
      },
      {
        text: 'Tipi contatti',
        path: '/tabelle/tipicontatti'
      },
      {
        text: 'Tipi animali',
        path: '/tabelle/tipianimali'
      },
      {
        text: 'Nazioni',
        path: '/tabelle/nazioni'
      }
    ]
  },

  {
    text: 'Permessi',
    icon: 'lock',
    isExpanded: false,
    items: [
      {
        text: 'Utenti',
        path: '/utenti'
      }
    ]
  },
  {
    text: 'Analisi',
    icon: 'datatrending',
    isExpanded: false,
    items: [
      {
        text: 'Carichi',
        path: '/bi/carichi'
      },
      {
        text: 'Progressivi',
        path: '/bi/chiusure'
      },
      {
        text: 'Medie',
        path: '/bi/medie'
      }
    ]
  }
  // ,
  // {
  //   text: 'Sviluppo',
  //   icon: 'clock',
  //   isExpanded: false,
  //   items: [
  //     {
  //       text: 'Test componenti',
  //       path: '/test'
  //     }
      
  //   ]
  // }
];
