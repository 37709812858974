import CustomStore from "devextreme/data/custom_store";
import { apiConfiguration } from "../apiConfig";
import { PagamentiApi, GetPagamentiRequest, GetPagamentoByIdRequest, InsertPagamentoRequest, UpdatePagamentoRequest, DeletePagamentoRequest } from "../data/apis";

export function pagamentiDataSource(idFiliera: number, idCiclo: number){
  return new CustomStore({
    key: "id",
    loadMode: 'raw',
    load: (loadOptions) => {
      const pagamentiApi = new PagamentiApi(apiConfiguration);
      const request: GetPagamentiRequest = {
        idFiliera: idFiliera,
        idCiclo: idCiclo
    };

      return pagamentiApi.getPagamenti(request);
    },

    byKey: (key) => {
      const pagamentiApi = new PagamentiApi(apiConfiguration);
      const request: GetPagamentoByIdRequest = {
        id: key,
        idFiliera: idFiliera,
        idCiclo: idCiclo
      };
      return pagamentiApi.getPagamentoById(request);
    },

    insert: (values) => {

      const pagamentiApi = new PagamentiApi(apiConfiguration);
      const request: InsertPagamentoRequest = {
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        pagamentoCreateDTO: values
      };
      return pagamentiApi.insertPagamento(request);
    },

    update: (key, values) => {
      const pagamentiApi = new PagamentiApi(apiConfiguration);
      const request: UpdatePagamentoRequest = {
        id: key,
        idFiliera: idFiliera,
        idCiclo: idCiclo,
        pagamentoUpdateDTO: values
      };
      return pagamentiApi.updatePagamento(request);
    },

    remove: (key) => {
      const pagamentiApi = new PagamentiApi(apiConfiguration);
      const request: DeletePagamentoRequest = {
        id: key,
        idFiliera: idFiliera,
        idCiclo: idCiclo
      };
      return pagamentiApi.deletePagamento(request);
    }
  })
};