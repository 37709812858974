import React, { useContext, useRef, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/form';
import FilieraContext from '../../../contexts/filieraContext';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    Popup,
    Form,
    Button,
    Lookup
} from 'devextreme-react/data-grid';
import { MovimentiMacelliDataSource } from '../../../datasources/movimentiMacelliDataSource';
import { dataGridErrorHandling, showError } from '../../../utils/errorHandling';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';
import { TipiAnimaliDataSource } from '../../../datasources/tipiAnimaliDataSource';
import { DocumentiApi } from '../../../data/apis/DocumentiApi';
import { apiConfiguration } from '../../../apiConfig';
import { CicloDTO, DocumentoDTO } from '../../../data';
import { capannoniDataSource } from '../../../datasources/capannoniDataSource';

interface TableProps {
    ciclo: CicloDTO,
    idCarico: number,
    idDocumento: number,
    onChanged: () => void,
    readonly?: boolean
}

export default function MacelliTable(params: TableProps) {
    const { currentFiliera } = useContext(FilieraContext);
    const [formData, setFormData] = useState<DocumentoDTO>();

    useEffect(() => {
        const api = new DocumentiApi(apiConfiguration);
            api.getDocumentoById({ idFiliera: currentFiliera.Id, idCiclo: Number(params.ciclo.id), idDocumento: params.idDocumento })
                .then(function (data) {
                    setFormData(data);
                })
                .catch(showError);
       
    }, [currentFiliera, params]);

    function grid_InitNewRow(e:InitNewRowEvent<any>){
        e.data.dataMacellazione=formData?.dataOperazione;
    }

    return (
        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                //dataSource={dataSource as any}
                dataSource={MovimentiMacelliDataSource(currentFiliera.Id, Number(params.ciclo.id), params.idCarico, params.idDocumento)}
                showBorders={false}
                focusedRowEnabled={true}
                errorRowEnabled={false}
                defaultFocusedRowIndex={0}
                defaultSelectionFilter=""
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowUpdating={function (e) {
                    e.newData = Object.assign({}, e.oldData, e.newData);
                }}
                onRowRemoved={params.onChanged}
                onRowUpdated={params.onChanged}
                onRowInserted={params.onChanged}
                onDataErrorOccurred={dataGridErrorHandling}
                onInitNewRow={grid_InitNewRow}
            >
                <Editing mode="popup" allowUpdating={true} allowAdding={!params.readonly} allowDeleting={true}>
                    <Form labelMode='floating'>
                        <Item dataField="dataMacellazione" />
                        <Item dataField="idTipoAnimale" isRequired={true} />
                        <Item dataField="quantita" isRequired={true} />
                        <Item dataField="capi" isRequired={true} />
                        <Item dataField="valoreFixed" />
                        <Item dataField="idCapannone" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} />
                        <Item dataField="note" colSpan={2} editorType='dxTextArea' />
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />

                <Column type="buttons">
                    <Button name="edit" disabled={params.readonly} />
                    <Button name="delete" disabled={params.readonly} />
                </Column>
                <Column dataField={'dataMacellazione'} caption={'Data macellazione'} dataType='date' />
                <Column dataField={'idTipoAnimale'} caption={'Tipo Animale'}>
                    <Lookup dataSource={TipiAnimaliDataSource(Number(params.ciclo.id))} valueExpr="id" displayExpr="descrizione" />
                </Column>
                <Column dataField={'idCapannone'} caption={'Capannone'} >
                    <Lookup dataSource={capannoniDataSource(Number(params.ciclo.allevamento?.idAllevatore),Number(params.ciclo.idAllevamento))} valueExpr="id" displayExpr={"numero"} allowClearing={true}/>
                </Column>
                <Column dataField={'quantita'} caption={'Quantità in Kg'} dataType='number' />
                <Column dataField={'capi'} caption={'Capi'} dataType='number' />
                <Column dataField={'peso'} caption={'Peso medio'} dataType='number' />
                <Column dataField={'note'} caption={'Note'} />
                <Column dataField={'valoreFixed'} caption={'Forzatura Prezzo'} dataType='number' />
            </DataGrid>
        </React.Fragment>
    );
};


